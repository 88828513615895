import nengi from "nengi"

class PlayerPickUpItem {
	constructor(a) {
		this.a = a
	}
}

PlayerPickUpItem.protocol = {
	a: nengi.Boolean
}

export default PlayerPickUpItem
