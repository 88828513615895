<template>
	<div class="equipment-slots">
		<ItemSlot
			size="small"
			:has-item="!!laser"
			:is-active="false"
			:item-image-data="laserImageData">
		</ItemSlot>
		<ItemSlot
			size="small"
			:has-item="hasScope"
			:is-active="false"
			:item-image-data="scopeImageData">
		</ItemSlot>
		<ItemSlot
			label='F'
			size="small"
			:has-item="!!fragGrenades"
			:is-active="false"
			:item-image-data="fragGrenadeImageData"
			:quantity="fragGrenades">
		</ItemSlot>
		<ItemSlot
			label='G'
			size="small"
			:has-item="!!smokeGrenades"
			:is-active="false"
			:item-image-data="smokeGrenadeImageData"
			:quantity="smokeGrenades">
		</ItemSlot>
		<ItemSlot
			label='H'
			size="small"
			:has-item="!!resGrenades"
			:is-active="false"
			:item-image-data="resGrenadeImageData"
			:quantity="resGrenades">
		</ItemSlot>
		<ItemSlot
			label='C'
			size="small"
			:has-item="!!molotovGrenades"
			:is-active="false"
			:item-image-data="molotovGrenadeImageData"
			:quantity="molotovGrenades">
		</ItemSlot>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import parcelImageAdapter from "./parcelImageAdapterWeaponsItems"
import ItemSlot from "./ItemSlot"
import gameConstants from "../../common/balance/gameConstants"
const {
	ZOOM_LEVEL_DEFAULT,
	ZOOM_LEVEL_2X,
	ZOOM_LEVEL_4X,
	ZOOM_LEVEL_8X,
} = gameConstants

const REDDOT_NAME = 'reddot'
const FRAGGRENADE_NAME = 'fragGrenade'
const SMOKEGRENADE_NAME = 'smokeGrenade'
const RESGRENADE_NAME ='resGrenade'
const MOLOTOVGRENADE_NAME = 'molotov'
const SCOPE_2X_NAME = 'twoxscope'
const SCOPE_4X_NAME = 'fourxscope'
const SCOPE_8X_NAME = 'eightxscope'

// this (GSD) sucks because the client is only getting the new scope's zoom level, no index/name for the scope
// so look up the scope by zoom level
const scopeNames = {}
scopeNames[`${ZOOM_LEVEL_2X}`] = SCOPE_2X_NAME
scopeNames[`${ZOOM_LEVEL_4X}`] = SCOPE_4X_NAME
scopeNames[`${ZOOM_LEVEL_8X}`] = SCOPE_8X_NAME

export default {
	name: 'EquipmentSlots',
	components: {
		ItemSlot
	},
	computed: {
		...mapGetters([
			'scope',
			'laser',
			'fragGrenades',
			'smokeGrenades',
			'resGrenades',
			'molotovGrenades'
		]),
		laserImageData() {
			return parcelImageAdapter(REDDOT_NAME)
		},
		scopeImageData() {
			return parcelImageAdapter(scopeNames[this.scope] || SCOPE_4X_NAME)
		},
		hasScope() {
			return this.scope != ZOOM_LEVEL_DEFAULT
		},
		fragGrenadeImageData() {
			return parcelImageAdapter(FRAGGRENADE_NAME)
		},
		smokeGrenadeImageData() {
			return parcelImageAdapter(SMOKEGRENADE_NAME)
		},
		resGrenadeImageData() {
			return parcelImageAdapter(RESGRENADE_NAME)
		},
		molotovGrenadeImageData() {
			return parcelImageAdapter(MOLOTOVGRENADE_NAME)
		}
	},
}
</script>

<style lang="scss" scoped>
.equipment-slots {
	display: flex;
	justify-content: center;
	margin-bottom: -16px;
}
</style>
