import nengi from "nengi"
import SAT from "sat"
import GAME_CONSTANTS from "../../common/balance/gameConstants"
import mathUtils from "../../common/misc/mathUtils"

class Corpse {
	constructor(skin, x, y, fx, fy, force, pinned, targetX, targetY, projectileSpeed) {
		this.skin = skin
		this.x = x
		this.y = y
		this.isCorpse = true

		this.vx = fx // -Math.cos(this.rotation)
		this.vy = fy // -Math.sin(this.rotation)
		this.momentum = force

		this.collider = new SAT.Circle(
			new SAT.Vector(this.x, this.y),
			GAME_CONSTANTS.PLAYER.COLLIDER_RADIUS
		)

		this.pinned = pinned
		this.targetX = targetX
		this.targetY = targetY

		let distanceToTarget = mathUtils.getDistance(x, y, targetX, targetY)
		this.timeToDest = distanceToTarget / projectileSpeed
		this.timeElapsed = 0
		this.originalX = this.x
		this.originalY = this.y
	}

	update(delta) {
		if(this.pinned) {
			this.timeElapsed += delta
			let distTraveled = Math.min(this.timeElapsed / this.timeToDest, 1)
			this.x = this.originalX + distTraveled * (this.targetX - this.originalX)
			this.y = this.originalY + distTraveled * (this.targetY - this.originalY)
		}
		else {
			this.x += this.vx * this.momentum * delta
			this.y += this.vy * this.momentum * delta
			this.momentum *= Math.pow(0.02, delta)
	
			this.collider.pos.x = this.x
			this.collider.pos.y = this.y
		}
		
	}
}

Corpse.protocol = {
	x: nengi.Float64,
	y: nengi.Float64,
	skin: nengi.String,
	pinned: nengi.Boolean
	// rotation: nengi.Float64
}

export default Corpse
