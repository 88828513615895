const TO_DEGREES_COEF = 0.0174533
const RELOAD_FUMBLE_PENALTY = 0.75
const RELOAD_GOOD_BONUS = 2

class Weapon {
	constructor(config) {
		this.config = config
		// weapon friendly name
		this.name = config.name || "Unnamed Weapon"
		this.canonicalName = config.canonicalName || "BaseWeapon"

		// a 0-255 unique identifier for this type of weapon
		this.index = config.index

		// image filename on the client
		this.image = config.image

		this.spawnWeighting = config.spawnWeighting || 100

		// how far from the center of the player do the bullets and muzzle flash appear
		this.barrelLength = config.barrelLength

		// how far a bullet travels
		this.bulletTravelDistance = config.bulletTravelDistance

		// delay between shots, in seconds
		this.cooldown = config.cooldown || 0.5

		// escalating cooldown, for the minigun
		this.escalatingCooldown = config.escalatingCooldown
		if (this.escalatingCooldown) {
			this.cooldownCap = config.cooldownCap
			this.cooldownEscalationTime = config.cooldownEscalationTime
			this.cooldownAccumulator = 0
		}

		this.grenadeProjectiles = config.grenadeProjectiles
		if (this.grenadeProjectiles) {
			this.grenadeProjectile = config.grenadeProjectile
			this.grenadeDistanceVariance = config.grenadeDistanceVariance
			this.grenadeAngleVariance = config.grenadeAngleVariance
		}

		this.kickAmount = config.kick

		this.moveSlowModifier = config.moveSlowModifier
		this.moveSlowDuration = config.moveSlowDuration

		this.accumulator = 0

		this._currentAmmo = 0
		this.clipSize = config.clipSize || 15

		this.isFiring = false

		this.isReloading = false
		this.reloadAccumulator = 0
		this.reloadDuration = config.reloadDuration || 1

		this.pelletCount = config.pelletCount // || 1
		this.pelletSpread = config.pelletSpread // || 0.05

		// increases when fired, decays over time
		this.recoilAccumulator = 0
		this.recoilMax = config.recoilMax || Math.PI
		this.recoilStrength = config.recoilStrength || 1

		this.shotsUntilMaxRecoil = config.shotsUntilMaxRecoil || 3
		this.recoilRecoveryPeriod = config.recoilRecoveryPeriod || 1

		this.usageTime = config.usageTime || 2.00

		this.activeStart = config.activeStart || 0.2
		this.activeEnd = config.activeEnd || 0.8
		this.goodEnoughStart = config.goodEnoughStart || config.activeStart * 0.85
		this.goodEnoughEnd = config.goodEnoughEnd || config.activeEnd * 1.18

		this.reloadable = config.reloadable
		this.instantSpeedProjectile = config.instantSpeedProjectile

		this.reloadCallback = null
		this.progressCb = null

		this.reloadState = null // 'fumble', 'good'

		this.triedToFireThisFrame = false

		// keeps track if active reload has been attempted (can only try it once per reload)
		this.activeReloadAttempted = false

		this.lastFired = Date.now()
		this.lastFiredDry = Date.now()

		this.itemUnavailableText = config.itemUnavailableText
		this.overwrittenFlairLifetime = config.overwrittenFlairLifetime

		this.bulletImage = config.bulletImage ? config.bulletImage : "rifle_bullet1.png"
		this.customBulletScale = config.customBulletScale
		this.dontScaleBulletOverTime = config.dontScaleBulletOverTime
		this.bulletLifeAfterHit = config.bulletLifeAfterHit
		this.pinsTarget = config.pinsTarget
		this.customProjectilePivot = config.customProjectilePivot

		this.shiftProjectileY = config.shiftProjectileY
		this.predictFromBarrelTip = config.predictFromBarrelTip
		this.hasVisibleProjectile = config.hasVisibleProjectile
	}

	set currentAmmo(value) {
		//console.log('set currentAmmo', value)
		//if (value > this._currentAmmo && value !== this.clipSize) {
		//	throw 'yo!dw'
		//}
		this._currentAmmo = value
	}

	get currentAmmo() {
		return this._currentAmmo
	}

	checkCanFire(epsilon) {
		const diff = this.effectiveCooldown() - this.accumulator - epsilon

		if (this.isReloading) {
			//console.log('still reloading')
			return { canFire: false, reason: "reloading" }
		}

		// This matters for the minigun, it stays "spun up" even if it's not actually firing
		this.triedToFireThisFrame = true

		if (this.currentAmmo <= 0) {
			//console.log('out of ammo')
			return { canFire: false, reason: "out of ammo" }
		}

		if (diff > 0) {
			//if (typeof window === 'undefined') {
			//console.log('weapon fired too early',  this.effectiveCooldown() - this.accumulator)
			//}
			//console.log('weapon fired too early',  this.effectiveCooldown() - this.accumulator)
			return { canFire: false, reason: "on cooldown" }
		}
		return { canFire: true }
	}

	outOfAmmo() {
		return this.currentAmmo == 0
	}

	dryFire() {
		this.lastFiredDry = Date.now()
	}

	canDryFire() {
		return Date.now() > this.lastFiredDry + 250
	}

	effectiveCooldown() {
		if (this.escalatingCooldown) {
			const cd = this.cooldownAccumulator / this.cooldownEscalationTime
			return (this.cooldown * (1 - cd)) + (this.cooldownCap * cd)
		}
		else {
			return this.cooldown
		}
	}

	resetEffectiveCooldown() {
		if(this.escalatingCooldown) 
			this.cooldownAccumulator = 0
	}

	startReload(
		optionalReloadCompleteCallback,
		progressCb,
		activeReloadFailCb,
		activeReloadSuccessCb,
		activeReloadGoodEnoughCb
	) {
		// console.log('startReload invoked', optionalReloadCompleteCallback, progressCb)
		if (!this.reloadable) {
			return false
		}

		if (this.currentAmmo === this.clipSize) {
			// prevents reloading when at full ammo
			return false
		}

		this.isFiring = false

		if (!this.isReloading) {
			// not reloading? start reloading!
			this.isReloading = true
			this.reloadCallback = optionalReloadCompleteCallback
			this.progressCb = progressCb
			// console.log('is reloading')
			return true
		} else {
			if (this.activeReloadAttempted) {
				return false
			}
			this.activeReloadAttempted = true
			let progress = this.reloadAccumulator / this.reloadDuration
			if (progress >= this.activeStart && progress <= this.activeEnd) {
				/* checking SWEET SPOT reload */
				// console.log('active reload success')
				this.completeReload()
				if (activeReloadSuccessCb) {
					activeReloadSuccessCb()
				}
				return false
			} else {
				/* checking GOOD ENOUGH reload */
				if (
					progress >= this.goodEnoughStart &&
					progress <= this.goodEnoughEnd
				) {
					this.reloadState = "good"
					if (activeReloadGoodEnoughCb) {
						activeReloadGoodEnoughCb()
					}
				} else {
					/* FUMBLE reload */
					this.reloadState = "fumble"
					if (activeReloadFailCb) {
						activeReloadFailCb()
					}
				}
				return false
			}
		}
	}

	tryFire(epsilon, clicked, ammoCallback) {
		this.triedToFireThisFrame = true
		let canFireResult = this.checkCanFire(epsilon)

		if (canFireResult) {
			if (!canFireResult.canFire) {
				if (clicked && (canFireResult.reason === 'reloading' || canFireResult.reason === 'out of ammo')) {
					const startedReload = this.startReload()
					//if (startedReload) {
					//	console.log('starting a reload!')
					//}
				}
				// can't shoot, return result which contains a reason
				return canFireResult
			} else {
				if (clicked || this.isFiring) {
					this.isFiring = true
					// can fire, append the weapon and return
					this.accumulator = 0
					this.currentAmmo--

					if (ammoCallback) {
						ammoCallback(this.currentAmmo, this.clipSize)
					}

					// apply the recoil to the NEXT frame
					// this is important so that the recoil caused by a shot does not affect that very same shot
					setTimeout(() => {
						this.recoilAccumulator += 1 / this.shotsUntilMaxRecoil
						this.lastFired = Date.now()
						if (this.recoilAccumulator > this.recoilMax * TO_DEGREES_COEF) {
							this.recoilAccumulator = this.recoilMax * TO_DEGREES_COEF
						}
					}, 0)

					canFireResult.weapon = this
				} else {
					canFireResult.canFire = false
					canFireResult.reason = "misc"
				}
				return canFireResult
			}
		}
	}

	interruptReload() {
		this.isReloading = false
		this.reloadAccumulator = 0
		this.activeReloadAttempted = false
		if (this.reloadCallback) {
			this.reloadCallback()
		}

	}

	completeReload() {
		this.currentAmmo = this.clipSize
		this.isReloading = false
		this.activeReloadAttempted = false
		this.reloadAccumulator = 0
		this.reloadState = null
		if (this.reloadCallback) {
			this.reloadCallback()
		}
	}

	update(delta) {
		// console.log(delta)
		this.accumulator += delta
		//console.log('acc', this.accumulator)
		//console.log('acc', this.accumulator, 'delta', delta)
		if (Date.now() > this.lastFired + this.effectiveCooldown() * 1000) {
			// console.log('recoil', this.recoilAccumulator)
			this.recoilAccumulator -= 1 / this.recoilRecoveryPeriod * delta
		}

		if (this.triedToFireThisFrame && this.isFiring) {
			this.cooldownAccumulator += delta
			if (this.cooldownAccumulator > this.cooldownEscalationTime) {
				this.cooldownAccumulator = this.cooldownEscalationTime
			}
		} else {
			this.cooldownAccumulator -= delta
			if (this.cooldownAccumulator < 0) {
				this.cooldownAccumulator = 0
			}

			this.isFiring = false
		}
		this.triedToFireThisFrame = false

		if (this.recoilAccumulator < 0) {
			this.recoilAccumulator = 0
		}
		if (this.isReloading) {
			// console.log(this.isReloading, this.reloadAccumulator, this.reloadDuration)
			let coef = 1.0
			if (this.reloadState === "fumble") {
				coef = RELOAD_FUMBLE_PENALTY
			} else if (this.reloadState === "good") {
				coef = RELOAD_GOOD_BONUS
			}
			this.reloadAccumulator += delta * coef
			if (this.progressCb) {
				this.progressCb(this.reloadAccumulator / this.reloadDuration)
			}
			if (this.reloadAccumulator >= this.reloadDuration) {
				this.completeReload()
			}
		}
	}
}

export default Weapon
