<template>
  <div class="disconnected-panel">🔌 DISCONNECTED 🔌</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.disconnected-panel {
  margin: 0 auto;
  width: 500px;
  height: 200px;
  line-height: 200px;
  text-align: center;
  color: white;
  font-size: 36px;
  background-color: rgba(60, 60, 60, 0.5);
  pointer-events: none;
  user-select: none;
}
</style>
