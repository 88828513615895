import ParticleAttribute from "./ParticleAttribute"



class SpawnEmitterAttribute extends ParticleAttribute {
    constructor(attributeBlueprint) {
        super(attributeBlueprint)
        this.attributeBlueprint = attributeBlueprint
        this.emitterToAdd = this.attributeBlueprint.emitterObj
    }

    die(particle) {
        super.die()
        particle.renderer.createEmitter(particle.x + particle.emitter.x, particle.y + particle.emitter.y, this.emitterToAdd)
    }
}

export default SpawnEmitterAttribute