import ProjectileGraphics from "../entity/Projectile"

export default ({ renderer }) => {
	return {
		create: ({ data }) => {
			const entity = new ProjectileGraphics(data)

			renderer.entities.set(entity.id, entity)
			renderer.middleground.addChild(entity)

			return entity
		},
		delete: ({ id }) => {
			renderer.deleteEntity(id)
		},
		watch: {
		}
	}
}