import nengi from "nengi"

class P7DA84105F9F54B2D20C66308619D78D {
	constructor(mouseDown, mouseClicked, x, y, w, a, s, d, aim, r, delta, one, two, three, four, five, space, spacePressed) {
		this.mouseDown = mouseDown
		this.mouseClicked = mouseClicked
		this.x = x
		this.y = y
		this.w = w
		this.a = a
		this.s = s
		this.d = d
		this.aim = aim
		this.r = r
		this.delta = delta
		this.one = one
		this.two = two
		this.three = three
		this.four = four
		this.five = five
		this.space = space
		this.spacePressed = spacePressed
	}
}

P7DA84105F9F54B2D20C66308619D78D.protocol = {
	mouseDown: nengi.Boolean,
	mouseClicked: nengi.Boolean,
	x: nengi.Float32,
	y: nengi.Float32,
	w: nengi.Boolean,
	a: nengi.Boolean,
	s: nengi.Boolean,
	d: nengi.Boolean,
	r: nengi.Boolean,
	one: nengi.Boolean,
	two: nengi.Boolean,
	three: nengi.Boolean,
	four: nengi.Boolean,
	five: nengi.Boolean,
	space: nengi.Boolean,
	spacePressed: nengi.Boolean,
	aim: nengi.Float32,
	delta: nengi.Float32,
}

export default P7DA84105F9F54B2D20C66308619D78D
