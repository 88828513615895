export default (tiledMap, layerName, tileIds) => {
	let layer = tiledMap.layers.find(layer => {
		return layer.name === layerName
	})
	if (!layer) {
		throw new Error(
			"Unable to parse tiled map data for requested object layer. Query:",
			layerName,
			tileIds
		)
	}
	return layer.objects.map(obj => {
		let type = tileIds[obj.gid]
		if (type) {
			return {
				id: obj.id,
				x: obj.x,
				y: obj.y,
				type: type
			}
		}
	})
}
