import nengi from "nengi"

class ChangeMap {
	constructor(name) {
		this.name = name
	}
}

ChangeMap.protocol = {
	name: nengi.UTF8String
}

export default ChangeMap
