class CharacterAffect {
	constructor(options) {
		this.affectType = "null"
		this.durationMs = 1000
		this.tag = ""
		// how often tickFn fires
		this.tickRateMs = 1000
		this.tickFn = null
		this.affectOffFn = null
		this._durationMsCounter = 0
		Object.assign(this, options)
	}

	tick(delta) {
		let prevTick = Math.floor(this._durationMsCounter / this.tickRateMs)
		this._durationMsCounter += delta * 1000
		if (
			this.tickFn && prevTick < Math.floor(this._durationMsCounter / this.tickRateMs)
		) {
			this.tickFn(this, delta)
		}
		if (this._durationMsCounter >= this.durationMs) {
			setTimeout(() => {
				this.affectOff()
			}, 1)
			return false
		}
		return true
	}

	affectOff() {
		if (this.affectOffFn) {
			return this.affectOffFn()
		}
	}
}

export default CharacterAffect
