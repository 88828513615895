// provides a version-aware interface / wrapper around interaction with the map jsons
import convertMap from "./convertMap"
import parseItemSpawns from "./parseItemSpawns"
import parsePlayerSpawns from "./parsePlayerSpawns"

import zTownConvert from "./ztown/convertMap"
import zTownItem from "./ztown/parseItemSpawns"
import zTownPlayer from "./ztown/parsePlayerSpawns"



class MapInterface {
	constructor(mapJson, version) {
		this.mapJson = mapJson
		this.version = version

		if (version === "ztown") {
			this.collisionGrid = zTownConvert(mapJson)
			this.itemSpawns = zTownItem(mapJson)
			this.playerSpawns = zTownPlayer(mapJson)
		} else {
			this.collisionGrid = convertMap(mapJson)
			this.itemSpawns = parseItemSpawns(mapJson)
			this.playerSpawns = parsePlayerSpawns(mapJson)
		}
	}
}

export default MapInterface
