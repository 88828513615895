import { Container, Sprite, Texture } from "pixi.js-legacy"

class BloodEffect extends Container {
	constructor(x, y) {
		super()
		this.x = x
		this.y = y
		this.isComplete = false
		this.animationFrame = 1
		this.accumulator = 0

		this.bloodSprite = new Sprite.from("blood001.png")
		this.bloodSprite.anchor.x = 0.5
		this.bloodSprite.anchor.y = 0.5
		this.bloodSprite.scale.x = 0.5
		this.bloodSprite.scale.y = 0.5
		this.addChild(this.bloodSprite)
	}

	update(delta) {
		this.accumulator += delta

		if (this.accumulator > 0.075) {
			this.animationFrame++
			if (this.animationFrame === 7) {
				this.isComplete = true
			}
			this.bloodSprite.texture = new Texture.from(
				"blood00" + this.animationFrame + ".png"
			)
		}
	}
}

export default BloodEffect
