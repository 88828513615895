<template>
	<div class="world-map" v-if="worldMapOpen">
		<Map></Map>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import Map from './Map'

export default {
	name: "WorldMap",
	components: {
		Map
	},
	computed: {
		...mapGetters(["worldMapOpen"]),
	}
}
</script>

<style lang="scss" scoped>
$world-map-size: 80vh;
$world-map-half-size: $world-map-size/2;

.world-map {
	z-index: 100;
	position: fixed;
	width: $world-map-size;
	height: $world-map-size;
	margin-top: -$world-map-half-size;
	margin-left: -$world-map-half-size;
	top: 50%;
	left: 50%;
}
</style>
