import ParticleAttribute from "./ParticleAttribute"



class ScaleAttribute extends ParticleAttribute {
    constructor(attributeBlueprint) {
        super(attributeBlueprint)
        this.attributeBlueprint = attributeBlueprint
        this.scaleOverTime = attributeBlueprint.scaleOverTime
        this.elapsedTime = 0
    }

    die(particle) {
        super.die()
    }

    reset() {
        super.reset()
    }

    particleSpawned(particle) {
        super.particleSpawned(this.particleSpawned)
        this.attributeBlueprint = this.attributeBlueprint
        if(this.attributeBlueprint.randomScale) {
            this.initialScale = {
                x: this.parseRandom(this.attributeBlueprint.randomScale.minX, this.attributeBlueprint.randomScale.maxX),
                y: this.parseRandom(this.attributeBlueprint.randomScale.minY, this.attributeBlueprint.randomScale.maxY)
            }
            if(this.attributeBlueprint.squareScale) {
                this.initialScale.y = this.initialScale.x
            }
        }
        else {
            this.initialScale = this.attributeBlueprint.initialScale
        }

        if(this.attributeBlueprint.scaleOverTime) {
            if(this.attributeBlueprint.randomFinalScale){
                this.finalScale = {
                    x: this.parseRandom(this.attributeBlueprint.randomFinalScale.minX, this.attributeBlueprint.randomFinalScale.maxX),
                    y: this.parseRandom(this.attributeBlueprint.randomFinalScale.minY, this.attributeBlueprint.randomFinalScale.maxY)
                }
                if(this.attributeBlueprint.squareScale) {
                    this.finalScale.y = this.finalScale.x
                }
            }
            else
                this.finalScale = this.attributeBlueprint.finalScale
                if(this.attributeBlueprint.squareScale) {
                    this.finalScale.y = this.finalScale.x
                }
            
        }

        if(this.attributeBlueprint.randomlyMirror && Math.random() > 0.5) {
            if(this.attributeBlueprint.randomlyMirror.onlyX) {
                this.initialScale.x *= -1
                this.finalScale.x *= -1
            } else if(this.attributeBlueprint.randomlyMirror.onlyY) {
                this.finalScale.y *= -1
                this.initialScale.y *= -1
            }
            else {
                this.initialScale.x *= -1
                this.initialScale.y *= -1
                this.finalScale.y *= -1
                this.finalScale.x *= -1
            }
        }

        particle.scale = this.initialScale
        this.lifetime = particle.lifetime
        this.elapsedTime = 0.0
    }

    apply(particle, delta) {
        super.apply(particle)
        if(this.scaleOverTime) {
            this.elapsedTime += delta
            let percentDone = Math.min(this.elapsedTime / this.lifetime, 1.0)
            let x = (percentDone * (this.finalScale.x - this.initialScale.x)) + this.initialScale.x
            let y = (percentDone * (this.finalScale.y - this.initialScale.y)) + this.initialScale.y
            particle.scale = {
                x: x,
                y: y
            }
        }
    }
}

export default ScaleAttribute