// NOTE: CLIENT VISIBLE!
const GAME_CONSTANTS = {
	CURRENT_GAME_VERSION: process.env.GAME_VERSION,
	// MAP: 'bruhnswick',
	MAP: 'bruhward',
	MAP_CONFIG: {
		bruhnswick: {
			MAP_WIDTH_TILES: 256,
			MAP_HEIGHT_TILES: 256,
			MAP_WIDTH: 16*256,
			MAP_HEIGHT: 16*256,

			LOBBY_POSITION: {
				X: 2371,
				Y: 3842
			},

			POISON_START_DELAY: 5,
			POISON_SPEED: 16.5,
			POISON_STAGES: 4,
			POISON_DAMAGE: 3,
		},
		bruhward: {
			MAP_WIDTH_TILES: 192,
			MAP_HEIGHT_TILES: 192,
			MAP_WIDTH: 16*192,
			MAP_HEIGHT: 16*192,

			LOBBY_POSITION: {
				X: 95*16,
				Y: 75*16
			},

			POISON_START_DELAY: 15,
			POISON_SPEED: 15.5,
			POISON_STAGES: 4,
			POISON_DAMAGE: 3,
		}
	},

	RENDERER: {
		GAME_WIDTH: 1280,
		GAME_HEIGHT: 720
	},

	DEBUG: {
		SPAWN_ALL_WEAPONS: process.env.NODE_ENV === "production" ? false : false,
		USE_PROD_MAP_SIZE: process.env.NODE_ENV === "production" ? true : true,
		RENDER_HITBOXES: false,
		RENDER_COLLISIONS: false,
		RENDER_GRENADE_RADIUS: false,
		RENDER_SERVER_SIDE_PLAYER_HITBOX: false,
		SHOW_CHUNK_SEAMS: false,
		SHOW_MISSION_LOGS: true,
	},

	TILE_SIZE: 16,
	TILE_WIDTH: 16,
	TILE_HEIGHT: 16,
	COLLISION_TILE_WIDTH: 16,
	COLLISION_TILE_HEIGHT: 16,
	REGION_SIZE: 256,

	MAX_PLAYERS: 30,
	GAMES_TILL_SWAP: 1,

	CIRCLE_INITIAL_RADIUS: 2828, // diagonal size of the world
	CIRCLE_REDUCTION_SPEED: 40, // note: unused
	AFK_TIMEOUT: process.env.NODE_ENV === "production" ? 60000 : 9999999, // milliseconds
	AFK_WARNING: process.env.NODE_ENV === "production" ? 59000 : 9999999,
	SPAM_MESSAGE_TIME: 500,
	SPAM_MAX_STRIKES: 5,
	DEBUG_AFK_TIMEOUT : 5000,
	DEBUG_AFK_WARNING : 4000,
	MAXIMUM_NAME_LENGTH: 10,
	BULLET_RADIUS: 0.5,
	GRENADE_RADIUS: 5,
	GRENADE_BASE_SCALE: 0.28,
	GRENADE_SCALE_H_MOD: 0.2,
	GRENADE_BASE_SHADOW: 1,
	GRENADE_SHADOW_H_MOD: 15,
	DISCARDED_WEAPON_LIFETIME: 2,
	SPREE_WINDOW: 10, // seconds allowed between double/triple kills

	ITEM_SPAWNER: {
		SPAWN_RATE_PER_NODE: 0.8,
		SPAWN_RATE_PER_BONUS_STACKABLE: 0.2,
		USE_SPAWN_MODES: false,
		PROBABILITY_FOR_SPAWN_MODE: 0.1,
	},

	CAMERA_HEIGHT: 3,
	ZOOM_LEVEL_DEATH_ANIMATION: 5.0,
	ZOOM_LEVEL_DEFAULT: 3.0,
	ZOOM_LEVEL_1X: 3.0,
	ZOOM_LEVEL_2X: 2.6,
	ZOOM_LEVEL_4X: 2.3,
	ZOOM_LEVEL_8X: 2.0,
	ZOOM_LEVEL_GHOST: 2.0,

	MAX_KILLFEED_MESSAGES: 10,

	LATE_JOIN_DURATION: 30000, // how late a player can be in ms and still not be a ghost
	BROADCAST_SCOREBOARD_INTERVAL: 4000,
	BROADCAST_PING_INTERVAL: 1000,
	BROADCAST_TIP_MESSAGE_INTERVAL: 300000,

	POISON_TICK_DURATION: 333,
	POISON_DAMAGE: 3,
	FIRE_TICK_RATE: 0.15, // in seconds
	PROJECTILE_SPEED: 1000,

	GAME_MODES: {
		BATTLE_ROYALE: "battle royale"
	},

	TEAM_PLAYERS_PER: {
		FREE_FOR_ALL: 1,
		SOLOS: 1,
		DUOS: 2,
		SQUADS: 4
	},

	GAME_STATE_DURATIONS: {
		LOBBY_BETWEEN_GAMES: process.env.NODE_ENV === "production" || process.env.NODE_ENV === "beta" ? 20.0 : 3.0,
		POSTGAME: 5.0
	},

	PLAYER: {
		COLLIDER_RADIUS: 7,
		DEFAULT_WEAPON: 0, // the index for PISTOL
		MOVEMENT_SPEED_DEFAULT: 70,
		MOVEMENT_SPEED_GHOST_FACTOR: 2,
		PICKUP_ITEM_COOLDOWN: 200,
	},

	PICKUPS: {
		COLLIDER_RADIUS: 10
	}
}

// const CURRENT_MAP_CONFIG = GAME_CONSTANTS.MAP_CONFIG[GAME_CONSTANTS.MAP] // Removing due to adding in map swapping 2022:04:23
const MAP_CONFIG = GAME_CONSTANTS.MAP_CONFIG

export { GAME_CONSTANTS as default, GAME_CONSTANTS, MAP_CONFIG }
