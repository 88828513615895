export default {
	name: "SBS",
	programmaticName: "sbs",
	canonicalName: "SG_SBS",
	itemClass: "weapon",
	itemSubclass: "shotgun",
	image: "sbs_default.png",
	heldImage: "sbs_hands.png",
	outlineImage: "sbs_outline.png",
	soundName: "SHOTGUN",
	reloadSoundName: "SHOTGUN5_reloading",
	spawnWeighting: 100,
	grenadeProjectiles: false,
	damage: 6,
	cooldown: 0.25,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 4,
	recoilRecoveryPeriod: 0.8,
	recoilMax: 20,
	clipSize: 5,
	kick: 40,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 24,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 80,
	pelletCount: 8,
	pelletSpread: 0.05,
	corpseForce: 100,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 2.0,
	activeStart: 0.53,
	activeEnd: 0.58,
	uiRotateDeg: -37,
	uiWidth: 103,
	uiTop: 45,
	uiLeft: 41
}
