import { Howl } from 'howler'

import musicBruh001Webm from '../assets/music/BRUH_MUS_001.webm'
import musicBruh001Ogg from '../assets/music/BRUH_MUS_001.ogg'
import musicBruh001Mp3 from '../assets/music/BRUH_MUS_001.mp3'
import musicBruh001Wav from '../assets/music/BRUH_MUS_001.wav'
import gunRifleShot01Webm from '../assets/sounds/gun_rifle_shot_01.webm'
import gunRifleShot01Wav from '../assets/sounds/gun_rifle_shot_01.wav'
import gunRifleShot02Webm from '../assets/sounds/gun_rifle_shot_02.webm'
import gunRifleShot02Wav from '../assets/sounds/gun_rifle_shot_02.wav'
import gunRifleShot03Webm from '../assets/sounds/gun_rifle_shot_03.webm'
import gunRifleShot03Wav from '../assets/sounds/gun_rifle_shot_03.wav'
import gunRifleShot04Webm from '../assets/sounds/gun_rifle_shot_04.webm'
import gunRifleShot04Wav from '../assets/sounds/gun_rifle_shot_04.wav'
import pistolChangeMagWebm from '/assets/sounds/gun_pistol_change_mag_800ms.webm'
import pistolChangeMagWav from '/assets/sounds/gun_pistol_change_mag_800ms.wav'
import rifleChangeMagWebm from '/assets/sounds/gun_rifle_magazine_change_03_1200ms.webm'
import rifleChangeMagWav from '/assets/sounds/gun_rifle_magazine_change_03_1200ms.wav'
import semiAutoMagChangeWebm from '/assets/sounds/gun_semi_auto_rifle_magazine_change_02_1250ms.webm'
import semiAutoMagChangeWav from '/assets/sounds/gun_semi_auto_rifle_magazine_change_02_1250ms.wav'
import shotgunMagSize2ChangeWebm from '/assets/sounds/gun_shotgun_load_2_shell_2000ms.webm'
import shotgunMagSize2ChangeWav from '/assets/sounds/gun_shotgun_load_2_shell_2000ms.wav'
import shotgunMagSize5ChangeWebm from '/assets/sounds/gun_shotgun_load_5_shell_3500ms.webm'
import shotgunMagSize5ChangeWav from '/assets/sounds/gun_shotgun_load_5_shell_3500ms.wav'
import sniperRifleMagChangeWebm from '/assets/sounds/gun_rifle_magazine_change_03_1500ms.webm'
import sniperRifleMagChangeWav from '/assets/sounds/gun_rifle_magazine_change_03_1500ms.wav'
import assaultRifleMagChangeWebm from '/assets/sounds/gun_rifle_magazine_change_03_1200ms.webm'
import assaultRifleMagChangeWav from '/assets/sounds/gun_rifle_magazine_change_03_1200ms.wav'
import dryFireWebm from '/assets/sounds/gun_semi_auto_rifle_dry_fire_04.webm'
import dryFireWav from '/assets/sounds/gun_semi_auto_rifle_dry_fire_04.wav'
import pistolShot01Webm from '/assets/sounds/gun_pistol_shot_01.webm'
import pistolShot01Wav from '/assets/sounds/gun_pistol_shot_01.wav'
import pistolShot02Webm from '/assets/sounds/gun_pistol_shot_02.webm'
import pistolShot02Wav from '/assets/sounds/gun_pistol_shot_02.wav'
import pistolShot03Webm from '/assets/sounds/gun_pistol_shot_03.webm'
import pistolShot03Wav from '/assets/sounds/gun_pistol_shot_03.wav'
import pistolShot04Webm from '/assets/sounds/gun_pistol_shot_04.webm'
import pistolShot04Wav from '/assets/sounds/gun_pistol_shot_04.wav'
import pistolShot05Webm from '/assets/sounds/gun_pistol_shot_05.webm'
import pistolShot05Wav from '/assets/sounds/gun_pistol_shot_05.wav'
import shotgunShot01Webm from '/assets/sounds/gun_shotgun_shot_01.webm'
import shotgunShot01Wav from '/assets/sounds/gun_shotgun_shot_01.wav'
import shotgunShot02Webm from '/assets/sounds/gun_shotgun_shot_02.webm'
import shotgunShot02Wav from '/assets/sounds/gun_shotgun_shot_02.wav'
import shotgunShot03Webm from '/assets/sounds/gun_shotgun_shot_03.webm'
import shotgunShot03Wav from '/assets/sounds/gun_shotgun_shot_03.wav'
import shotgunShot04Webm from '/assets/sounds/gun_shotgun_shot_04.webm'
import shotgunShot04Wav from '/assets/sounds/gun_shotgun_shot_04.wav'
import grenadeLauncherShot01Webm from '../assets/sounds/gun_grenade_launcher_shot_01.webm'
import grenadeLauncherShot01Wav from '../assets/sounds/gun_grenade_launcher_shot_01.wav'
import grenadeExplosionWebm from '../assets/sounds/grenade_explosion.webm'
import grenadeExplosionWav from '../assets/sounds/grenade_explosion.wav'
import resGrenadeExplosionWav from '../assets/sounds/res_grenade.wav'
import resGrenadeExplosionWebm from '../assets/sounds/res_grenade.webm'
import smokeGrenadeOgg from '../assets/sounds/smoke_grenade.ogg'
import impact01Webm from '/assets/sounds/punch_general_body_impact_01.webm'
import impact01Wav from '/assets/sounds/punch_general_body_impact_01.wav'
import impact02Webm from '/assets/sounds/punch_general_body_impact_02.webm'
import impact02Wav from '/assets/sounds/punch_general_body_impact_02.wav'
import impact03Webm from '/assets/sounds/punch_general_body_impact_03.webm'
import impact03Wav from '/assets/sounds/punch_general_body_impact_03.wav'
import impact04Webm from '/assets/sounds/punch_general_body_impact_04.webm'
import impact04Wav from '/assets/sounds/punch_general_body_impact_04.wav'
import impact05Webm from '/assets/sounds/punch_general_body_impact_05.webm'
import impact05Wav from '/assets/sounds/punch_general_body_impact_05.wav'
import pickUpItemWebm from '/assets/sounds/foley_soldier_gear_equipment_movement_grab_item_02.webm'
import pickUpItemWav from '/assets/sounds/foley_soldier_gear_equipment_movement_grab_item_02.wav'
import sniperRifleShot01Webm from '/assets/sounds/gun_rifle_sniper_shot_01.webm'
import sniperRifleShot01Wav from '/assets/sounds/gun_rifle_sniper_shot_01.wav'
import sniperRifleShot02Webm from '/assets/sounds/gun_rifle_sniper_shot_02.webm'
import sniperRifleShot02Wav from '/assets/sounds/gun_rifle_sniper_shot_02.wav'
import sniperRifleShot03Webm from '/assets/sounds/gun_rifle_sniper_shot_03.webm'
import sniperRifleShot03Wav from '/assets/sounds/gun_rifle_sniper_shot_03.wav'
import sniperRifleShot04Webm from '/assets/sounds/gun_rifle_sniper_shot_04.webm'
import sniperRifleShot04Wav from '/assets/sounds/gun_rifle_sniper_shot_04.wav'
import killingSpreeWebm from '/assets/sounds/bruh_announcer_killing_spree.webm'
import killingSpreeWav from '/assets/sounds/bruh_announcer_killing_spree.wav'
import frenzyWebm from '/assets/sounds/bruh_announcer_killing_frenzy.webm'
import frenzyWav from '/assets/sounds/bruh_announcer_killing_frenzy.wav'
import doubleKillWebm from '/assets/sounds/bruh_announcer_double_kill.webm'
import doubleKillWav from '/assets/sounds/bruh_announcer_double_kill.wav'
import tripleKillWebm from '/assets/sounds/bruh_announcer_triple_kill.webm'
import tripleKillWav from '/assets/sounds/bruh_announcer_triple_kill.wav'
import molotovImpactWav from '/assets/sounds/molotov_impact.wav'
import molotovImpactWebm from '/assets/sounds/molotov_impact.webm'
import molotovImpact02Wav from '/assets/sounds/molotov_impact_02.wav'
import molotovImpact02Webm from '/assets/sounds/molotov_impact_02.webm'
import molotovImpact03Wav from '/assets/sounds/molotov_impact_03.wav'
import molotovImpact03Webm from '/assets/sounds/molotov_impact_03.webm'

export default () => {
	const gun01 = new Howl({ src: [gunRifleShot01Webm, gunRifleShot01Wav] })
	const gun02 = new Howl({ src: [gunRifleShot02Webm, gunRifleShot02Wav] })
	const gun03 = new Howl({ src: [gunRifleShot03Webm, gunRifleShot03Wav] })
	const gun04 = new Howl({ src: [gunRifleShot04Webm, gunRifleShot04Wav] })

	const music = {
		BattleRoyale: [
			new Howl({
				src: [musicBruh001Webm, musicBruh001Ogg, musicBruh001Mp3, musicBruh001Wav],
				volume: 0.15,
				loop: true,
			})
		],
	}
	const sounds = {
		PISTOL_reloading: [
			new Howl({
				src: [pistolChangeMagWebm, pistolChangeMagWav],
				volume: 2.0
			})
		],
		MACHINE_GUN_reloading: [
			new Howl({ src: [rifleChangeMagWebm, rifleChangeMagWav] })
		],
		SUBMACHINE_GUN_reloading: [
			new Howl({ src: [semiAutoMagChangeWebm, semiAutoMagChangeWav] })
		],
		SHOTGUN2_reloading: [
			new Howl({ src: [shotgunMagSize2ChangeWebm, shotgunMagSize2ChangeWav] })
		],
		SHOTGUN5_reloading: [
			new Howl({ src: [shotgunMagSize5ChangeWebm, shotgunMagSize5ChangeWav] })],
		SNIPER_RIFLE_reloading: [
			new Howl({ src: [sniperRifleMagChangeWebm, sniperRifleMagChangeWav] })],
		ASSAULT_RIFLE_reloading: [
			new Howl({ src: [assaultRifleMagChangeWebm, assaultRifleMagChangeWav] })],
		dry_fire: [
			new Howl({ src: [dryFireWebm, dryFireWav] })],
		PISTOL: [
			new Howl({ src: [pistolShot01Webm, pistolShot01Wav] }),
			new Howl({ src: [pistolShot02Webm, pistolShot02Wav] }),
			new Howl({ src: [pistolShot03Webm, pistolShot03Wav] }),
			new Howl({ src: [pistolShot04Webm, pistolShot04Wav] }),
			new Howl({ src: [pistolShot05Webm, pistolShot05Wav] })
		],
		MACHINE_GUN: [gun01, gun02, gun03, gun04],
		SUBMACHINE_GUN: [gun01, gun02, gun03, gun04],
		HV_GRENADELAUNCHER: [
			new Howl({ src: [grenadeLauncherShot01Webm, grenadeLauncherShot01Wav] }),
		],
		SHOTGUN: [
			new Howl({ src: [shotgunShot01Webm, shotgunShot01Wav] }),
			new Howl({ src: [shotgunShot02Webm, shotgunShot02Wav] }),
			new Howl({ src: [shotgunShot03Webm, shotgunShot03Wav] }),
			new Howl({ src: [shotgunShot04Webm, shotgunShot04Wav] })
		],
		SNIPER_RIFLE: [
			new Howl({ src: [sniperRifleShot01Webm, sniperRifleShot01Wav] }),
			new Howl({ src: [sniperRifleShot02Webm, sniperRifleShot02Wav] }),
			new Howl({ src: [sniperRifleShot03Webm, sniperRifleShot03Wav] }),
			new Howl({ src: [sniperRifleShot04Webm, sniperRifleShot04Wav] })
		],
		ASSAULT_RIFLE: [gun01, gun02, gun03, gun04],
		killing_spree: [new Howl({ src: [killingSpreeWebm, killingSpreeWav] })],
		frenzy: [
			new Howl({ src: [frenzyWebm, frenzyWav] })
		],
		double_kill: [
			new Howl({src: [doubleKillWebm, doubleKillWav]})
		],
		triple_kill: [
			new Howl({src: [tripleKillWebm, tripleKillWav]})
		],
		pick_up_weapon: [
			new Howl({ src: [pickUpItemWebm, pickUpItemWav] })
		],
		explosion: [
			new Howl({
				src: [grenadeExplosionWebm, grenadeExplosionWav],
				volume: 3.0
			}),
		],
		molotov_impact: [
			new Howl({
				src: [molotovImpactWebm, molotovImpactWav],
				volume: 4.0
			}),
			new Howl({
				src: [molotovImpact02Webm, molotovImpact02Wav]
			}),
			new Howl({
				src: [molotovImpact03Webm, molotovImpact03Wav]
			})
		],
		smoke_grenade: [
			new Howl({ src: [smokeGrenadeOgg] }),
		],
		bullet_impact: [
			new Howl({ src: [impact01Webm, impact01Wav] }),
			new Howl({ src: [impact02Webm, impact02Wav] }),
			new Howl({ src: [impact03Webm, impact03Wav] }),
			new Howl({ src: [impact04Webm, impact04Wav] }),
			new Howl({ src: [impact05Webm, impact05Wav] })
		],
		res_grenade: [
			new Howl({src:[resGrenadeExplosionWebm, resGrenadeExplosionWav]})
		]
	}

	return { music, sounds }
}
