import nengi from "nengi"

class DiedToPoisonKillFeedMessage {
	constructor(name) {
		this.name = name
		this.variant = Math.floor(Math.random() * 30)
	}
}

DiedToPoisonKillFeedMessage.protocol = {
	name: nengi.String,
	variant: nengi.UInt8
}

export default DiedToPoisonKillFeedMessage
