export default {
	name: "2x Scope",
	programmaticName: "twoxscope",
	canonicalName: "TWO_X_SCOPE",
	itemClass: "scope",
	itemSubclass: "scope",
	equipSlot: "equipmentScope",
	spawnWeighting: 180,
	zoomLevel: 2.6,
	image: "2x_scope.png",
	uiImage: "2xscope.png",
	scaleDown: true
}
