import Weapon from "../../common/weapon/Weapon"
import { Container, Sprite } from "pixi.js-legacy"
class LaserSight extends Container {
	constructor() {
		super()
		this.x = 0
		this.y = 0
		this.laserLine = new Sprite.from("16x16.png")
		this.laserLine.scale.y = 1 / 16 // 16 pixels at 1/16 scale is 1 pixel
		this.laserLine.scale.x = 800 / 16 // 800 pixels, made in 16 pixel chunks

		this.addChild(this.laserLine)
	}

	update(player, length) {
		const equippedWeapon = Weapon.byIndex(player.currentlyEquipped)

		this.laserLine.tint = equippedWeapon.laserSightColor
		this.laserLine.x = 6 + equippedWeapon.barrelLength
		this.laserLine.y = 1
		this.laserLine.scale.x = (length - (6 + equippedWeapon.barrelLength)) / 16 // n pixels, made in 16 pixel chunks
	}
}

export default LaserSight
