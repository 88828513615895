import GAME_CONSTANTS from "../../common/balance/gameConstants"
import Weapon from "../../common/weapon/Weapon"
import { Container, Text, Sprite } from "pixi.js-legacy"

class WeaponItem extends Container {
	constructor(entity, renderer) {
		super()

		this.renderer = renderer

		this.maximumDeviation = 2
		this.id = entity.id
		this.x = entity.x
		this.y = entity.y
		this.originalY = this.y
		this.weaponType = entity.weaponType
		this.down = true
		this.isWeaponItem = true
		this.pickupable = entity.pickupable

		this.a = GAME_CONSTANTS.DISCARDED_WEAPON_LIFETIME

		if (GAME_CONSTANTS.DEBUG.RENDER_HITBOXES) {
			let circleGraphics = new Sprite.from("white_circle_64x64.png")
			circleGraphics.x = GAME_CONSTANTS.PICKUPS.COLLIDER_RADIUS / -2
			circleGraphics.y = GAME_CONSTANTS.PICKUPS.COLLIDER_RADIUS / -2
			circleGraphics.scale.x = GAME_CONSTANTS.PICKUPS.COLLIDER_RADIUS / 64
			circleGraphics.scale.y = GAME_CONSTANTS.PICKUPS.COLLIDER_RADIUS / 64
			circleGraphics.tint = 0xff0000
			this.addChild(circleGraphics)
		}

		this.shadowGraphics = new Sprite.from("white_circle_64x64.png")
		this.shadowGraphics.x = -5
		this.shadowGraphics.y = 0
		this.shadowGraphics.scale.x = 10 / 64
		this.shadowGraphics.scale.y = 10 / 64
		this.shadowGraphics.tint = 0x000000
		this.shadowGraphics.alpha = 0.4
		this.addChild(this.shadowGraphics)

		// let randomInitialDeviation = Math.random() < 0.5 ? Math.random() - 2 : Math.random() + 2

		this.weaponSpriteGraphics = new Sprite.from(
			Weapon.byIndex(entity.weaponType).image
			// Weapon.byIndex(entity.weaponType).candycane_image
		)
		// this.weaponSpriteGraphics.y = randomInitialDeviation
		// this.weaponSpriteGraphics.scale.x = 1//-GAME_CONSTANTS.WEAPON_VISUAL_PROPS[entity.weaponType].scale
		// this.weaponSpriteGraphics.scale.y = 1//GAME_CONSTANTS.WEAPON_VISUAL_PROPS[entity.weaponType].scale
		this.weaponSpriteGraphics.anchor.set(0.5, 0.5)
		if (entity.weaponType === 7) {
			this.weaponSpriteGraphics.scale.set(0.42, 0.42)
		} else if (Weapon.byIndex(entity.weaponType).scaleDown) {
			// console.log("scaling down");
			this.weaponSpriteGraphics.scale.set(0.35, 0.35)
		} else {
			this.weaponSpriteGraphics.scale.set(0.5, 0.5)
		}

		this.weaponSpriteGraphics.rotation = (Math.random() - 0.5) * 0.5
		// this.weaponSpriteGraphics.pivot.x = GAME_CONSTANTS.WEAPON_VISUAL_PROPS[entity.weaponType].pivot.x
		// this.weaponSpriteGraphics.pivot.y = GAME_CONSTANTS.WEAPON_VISUAL_PROPS[entity.weaponType].pivot.y
		this.addChild(this.weaponSpriteGraphics)

		this._showText = false

		const downScale = 1 / 6
		this.pickupText = new Text(
			Weapon.byIndex(entity.weaponType).name + "\nPress [e] ",
			{
				fontFamily: "Russo One",
				fontSize: 36,
				fill: 0xffffff,
				align: "center"
			}
		)
		this.pickupText.scale.set(downScale, downScale)
		this.pickupText.anchor.set(0.5, 1)
		this.pickupText.x = this.x
		this.pickupText.y = this.y - 3
		this.renderer.foreground.addChild(this.pickupText)
		this.pickupText.alpha = 0
	}

	set showText(value) {
		if (!this.pickupable) {
			this._showText = 0
			return
		}
		if (value !== this._showText) {
			this.pickupText.alpha = value ? 1 : 0
		}
		this._showText = value
	}

	update(delta) {
		if (
			this.weaponSpriteGraphics.y > this.maximumDeviation + 1 ||
			this.weaponSpriteGraphics.y < -this.maximumDeviation + -1
		) {
			this.weaponSpriteGraphics.y = 0 // correct sprite position if they tabbed away
		}

		if (this.down) {
			this.weaponSpriteGraphics.y -= delta / 0.4
			// this.shadowGraphics.scale.x += delta/400
			// this.shadowGraphics.scale.y += delta/400
		} else {
			this.weaponSpriteGraphics.y += delta / 0.4
			// this.shadowGraphics.scale.x -= delta/400
			// this.shadowGraphics.scale.y -= delta/400
		}
		if (this.weaponSpriteGraphics.y > this.maximumDeviation) {
			this.down = true
		} else if (this.weaponSpriteGraphics.y < -this.maximumDeviation) {
			this.down = false
		}

		this.pickupText.x = this.x
		this.pickupText.y = this.y - 3

		if (!this.pickupable) {
			this.a -= delta

			this.weaponSpriteGraphics.alpha = Math.min(this.a, 1)
			this.shadowGraphics.alpha = Math.min(this.a, 1) * 0.4
		}
	}

	onDestroy() {
		this.renderer.foreground.removeChild(this.pickupText)
	}
}

export default WeaponItem
