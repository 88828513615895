import PlayerGraphics from '../entity/Player'
import store from '../ui/store'
import Weapon from '../../common/weapon/Weapon'
import { throttle } from 'lodash'
import GAME_CONSTANTS from "../../common/balance/gameConstants"
const { ZOOM_LEVEL_DEFAULT } = GAME_CONSTANTS

const isSelf = (entity, simulator) => {
	return simulator.myId && simulator.myId === entity.id
}

const isTeammateById = (id, simulator) => {
	return simulator.partyMemberIds.indexOf(id) !== -1
}

const isTeammate = (entity, simulator) => {
	return isTeammateById(entity.id, simulator)
}

const isTeammateAndNotSelf = (entity, simulator) => {
	return isTeammate(entity, simulator) && !isSelf(entity, simulator)
}

const associateSelfWithRenderer = (entity, renderer) => {
	renderer.AB45203628E28928D1FB446B5C6EB50C = entity
	renderer.resize()
	store.commit('newHp', 100)
	if (entity._isGhost) {
		store.commit('playerDied')
	}
	// if (!store.getters.showOwnUsername) {
	// 	entity.deleteName()
	// }
	if (!store.getters.showOwnHealthBar) {
		entity.deleteHealthBar()
	}
}

const getWeaponData = (weapon) => {
	let weaponData = {
		type: weapon.index,
		reloadBarEarlyWindowStart: weapon.goodEnoughStart,
		reloadBarEarlyWindowEnd: weapon.goodEnoughEnd,
		reloadBarPerfectWindowStart: weapon.activeStart,
		reloadBarPerfectWindowEnd: weapon.activeEnd,
		clipSize: weapon.clipSize,
	}
	return weaponData
}

const updateCoords = throttle((x, y) => {
	x = Math.floor(x)
	y = Math.floor(y)
	store.commit('updatePlayerCoords', {
		x,
		y,
	})
}, 100)

export default ({ renderer, simulator, gameClient }) => {
	return {
		create: ({ data, sim }) => {
			const entity = new PlayerGraphics(data)

			renderer.entities.set(entity.id, entity)
			renderer.middleground.addChild(entity)

			if (isSelf(entity, simulator)) {
				simulator.predictedEntity = sim
				associateSelfWithRenderer(entity, renderer)
				store.commit('newPlayerId', sim.id)
			}

			if (isTeammate(entity, simulator)) {
				store.commit('createTeamMember', sim)
			}

			if (isTeammateAndNotSelf(entity, simulator)) {
				entity.showTeamIndicator()
			}

			return entity
		},
		delete: ({ id }) => {
			renderer.deleteEntity(id)

			if (isTeammateById(id, simulator)) {
				store.commit('deleteTeamMember', id)
			}
		},
		watch: {
			x: ({ entity }) => {
				if(isTeammate(entity, simulator)) {
					store.commit('updateTeamMember', {id: entity.id, prop: 'x', value: entity.x})
				}
				if (!isSelf(entity, simulator)) { return }
				updateCoords(entity.x, entity.y)
			},
			y: ({ entity }) => {
				if(isTeammate(entity, simulator)) {
					store.commit('updateTeamMember', {id: entity.id, prop: 'y', value: entity.y})
				}
				if (!isSelf(entity, simulator)) { return }
				updateCoords(entity.x, entity.y)
			},
			movementSpeed: ({ entity }) => {
				// console.log('movementSpeed', entity.movementSpeed)
			},
			isGhost: ({ entity, sim, value }) => {
				if (!isSelf(entity, simulator)) { return }
				store.commit('playerDied')
				// hides reload bar upon death
				sim.weaponSystem.interruptReload()

				if (value) {
					renderer.performDeathAnimation()
				} else {
					renderer.setZoomLevel(ZOOM_LEVEL_DEFAULT)
				}
			},

			isHealingOverTime: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				if (value) {
					store.commit('startHealingOverTime')
				} else {
					store.commit('stopHealingOverTime')
				}
			},
			hpHealingOverTime: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				store.commit('newHpHealingOverTime', value)
			},
			hp: ({ entity, value }) => {
				if (isTeammate(entity, simulator)) {
					store.commit('updateTeamMember', { id: entity.id, prop: 'hp', value: value })
				}
				if (!isSelf(entity, simulator)) { return }
				store.commit('newHp', value)
			},

			shields: ({ entity, value }) => {
				if (isTeammate(entity, simulator)) {
					store.commit('updateTeamMember', { id: entity.id, prop: 'shields', value: value })
				}
				if (!isSelf(entity, simulator)) { return }
				store.commit('newShields', value)
			},

			activeSlot: ({ entity, sim, value }) => {
				if (!isSelf(entity, simulator)) {
					// other people
					let itemToDisplay = sim[value] // the item in slot1 or slot2
					// if the active slot is 3,4,5 then we show a hardcoded graphic
					if (value === 'slot3') {
						itemToDisplay = Weapon.BANDAGE.index
					}
					if (value === 'slot4') {
						itemToDisplay = Weapon.MEDPACK.index
					}
					if (value === 'slot5') {
						itemToDisplay = Weapon.PAINKILLERS.index
					}

					if (entity.currentlyEquipped !== itemToDisplay) {
						entity.setWeaponSprite(itemToDisplay)
					}
				} else {
					// self
					const weapon = sim.weaponSystem[value]
					const weaponData = getWeaponData(weapon)
					//console.log('swithcing to', type)
					const type = weapon.index
					if (entity.currentlyEquipped !== type) {
						entity.setWeaponSprite(type)
					}
					if (!isSelf(entity, simulator)) { return }

					if (gameClient.soundSystem) {
						gameClient.soundSystem.playWeaponSwap()
					}
					// 100% predicted, ignore this network data
					//gameClient.switchWeapon(type)
					store.commit('newWeapon', weaponData)
				}
			},
			slot1: ({ entity, value, sim }) => {
				if (sim.activeSlot === 'slot1') {
					entity.setWeaponSprite(value)
				}
				if (isTeammateAndNotSelf(entity, simulator)) {
					store.commit('updateTeamMember', { id: entity.id, prop: 'slot1', value })
				}
				if (!isSelf(entity, simulator)) { return }

				sim.weaponSystem.cl_changeWeapon('slot1', value)
				if (entity.activeSlot == 'slot1') {
					const weapon = sim.weaponSystem['slot1']
					const weaponData = getWeaponData(weapon)
					store.commit('newWeapon', weaponData)
				}
				store.commit('newSlot1', value)

			},

			slot2: ({ entity, value, sim }) => {
				if (sim.activeSlot === 'slot2') {
					entity.setWeaponSprite(value)
				}

				if (isTeammateAndNotSelf(entity, simulator)) {
					store.commit('updateTeamMember', { id: entity.id, prop: 'slot2', value })
				}
				if (!isSelf(entity, simulator)) { return }

				sim.weaponSystem.cl_changeWeapon('slot2', value)
				if (entity.activeSlot == 'slot2') {
					const weapon = sim.weaponSystem['slot2']
					const weaponData = getWeaponData(weapon)
					store.commit('newWeapon', weaponData)
				}
				store.commit('newSlot2', value)
			},

			slot1Ammo: ({ entity, value }) => {
				// TODO if teammate, put this state in the store so we can see teammate's weapon in hud
				if (!isSelf(entity, simulator)) { return }
				store.commit('newAmmo', { slot: 'slot1', ammo: value })
			},

			slot2Ammo: ({ entity, value }) => {
				// TODO if teammate, put this state in the store so we can see teammate's weapon in hud
				if (!isSelf(entity, simulator)) { return }
				store.commit('newAmmo', { slot: 'slot2', ammo: value })
			},

			medpacks: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				store.commit('newMedpacks', value)
			},

			painkillers: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				store.commit('newPainkillers', value)
			},

			bandages: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				store.commit('newBandages', value)
			},

			equipmentSight: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				if (value !== -1) {
					entity.addLaserSight()
					store.commit('newLaser', true)
				} else {
					entity.removeLaserSight()
					store.commit('newLaser', false)
				}
			},

			equipmentScope: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				if (value === -1) {
					store.commit('newScope', 3)
				}
			},

			equipmentChest: ({ entity, value }) => {
				if (value !== -1) {
					entity.setChestSprite(value)
				} else {
					entity.removeChestSprite()
				}
			},

			fragGrenades: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				store.commit('newFragGrenades', value)
			},

			smokeGrenades: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				store.commit('newSmokeGrenades', value)
			},

			resGrenades: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				store.commit('newResGrenades', value)
			},

			molotovGrenades: ({ entity, value }) => {
				if (!isSelf(entity, simulator)) { return }
				store.commit('newMolotovGrenades', value)
			},
		}
	}
}
