import nengi from "nengi"

class EntityHit {
	constructor(entityId, x, y, pinsTarget, fatalShot) {
		this.entityId = entityId
		this.x = x
		this.y = y
		this.pinsTarget = pinsTarget
		this.fatalShot = fatalShot
	}
}

EntityHit.protocol = {
	entityId: nengi.UInt16,
	x: nengi.UInt16,
	y: nengi.UInt16,
	projectileId: nengi.UInt16,
	pinsTarget: nengi.Boolean,
	fatalShot: nengi.Boolean,
}

export default EntityHit
