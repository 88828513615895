import nengi from "nengi"

class NewZoomLevel {
	constructor(newZoom) {
		this.newZoomLevel = newZoom
	}
}

NewZoomLevel.protocol = {
	newZoomLevel: nengi.Number
}

export default NewZoomLevel
