export default {
	name: "MK2",
	programmaticName: "mk2",
	canonicalName: "SMG_MK2",
	itemClass: "weapon",
	itemSubclass: "submachine_gun",
	image: "mk2_default.png",
	heldImage: "mk2_hands.png",
	outlineImage: "mk2_outline.png",
	soundName: "SUBMACHINE_GUN",
	reloadSoundName: "SUBMACHINE_GUN_reloading",
	spawnWeighting: 100,
	grenadeProjectiles: false,
	damage: 7,
	cooldown: 0.1,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 13,
	recoilRecoveryPeriod: 1.0,
	recoilMax: 11,
	clipSize: 30,
	kick: 25,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 15,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 200,
	corpseForce: 50,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 1.7,
	activeStart: 0.4,
	activeEnd: 0.47,
	uiRotateDeg: -25,
	uiWidth: 116,
	uiTop: 39,
	uiLeft: 55
}
