import nengi from "nengi"
import Player from "../../server/entity/Player"
import WeaponItem from "../../server/entity/WeaponItem"
import Corpse from "../../server/entity/Corpse"
import Grenade from "../../server/entity/Grenade"
import SmokeCloud from "../../server/entity/SmokeCloud"
import WeaponFired from "../../server/message/WeaponFired"
import EntityHit from "../../server/message/EntityHit"
import PoisonShrinkingMessage from "../../server/message/PoisonShrinkingMessage"
import InterpFixMessage from "../../server/message/InterpFixMessage"
import NewZoomLevel from "../../server/message/NewZoomLevel"
import AFKMessage from "../../server/message/AFKMessage"
import Identity from "../../server/message/Identity"
import ServerMessage from "../../server/message/ServerMessage"
import GameStateChange from "../../server/message/GameStateChange"
import GameWinner from "../../server/message/GameWinner"
import MapName from "../../server/message/MapName"
import ChatMessage from "../../server/message/ChatMessage"
import AliveCount from "../../server/message/AliveCount"
import UpdatedKillCountMessage from "../../server/message/UpdatedKillCountMessage"
import KillFeedMessage from "../../server/message/KillFeedMessage"
import ScoreboardMessage from "../../server/message/ScoreboardMessage"
import DiedToPoisonKillFeedMessage from "../../server/message/DiedToPoisonKillFeedMessage"
import PoisonMessage from "../../server/message/PoisonMessage"
import MissionMessage from "../../server/message/MissionMessage"
import MissionListUpdateMessage from "../../server/message/MissionListUpdateMessage"
import MissionsCompleted from "../../server/message/MissionsCompleted"
import P7DA84105F9F54B2D20C66308619D78D from "../../client/command/P7DA84105F9F54B2D20C66308619D78D"
import PlayerPickUpItem from "../../client/command/PlayerPickUpItem"
import PlayerSubmitChatMessage from "../../client/command/PlayerSubmitChatMessage"
import PlayerUseItem from "../../client/command/PlayerUseItem"
import Explosion from "../../server/message/Explosion"
import MolotovExplosion from "../../server/message/MolotovExplosion"
import AABBDebug from "../../server/message/AABBDebug"
import PartyIdentities from "../../server/message/PartyIdentities"
import UpdateTeamPlacement from "../../server/message/UpdateTeamPlacement"
import UpdateBattlepassProgression from "../../server/message/UpdateBattlepassProgression"
import Settings from '../../server/message/Settings'
import RoundSummary from "../../server/message/RoundSummary"
import DebugPositions from "../../server/message/DebugPositions"
import NetSound from "../../server/message/NetSound"
import ChangeMap from "../../server/message/ChangeMap"
import WeaponPickupFallback from "../../server/message/WeaponPickupFallback"
import KillSpreeMessage from "../../server/message/KillSpreeMessage"
import KillStreakMessage from "../../server/message/KillStreakMessage"

const config = {
	UPDATE_RATE: 20,
	ID_BINARY_TYPE: nengi.UInt16,
	TYPE_BINARY_TYPE: nengi.UInt8,
	ID_PROPERTY_NAME: "id",
	TYPE_PROPERTY_NAME: "type",

	protocols: {
		entities: [
			["Player", Player],
			["WeaponItem", WeaponItem],
			["Corpse", Corpse],
			["Grenade", Grenade],
			["SmokeCloud", SmokeCloud]
		],

		localMessages: [
			["WeaponFired", WeaponFired],
			["EntityHit", EntityHit],
			["NetSound", NetSound]
		],

		messages: [
			["PoisonShrinkingMessage", PoisonShrinkingMessage],
			["InterpFixMessage", InterpFixMessage],
			["NewZoomLevel", NewZoomLevel],
			["AFKMessage", AFKMessage],
			["Identity", Identity],
			["ServerMessage", ServerMessage],
			["GameStateChange", GameStateChange],
			["GameWinner", GameWinner],
			["MapName", MapName],
			["ChatMessage", ChatMessage],
			["AliveCount", AliveCount],
			["UpdatedKillCountMessage", UpdatedKillCountMessage],
			["KillFeedMessage", KillFeedMessage],
			["ScoreboardMessage", ScoreboardMessage],
			["DiedToPoisonKillFeedMessage", DiedToPoisonKillFeedMessage],
			["KillSpreeMessage", KillSpreeMessage],
			["KillStreakMessage", KillStreakMessage],
			["PoisonMessage", PoisonMessage],
			["Explosion", Explosion],
			["MolotovExplosion", MolotovExplosion],
			["MissionMessage", MissionMessage],
			["MissionListUpdateMessage", MissionListUpdateMessage],
			["MissionsCompleted", MissionsCompleted],
			["AABBDebug", AABBDebug],
			["PartyIdentities", PartyIdentities],
			["UpdateTeamPlacement", UpdateTeamPlacement],
			["UpdateBattlepassProgression", UpdateBattlepassProgression],
			['Settings', Settings],
			['RoundSummary', RoundSummary],
			['DebugPositions', DebugPositions],
			['ChangeMap', ChangeMap],
			['WeaponPickupFallback', WeaponPickupFallback]
		],

		commands: [
			["P7DA84105F9F54B2D20C66308619D78D", P7DA84105F9F54B2D20C66308619D78D],
			["PlayerPickUpItem", PlayerPickUpItem],
			["PlayerSubmitChatMessage", PlayerSubmitChatMessage],
			["PlayerUseItem", PlayerUseItem]
		],

		basics: []
	}
}

export default config
