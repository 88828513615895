import nengi from "nengi"
import MissionMessage from './MissionMessage'

class MissionListUpdateMessage {
	constructor(missions) {
		this.missions = missions
	}
}

MissionListUpdateMessage.protocol = {
	missions: { type: MissionMessage, indexType: nengi.UInt16 },
}

export default MissionListUpdateMessage
