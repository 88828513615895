import nengi from "nengi"

class PoisonShrinkingMessage {
	constructor() {
		this.shrinking = true
	}
}

PoisonShrinkingMessage.protocol = {
	shrinking: nengi.Boolean
}

export default PoisonShrinkingMessage
