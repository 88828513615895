import nengi from "nengi"

class UpdateTeamPlacement {
	constructor(placement) {
		this.placement = placement
	}
}

UpdateTeamPlacement.protocol = {
	placement: nengi.Int8
}

export default UpdateTeamPlacement
