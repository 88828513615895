import nengi from "nengi"

class GameWinner {
	constructor(winnerName) {
		this.winnerName = winnerName || "f"
	}
}

GameWinner.protocol = {
	winnerName: nengi.String
}

export default GameWinner
