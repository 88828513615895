import SAT from "sat"
import Utils from "../../common/classes/Utils"
import GAME_CONSTANTS from "../../common/balance/gameConstants"

class Projectile {
	constructor(x, y, targetX, targetY, ownerId, instant = false, pinsTarget = false, lifeAfterHit = 0.0, fatalShot = false) {
		this._x = x
		this._y = y
		this.targetX = targetX
		this.targetY = targetY
		this.ownerId = ownerId
		this.rotation = Utils.angleBetweenPointsInRads(
			this._x,
			this._y,
			this.targetX,
			this.targetY
		)
		this.instant = instant

		// list of ids with which this projectile has collided, used to prevent multiple hits on same target
		this.alreadyCollided = {}

		this.speed = GAME_CONSTANTS.PROJECTILE_SPEED // pixels per second
		this.maxDuration = 1.0 // seconds before projectile disappears
		this.currentDuration = 0 // variable for counting the age of this projectile
		this.needsRemoved = false

		// calculate the heading of the projectile when it is created, no need to do this in update, it'll never change
		let dx = targetX - x
		let dy = targetY - y

		this.heading = {
			x: 0,
			y: 0
		}

		let len = Math.sqrt(dx * dx + dy * dy)
		if (len > 0) {
			// a unit vector
			this.heading.x = dx / len
			this.heading.y = dy / len
		}

		this.collider = new SAT.Circle(
			new SAT.Vector(this.x, this.y),
			GAME_CONSTANTS.BULLET_RADIUS
		)
		
		this.pinsTarget = pinsTarget
		this.lifeAfterHit = lifeAfterHit
		this.fatalShot = fatalShot
	}

	get x() {
		return this._x
	}
	set x(value) {
		this._x = value
		this.collider.pos.x = value
	}

	get y() {
		return this._y
	}
	set y(value) {
		this._y = value
		this.collider.pos.y = value
	}

	update(delta) {
		this.currentDuration += delta
		if(this.entityToStickTo && this.registeredHit) {
			this.x = this.entityToStickTo.x + this.initialStickOffset.x
			this.y = this.entityToStickTo.y + this.initialStickOffset.y
			return
		}

		if (this.x === this.targetX && this.y === this.targetY) {
			if(this.pinsTarget) {
				if(!this.registeredHit) {
					if(!this.fatalShot) {
						setTimeout(() => {
							this.needsRemoved = true
						}, this.lifeAfterHit)
					}

					this.registeredHit = true
				}
			}
			else {
				this.needsRemoved = true
			}
	
		}


		if (this.instant) {
			this.x = this.targetX
			this.y = this.targetY
		}
		else {
			this.x += this.speed * this.heading.x * delta
			this.y += this.speed * this.heading.y * delta
		}

		if (
			(this.heading.x > 0 && this.x > this.targetX) ||
		(this.heading.x < 0 && this.x < this.targetX)
		) {
			this.x = this.targetX
		}
		if (
			(this.heading.y > 0 && this.y > this.targetY) ||
		(this.heading.y < 0 && this.y < this.targetY)
		) {
			this.y = this.targetY
		}

		// do not pass the target
		this.collider.pos.x = this.x
		this.collider.pos.y = this.y	
	}

	stickToEntity(entity, offset) {
		this.entityToStickTo = entity
		this.initialStickOffset = offset
	}
}

export default Projectile
