import { t } from "./i18n"

export const showLoadingScreen = () => {
	const loadingElement = document.getElementById("loading-page")
	loadingElement.style.display = "flex"
}

export const hideLoadingScreen = () => {
	const loadingElement = document.getElementById("loading-page")
	loadingElement.style.display = "none"
}

export const stopLoadingAndDisplayError = (errorCode) => {
	const loadingElement = document.getElementById("loading-page")
	const loadingElementText = document.getElementById("loading-page-text")
	loadingElementText.innerHTML = t(errorCode)
	loadingElement.style.display = "flex"
	loadingElement.classList.add("failed-loading")
}
