export default {
	name: "UMP45",
	programmaticName: "ump45",
	canonicalName: "SMG_UMP45",
	itemClass: "weapon",
	itemSubclass: "submachine_gun",
	image: "ump45_default.png",
	heldImage: "ump45_hands.png",
	outlineImage: "ump45_outline.png",
	soundName: "SUBMACHINE_GUN",
	reloadSoundName: "SUBMACHINE_GUN_reloading",
	spawnWeighting: 100,
	grenadeProjectiles: false,
	damage: 8,
	cooldown: 0.12,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 18,
	recoilRecoveryPeriod: 0.8,
	recoilMax: 8,
	clipSize: 30,
	kick: 25,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 17,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 225,
	corpseForce: 50,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 1.6,
	activeStart: 0.44,
	activeEnd: 0.5,
	uiRotateDeg: -35,
	uiWidth: 117,
	uiTop: 38,
	uiLeft: 62
}
