import parseTiledMapForObjects from "../parseTiledMapForObjects"

const TILE_IDS = {
	// sprite of a closed treasure chest with silver trim
	11999: "weapon",
	// red potion
	11997: "medpack"
}

export default tiledMap => {
	return parseTiledMapForObjects(tiledMap, "ItemSpawns", TILE_IDS)
}
