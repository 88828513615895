import Vue from "vue"
import Vuex from "vuex"
import {
	heartbeatRequest,
	updateGamePreferencesRequest,
	getUserAdRewardsProgress,
	getCurrentAdRewards
} from "./api-requests"
import GAME_CONSTANTS from "../../common/balance/gameConstants"
import Weapon from '../../common/weapon/Weapon'
import { t } from './i18n.js'
// import vuexDebugMiddleware from "./vuexDebugMiddleware"
import moment from 'moment'

Vue.use(Vuex)

const UNKNOWN_PLACEMENT = '??'

export default new Vuex.Store({
	// plugins: [vuexDebugMiddleware],
	state: {
		averagePing: 0,
		environment: '',
		connected: false,
		gameClient: {},
		roundSummary: null,
		identifier: null,
		identifierType: null,
		userDataFetching: false,
		showEscapeMenu: false,
		showConfirmWatchAd: false,
		debug: {
			// rays of predicted shots
			hitscan: false,
			// aabb shot query boundaries sent from server
			aabb: false
		},
		settings: {
			// persistable settings
			showOwnHealthBar: false,
			showOwnUsername: false,
			sfxEnabled: false,
			musicEnabled: false,
			sfxVolume: 0.3,
			musicVolume: 0.3,
			disableHud: false,
			veganModeEnabled: false,
			preferredLanguage: null,
			hasBattlepass: false,

			// other; refactor?
			fullScreen: true,
			worldMapOpen: false,
			menuOpen: false
		},
		round: {
			map: '',
			poisonShrinking: false,
			playerPosition: {},
			killFeed: [],
			circlePosition: {},
			poison: {},
			scoreboard: {
				showing: true,
				scores: []
			},
			chat: {
				showing: false,
				messages: []
			},
			serverRegion: "Moon",
			roundState: 0,
			playersAlive: 0,
			gameVersion: process.env.GAME_VERSION,
			postgame: {
				showPostgame: false,
				showPostgameTimer: null,
				hidePostgameTimer: null,
				isWinner: false,
				placement: UNKNOWN_PLACEMENT,
				totalKills: 0,
				damageDealt: 0,
				numberOfEntitiesDamaged: 0,
				killedByPlayerName: "",
				killedByWeaponType: 0,
				killedByWeaponName: "",
				killedByPoison: false,
			},
		},
		party: {
			// vue needs predefined objects to be reactive to changes
			member1: {
				id: -1,
				hp: 0,
				shields: 0,
				name: "anon",
				slot1: 0,
				slot2: 0,
				outOfRange: false,
				x: 0,
				y: 0
			},
			member2: {
				id: -1,
				hp: 0,
				shields: 0,
				name: "anon",
				slot1: 0,
				slot2: 0,
				outOfRange: false,
				x: 0,
				y: 0,
			},
			member3: {
				id: -1,
				hp: 0,
				shields: 0,
				name: "anon",
				slot1: 0,
				slot2: 0,
				outOfRange: false,
				x: 0,
				y: 0,
			},
			member4: {
				id: -1,
				hp: 0,
				shields: 0,
				name: "anon",
				slot1: 0,
				slot2: 0,
				outOfRange: false,
				x: 0,
				y: 0
			}
		},
		player: {
			id: null,
			selectedCharacterSkin: 'banananew',
			selectedWeaponSkin: 'deadred',
			x: -1,
			y: -1,
			kills: 0,
			ping: 100,
			health: 100,
			hpHealingOverTime: 0,
			shields: 0,
			alive: true,
			isWinner: false,
			causeOfDeath: "",
			isHealingOverTime: false,
			activeSlot: "slot1",
			currentWeapon: -1,
			slot1: null,
			slot2: null,
			useItem: null,
			useItemProgress: 0,
			useItemDuration: 0,
			bandages: 0,
			medpacks: 0,
			painkillers: 0,
			fragGrenades: 0,
			smokeGrenades: 0,
			resGrenades: 0,
			molotovGrenades: 0,
			slot1Ammo: 0,
			slot1ClipSize: 0,
			slot2Ammo: 0,
			slot2ClipSize: 0,
			equipment: {
				scope: 0,
				laser: false
			},
			reloadBar: {
				isReloading: false,
				progress: 0,
				attemptFumbled: false,
				attemptGoodEnough: false,
				attemptPerfect: false,
				earlyWindow: {
					start: 45,
					end: 95
				},
				perfectWindow: {
					start: 55,
					end: 65
				},
				triedToUseItem: false,
				itemUnavailableText: ""
			}
		},
		battlepass: {
			level: 0,
			experience: 0,
			experiencePercentTilNextLevel: 0,
			round: {
				experienceEarnedNow: 0,
				levelsGained: 0,
				missionsCompleted: [],
			},
		},
		adRewards: {
			adsWatched: 0,
			nextAdTime: null,
			adsRequired: 0,
			rewardId: null,
			hasAdReward: false,
			friendlyName: "",
			adRewardsEarned: null
		},
		missions: [] // MissionMessage
	},
	getters: {
		averagePing: state => state.averagePing,
		environment: state => state.environment,
		isLocalEnv: state => state.environment === 'local',
		isDevelopmentEnv: state => state.environment === 'development',
		isQaEnv: state => state.environment === 'qa',
		isBetaEnv: state => state.environment === 'beta',
		isDebugEnv: state => {
			return (state.environment === 'local' || state.environment === 'development' || state.environment === 'qa')
		},
		isProductionEnv: state => state.environment === 'production',
		pageTitle: state => {
			return `BRUH.IO ${(state.environment !== 'production' ? state.environment : '')} | Battle Royale in your browser!`
		},
		gameClient: state => state.gameClient,
		roundSummary: state => state.roundSummary,

		// debug
		debugHitscan: state => state.debug.hitscan,
		debugAABB: state => state.debug.aabb,

		// settings getters
		showOwnHealthBar: state => state.settings.showOwnHealthBar,
		showOwnUsername: state => state.settings.showOwnUsername,
		sfxEnabled: state => state.settings.sfxEnabled,
		sfxVolume: state => state.settings.sfxVolume,
		musicVolume: state => state.settings.musicVolume,
		musicEnabled: state => state.settings.musicEnabled,
		disableHud: state => state.settings.disableHud,
		veganModeEnabled: state => state.settings.veganModeEnabled,
		preferredLanguage: state => state.settings.preferredLanguage,
		hasBattlepass: state => state.settings.hasBattlepass,

		// settings, extra
		fullScreenEnabled: state => state.settings.fullScreen,
		worldMapOpen: state => state.settings.worldMapOpen,
		menuOpen: state => state.settings.menuOpen,

		// round getters
		bruhnswickMap: state => {
			return state.round.map === 'bruhnswick'
		},
		bruhwardMap: state => {
			return state.round.map === 'bruhward'
		},
		killFeed: state => state.round.killFeed,
		circlePosition: state => state.round.circlePosition,
		scoreboardShowing: state => state.round.scoreboard.showing,
		scoreboardScores: state => state.round.scoreboard.scores,
		chatShowing: state => state.round.chat.showing,
		chatMessages: state => state.round.chat.messages,
		serverRegion: state => state.round.serverRegion,
		roundState: state => state.round.roundState,
		playersAlive: state => state.round.playersAlive,
		gameVersion: state => state.round.gameVersion,
		playerPosition: state => state.round.playerPosition,
		poisonShrinking: state => state.round.poisonShrinking,
		poison: state => state.round.poison,

		// player getters
		myId: state => state.player.id,
		selectedCharacterSkin: state => state.player.selectedCharacterSkin,
		selectedWeaponSkin: state => state.player.selectedWeaponSkin,
		coords: state => {
			return {
				x: state.player.x,
				y: state.player.y,
				tileX: Math.floor(state.player.x / GAME_CONSTANTS.TILE_SIZE),
				tileY: Math.floor(state.player.y / GAME_CONSTANTS.TILE_SIZE),
				regionX: Math.floor(state.player.x / GAME_CONSTANTS.REGION_SIZE),
				regionY: Math.floor(state.player.y / GAME_CONSTANTS.REGION_SIZE),
			}
		},
		mapName: state => state.round.map,
		kills: state => state.player.kills,
		currentPing: state => state.player.ping,
		isWinner: state => state.player.isWinner,
		causeOfDeath: state => state.player.causeOfDeath,
		health: state => state.player.health,
		hpHealingOverTime: state => state.player.hpHealingOverTime,
		shields: state => state.player.shields,
		alive: state => state.player.alive,
		scope: state => state.player.equipment.scope,
		laser: state => state.player.equipment.laser,
		hasKevlar: state => state.player.shields > 0,
		isHealingOverTime: state => state.player.isHealingOverTime,
		activeSlot: state => state.player.activeSlot,
		currentWeapon: state => state.player[state.player.activeSlot],
		slot1: state => state.player.slot1,
		slot2: state => state.player.slot2,
		useItem: state => state.player.useItem,
		useItemProgress: state => state.player.useItemProgress,
		useItemDuration: state => state.player.useItemDuration,
		bandages: state => state.player.bandages,
		medpacks: state => state.player.medpacks,
		painkillers: state => state.player.painkillers,
		fragGrenades: state => state.player.fragGrenades,
		smokeGrenades: state => state.player.smokeGrenades,
		resGrenades: state => state.player.resGrenades,
		molotovGrenades: state => state.player.molotovGrenades,
		//slot3: state => state.player.slot3,
		//slot4: state => state.player.slot4,
		//slot5: state => state.player.slot5,
		//slot6: state => state.player.slot6,
		slot1Ammo: state => state.player.slot1Ammo,
		slot1ClipSize: state => state.player.slot1ClipSize,
		slot2Ammo: state => state.player.slot2Ammo,
		slot2ClipSize: state => state.player.slot2ClipSize,
		currentWeaponAmmoRemaining: state => state.player[state.player.activeSlot + 'Ammo'],
		currentWeaponClipSize: state => state.player[state.player.activeSlot + 'ClipSize'],

		//team
		partyMemberIds: state => state.partyMemberIds,// available just in case, but not used i think
		teammates: state => state.party.members,
		teammate1: state => state.party.member1,
		teammate2: state => state.party.member2,
		teammate3: state => state.party.member3,
		teammate4: state => state.party.member4,
		teammatePositions: (state) => {
			let result = []
			for(let i = 1; i <= 4; i++) {
				let currentMember = state.party['member' + i]
				if(currentMember.hp > 0) {
					result.push( 
					{
						x: currentMember.x,
						y: currentMember.y
					})
				}
			}
			return result
		},

		// reloading
		reloadBarIsReloading: state => state.player.reloadBar.isReloading,
		reloadBarProgress: state => state.player.reloadBar.progress,
		reloadBarAttemptFumbled: state => state.player.reloadBar.attemptFumbled,
		reloadBarAttemptGoodEnough: state => state.player.reloadBar.attemptGoodEnough,
		reloadBarAttemptPerfect: state => state.player.reloadBar.attemptPerfect,
		reloadBarEarlyWindowStart: state =>
			state.player.reloadBar.earlyWindow.start,
		reloadBarEarlyWindowEnd: state =>
			state.player.reloadBar.earlyWindow.end,
		reloadBarPerfectWindowStart: state =>
			state.player.reloadBar.perfectWindow.start,
		reloadBarPerfectWindowEnd: state =>
			state.player.reloadBar.perfectWindow.end,

		missions: state => state.missions,
		missionsByCompletionPercent: state => {
			return state.missions.sort((mission1, mission2) => {
				let mission1p = mission1.progressCurrent / Math.max(1, mission1.progressMax)
				let mission2p = mission2.progressCurrent / Math.max(1, mission2.progressMax)

				if (mission1p < mission2p) {
					return -1
				} else if (mission1p > mission2p) {
					return 1
				} else {
					return 0
				}
			}).reverse()
		},
		missionsShortList: (state, getters) => {
			return getters.missionsByCompletionPercent.slice(0, 5)
		},

		postgame: state => state.round.postgame,
		battlepass: state => state.battlepass,
		battlepassRound: state => state.battlepass.round,
		battlepassMissionsCompletedThisRound: state => state.battlepass.round.missionsCompleted,
		showPostgame: state => state.round.postgame.showPostgame,
		showConfirmWatchAd: state => state.showConfirmWatchAd,


		// Non weapon item use
		triedToUseItem: state =>  
			 state.player.reloadBar.triedToUseItem,
			
		itemUnavailableText: state =>
			state.player.reloadBar.itemUnavailableText,

		// ad rewards
		nextAdTime: state => state.adRewards.nextAdTime,
		adsWatched: state => state.adRewards.adsWatched,
		hasAdReward: state => state.adRewards.hasAdReward,
		adsRequired: state => state.adRewards.adsRequired,
		adRewardId: state => state.adRewards.rewardId,
		friendlyAdRewardName: state => state.adRewards.friendlyName,
		adRewardsEarned: state => state.adRewardsEarned,
		unlockedCurrentAdReward: state => (state.adRewards.adRewardsEarned && state.adRewards.adRewardsEarned.skins &&
			state.adRewards.adRewardsEarned.skins.some(s => s.id === state.adRewards.rewardId))
	},
	actions: {
		async initClientHeartbeats({ dispatch }, identifier) {
			try {
				setTimeout(async () => {
					await heartbeatRequest(identifier)
					dispatch("initClientHeartbeats", identifier)
				}, 60000)
			} catch (e) {
				console.error(e)
			}
		},
		resetPlayerReloadBar({ commit, state }) {
			setTimeout(() => {
				if (state.player.reloadBar.attemptPerfect == true) {
					commit('reloadActionFinished')
				}
			}, 1000)
		},

		findNewMatch({ commit, state }) {
			let params = new URLSearchParams(window.location.search)
			let partyId = params.get('party_id')
			let redirectUrl = process.env.GAME_WEBSITE_URL
			if (partyId) {
				redirectUrl += '/play/party/' + partyId
			} else {
				redirectUrl += '/play'
			}
			window.location.replace(redirectUrl)
		},
		toggleEscapeMenu({ commit, state}) {
			commit('setEscapeMenuVisibility', !state.showEscapeMenu)
			window.mouseOverUI = state.showEscapeMenu

		},
		hidePostgame({ commit, dispatch }) {
			dispatch('clearPostgameTimer')
			commit('setShowPostgame', false)
			commit('setShowConfirmWatchAd', false)
		},
		showPostgame({ commit, dispatch, state }, delay) {
			if (state.identifierType === "registered") {
				dispatch('getAdRewardsTime')
			}

			dispatch('getCurrentAdRewards')

			dispatch('clearPostgameTimer')
			commit('setShowConfirmWatchAd', false)

			let timer = setTimeout(function () {
				commit('setShowPostgame', true)
			}, delay)
			commit('setPostgameTimer', timer)

			let timer2 = setTimeout(function () {
				commit('setShowPostgame', false)
			}, delay + 10000)
			commit('setHidePostgameTimer', timer2)
		},
		clearPostgameTimer({ commit, state }) {
			if (state.round.postgame.showPostgameTimer) {
				clearTimeout(state.round.postgame.showPostgameTimer)
				commit('setPostgameTimer', null)
			}
			if (state.round.postgame.hidePostgameTimer) {
				clearTimeout(state.round.postgame.hidePostgameTimer)
				commit('setHidePostgameTimer', null)
			}
		},
		goToVidAd({ commit, state }) {
			let params = new URLSearchParams(window.location.search)
			let partyId = params.get('party_id')
			let redirectUrl = process.env.GAME_WEBSITE_URL
			if (partyId) {
				redirectUrl += '/vidad/party/' + partyId
			} else {
				redirectUrl += '/vidad'
			}
			window.location.replace(redirectUrl)
		},
		swapShowConfirmWatchAd({ commit, state }) {
			commit('setShowConfirmWatchAd', !state.showConfirmWatchAd)
		},
		updateMusicVolume({state}, params) {
			const event = new CustomEvent('musicVolume', {detail: {volume: params}})
			document.dispatchEvent(event)
		},
		async changePreference({ state, commit }, params) {
			try {
				const { preference, newValue } = params
				commit("userDataFetching")

				if(preference === 'musicEnabled') {
					const event = new CustomEvent('musicEnabled', { detail: { isEnabled: newValue }})
					document.dispatchEvent(event)
				}
				else if(preference === 'musicVolume') {
					const event = new CustomEvent('musicVolume', {detail: {volume: newValue}})
					document.dispatchEvent(event)
				}

				const preferencesPayload = {
					sfxEnabled: state.settings.sfxEnabled,
					musicEnabled: state.settings.musicEnabled,
					disableHud: state.settings.disableHud,
					veganModeEnabled: state.settings.veganModeEnabled,
					showOwnHealthBar: state.settings.showOwnHealthBar,
					showOwnUsername: state.settings.showOwnUsername,
					sfxVolume: state.settings.sfxVolume,
					musicVolume: state.settings.musicVolume,
					[preference]: newValue
				}

				const preferencesHeaderBlob =
					state.identifierType === "registered"
						? { 'auth-token': state.identifier }
						: { 'anonymous-user-id': state.identifier }

				const updateResults = await updateGamePreferencesRequest(
					preferencesHeaderBlob,
					preferencesPayload
				)

				commit("updatedPreferences", updateResults.preferences)
			} catch (e) {
				console.error("Error occurred when attempting to change a user setting", e)
			} finally {
				commit("userDataNoLongerFetching")
			}
		},
		async getAdRewardsTime({ commit, state }) {
			try {
				let adResults = await getUserAdRewardsProgress(state.identifier)
				commit('updateAdRewards', adResults)
			} catch (err) {
				console.error("Error when getting ad reward timing", err)
			}
		},
		async getCurrentAdRewards({ commit }) {
			try {
				let results = await getCurrentAdRewards()
				commit('updateSeasonAdRewards', results)
			} catch (err) {
				console.error("Error when getting season ad rewards", err)
			}
		}
	},
	mutations: {
		identifierUpdated(state, { identifier, identifierType }) {
			state.identifier = identifier
			state.identifierType = identifierType
		},
		averagePing(state, ping) {
			state.averagePing = ping
		},
		updatePlayerCoords(state, coords) {
			state.player.x = coords.x
			state.player.y = coords.y
		},
		updateMap(state, map) {
			state.round.map = map
		},
		updatedPreferences: (state, latestPreferences) => {
			state.settings = { ...latestPreferences }
		},
		userDataFetching: state => {
			state.userDataFetching = true
		},
		userDataNoLongerFetching: (state) => {
			state.userDataFetching = false
		},
		newEnvironment(state, environment) {
			state.environment = environment
		},
		newPlayerPosition(state, pos) {
			state.round.playerPosition = pos
		},
		newHp(state, newHp) {
			state.player.health = newHp
		},
		newHpHealingOverTime(state, newHp) {
			state.player.hpHealingOverTime = newHp
		},
		newWeapon(state, newWeaponData) {
			let {
				type,
				reloadBarEarlyWindowStart,
				reloadBarEarlyWindowEnd,
				reloadBarPerfectWindowStart,
				reloadBarPerfectWindowEnd,
				clipSize,
			} = newWeaponData
			state.player.currentWeapon = type
			state.player.reloadBar.earlyWindow.start = reloadBarEarlyWindowStart * 100
			state.player.reloadBar.earlyWindow.end = reloadBarEarlyWindowEnd * 100
			state.player.reloadBar.perfectWindow.start = reloadBarPerfectWindowStart * 100
			state.player.reloadBar.perfectWindow.end = reloadBarPerfectWindowEnd * 100
			state.player[`${state.player.activeSlot}ClipSize`] = clipSize
		},
		winner(state, message) {
			let {
				totalKills,
				dmgDealt: damageDealt,
				numEntitiesDmgd: numberOfEntitiesDamaged
			} = message
			state.round.postgame.isWinner = true
			state.round.postgame.placement = 1
			state.round.postgame.totalKills = totalKills
			state.round.postgame.damageDealt = damageDealt
			state.round.postgame.numberOfEntitiesDamaged = numberOfEntitiesDamaged
			state.player.isWinner = true
		},
		updateRoundSummary(state, payload) {
			state.roundSummary = payload
		},
		selfDied(state, message) {
			let {
				placement,
				kills: totalKills,
				dmgDealt: damageDealt,
				numEntitiesDmgd: numberOfEntitiesDamaged,
				killerName,
				weaponType
			} = message

			state.round.postgame.isWinner = false
			state.round.postgame.totalKills = totalKills
			state.round.postgame.damageDealt = damageDealt
			state.round.postgame.numberOfEntitiesDamaged = numberOfEntitiesDamaged
			state.player.isWinner = false
			state.player.alive = false

			if (placement === -1) {
				state.round.postgame.placement = UNKNOWN_PLACEMENT
			} else {
				state.round.postgame.placement = placement
			}
			if (!killerName && !weaponType) {
				// died to gas
				state.round.postgame.killedByPoison = true
			} else {
				state.round.postgame.killedByPlayerName = killerName
				state.round.postgame.killedByWeaponType = weaponType
			}

		},
		updateTeamPlacement(state, placement) {
			if (placement === -1) {
				state.round.postgame.placement = UNKNOWN_PLACEMENT
			} else {
				state.round.postgame.placement = placement
			}
		},
		updateBattlepassProgression(state, battlepass) {
			let {
				level,
				experience,
				experienceEarnedNow,
				experiencePercentTilNextLevel,
				levelsGained,
			} = battlepass

			state.battlepass.level = level
			state.battlepass.experience = experience
			state.battlepass.experiencePercentTilNextLevel = experiencePercentTilNextLevel
			state.battlepass.round.experienceEarnedNow = experienceEarnedNow
			state.battlepass.round.levelsGained = levelsGained
		},
		newAmmo(state, { slot, ammo }) {
			state.player[`${slot}Ammo`] = ammo
		},
		startHealingOverTime(state) {
			state.player.isHealingOverTime = true
		},
		stopHealingOverTime(state) {
			state.player.isHealingOverTime = false
		},
		toggleChat(state) {
			state.round.chat.showing = !state.round.chat.showing

			if (!state.round.chat.showing) {
				window.mouseOverUI = false
			}
		},
		toggleEscapeMenu(state) {
			/* Need ot call this on whatever initially calls this to close
			let preference = 'musicVolume'
			dispatch('changePreference', {
				preference,
				newValue: state.settings.musicVolume
			})
			*/
			state.showEscapeMenu = !state.showEscapeMenu
			window.mouseOverUI = state.showEscapeMenu
		},
		useItemProgress(state, useReport) {
			const { useItem, useItemProgress, useItemDuration } = useReport
			state.player.useItem = useItem
			state.player.useItemProgress = useItemProgress
			state.player.useItemDuration = useItemDuration
		},
		useItemCancelled(state) {
			state.player.useItem = 0
			state.player.useItemProgress = 0
			state.player.useItemDuration = 0
		},
		useItemFinished(state) {
			state.player.useItem = 0
			state.player.useItemProgress = 0
			state.player.useItemDuration = 0
		},
		newItemUnavailableText(state, payload) {
			state.player.reloadBar.itemUnavailableText = payload
		},
		useItemWasAttempted(state) {
			state.player.reloadBar.triedToUseItem = true
		},
		endOfUseItemPopup(state) {
			state.player.reloadBar.triedToUseItem = false
		},
		newScope(state, newScope) {
			state.player.equipment.scope = newScope
		},
		newLaser(state, hasLaser) {
			state.player.equipment.laser = hasLaser
		},
		newBandages(state, amount) {
			state.player.bandages = amount
		},
		newPainkillers(state, amount) {
			state.player.painkillers = amount
		},
		newMedpacks(state, amount) {
			state.player.medpacks = amount
		},
		playerDied(state) {
			state.player.alive = false
		},
		newPlayersAlive(state, playersAlive) {
			state.round.playersAlive = playersAlive
		},
		toggleMenu(state) {
			state.settings.menuOpen = !state.settings.menuOpen
		},
		toggleScoreboard(state) {
			state.round.scoreboard.showing = !state.round.scoreboard.showing
		},
		toggleMap(state) {
			state.settings.worldMapOpen = !state.settings.worldMapOpen
		},
		newShields(state, newShieldValue) {
			state.player.shields = newShieldValue
		},
		newGameState(state, newRoundState) {
			state.round.roundState = newRoundState
		},
		setShowPostgame(state, postgame) {
			state.round.postgame.showPostgame = postgame
			state.settings.worldMapOpen = false

			if (!state.round.postgame.showPostgame) {
				window.mouseOverUI = false
			}
		},
		setPostgameTimer(state, timer) {
			state.round.postgame.showPostgameTimer = timer
		},
		setHidePostgameTimer(state, timer) {
			state.round.postgame.hidePostgameTimer = timer
		},
		missionListUpdate(state, missions) {
			state.missions = missions
		},
		missionsCompleted(state, missions) {
			state.battlepass.round.missionsCompleted = missions
		},
		togglePoisonShrinking(state) {
			state.round.poisonShrinking = !state.round.poisonShrinking
		},
		newKillCount(state, newCount) {
			state.player.kills = newCount
		},
		causeOfDeath(state, causeOfDeathMessage) {
			state.player.causeOfDeath = causeOfDeathMessage
		},
		newPing(state, ping) {
			state.player.ping = ping
		},
		newScoreboard(state, scoreboardJSON) {
			state.round.scoreboard.scores = JSON.parse(scoreboardJSON)
		},
		newServerMessage(state, serverMessage) {
			state.round.chat.messages = [
				...state.round.chat.messages,
				serverMessage
			]
		},
		newChatMessage(state, chatMessage) {
			state.round.chat.messages = [
				...state.round.chat.messages,
				chatMessage
			]
		},
		newPoisonDetails(state, poisonDetails) {
			state.round.poison = poisonDetails
		},
		newPlayerId(state, id) {
			state.player.id = id
		},
		newCharacterSkin(state, skin) {
			state.player.selectedCharacterSkin = skin
		},
		newWeaponSkin(state, skin) {
			state.player.selectedWeaponSkin = skin
		},
		newActiveSlot(state, payload) {
			state.player.activeSlot = payload
		},
		newSlot1(state, payload) {
			state.player.slot1 = payload
		},
		newSlot2(state, payload) {
			state.player.slot2 = payload
		},
		newSlot3(state, payload) {
			state.player.slot3 = payload
		},
		newSlot4(state, payload) {
			state.player.slot4 = payload
		},
		newSlot5(state, payload) {
			state.player.slot5 = payload
		},
		newSlot6(state, payload) {
			state.player.slot6 = payload
		},
		newFragGrenades(state, amount) {
			state.player.fragGrenades = amount
		},
		newSmokeGrenades(state, amount) {
			state.player.smokeGrenades = amount
		},
		newResGrenades(state, amount) {
			state.player.resGrenades = amount
		},
		newMolotovGrenades(state, amount) {
			state.player.molotovGrenades = amount
		},
		createTeamMember(state, entity) {
			if (entity.id === state.player.id) {
				// don't populate party slots for ourself
				return
			}
			const newMember = {
				id: entity.id,
				name: entity.name,
				hp: entity.hp,
				shields: entity.shields,
				slot1: entity.slot1,
				slot2: entity.slot2,
				x: entity.x,
				y: entity.y
			}

			// put the new teammember into the first empty slot
			const slots = ["member1", "member2", "member3", "member4"]
			for (let i = 0; i < slots.length; i++) {
				const member = state.party[slots[i]]
				if (member.id === -1) {
					Object.assign(member, newMember)
					member.slot = slots[i]
					member.outOfRange = false
					return
				}
			}

			// console.warn("vuex store did not have a slot for a party member")
		},

		updateTeamMember(state, payload) {
			// we only have the id, figure out which slot had this player in it
			const slots = ["member1", "member2", "member3", "member4"]
			for (let i = 0; i < slots.length; i++) {
				const member = state.party[slots[i]]
				if (member.id === payload.id) {
					member[payload.prop] = payload.value
					member.outOfRange = false
				}
			}
		},
		deleteTeamMember(state, id) {
			const slots = ["member1", "member2", "member3", "member4"]
			for (let i = 0; i < slots.length; i++) {
				const member = state.party[slots[i]]
				if (member.id === id) {
					//Object.assign(member, {
					//	outOfRange: true
					//})
					// reset the slot to being empty
					Object.assign(member, {
						id: -1,
						hp: 0,
						shields: 0,
						name: "anon",
						slot1: 0,
						slot2: 0
					})
				}
			}
		},
		registerGameClient(state, client) {
			state.gameClient = client
		},
		reloadActionStarted(state) {
			state.player.reloadBar.isReloading = true
			state.player.reloadBar.progress = 0
			state.player.reloadBar.attemptFumbled = false
			state.player.reloadBar.attemptPerfect = false
			state.player.reloadBar.attemptGoodEnough = false
		},
		reloadActionFinished(state) {
			state.player.reloadBar.isReloading = false
			state.player.reloadBar.progress = 0
			state.player.reloadBar.attemptFumbled = false
			state.player.reloadBar.attemptPerfect = false
			state.player.reloadBar.attemptGoodEnough = false
		},
		reloadActionProgress(state, currentProgress) {
			state.player.reloadBar.progress = currentProgress * 100
		},
		reloadAttemptWasFumbled(state) {
			state.player.reloadBar.attemptFumbled = true
		},
		reloadAttemptWasGoodEnough(state) {
			state.player.reloadBar.attemptGoodEnough = true
		},
		reloadAttemptWasPerfect(state) {
			state.player.reloadBar.attemptPerfect = true
			state.player.reloadBar.isReloading = false
		},
		newPartyMemberIds(state, ids) {
			state.partyMemberIds = ids
		},
		newSettings(state, payload) {
			state.settings.showOwnUsername = payload.showOwnUsername
			state.settings.showOwnHealthBar = payload.showOwnHealthBar
			state.settings.sfxEnabled = payload.sfxEnabled
			state.settings.musicEnabled = payload.musicEnabled
			if (state.settings.musicEnabled) {
				state.gameClient.soundSystem.playMusic()
			} else {
				state.gameClient.soundSystem.stopMusic()
			}
			state.settings.musicVolume = payload.musicVolume
			state.settings.sfxVolume = payload.sfxVolume
			state.settings.disableHud = payload.disableHud
			state.settings.veganModeEnabled = payload.veganModeEnabled
			state.settings.preferredLanguage = payload.preferredLanguage
			state.settings.hasBattlepass = payload.hasBattlepass
		},
		newKillfeedMessage(state, payload) {
			payload.time = (new Date()).getTime()
			if (payload.killerName) {
				if (!payload.resurrected) {
					if (!payload.suicide) {
						payload.image = Weapon.byIndex(payload.killedWith).programmaticName
					} else {
						payload.text = t("killfeedSuicide" + payload.variant)
					}
				} else {
					payload.text = t("killfeedResurrected" + payload.variant)
				}
			} else {
				payload.text = t("killfeedPoison" + payload.variant)
			}

			if (payload.killSpree) {
				if (payload.killSpree === 2) {
					payload.text = t("killfeed2Spree")
				} else if (payload.killSpree === 3) {
					payload.text = t("killfeed3Spree")
				}
			}
			if (payload.kills) {
				if (payload.kills === 5) {
					payload.text = t("killStreak5")
				} else  if (payload.kills === 10) {
					payload.text = t("killStreak10")
				}
			}

			state.round.killFeed = state.round.killFeed.slice(
				Math.max(0, state.round.killFeed.length - (GAME_CONSTANTS.MAX_KILLFEED_MESSAGES - 1)),
				state.round.killFeed.length)

			state.round.killFeed = [
				...state.round.killFeed,
				payload
			]
			// console.log(payload, state.round.killFeed)
		},
		setEscapeMenuVisibility(state, isVisible) {
			state.showEscapeMenu = isVisible
		},
		setShowConfirmWatchAd(state, isVisible) {
			state.showConfirmWatchAd = isVisible
		},
		updateAdRewards(state, adResults) {
			if (adResults === null) {
				state.adRewards.nextAdTime = null
				state.hasAdReward = false

				console.error("No ad results!!")
				return; 
			}

			const { numAdsWatched, adRewardsEarned, timeRemaining} = adResults
			
			const now = moment()
			now.add(timeRemaining.minutes, 'm')
			now.add(timeRemaining.seconds, 's')
			now.add(timeRemaining.hours, 'h')

			state.adRewards.adsWatched = numAdsWatched ? numAdsWatched : 0
			state.adRewards.nextAdTime = now
			state.adRewards.adRewardsEarned = adRewardsEarned
		},
		updateSeasonAdRewards(state, adRewards) {
			if (adRewards === null) {
				state.adRewards.hasAdReward = false
				return
			}

			state.adRewards.adsRequired = adRewards.numAdsRequiredToWatch
			state.adRewards.rewardId = adRewards.rewardIdentifier
			state.adRewards.friendlyName = adRewards.friendlyName

			state.adRewards.hasAdReward = true
		}
	}
})
