import Grid from "./Grid"

const Utils = {
	lastLogged: new Date().getTime(),

	angleBetweenPointsInRads: function (sourceX, sourceY, targetX, targetY) {
		return Math.atan2(targetY - sourceY, targetX - sourceX)
	},

	angleBetweenPointsInDegs: function (sourceX, sourceY, targetX, targetY) {
		return Math.atan2(targetY - sourceY, targetX - sourceX) * (180 / Math.PI)
	},

	randomBoolean: function () {
		return Math.random() >= 0.5
	},

	updateQueryStringParameter: function (uri, key, value) {
		var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i")
		if (uri.match(re)) {
			return uri.replace(re, "$1" + key + "=" + value + "$2")
		} else {
			var hash = ""
			if (uri.indexOf("#") !== -1) {
				hash = uri.replace(/.*#/, "#")
				uri = uri.replace(/#.*/, "")
			}
			var separator = uri.indexOf("?") !== -1 ? "&" : "?"
			return uri + separator + key + "=" + value + hash
		}
	},

	degToRad: function (deg) {
		return deg * (Math.PI / 180)
	},

	radToDeg: function (rad) {
		return rad * (180 / Math.PI)
	},

	convertMap: function (tiledMap) {
		var collisionLayer = tiledMap.layers.find(layer => {
			return layer.name === "Collisions"
		})

		var collisionGrid = new Grid(collisionLayer.width, collisionLayer.height)

		for (var i = 0; i < collisionLayer.data.length; i++) {
			// presuming that any tile value other than 0 means blocked
			collisionGrid.cells[i] = !!collisionLayer.data[i]
		}

		return collisionGrid
	},

	getRandomIntInclusive: function (upperBound, lowerBound) {
		lowerBound = lowerBound || 0
		lowerBound = Math.ceil(lowerBound)
		upperBound = Math.floor(upperBound)
		return (
			Math.floor(Math.random() * (upperBound - lowerBound + 1)) + lowerBound
		)
	},

	sampleLog: function (txt, data, frequency) {
		if (new Date().getTime() - this.lastLogged > (frequency || 500)) {
			// console.log(txt, data)
			this.lastLogged = new Date().getTime()
		}
	},

	depthSort: function (a, b) {
		if (a.y > b.y) {
			return 1
		} else if (a.y < b.y) {
			return -1
		} else {
			return 0
		}
	}
}

export default Utils
