import PRNG from '../../common/classes/PRNG'
import GAME_CONSTANTS from "../../common/balance/gameConstants"
import { Container, Graphics, Sprite, PI_2 } from "pixi.js-legacy"

class SmokeCloud extends Container {
	constructor(entity, renderer) {
		super()
		this.id = entity.id
		this.x = entity.x
		this.y = entity.y
		this.radius = entity.radius
		this.expansionDuration = entity.expansionDuration
		this.puffDuration = entity.puffDuration
		this.puffDurationRNGMod = entity.puffDurationRNGMod
		this.puffFrequency = entity.puffFrequency
		this.puffSize = entity.puffSize
		this.puffSizeRNGMod = entity.puffSizeRNGMod
		this.puffRotationSpeed = entity.puffRotationSpeed
		this.puffRotationSpeedRNGMod = entity.puffRotationSpeedRNGMod
		this.puffMaxAlpha = entity.puffMaxAlpha
		this.puffFadeTime = entity.puffFadeTime
		this.puffImage = entity.puffImage
		this.renderer = renderer
		this.prng = new PRNG(this.x - this.y)
		this.accumualtor = 0
		this.puffAccumulator = this.puffFrequency

		if (GAME_CONSTANTS.DEBUG.RENDER_HITBOXES) {
			let circleGraphics = new Graphics()
			circleGraphics.beginFill(0x88ffff, 0.2)
			circleGraphics.drawCircle(0, 0, this.radius)
			circleGraphics.endFill()
			this.addChild(circleGraphics)
		}
	}

	RNGMod(value, mod) {
		return value - mod + (this.prng.nextFloat() * mod * 2)
	}

	update(delta) {
		this.accumualtor += delta
		this.puffAccumulator += delta

		if (this.puffAccumulator >= this.puffFrequency) {
			this.puffAccumulator -= this.puffFrequency
			
			const r = this.prng.nextFloat() * this.radius * Math.min(1, this.accumualtor / this.expansionDuration)
			const theta = this.prng.nextFloat() * PI_2

			this.renderer.drawSmokePuff(
				this.x + (Math.cos(theta) * r), 
				this.y + (Math.sin(theta) * r), 
				this.puffImage, 
				this.RNGMod(this.puffDuration, this.puffDurationRNGMod),
				this.RNGMod(this.puffSize, this.puffSizeRNGMod),
				this.RNGMod(this.puffRotationSpeed, this.puffRotationSpeedRNGMod),
				this.puffMaxAlpha,
				this.puffFadeTime,
				this.prng
			)
		}
	}
}

export default SmokeCloud
