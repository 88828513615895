<template>
	<div class="wrapper">
        <div class="popup-text">
            <div class ="tried-to-heal" v-if="triedToUseItem">
				{{itemUnavailableText}}
			</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters([
            'triedToUseItem',
            'itemUnavailableText'
		]),
	},
}
</script>


<style lang='scss' scoped>
.wrapper {
	position: relative;
	text-align: center;

    .popup-text {
        margin-bottom: 15px;
        span {
            font-size: 34px;
            color: white;
            text-shadow: -1px -1px 0px rgba(0, 0, 0, 1),
                -1px 1px 0px rgba(0, 0, 0, 1), 1px -1px 0px rgba(0, 0, 0, 1),
                1px 1px 0px rgba(0, 0, 0, 1);

            &.empty {
                color: red;
            }
        }

        .tried-to-heal {
			color: red;
			font-size: 20px;
			margin-bottom: 5px;
			text-shadow: -1px -1px 0px rgba(0, 0, 0, 1),
				-1px 1px 0px rgba(0, 0, 0, 1), 1px -1px 0px rgba(0, 0, 0, 1),
				1px 1px 0px rgba(0, 0, 0, 1);
		}
    }
}
</style>