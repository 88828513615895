<template>
    <div class="slider-container">
        <div class="slider">
            <input :value="value" @input="handleDifferentSlide" type="range" :min="minValue" :max="maxValue"  class="slider">
	    </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
	components: {
	},
	props: [
        'minValue',
        'maxValue',
        'setting',
        'value'
	],  
    computed: {
        ...mapState(["settings"])
    },
    methods: {
        handleDifferentSlide(event) {
            event.target.settingType = this.setting
            this.$emit('input', event)
        }
    },
	mounted() {

	},
}
</script>

<style lang="scss" scoped>
.slider-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    .slider {
        -webkit-appearance: none;  /* Override default CSS styles */
        appearance: none;
        width: 100%; /* Full-width */
        height: 20px; /* Specified height */
        background: #d3d3d3; /* Grey background */
        outline: none; /* Remove outline */
        opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
        -webkit-transition: .2s; /* 0.2 seconds transition on hover */
        transition: opacity .2s;
        &:hover {
            opacity: 1;
        }
    }
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: url("../images/slider-icon.png");
        cursor: pointer; /* Cursor on hover */
    }
    .slider::-moz-range-thumb {
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: url("../images/slider-icon.png");
        cursor: pointer; /* Cursor on hover */
    }
}



</style>