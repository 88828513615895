import nengi from "nengi"

class InterpFixMessage {
	constructor(ids, ghosts, weapons) {
		this.ids = ids
		this.ghosts = ghosts
		this.weapons = weapons
	}
}

InterpFixMessage.protocol = {
	ids: { type: nengi.UInt16, indexType: nengi.UInt16 },
	ghosts: { type: nengi.Boolean, indexType: nengi.UInt16 },
	weapons: { type: nengi.Int8, indexType: nengi.UInt16 }
}

export default InterpFixMessage
