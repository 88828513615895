export default {
	clamp: function (min, value, max) {
		return Math.min(max, Math.max(min, value))
	},

	randInt: function (min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min)
	},

	randFloat: function (min, max) {
		return Math.random() * (max - min) + min
	},

	normalizeVector: function(vector) {
		let length = Math.sqrt((vector.x * vector.x) + (vector.y * vector.y))
		return {
			x: vector.x / length,
			y: vector.y / length
		}
	},
	
	colourVecToHex(colour) {
		let r = this.numberToOneByteHex(colour.r)
		let g = this.numberToOneByteHex(colour.g)
		let b = this.numberToOneByteHex(colour.b)
		return parseInt("0x" + r + g + b)
	},

	numberToOneByteHex(number) {
		let result = number.toString(16)
		if(result.length === 1)
			result = "0" + result
		else if(result.length > 2)
			result = "FF"
		return result
	},

	rollDice: function (dice, sides) {
		var value = 0
		while (dice > 0) {
			dice--
			var roll = Math.randInt(1, sides)
			value += roll
		}
		return value
	},

	randomPointNearPoint(x, y, xRand, yRand) {
		let result = {x: 0, y: 0}
		result.x = x + (Math.random() * xRand * 2) - xRand
		result.y = y + (Math.random() * yRand * 2) - yRand
		return result
	},

	getDistance: function (x, y, x2, y2) {
		let xd = x - x2
		let yd = y - y2
		return Math.sqrt(xd * xd + yd * yd)
	},

	getDistanceV: function (pos1, pos2) {
		let xd = pos1.x - pos2.x
		let yd = pos1.y - pos2.y
		return Math.sqrt(xd * xd + yd * yd)
	},

	getDistSq: function(x, y, x2, y2) {
		let xd = x - x2
		let yd = y - y2
		return (xd * xd + yd * yd)
	},

	radToDeg: function (rad) {
		return rad * 180 / Math.PI
	},

}

