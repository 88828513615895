import SmokeCloudGraphics from "../entity/SmokeCloud"

// I realize this is ghetto, but this is GSD because this is the only spatially aware sound that lasts longer than a split second
let soundInterval

export default ({ renderer, gameClient }) => {
	return {
		create: ({ data }) => {
			const entity = new SmokeCloudGraphics(data, renderer)

			if(gameClient && gameClient.soundSystem) {
				let soundId = gameClient.soundSystem.playSmokeGrenade(entity.x, entity.y)
				soundInterval = setInterval(function() {
					if(entity) {
						gameClient.soundSystem.updateSmokeGrenade(soundId, entity.x, entity.y)
					}
				}, 100)
			}

			renderer.entities.set(entity.id, entity)
			renderer.middleground.addChild(entity)

			return entity
		},
		delete: ({ id }) => {
			renderer.deleteEntity(id)
			clearInterval(soundInterval)
		},
		watch: {
		}
	}
}
