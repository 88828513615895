import nengi from "nengi"

class ScoreboardMessage {
	constructor(scoreboard) {
		this.scoreboard = scoreboard
	}
}

ScoreboardMessage.protocol = {
	scoreboard: nengi.String // JSON
}

export default ScoreboardMessage
