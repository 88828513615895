import { Container, Texture, Sprite } from "pixi.js-legacy"

class ShieldEffect extends Container {
	constructor(x, y) {
		super()
		this.x = x
		this.y = y
		this.isComplete = false
		this.animationFrame = 1
		this.accumulator = 0

		this.shieldSprite = new Sprite.from("shield_hit_1.png")
		this.shieldSprite.anchor.x = 0.5
		this.shieldSprite.anchor.y = 0.5
		this.shieldSprite.scale.x = 0.5
		this.shieldSprite.scale.y = 0.5
		this.addChild(this.shieldSprite)
	}

	update(delta) {
		this.accumulator += delta

		if (this.accumulator > 0.075) {
			this.animationFrame++
			if (this.animationFrame === 6) {
				this.isComplete = true
			}
			this.shieldSprite.texture = new Texture.from(
				"shield_hit_" + this.animationFrame + ".png"
			)
		}
	}
}

export default ShieldEffect
