import factory from './factory'
import GAME_CONSTANTS from '../balance/gameConstants'
import Weapon from './Weapon'
import PRNG from '../classes/PRNG'
import {
	canUseBandage,
	canUseMedpack,
	canUsePainkillers,
} from './usable'


// copies asthetic state (such as the weapon types)
// from the weapons system onto the entity's networked properties
const syncSlots = (weaponSystem, entity) => {
	entity.slot1 = weaponSystem.slot1.index
	entity.slot2 = weaponSystem.slot2.index
}

const canSwitchToSlot = (slot, entity) => {
	if (slot === 1 || slot === 2) {
		return true
	}
	if (slot === 3) {
		return entity.bandages > 0
	}
	if (slot === 4) {
		return entity.medpacks > 0
	}
	if (slot === 5) {
		return entity.painkillers > 0
	}
	return false
}

const channelingStrategy = {
	'slot3': { usable: canUseBandage },
	'slot4': { usable: canUseMedpack },
	'slot5': { usable: canUsePainkillers }
}

class WeaponSystem {
	constructor(myEntity) {
		this.entity = myEntity
		this.prng = new PRNG(666)

		this.activeSlot = 'slot1'
		this.lastActiveWeaponSlot = 'slot1'

		this.slot1 = factory(Weapon.byIndex(GAME_CONSTANTS.PLAYER.DEFAULT_WEAPON))
		if (process.env.NODE_ENV === 'local') {
			this.slot2 = factory(Weapon.byProgrammaticName('supershooty'))
		} else {
			this.slot2 = factory(Weapon.byIndex(GAME_CONSTANTS.PLAYER.DEFAULT_WEAPON))
		}

		this.slot1.currentAmmo = this.slot1.config.clipSize
		this.slot2.currentAmmo = this.slot2.config.clipSize

		this.slot3 = factory(Weapon.byName('BANDAGE'))
		this.slot4 = factory(Weapon.byName('MEDPACK'))
		this.slot5 = factory(Weapon.byName('PAINKILLERS'))

		this.channelAcc = 0

		this.globalCd = false
		this.globalAcc = 0.100 // a cooldown inbetween misc actions

		this.syncToEntity(this.entity)
	}

	triggerGlobalCd(cd = 0.100) {
		this.globalCd = true
		this.globalAcc = cd // a cooldown inbetween misc actions
	}

	isWeaponActive() {
		return this.activeSlot === 'slot1' || this.activeSlot === 'slot2'
	}

	isItemActive() {
		return this.activeSlot === 'slot3' || this.activeSlot === 'slot4' || this.activeSlot === 'slot5'
	}

	cancelChanneling() {
		this.channelAcc = 0
	}

	channeling(entity, delta) {
		// NOTE: this function uses the entity passed to it,
		// despite there being a this.entity, unknown if this matters
		const useReport = {
			valid: false,
			current: 0, // aesthetic only for the ui bar, not for logic
			max: this.currentWeapon.usageTime,
			slot: this.activeSlot,
			complete: false // triggers use<item> logic
		}

		if (channelingStrategy[this.activeSlot].usable(entity)) {
			this.channelAcc += delta
			useReport.valid = true
			useReport.current = this.channelAcc
		}

		if (this.channelAcc > this.currentWeapon.usageTime) {
			useReport.complete = true
			this.channelAcc = 0
		}
		return useReport
	}

	reset() {
		this.activeSlot = 'slot1'
		this.slot1 = factory(Weapon.byIndex(GAME_CONSTANTS.PLAYER.DEFAULT_WEAPON))
		if (process.env.NODE_ENV === 'local') {
			this.slot2 = factory(Weapon.byProgrammaticName('supershooty'))
		} else {
			this.slot2 = factory(Weapon.byIndex(GAME_CONSTANTS.PLAYER.DEFAULT_WEAPON))
		}

		this.slot1.currentAmmo = this.slot1.config.clipSize
		this.slot2.currentAmmo = this.slot2.config.clipSize
		this.syncToEntity(this.entity)
	}

	get currentType() {
		return this[this.activeSlot].index
	}

	get currentWeapon() {
		return this[this.activeSlot]
	}

	set currentWeapon(weaponType) {
		this[this.activeSlot] = factory(Weapon.byIndex(weaponType))
	}

	interruptReload() {
		if (this.currentWeapon) {
			// hides reload bar
			this.cancelChanneling()
			this.currentWeapon.interruptReload()
		}
	}


	cl_changeWeapon(slot, type) {
		//console.log('cl_changeweapon')
		this.interruptReload()
		this.cancelChanneling()
		this[slot] = factory(Weapon.byIndex(type))
		this.interruptReload()
	}

	pickup(item) {
		this.currentWeapon = item.weaponType
		this.currentWeapon.currentAmmo = item.ammo
		// TODO: is the aesthetic value of entity.weapon still needed?
		if (this.entity) {
			//this.entity.weapon = item.weaponType
		}
	}

	syncToEntity(entity) {
		syncSlots(this, entity)
	}

	tryFire(epsilon, clicked, optionalAmmoCb) {
		if (this.globalCd) {
			return { canFire: false }
		}

		return this.currentWeapon.tryFire(epsilon, clicked, optionalAmmoCb)
	}

	resetFire() {
		this.currentWeapon.isFiring = false
	}

	startReload(
		endCb,
		progressCb,
		activeReloadFailCb,
		activeReloadSuccessCb,
		activeReloadGoodEnoughCb
	) {
		if(this.globalCd) {
			return false
		}
		this.prng = new PRNG(666)
		return this.currentWeapon.startReload(
			endCb,
			progressCb,
			activeReloadFailCb,
			activeReloadSuccessCb,
			activeReloadGoodEnoughCb
		)
	}

	// sv only
	switchWeapon(weaponType) {
		this.currentWeapon.interruptReload()
		this.currentWeapon = weaponType
		this.triggerGlobalCd()
		this.interruptReload()
		//console.log('switched to', this.currentWeapon.name)
	}

	backToWeapon() {
		this.activeSlot = this.lastActiveWeaponSlot
		this.triggerGlobalCd(0.400)
	}

	changeSlot(num, entity) {
		this.currentWeapon.isFiring = false
		this.currentWeapon.resetEffectiveCooldown()
		this.triggerGlobalCd()
		if (this.activeSlot === 'slot1' || this.activeSlot === 'slot2') {
			this.lastActiveWeaponSlot = this.activeSlot
			this.interruptReload()
		}

		if (canSwitchToSlot(num, entity)) {
			this.activeSlot = `slot${num}`
			this.currentWeapon.isFiring = false
			this.interruptReload()
			//console.log('active', this.activeSlot)
			//console.log('change slot', this.activeSlot, this.currentWeapon.currentAmmo, '/', this.currentWeapon.clipSize)
		} else {
			// console.log('unable to switch slots', num, 'insufficient quantity')
		}
	}

	update(delta) {
		if (this.globalCd) {
			this.globalAcc -= delta
			//console.log('gc', this.globalAcc)
			if (this.globalAcc <= 0) {
				this.globalCd = false
			}
		}
		this.currentWeapon.update(delta)
	}
}

export default WeaponSystem
