
export default {
	name: "SmokeEmitter",
	image: "fire-fx.png",
	emissionType: "circle",
	lifetime: 10,
	particleLife: 5,
    outerRadius: 30,
	maxParticles: 300,
	blendMode: "LIGHTEN",
	emissionRate: 5,
	attributes: [
		{
			type: "VELOCITY",
			randomVelocity: {
				minX: -5,
				maxX: 5,
				minY: -20,
				maxY: -10
			}
		},
		{
			type: "SCALE",
			squareScale: true,
			scaleOverTime: true,
			randomScale: {
				minX: 0,
				maxX: 0.1
			},
			finalScale: {
				x: 1.3
			}
		},
		{
			type: "COLOUR",
			initialColour: {
				r: 180,
				g: 180,
				b: 180,
			}
		},
        {
            type: "ALPHA",
            initialAlpha: 0.4,
            alphaOverTime: true,
            finalAlpha: 0
        }
	]
}
