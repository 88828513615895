import nengi from "nengi"

class WeaponPickupFallback {
	constructor() {
	}
}

WeaponPickupFallback.protocol = {
}

export default WeaponPickupFallback
