import missions from '../core/MissionTracker'
import Scoreboard from './Scoreboard'
import Weapon from '../../common/weapon/Weapon'

const resetRoundStats = (client) => {
	client.roundStats = {
		wins: 0,
		shotsFired: 0,
		placement: 99,
		kills: 0,
		deaths: 0,
		damageDealt: 0,
		damageTaken: 0,
		healed: 0,
		distance: 0,
		poisonDamage: 0,
		playerResurrects: 0,
		selfResurrects: 0,
		// count of each item gathered
		gathered: {},
		// count of each item used
		used: {},
		// damage dealt per weapon
		weaponDamages: {},
		// kills obtained per weapon
		weaponKills: {},
		// unique entities damaged
		victimIds: new Set()
	}
}

const updatePlacement = (player, teams) => {
	// current placement
	const placement = teams.countLiving() + 1
	// is my whole team dead? then this all of our placements!
	const { team } = player
	if (team.countLiving() === 0) {
		team.placement = placement
		team.members.forEach(member => {
			if (member.client) {
				setPlacement(member.client, placement)
			}
		})
	}
	return placement
}

const mutate = (state, prop, type, amount) => {
	if (!state[prop][type]) {
		state[prop][type] = 0
	}
	state[prop][type] += amount
}

const setPlacement = (client, placement) => {
	client.roundStats.placement = placement
	missions.trackPlacement(client.missionState, placement)
}

const addPlayerResurrect = (client) => {
	client.roundStats.resurrections++
	missions.trackResurrection(client.missionState)
}

const addSelfResurrect = (client) => {
	client.roundStats.selfResurrects++
}

const addPoisonDamage = (client, amount) => {
	client.roundStats.poisonDamage += amount
	// TODO triggers
}

const addDistance = (client, amount) => {
	client.roundStats.distance += amount
	missions.trackMovement(client.missionState, amount)
}

const gather = (client, itemType) => {
	const canonicalName = Weapon.byIndex(itemType).canonicalName
	mutate(client.roundStats, 'gathered', canonicalName, 1)
	missions.gatherAdapter(client, itemType)
}

const use = (client, itemType) => {
	const canonicalName = Weapon.byIndex(itemType).canonicalName
	mutate(client.roundStats, 'used', canonicalName, 1)
	missions.useAdapter(client, itemType)
}

const addWin = (client) => {
	client.roundStats.wins++
	client.roundStats.placement = 1
}

const addShotFired = (client) => {
	client.roundStats.shotsFired++
}

const addDeath = (client, teams) => {
	client.roundStats.deaths++
	Scoreboard.ghostify(client.entity.id)
	missions.trackDeath(client.missionState)
	updatePlacement(client.entity, teams)
}

const addHeal = (client, amount) => {
	client.roundStats.healed += amount
	missions.trackHealing(client.missionState, amount)
}

const addWeaponDamage = (attacker, victim, itemType, amount) => {
	const { roundStats: attackerRoundStats } = attacker
	attackerRoundStats.damageDealt += amount
	const canonicalName = Weapon.byIndex(itemType).canonicalName
	mutate(attackerRoundStats, 'weaponDamages', canonicalName, amount)
	attackerRoundStats.victimIds.add(victim.entity.id)

	const { roundStats: victimRoundStats } = victim
	victimRoundStats.damageTaken += amount

	missions.trackDamage(attacker.missionState, itemType, amount)
}

const addKill = (attacker, victim, itemType) => {
	if (attacker.entity.id == victim.entity.id) { return } // suicide isn't good enough!
	const { roundStats: attackerRoundStats } = attacker
	attackerRoundStats.kills++
	attacker.entity.addRapidKill()
	const canonicalName = Weapon.byIndex(itemType).canonicalName
	mutate(attackerRoundStats, 'weaponKills', canonicalName, 1)
	missions.trackKill(attacker.missionState, attacker.entity, victim.entity, itemType)
	Scoreboard.addKill(attacker.entity.id)
}

export {
	resetRoundStats,
	addPoisonDamage,
	addHeal,
	addDistance,
	gather,
	use,
	addWeaponDamage,
	addKill,
	addDeath,
	addWin,
	addShotFired,
	addPlayerResurrect,
	addSelfResurrect
}
