export default {
	name: "Molotov",
	programmaticName: "molotov",
	canonicalName: "GRENADE_Molotov",
	itemClass: "grenade",
	itemSubclass: "grenade",
	prop: "molotovGrenades",
	image: "molotov_grenade.png",
	thrownImage: "molotov_grenade.png",
	outlineImage: "ak47_outline.png",
	spawnWeighting: 175,
	thrownEffect: 'ThrownFire',
	scaleDown: false,
	throwMod: 0.55,
	rollMod: 0,
	minThrowForce: 10,
	maxThrowForce: 150,
	damageNear: 45,
	damageFar: 0,
	explosionRadius: 35,
	fuseTimer: 10, // explodes on contact, so the timer just stops premature mid-travel explosions
	corpseForce: 50,
	carryLimit: 4,
	createSmoke: false,
	gunCooldown: 0.4,
	impactExplosion: true,
	fireDuration: 10
}
