import Projectile from '../../server/entity/Projectile'
import CollisionSystem from '../../common/classes/CollisionSystem'
import tiledLoaderMiddleware from '../visuals/tiledLoaderMiddleware'
import MapInterface from '../../common/map/MapInterface'
import Poison from '../../server/core/Poison'
import GAME_CONSTANTS from '../../common/balance/gameConstants'
import store from '../ui/store'
import drawPositions from './positionDebugger'
import MathUtils from "../../common/misc/mathUtils"
import Weapon from '../../common/weapon/Weapon'

const ignoreProps = ['allegedX', 'allegedY', 'rotation', 'slot1', 'slot2', 'slot1Ammo', 'slot2Ammo']
const shouldIgnore = (myId, update) => {
	if (update.id === myId) {
		if (ignoreProps.indexOf(update.prop) !== -1) {
			return true
		}
	}
	return false
}

class Simulator {
	constructor(renderer, client, soundSystem) {
		this.renderer = renderer
		this.client = client
		this.mapInterface = null
		this.collisionSystem = null
		this.poison = new Poison(null, null, 'bruhward')
		this.entities = new Map()
		this.projectiles = new Map()
		this.grenades = new Map()

		this.partyMemberIds = []

		this.soundSystem = soundSystem
		this.predictedEntity = null
		this.firingDesyncSum = 0

		/* since we are making up ids for the clientside simulated projectiles,
        let's use an id that wouldn't have been valid over the network anyways */
		this._tempId = 65536 // bigger than UInt16

		this.client.on('message::WeaponFired', message => {
			if (message.sourceId !== this.myId) {
				const weaponFiring = Weapon.byIndex(message.weaponType)
				// if the raycast already hit us on the server, then we spoof this projectile and draw it going right towards us
				if (message.victimId === this.myId && !(weaponFiring.pinsTarget && message.fatalShot)) {
					// If it hits us, draw it going right to us!!
					// the randomness is just to make it look a little natural (+/- 4 pixels)d
					let randResult = MathUtils.randomPointNearPoint(this.predictedEntity.x, this.predictedEntity.y, 4, 4)
					message.targetX = randResult.x
					message.targetY = randResult.y
				}

				// else just draw what the server said
				const line = {
					startX: message.x,
					startY: message.y,
					endX: message.targetX,
					endY: message.targetY
				}

				// If we have some offsets to apply though apply those
				if(weaponFiring.predictFromBarrelTip) {
					let hypSq = MathUtils.getDistSq(line.startX, line.startY, line.endX, line.endY)
					let oppSq =  Math.pow(line.endY - line.startY, 2)

					let aim = Math.asin(oppSq / hypSq)
					if(line.endX < line.startX) {
						let halfPi = Math.PI / 2
						aim = ((halfPi - aim) + halfPi)
					}
					if(line.endY < line.startY)
						aim = -aim

					let offset = this.applyWeaponBulletOffsets(aim, weaponFiring)
					line.startX += offset.x
					line.startY += offset.y
				}


				const projectileData = {
					color: 0xff00ff,
					initialScale: weaponFiring.customBulletScale,
					scaleOverTime: !weaponFiring.dontScaleBulletOverTime,
					image: weaponFiring.bulletImage,
					pinsTarget: weaponFiring.pinsTarget,
					lifeAfterHit: weaponFiring.bulletLifeAfterHit,
					customPivot: weaponFiring.customProjectilePivot
				}
				this.beginProjectileWithDestination(line, message.sourceId, projectileData, message.victimId, message.fatalShot, weaponFiring.instantSpeedProjectile)
				if (this.soundSystem) {
					this.soundSystem.playGunShot(message)
				}
			}
		})

		this.client.on('message::DebugPositions', message => {
			//console.log(message)
			drawPositions(message, this.renderer)

		})

		this.client.on('message::MapName', message => {
			this.poison.receivedMapName(message.name)
			this.initializeCollisionSystem(message.name)
		})
		
		this.client.on('message::ChangeMap', message => {
			this.poison.receivedMapName(message.name)
			try {
				this.reinitializeMapValues(message.name)
			}
			catch(error) {
				console.log(error)
				// Dealing with cache issues here, taking away resources but still trying to load, no idea really
				this.renderer.forceReload()
			}

		})

		this.client.on('message::NetSound', message => {
			this.soundSystem.playItemInteract(message.x, message.y)
		})

		this.client.on("message::EntityHit", message => {
			this.soundSystem.playBulletImpact(message.x, message.y)
			if(message.pinsTarget) {
				if(message.fatalShot) {
					let closestProj = this.getClosestPinningProjectile(message.x, message.y)
					if(closestProj) {
						this.renderer.registerProjectileForCorpse(closestProj)
					}
				}
				else {
					const entity = this.renderer.entities.get(message.entityId)
					let closestProj = this.getClosestPinningProjectile(message.x, message.y)
					if(closestProj) {
						closestProj.stickToEntity(entity, {x: entity.x - message.x, y: entity.y - message.y})
					}
				}
			}
		})

		this.client.on("message::GameStateChange", message => {
			if(message.newState === "postgame") {
				this.projectiles.forEach(projectile => {
					this.projectiles.delete(projectile.id)
					this.renderer.removeProjectile(projectile.id)
				})
			}
		})

		this.client.on("message::Explosion", message => {
			this.soundSystem.playExplosion(message.x, message.y)
		})

		this.client.on("message::MolotovExplosion", message => {
			this.soundSystem.playMolotovExplosion(message.x, message.y)
		})

		this.client.on('message::PoisonMessage', message => {
			this.poison.sync(message)
		})

		this.client.on('message::Identity', message => {
			this.myId = message.entityId

			this.client.entityUpdateFilter = (update) => {
				return shouldIgnore(message.entityId, update)
			}
		})

		this.client.on('message::Settings', message => {
			store.commit('newSettings', message)
		})

		client.on('message::PartyIdentities', message => {
			this.partyMemberIds = message.ids
			store.commit('newPartyMemberIds', message.ids)
		})

		this.client.on('message::UpdatedKillCountMessage', message => {
			if (this.soundSystem) {
				if (message.newKilled === 5) {
					this.soundSystem.playKillingSpree()
				} else if (message.newKilled === 10) {
					this.soundSystem.playFrenzy()
				} else if (message.rapidKilled === 2) {
					this.soundSystem.playDoubleKill()
				} else if (message.rapidKilled === 3) {
					this.soundSystem.playTripleKill()
				}
			}
		})

		this.client.on("message::WeaponPickupFallback", message => {
			this.predictedEntity.weaponSystem.resetFire()
		})
	}

	applyWeaponBulletOffsets(aim, weapon) {
		let yOffset = 0
		let xOffset = 0

		if(weapon.predictFromBarrelTip) {
			// Now we need to shift along the x
			xOffset = Math.cos(aim) * weapon.barrelLength / 2
			yOffset = Math.sin(aim) * weapon.barrelLength / 2
		}

		if(weapon.shiftProjectileY) {
			let overHalfway = (Math.abs(aim) >= Math.PI / 2)
			// Need to shift the start based on our aim for some guns we hold lower
			yOffset += overHalfway ? Math.cos(aim) * weapon.shiftProjectileY : -Math.cos(aim) * weapon.shiftProjectileY
			xOffset += overHalfway ? -Math.sin(aim) * weapon.shiftProjectileY : Math.sin(aim) * weapon.shiftProjectileY
		}
		return {x: xOffset, y: yOffset}
	}

	initializeCollisionSystem(mapName) {
		let selectedMap
		if (mapName === 'bruhnswick') {
			selectedMap = require('../../common/map/bruhnswick/bruhnswick.json')
		} else if (mapName === 'bruhward') {
			selectedMap = require('../../common/map/bruhward/bruhward.json')
		}
		window.PIXILoader.reset()
		window.PIXILoader.add(selectedMap)
		window.PIXILoader.use(tiledLoaderMiddleware)
		window.PIXILoader.load((loader, resources) => {
			let mapResource = resources[selectedMap]
			if (!mapResource) {
				throw new Error('failed to load map, file not found')
			}
			this.mapInterface = new MapInterface(mapResource.data, mapName)
			this.collisionSystem = new CollisionSystem(
				this.mapInterface.collisionGrid,
				GAME_CONSTANTS.TILE_WIDTH,
				GAME_CONSTANTS.TILE_HEIGHT
			)
			this.renderer.loadMap(mapResource)
		})
		store.commit('updateMap', mapName)
	}

	reinitializeMapValues(mapName) {
		let selectedMap
		if (mapName === 'bruhnswick') {
			selectedMap = require('../../common/map/bruhnswick/bruhnswick.json')
		} else if (mapName === 'bruhward') {
			selectedMap = require('../../common/map/bruhward/bruhward.json')
		}
		//this.renderer.printChildCount()
		window.PIXILoader.reset()
		window.PIXILoader.add(selectedMap)
		//window.PIXILoader.use(tiledLoaderMiddleware)
		window.PIXILoader.load((loader, resources) => {
			let mapResource = resources[selectedMap]
			if (!mapResource) {
				throw new Error('failed to load map, file not found')
			}
			this.mapInterface = new MapInterface(mapResource.data, mapName)
			this.collisionSystem = new CollisionSystem(
				this.mapInterface.collisionGrid,
				GAME_CONSTANTS.TILE_WIDTH,
				GAME_CONSTANTS.TILE_HEIGHT
			)
			this.renderer.loadMap(mapResource, true)
		})
		store.commit('updateMap', mapName)
	}

	// used to draw a projectile along a *known* course sent to us by the server
	beginProjectileWithDestination(line, sourceId, projectileData, victimId, fatalShot, instantSpeed) {//, fakeStartX, fakeStartY) {
		//console.log('beginProjectileWithDestination', line, sourceId, color, fakeStartX, fakeStartY)
		const projectile = new Projectile(
			//fakeStartX || line.startX,
			//fakeStartY || line.startY,
			line.startX,
			line.startY,
			line.endX,
			line.endY,
			0,
			instantSpeed,
			projectileData.pinsTarget,
			projectileData.lifeAfterHit,
			fatalShot
		)

		if(projectileData.pinsTarget && victimId !== 0 && !fatalShot) {
			const entity = this.renderer.entities.get(victimId)

			if(this.myId === victimId)
				projectile.stickToEntity(entity, {x: entity.x - line.endX, y: entity.y - line.endY})
			else {
				let randPoint = MathUtils.randomPointNearPoint(entity.x, entity.y, 4, 4)
				projectile.stickToEntity(entity, {x: entity.x - randPoint.x, y: entity.y - randPoint.y})
			}
		}

		projectile.sourceId = sourceId
		projectile.color = projectileData.color // store this so that on-hit effects can be colored
		projectile.id = this._tempId++
		this.projectiles.set(projectile.id, projectile)
		projectile.graphicsRef = this.renderer.createAndSyncProjectile(
			projectile,
			projectileData
		)
	}


	// used in client-side prediction to draw a projectile and guesstimate about it making an impact
	//beginProjectileSimulation(line, sourceId, projectileData, instantSpeed, fakeStartX, fakeStartY) {
	beginProjectileSimulation(line, sourceId, projectileData, instantSpeed, weaponDamage) {
		//console.log('beginProjectile', line, sourceId, color, fakeStartX, fakeStartY)
		let alreadyHit = false
		const projectile = new Projectile(
			//fakeStartX || line.startX,
			//fakeStartY || line.startY,
			line.startX,
			line.startY,
			line.endX,
			line.endY,
			0,
			instantSpeed,
			projectileData.pinsTarget,
			projectileData.lifeAfterHit
		)

		if (store.getters.debugHitscan) {
			this.renderer.drawDebugWeaponFired({
				x: line.startX,
				y: line.startY,
				targetX: line.endX,
				targetY: line.endY
			})
		}


		// technically this may not always be accurate, albeit purely asethetic and fake
		// this seems like it has a chance of drawing the wrong projectile if the ray passes
		// through two entities
		this.client.sims.forEach(entity => {
			if (alreadyHit) {
				return
			}
			if (entity.id === sourceId) {
				return
			}
			if (entity.isGhost) {
				return
			}
			if (entity.protocol.name === 'WeaponItem') {
				return
			}

			const hit = this.collisionSystem.checkLineCircle(
				line.startX,
				line.startY,
				line.endX,
				line.endY,
				entity.collider
			)
			
			if (hit) {
				alreadyHit = true
				let weThinkKills = ( ( (entity.hp + entity.shields) - weaponDamage ) <= 0)
				if( !(projectile.pinsTarget && weThinkKills) ) {
					let randResult = MathUtils.randomPointNearPoint(entity.x, entity.y, 4, 4)
					projectile.targetX = randResult.x
					projectile.targetY = randResult.y
				} 
				projectile.fatalShot = weThinkKills
				projectile.hitEntityId = entity.id
			}
		})

		projectile.sourceId = sourceId
		projectile.color = projectileData.color // store this so that on-hit effects can be colored
		projectile.id = this._tempId++
		this.projectiles.set(projectile.id, projectile)
		projectile.graphicsRef = this.renderer.createAndSyncProjectile(
			projectile,
			projectileData
		)
	}

	getClosestPinningProjectile(x, y) {
		let closest = undefined
		let closestDistSq = 999999
		this.projectiles.forEach(proj => {
			if(proj.pinsTarget) {
				let lengthSq = MathUtils.getDistSq(x, y, proj.x, proj.y)
				if(closestDistSq > lengthSq) {
					closestDistSq = lengthSq
					closest = proj
				}

			}
		})
		return closest
	}

	// Not sure if this is still relevant or used at all - 2022-04-07
	simulateWeaponFired(message) {
		if (this.collisionSystem) {
			const line = this.collisionSystem.computeStartAndEnd(message)
			this.beginProjectileSimulation(line, message.sourceId, 0xff00ff, message.instantSpeed)
		}
	}

	update(delta) {
		// laser sight AB45203628E28928D1FB446B5C6EB50C
		if (this.collisionSystem && this.renderer.AB45203628E28928D1FB446B5C6EB50C) {
			const lineCoords = this.collisionSystem.computeRay({
				x: this.renderer.AB45203628E28928D1FB446B5C6EB50C.x,
				y: this.renderer.AB45203628E28928D1FB446B5C6EB50C.y
			},
				this.renderer.AB45203628E28928D1FB446B5C6EB50C.aim,
				400
			)

			const distance = this.collisionSystem.calculateDistance(
				lineCoords.startX,
				lineCoords.startY,
				lineCoords.endX,
				lineCoords.endY
			)
			this.renderer.AB45203628E28928D1FB446B5C6EB50C.updateLaserSight(distance * 2)
		}

		this.renderer.update(delta)
		this.projectiles.forEach(projectile => {
			projectile.update(delta)
			if (projectile.needsRemoved) {
				this.renderer.drawBulletEnd(
					projectile.collidedWithPlayer,
					projectile.targetX,
					projectile.targetY
				)
				if (typeof projectile.hitEntityId !== 'undefined') {
					this.renderer.showOnHitEffect(
						projectile.hitEntityId,
						projectile.color
					)
				}
				this.projectiles.delete(projectile.id)
				this.renderer.removeProjectile(projectile.id)
			} else {
				// move the graphics, too
				projectile.graphicsRef.x = projectile.x
				projectile.graphicsRef.y = projectile.y
			}
		})

		let inverseScale = 1 / this.renderer.masterScale

		if (this.renderer.AB45203628E28928D1FB446B5C6EB50C) {
			
			let view = {
				x: -this.renderer.camera.x * inverseScale,
				y: -this.renderer.camera.y * inverseScale,
				width: 1280 * inverseScale,
				height: 720 * inverseScale
			}
			let poi = this.poison.viewIntersection(view)
			this.renderer.drawPoison(poi)
		}
	}
}

export default Simulator
