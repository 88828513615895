import nengi from "nengi"

class UpdatedKillCountMessage {
	constructor(newKilled, rapidKilled) {
		this.newKilled = newKilled
		this.rapidKilled = rapidKilled
	}
}

UpdatedKillCountMessage.protocol = {
	newKilled: nengi.Number,
	rapidKilled: nengi.Number
}

export default UpdatedKillCountMessage
