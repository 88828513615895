export default {
	name: "8x Scope",
	programmaticName: "eightxscope",
	canonicalName: "EIGHT_X_SCOPE",
	itemClass: "scope",
	itemSubclass: "scope",
	equipSlot: "equipmentScope",
	spawnWeighting: 70,
	zoomLevel: 2.0,
	image: "8x_scope.png",
	uiImage: "8xscope.png",
	scaleDown: true
}
