export default {
	name: "QBZ95",
	programmaticName: "qbz95",
	canonicalName: "AR_QBZ95",
	itemClass: "weapon",
	itemSubclass: "assault_rifle",
	image: "qbz95_default.png",
	heldImage: "qbz95_hands.png",
	outlineImage: "qbz95_outline.png",
	soundName: "ASSAULT_RIFLE",
	reloadSoundName: "ASSAULT_RIFLE_reloading",
	spawnWeighting: 75,
	grenadeProjectiles: false,
	damage: 18,
	cooldown: 0.3,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 12,
	recoilRecoveryPeriod: 0.5,
	recoilMax: 7,
	clipSize: 15,
	kick: 40,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 30,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 325,
	corpseForce: 100,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 2.7,
	activeStart: 0.6,
	activeEnd: 0.65,
	uiRotateDeg: -39,
	uiWidth: 94,
	uiTop: 39,
	uiLeft: 56
}
