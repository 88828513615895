import nengi from "nengi"

class KillStreakMessage {
	constructor(name, kills) {
		this.name = name
		this.kills = kills
	}
}

KillStreakMessage.protocol = {
	name: nengi.String,
	kills: nengi.Number,
}

export default KillStreakMessage
