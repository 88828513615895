import nengi from "nengi"
import nengiConfig from "../../common/misc/nengiConfig"
import InputSystem from "./InputSystem"
import SoundSystem from "./SoundSystem"
import PIXIRenderer from "../visuals/PIXIRenderer"
import P7DA84105F9F54B2D20C66308619D78D from "../command/P7DA84105F9F54B2D20C66308619D78D"
import PlayerUseItem from "../command/PlayerUseItem"
import PlayerSubmitChatMessage from "../command/PlayerSubmitChatMessage"
import Predictor from "../lag_compensation/Predictor"
import Simulator from "../lag_compensation/Simulator"
import store from "../ui/store"
import messageDispatcher from "./messageDispatcher"
import createFactories from "../factories/createFactories"
import niceClientExtension from "./niceClientExtension"
import GAME_CONSTANTS from "../../common/balance/gameConstants"
import { stopLoadingAndDisplayError, showLoadingScreen } from "../ui/loading"
import Weapon from "../../common/weapon/Weapon"

const chatBoxWidth = 366
const chatBoxHeight = 35
const isNotChattingAndDoesNotHaveMouseOverChatInputElement = (inputState) => {
	return (!store.getters.chatShowing || !(inputState.mx < chatBoxWidth && inputState.my > window.innerHeight - chatBoxHeight))
}

class GameClient {
	constructor(gameInstanceAddress, clientConnectionParams) {
		this.client = new nengi.Client(nengiConfig, 100)

		setInterval(() => {
			store.commit("averagePing", this.client.averagePing)
		}, 5000)

		store.commit("registerGameClient", this)
		store.commit("newEnvironment", process.env.NODE_ENV)
		store.commit("updateMap", GAME_CONSTANTS.MAP)

		this.input = new InputSystem(this.client)
		this.soundSystem = new SoundSystem(this)
		this.renderer = new PIXIRenderer(this.input, this.soundSystem)
		this.simulator = new Simulator(this.renderer, this.client, this.soundSystem)

		this.client.factory = createFactories({
			renderer: this.renderer,
			simulator: this.simulator,
			gameClient: this
		})

		niceClientExtension(this.client) // mixin the "nice" event api
		messageDispatcher(this.client)

		this.renderer.listen(this.client) // wires up renderer to client

		this.predictor = new Predictor(
			this.renderer,
			this.simulator,
			this.soundSystem,
			this.client
		)

		this.clientAFK = false

		this.soundSystem.retryPlayMusicUntilAudioAvailable()

		this.client.on("connected", connectionResponse => {
			if (connectionResponse.accepted === false) {
				// console.error(`Server connection rejected. Error code: ${connectionResponse.text}`)
				// `knownCloseReason` flag exists so that the soon-thereafter-disconnection-message
				// does not overwrite the error message we are provided here (e.g. the server is full)
				window.knownCloseReason = true
				stopLoadingAndDisplayError(connectionResponse.text)
			}
		})

		this.client.on("disconnected", e => {
			// console.error("Socket closed. Reason:", e)
			if (window.knownCloseReason) {
				showLoadingScreen()
			} else {
				stopLoadingAndDisplayError(this.clientAFK ? "afkDisconnection" : "connectionLost")
			}
		})

		this.client.on("message::AFKMessage", message => {
			// Bit hacky but don't want to add a crapton of code to 
			// support keeping track of the last activity on the client too, instead just set this back
			// after 2 seconds
			this.clientAFK = true
			setTimeout(() => {
				this.clientAFK = false
			}, 2000)
		})

		setInterval(() => {
			store.commit("newPing", this.client.averagePing)
		}, GAME_CONSTANTS.BROADCAST_PING_INTERVAL)
		this.connect(gameInstanceAddress, clientConnectionParams)
	}

	switchWeapon(name) {
		this.predictor.switchWeapon(name)
	}

	connect(serverAddress, config) {
		this.client.connect(serverAddress, config)
	}

	update(delta) {
		this.client.readNetworkAndEmit()

		const worldCoord = this.renderer.toWorldCoordinates(
			this.input.currentState.mx,
			this.input.currentState.my
		)

		if (this.renderer.AB45203628E28928D1FB446B5C6EB50C) {
			this.renderer.AB45203628E28928D1FB446B5C6EB50C.sendPositionToMinimap()

			// old aim
			this.renderer.AB45203628E28928D1FB446B5C6EB50C.aim = Math.atan2(
				worldCoord.y - this.renderer.AB45203628E28928D1FB446B5C6EB50C.y,
				worldCoord.x - this.renderer.AB45203628E28928D1FB446B5C6EB50C.x
			)

			const isNotChatting = isNotChattingAndDoesNotHaveMouseOverChatInputElement(this.input.currentState)

			const command = new P7DA84105F9F54B2D20C66308619D78D(
				(isNotChatting) ? this.input.frameState.mouseDown : false,
				this.input.frameState.mouseClicked,
				worldCoord.x,
				worldCoord.y,
				this.input.frameState.w,
				this.input.frameState.a,
				this.input.frameState.s,
				this.input.frameState.d,
				this.renderer.AB45203628E28928D1FB446B5C6EB50C.aim,
				this.input.frameState.r,
				delta,
				this.input.frameState.one,
				this.input.frameState.two,
				this.input.frameState.three,
				this.input.frameState.four,
				this.input.frameState.five,
				this.input.frameState.space,
				this.input.frameState.spacePressed,
			)

			this.predictor.applyP7DA84105F9F54B2D20C66308619D78D(command)
			this.predictor.update(delta)

			// aim fix
			this.renderer.AB45203628E28928D1FB446B5C6EB50C.aim = Math.atan2(
				worldCoord.y - this.renderer.AB45203628E28928D1FB446B5C6EB50C.y,
				worldCoord.x - this.renderer.AB45203628E28928D1FB446B5C6EB50C.x
			)
			// aim fix, con't
			command.aim = this.renderer.AB45203628E28928D1FB446B5C6EB50C.aim
			this.client.addCommand(command)

			if(this.input.holdingE && !this.attemptedPickUp) {
				this.simulator.predictedEntity.weaponSystem.triggerGlobalCd()
				this.attemptedPickUp = true
			}
			else if(!this.input.holdingE && this.attemptedPickUp) {
				this.attemptedPickUp = false
			}
		}

		// [F]rag grenades
		if (this.input.frameState.f) {
			if (this.renderer.AB45203628E28928D1FB446B5C6EB50C && !this.renderer.AB45203628E28928D1FB446B5C6EB50C.isGhost) {
				this.client.addCommand(
					new PlayerUseItem(4, worldCoord.x, worldCoord.y, this.renderer.AB45203628E28928D1FB446B5C6EB50C.aim, Weapon.GRENADE_FragGrenade.index)
				)

				this.predictor.predictGrenadeThrow(Weapon.GRENADE_FragGrenade)
			}
		}

		// smoke [G]renades
		if (this.input.frameState.g) {
			if (this.renderer.AB45203628E28928D1FB446B5C6EB50C && !this.renderer.AB45203628E28928D1FB446B5C6EB50C.isGhost) {
				this.client.addCommand(
					new PlayerUseItem(4, worldCoord.x, worldCoord.y, this.renderer.AB45203628E28928D1FB446B5C6EB50C.aim, Weapon.GRENADE_SmokeGrenade.index)
				)

				this.predictor.predictGrenadeThrow(Weapon.GRENADE_SmokeGrenade)
			}
		}

		// res un[H]oly grenades
		if (this.input.frameState.h) {
			if (this.renderer.AB45203628E28928D1FB446B5C6EB50C && !this.renderer.AB45203628E28928D1FB446B5C6EB50C.isGhost) {
				this.client.addCommand(
					new PlayerUseItem(4, worldCoord.x, worldCoord.y, this.renderer.AB45203628E28928D1FB446B5C6EB50C.aim, Weapon.GRENADE_ResGrenade.index)
				)

				this.predictor.predictGrenadeThrow(Weapon.GRENADE_ResGrenade)
			}
		}

		// [C] Molotovs
		if (this.input.frameState.c) {
			if (this.renderer.AB45203628E28928D1FB446B5C6EB50C && !this.renderer.AB45203628E28928D1FB446B5C6EB50C.isGhost) {
				this.client.addCommand(
					new PlayerUseItem(4, worldCoord.x, worldCoord.y, this.renderer.AB45203628E28928D1FB446B5C6EB50C.aim, Weapon.GRENADE_Molotov.index)
				)

				this.predictor.predictGrenadeThrow(Weapon.GRENADE_Molotov)
			}
		}

		this.client.update()
		this.input.releaseKeys()
		this.simulator.update(delta)
	}

	chatCommand(text) {
		const chatCommand = new PlayerSubmitChatMessage(text)

		this.client.addCommand(chatCommand)
	}
}

export default GameClient
