import Grid from "./../classes/Grid"
import Wall from "./Wall"

// 12033, 12034, 12035, 12036, 12037

const lookup = {
	65: Wall.LEFT,
	66: Wall.RIGHT,
	67: Wall.TOP,
	68: Wall.BOTTOM,
	69: Wall.FULL,

	1: Wall.LEFT_PIERCE,
	2: Wall.RIGHT_PIERCE,
	3: Wall.TOP_PIERCE,
	4: Wall.BOTTOM_PIERCE,
	5: Wall.FULL_PIERCE,

	129: Wall.TOP_LEFT,
	130: Wall.TOP_RIGHT,
	131: Wall.BOTTOM_LEFT,
	132: Wall.BOTTOM_RIGHT,
	133: Wall.MIDDLE,

	193: Wall.TOP_LEFT_PIERCE,
	194: Wall.TOP_RIGHT_PIERCE,
	195: Wall.BOTTOM_LEFT_PIERCE,
	196: Wall.BOTTOM_RIGHT_PIERCE,
	197: Wall.MIDDLE_PIERCE
}

const TILE_FLIPPED_DIAGONALLY = 0x20000000
const TILE_FLIPPED_HORIZONTALLY = 0x80000000
const TILE_FLIPPED_VERTICALLY = 0x40000000

let toWallType = type => {
	type &= ~(TILE_FLIPPED_DIAGONALLY |
		TILE_FLIPPED_HORIZONTALLY |
		TILE_FLIPPED_VERTICALLY)
	let val = lookup[type]
	if (val) {
		return val
	} else {
		// if(type)
		// console.log({ val, type })

		return Wall.NONE
	}
}

// hardcoded to read the 'Collisions' layer out of a map and produce a grid of true/false
// where true means there is a wall and false means there is empty space
function convertMap(tiledMap) {
	let collisionLayer = tiledMap.layers.find(layer => {
		return layer.name === "Walls"
	})

	let collisionGrid = new Grid(collisionLayer.width, collisionLayer.height)

	for (let i = 0; i < collisionLayer.data.length; i++) {
		// presuming that any tile value other than 0 means blocked
		collisionGrid.cells[i] = toWallType(collisionLayer.data[i])
	}

	return collisionGrid
}

export default convertMap
