import nengi from "nengi"

class DebugPositions {
	constructor(playerCollection) {

		const arr = []
		// supports EDictionary (which cannot be filtered/mapped)
		playerCollection.forEach(p => {
			const { id, x, y } = p
			arr.push({ id, x, y })
		})
		this.json = JSON.stringify(arr)
	}
}

DebugPositions.protocol = {
	json: nengi.String
}

export default DebugPositions
