<template>
	<div>
		<div class="party">
			<PartyUnitFrame v-if="teammate1.id !== -1" :teammate=teammate1 ></PartyUnitFrame>
			<PartyUnitFrame v-if="teammate2.id !== -1" :teammate=teammate2 ></PartyUnitFrame>
			<PartyUnitFrame v-if="teammate3.id !== -1" :teammate=teammate3 ></PartyUnitFrame>
			<PartyUnitFrame v-if="teammate4.id !== -1" :teammate=teammate4 ></PartyUnitFrame>
		</div>
		<Killfeed></Killfeed>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import PartyUnitFrame from './PartyUnitFrame.vue'
import Killfeed from './Killfeed.vue'

export default {
	components: {
		PartyUnitFrame,
		Killfeed
	},
  computed: {
    ...mapGetters(["teammate1", "teammate2", "teammate3", "teammate4"])
  }
};
</script>

<style lang="scss" scoped>
.party {
  position: relative;
  padding: 12px;
  width: 120px;
  color: white;

  .kevlar {
    padding: 1px;
    .foreground {
      position: fixed;
      width: 20px;
      height: 6px;
      background-color: #105870;
      border: 1px solid #5190c3;
    }
    .background {
      width: 150px;
      height: 6px;
      background-color: rgba(26, 26, 26, 0.8);
      border: 1px solid rgba(26, 26, 26, 0.8);
    }
  }

  .hitpoints {
    padding: 2px;
    .foreground {
      position: fixed;
      height: 9px;
      background-color: #185a1a;
      border: 1px solid #4b8d4d;
    }
    .background {
      width: 150px;
      height: 9px;
      background-color: rgba(26, 26, 26, 0.8);
      border: 1px solid rgba(26, 26, 26, 0.8);
    }
  }
}
</style>
