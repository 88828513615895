import nengi from "nengi"

class MissionMessage {
	constructor(description, progressCurrent, progressMax, isComplete) {
		this.description = description
		this.progressCurrent = progressCurrent
		this.progressMax = progressMax
		this.isComplete = isComplete
	}
}

MissionMessage.protocol = {
	description: nengi.String,
	progressCurrent: nengi.UInt16,
	progressMax: nengi.UInt16,
	isComplete: nengi.Boolean
}

export default MissionMessage
