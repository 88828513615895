import lineIntersection from "./lineIntersection"

let blocksBullets = wall => {
	return !wall.pierce
}

function dist(x1, y1, x2, y2) {
	return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2))
}

function length(x, y) {
	return Math.sqrt(x * x + y * y)
}

function norm(x, y) {
	let len = length(x, y)
	if (len > 0) {
		return { x: x / len, y: y / len }
	} else {
		return { x: 0, y: 0 }
	}
}

export default function raycast(grid, x1, y1, x2, y2, maxLength) {
	let dim = 16

	// direction and magnitude
	let dx = x2 - x1
	let dy = y2 - y1

	// direction, normalized
	let rayDir = norm(dx, dy)

	// grid coordinates for start of the ray
	let gx = Math.floor(x1 / dim)
	let gy = Math.floor(y1 / dim)

	// directionality
	let stepX = dx > 0 ? 1 : -1
	let stepY = dy > 0 ? 1 : -1

	// distance to next cell
	let tMaxX = 99999
	let tMaxY = 99999

	let tDeltaX = 0
	let tDeltaY = 0

	if (rayDir.x < 0) {
		tMaxX = (gx * dim - x1) / rayDir.x
		tDeltaX = dim / -rayDir.x
	} else if (rayDir.x > 0) {
		tMaxX = ((gx + 1) * dim - x1) / rayDir.x
		tDeltaX = dim / rayDir.x
	}

	if (rayDir.y < 0) {
		tMaxY = (gy * dim - y1) / rayDir.y
		tDeltaY = dim / -rayDir.y
	} else if (rayDir.y > 0) {
		tMaxY = ((gy + 1) * dim - y1) / rayDir.y
		tDeltaY = dim / rayDir.y
	}

	let i = 0
	let distanceTravelled = 0

	while (i < 30 && distanceTravelled <= maxLength) {
		distanceTravelled = length(gx * dim, gy * dim)
		let wall = grid.get(gx, gy)
		// console.log('check', wall)
		if (wall && blocksBullets(wall)) {
			let lines = []

			lines.push({
				x1: wall.pos.x + wall.points[0].x,
				y1: wall.pos.y + wall.points[0].y,
				x2: wall.pos.x + wall.points[1].x,
				y2: wall.pos.y + wall.points[1].y
			})

			lines.push({
				x1: wall.pos.x + wall.points[1].x,
				y1: wall.pos.y + wall.points[1].y,
				x2: wall.pos.x + wall.points[2].x,
				y2: wall.pos.y + wall.points[2].y
			})

			lines.push({
				x1: wall.pos.x + wall.points[2].x,
				y1: wall.pos.y + wall.points[2].y,
				x2: wall.pos.x + wall.points[3].x,
				y2: wall.pos.y + wall.points[3].y
			})

			lines.push({
				x1: wall.pos.x + wall.points[3].x,
				y1: wall.pos.y + wall.points[3].y,
				x2: wall.pos.x + wall.points[0].x,
				y2: wall.pos.y + wall.points[0].y
			})

			// top edge
			/*
            lines.push({
                x1: gx * dim,
                y1: gy * dim,
                x2: (gx + 1) * dim,
                y2: gy * dim
            })

            // right edge
            lines.push({
                x1: (gx + 1) * dim,
                y1: gy * dim,
                x2: (gx + 1) * dim,
                y2: (gy + 1) * dim
            })

            // bottom edge
            lines.push({
                x1: gx * dim,
                y1: (gy + 1) * dim,
                x2: (gx + 1) * dim,
                y2: (gy + 1) * dim
            })

            // left edge
            lines.push({
                x1: gx * dim,
                y1: gy * dim,
                x2: gx * dim,
                y2: (gy + 1) * dim
            })
            */

			let intersections = []
			for (let j = 0; j < lines.length; j++) {
				let line = lines[j]
				let result = lineIntersection(
					line.x1,
					line.y1,
					line.x2,
					line.y2,
					x1,
					y1,
					x2,
					y2
				)
				if (result) {
					intersections.push(result)
				}
			}

			//  might be able to skip this if there were a way to tell
			// which line segment should be checked first, and return early if hit
			let closestDistance = 999999
			let closestX = 0
			let closestY = 0
			for (let j = 0; j < intersections.length; j++) {
				let intersection = intersections[j]
				let distance = dist(x1, y1, intersection.x, intersection.y)
				// console.log('intersect dist', distance)
				if (distance < closestDistance) {
					closestDistance = distance
					closestX = intersection.x
					closestY = intersection.y
				}
			}

			if (intersections.length > 0) {
				return {
					hit: true,
					x: closestX,
					y: closestY
				}
			}
		}

		if (tMaxX < tMaxY) {
			tMaxX += tDeltaX
			gx += stepX
		} else {
			tMaxY += tDeltaY
			gy += stepY
		}
		i++
	}

	return { hit: false, x: x2, y: y2 }
}
