<template>
	<div class="escape-menu-hint">
		<div class="text"> toggle menu [ESC]</div>
		<div class="escape-panel" v-if="showEscapeMenu">
			<div class="panel-close" @click="handleCloseMenu()">
				<img src="./assets/close_icon.png" />
			</div>
			<div class="slider-label">
				Sound Effect Volume
			</div>

			<Slider
				minValue='0'
				maxValue='200'
				setting='sfxVolume'
				:value='settings.sfxVolume'
				@input="handleSlide"
			></Slider>
			<Button class="gray" @click="togglePreference('sfxEnabled')" :disabled="changingPreferences['sfxEnabled']">
				<template #body>
					{{sfxEnabled() ? "Disable" : "Enable"}} Sound Effects
				</template>
			</Button>

			<div class="slider-label">
				Music Volume
			</div>
			
			<Slider
				minValue='0'
				maxValue='200'
				setting='musicVolume'
				:value='settings.musicVolume'
				@input="handleSlide"
			></Slider>
			<Button class="gray" @click="togglePreference('musicEnabled')" :disabled="changingPreferences['musicEnabled']">
				<template #body>
					{{musicEnabled() ? "Disable" : "Enable"}} Music
				</template>
			</Button>

			<Button class="green" @click="findNewMatch()">
				<template #body>
					Exit Game
				</template>
			</Button>
			<SocialButtons></SocialButtons>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import Button from './Button.vue'
import SocialButtons from './SocialButtons.vue'
import Slider from './Slider.vue'

export default {
	name: 'EscapeMenu',
	components: {
		Button,
		SocialButtons,
		Slider
	},
	data: () => {
		return {
			isVisible: false,
			changingPreferences: {
				musicEnabled: false,
				sfxEnabled: false,
			},
			queuedVolumePrefChange: false
		}
	},
	methods: {
		...mapActions(["changePreference", "toggleEscapeMenu", "findNewMatch", "updateMusicVolume"]),
		...mapGetters(["sfxEnabled", "musicEnabled"]),
		toggleList() {
			this.isVisible = !this.isVisible
		},
		handleSlide(event) {
			this.queuedVolumePrefChange = true
			this.settings[event.target.settingType] = event.target.value
			if(event.target.settingType === 'musicVolume') {
				this.updateMusicVolume(event.target.value)
			}
		},
		async handleCloseMenu() {
			if(this.queuedVolumePrefChange) {
				let preference = 'musicVolume'
				//It seems silly to make a new method, so we spoof this one by calling it on one of the preferences
				await this.changePreference({
					preference,
					newValue: this.settings.musicVolume
				})
			}
			this.toggleEscapeMenu()
		},
		async togglePreference(preference) {
			if (this.userDataFetching) return
			this.changingPreferences[preference] = true
			await this.changePreference({
				preference,
				newValue: !this.settings[preference]
			})
			this.changingPreferences[preference] = false
		}
	},
	computed: {
		...mapState(["userDataFetching", "settings", "showEscapeMenu"]),
	}
}
</script>

<style lang='scss' scoped>
.escape-menu-hint {
	position: fixed;
	right: 16px;
	top: 4px;
	width: 200px;
	height: 200px;

	.text {
		text-align: right;
		color: rgb(233, 233, 233);
		padding-bottom: 4px;
	}
}


.slider-label {
	text-align: center;
	color: rgb(233, 233, 233);
}

.escape-panel {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	image-rendering: auto;

	background-image: url("./assets/tile.png");
	background-repeat: repeat;
	border: 10px solid rgba(0, 0, 0, 0);
	border-image: url("./assets/bruh-panel.png");
	border-image-slice: 18%;
	border-image-width: 12px;

	display: flex;
	flex-direction: column;

	button {
		margin: 5px 10px;
	}

	.panel-close {
		position: absolute;
		cursor: pointer;
		top: -30px;
		right: -30px;

		cursor: pointer;
		pointer-events: all;

		opacity: 1;
		transform: scale(0.8);

		&:hover {
			opacity: 0.8;
		}
	}
}

</style>
