export default {
	name: "Double Eagle",
	programmaticName: "dbleagle",
	canonicalName: "SG_DOUBLEEAGLE",
	itemClass: "weapon",
	itemSubclass: "shotgun",
	image: "dbleagle_default.png",
	heldImage: "dbleagle_hands.png",
	outlineImage: "dbleagle_outline.png",
	soundName: "SHOTGUN",
	reloadSoundName: "SHOTGUN5_reloading",
	spawnWeighting: 100,
	grenadeProjectiles: false,
	damage: 16,
	cooldown: 0.20,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 2,
	recoilRecoveryPeriod: 0.4,
	recoilMax: 20,
	clipSize: 3,
	kick: 40,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 24,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 90,
	pelletCount: 3,
	pelletSpread: 0.03,
	corpseForce: 100,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 1.5,
	activeStart: 0.4,
	activeEnd: 0.47,
	uiRotateDeg: -37,
	uiWidth: 103,
	uiTop: 36,
	uiLeft: 60
}
