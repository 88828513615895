import nengi from "nengi"

class NetSound {
	// Note: soundType is not yet used, but it is stubbed out so that it can be
	constructor(sourceId, x, y, soundType) {
		this.soruceId = sourceId
		this.x = x
		this.y = y
		this.soundType = soundType
	}
}

NetSound.protocol = {
	sourceId: nengi.Int32,
	x: nengi.Float32,
	y: nengi.Float32,
	soundType: nengi.UInt8
}

export default NetSound
