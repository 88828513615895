import nengi from "nengi"
import SAT from "sat"
import GAME_CONSTANTS from "../../common/balance/gameConstants"
import Weapon from "../../common/weapon/Weapon"

class WeaponItem {
	constructor(state) {
		this.weaponType = -1
		this.x = 0
		this.y = 0
		this.vx = 0
		this.vy = 0
		this.momentum = 0
		this.pickupable = true
		this.ammo = 0

		this.timer = 0
		this.needsRemoved = false

		Object.assign(this, state)
		if (this.weaponType < 0 || this.weaponType > Weapon.maxIndex) {
			// console.error(`Tried to create a new WeaponItem with out of bounds index: ${this.weaponType}`)
		}

		this.collider = new SAT.Circle(
			new SAT.Vector(this.x, this.y),
			GAME_CONSTANTS.PICKUPS.COLLIDER_RADIUS
		)
	}

	collidesWithPlayer(entity) {
		let dx = this.x - entity.x
		let dy = this.y - entity.y

		let len = Math.sqrt(dx * dx + dy * dy)

		return (
			len <= GAME_CONSTANTS.PLAYER.COLLIDER_RADIUS + GAME_CONSTANTS.PICKUPS.COLLIDER_RADIUS
		)
	}

	update(delta) {
		this.x += this.vx * this.momentum * delta
		this.y += this.vy * this.momentum * delta
		this.momentum *= Math.pow(0.02, delta)

		this.collider.pos.x = this.x
		this.collider.pos.y = this.y

		if (!this.pickupable) {
			this.timer += delta
			if (this.timer >= GAME_CONSTANTS.DISCARDED_WEAPON_LIFETIME) {
				this.needsRemoved = true
			}
		}
	}
}

WeaponItem.protocol = {
	x: nengi.Float64,
	y: nengi.Float64,
	weaponType: nengi.UInt8,
	pickupable: nengi.Boolean
}

export default WeaponItem
