<template>
	<div class="map" :class="{ 'bruhnswick': bruhnswickMap, 'bruhward': bruhwardMap }" ref="mapWrapper">
		<div class="inner-border">
			<div class="poisonSquareNext" :style="'left:' + nextPoisonX + 'px; top:' + nextPoisonY + 'px; width:' + nextPoisonWidth + 'px; height:' + nextPoisonWidth + 'px;'">
			</div>
			<div class="poisonSquareCurrent" :style="'left:' + currentPoisonX + 'px; top:' + currentPoisonY + 'px; width:' + currentPoisonWidth + 'px; height:' + currentPoisonWidth + 'px;'">
			</div>
			<div class="playerIcon" :style="'left:' + playerX + 'px; top:' + playerY + 'px;'">
			</div>
			<div v-for="teammate in scaledTeammates">
				<div class="teammateIcon" :style="'left:' + teammate.x + 'px; top:' + teammate.y + 'px;'"></div>
			</div>
		</div>
</div>
</template>

<script>
import { mapGetters } from "vuex"
import { MAP_CONFIG } from '../../common/balance/gameConstants'

export default {
	data() {
		return {
			scale: 1
		}
	},
	methods: {
		resize() {
			if (this.$refs.mapWrapper) {
				const height = this.$refs.mapWrapper.clientHeight
				if(this.mapName)
					this.scale = height / MAP_CONFIG[this.mapName].MAP_WIDTH
				else
					this.scale = height / MAP_CONFIG['bruhward'].MAP_WIDTH
			}
		}
	},
	mounted() {
		window.addEventListener('resize', this.resize)
		this.resize()
	},
	beforeDestroy() {
		// this component was not ever destroyed when this code was added
		window.removeEventListener('resize', this.resize)	
	},
	computed: {
		...mapGetters(["playerPosition", "poison", "bruhnswickMap", "bruhwardMap", "mapName", "teammatePositions"]),
		playerX() {
			return (this.playerPosition.x * this.scale) - 2
		},
		playerY() {
			return (this.playerPosition.y * this.scale) - 2
		},
		scaledTeammates() {
			let teammates = this.teammatePositions
			for(let i = 0; i < teammates.length; i++) {
				teammates[i].x = (teammates[i].x * this.scale) - 2
				teammates[i].y = (teammates[i].y * this.scale) - 2
			}
			return teammates
			
		},
		currentPoisonX() {
			return (this.poison.currX * this.scale) - 1
		},
		currentPoisonY() {
			return (this.poison.currY * this.scale) - 1
		},
		currentPoisonWidth() {
			return (this.poison.currWidth * this.scale) - 2
		},
		nextPoisonX() {
			return (this.poison.nextX * this.scale) - 1
		},
		nextPoisonY() {
			return (this.poison.nextY * this.scale) - 1
		},
		nextPoisonWidth() {
			return (this.poison.nextWidth * this.scale) - 2
		}
	},
	watch: {
		mapName(value) {
			this.resize()
		}
	}
};
</script>

<style lang="scss" scoped>
.map {
	position: relative;
	width: 100%;
	height: 100%;
	border: solid 2px #7d8085;
	background-repeat: no-repeat;
	background-size: cover;
}

.bruhnswick {
	background-image: url('../images/bruhnswick-full.png');
}

.bruhward {
	background-image: url('../images/bruhward-full.png');
}

.inner-border {
	position: relative;
	width: 100%;
	height: 100%;
	border: solid 2px rgb(17, 17, 17);
}
.playerIcon {
	position: absolute;
	width: 4px;
	height: 4px;
	background-color: white;
	border-width: 0px;
}
.teammateIcon {
	position: absolute;
	width: 4px;
	height: 4px;
	background-color: yellow;
	border-width: 0px;
}
.poisonSquareNext {
	position: absolute;
	border: dashed 2px rgb(172, 172, 172);
}
.poisonSquareCurrent {
	position: absolute;
	border: solid 2px rgb(0, 187, 140);
}
</style>
