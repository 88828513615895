<template>
	<div class="wrapper" v-bind:class="wrapperClass">
		<div class="reload-text-wrapper">
			<div class="good" v-if="reloadBarAttemptGoodEnough">Good!</div>
			<div class="perfect" v-if="reloadBarAttemptPerfect">Perfect!</div>
			<div class="fail" v-if="reloadBarAttemptFumbled">Fumbled!</div>
		</div>
		<div class="reload-progress-bar" v-if="reloadBarIsReloading" v-bind:style="{ width: `200px` }">
			<div class="good-enough" v-bind:style="{ left: `${reloadBarEarlyWindowStart}%`, width: `${reloadBarEarlyWindowWidth}%` }">
			</div>
			<div class="sweet-spot" v-bind:style="{ left: `${reloadBarPerfectWindowStart}%`, width: `${reloadBarPerfectWindowWidth}%` }">
			</div>
			<div class="progress-indicator" v-bind:style="{ left: `${reloadBarProgress}%` }">
			</div>
		</div>
		<div class="ammo-count">
			<div class="r-to-reload" v-if="!currentWeaponAmmoRemaining && !reloadBarIsReloading">
				Press R to reload
			</div>
			<span class="ammo-count">
				<span v-if="!isUsingHeavyWeapon">
					{{ currentWeaponAmmoRemaining }} | {{ currentWeaponClipSize }}
				</span>
				<span v-else>
					{{ currentWeaponAmmoRemaining }}
				</span>
				<span v-for="(n, i) in bulletIconsMax" :key="n" class="bullet-wrapper">
					<img v-if="i < bulletIconsCurrent" class="bullet" src="../../creative/spritesheetsourceimages/bullet_icon.png" />
					<img v-if="i >= bulletIconsCurrent" class="bullet" src="../../creative/spritesheetsourceimages/bullet_icon_empty.png" />
				</span>
			</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Weapon from "../../common/weapon/Weapon"

export default {
	computed: {
		...mapGetters([
			'currentWeapon',
			'reloadBarIsReloading',
			'reloadBarProgress',
			'reloadBarAttemptFumbled',
			'reloadBarAttemptGoodEnough',
			'reloadBarAttemptPerfect',
			'reloadBarEarlyWindowStart',
			'reloadBarEarlyWindowEnd',
			'reloadBarPerfectWindowStart',
			'reloadBarPerfectWindowEnd',
			'currentWeaponAmmoRemaining',
			'currentWeaponClipSize',
			'triedToHealAtFull',
		]),

		currentWeaponAmmoRemainingPercent: function() {
			return this.currentWeaponAmmoRemaining / this.currentWeaponClipSize
		},

		bulletIconsMax: function() {
			return Math.min(this.currentWeaponClipSize, 5)
		},

		bulletIconsCurrent: function() {
			return Math.min(this.bulletIconsMax, Math.ceil(this.currentWeaponAmmoRemainingPercent * Math.min(5, this.currentWeaponClipSize)))
		},

		reloadBarEarlyWindowWidth: function() {
			return this.reloadBarEarlyWindowEnd - this.reloadBarEarlyWindowStart
		},

		reloadBarPerfectWindowWidth: function() {
			return this.reloadBarPerfectWindowEnd - this.reloadBarPerfectWindowStart
		},

		wrapperClass: function() {
			const rap = {
				'fumbled': this.reloadBarAttemptFumbled,
				'good-enough': this.reloadBarAttemptGoodEnough,
				'perfect': this.reloadBarAttemptPerfect,
			}
			return rap
		},
		isUsingHeavyWeapon: function() {
			let currWeapon = Weapon.byIndex(this.currentWeapon)
			return currWeapon && currWeapon.itemSubclass === "heavy_weapon"
		},
	},
}
</script>

<style lang='scss' scoped>
.wrapper {
	position: relative;
	text-align: center;

	&.fumbled {
		.reload-text-wrapper {
			color: red;
			opacity: 1;
		}

		.reload-progress-bar {
			border: 2px solid red;

			.sweet-spot,
			.indicator {
				background-color: red;
			}

			.good-enough {
				background-color: darken(red, 30%);
			}
		}

	}

	&.good-enough {
		.reload-text-wrapper {
			color: lightblue;
			opacity: 1;
		}

		.reload-progress-bar {
			border: 2px solid lightblue;

			.sweet-spot,
			.indicator {
				background-color: lightblue;
			}

			.good-enough {
				background-color: darken(lightblue, 30%);
			}
		}
	}

	&.perfect {
		.reload-text-wrapper {
			color: cyan;
			opacity: 1;
		}

		.reload-progress-bar {
			border: 2px solid cyan;

			.sweet-spot,
			.indicator {
				background-color: cyan;
			}

			.good-enough {
				background-color: darken(cyan, 30%);
			}
		}
	}


	.reload-text-wrapper {
		position: relative;
		display: block;
		font-size: 20px;
		margin-bottom: 5px;
		position: relative;
		top: 0px;
		text-shadow: -1px -1px 0px rgba(0, 0, 0, 1),
			-1px 1px 0px rgba(0, 0, 0, 1), 1px -1px 0px rgba(0, 0, 0, 1),
			1px 1px 0px rgba(0, 0, 0, 1);

		opacity: 0;
		transition: opacity 500ms ease;

	}

	.ammo-count {
		margin-bottom: 15px;
		span {
			font-size: 34px;
			color: white;
			text-shadow: -1px -1px 0px rgba(0, 0, 0, 1),
				-1px 1px 0px rgba(0, 0, 0, 1), 1px -1px 0px rgba(0, 0, 0, 1),
				1px 1px 0px rgba(0, 0, 0, 1);

			&.empty {
				color: red;
			}
		}

		.r-to-reload {
			color: red;
			font-size: 20px;
			margin-bottom: 5px;
			text-shadow: -1px -1px 0px rgba(0, 0, 0, 1),
				-1px 1px 0px rgba(0, 0, 0, 1), 1px -1px 0px rgba(0, 0, 0, 1),
				1px 1px 0px rgba(0, 0, 0, 1);
		}

		.bullet-wrapper {
			font-size: 10px;
			
			img {
				image-rendering: pixelated;
				image-rendering: -moz-crisp-edges;
				image-rendering: crisp-edges;
				width: 14px;
			}
		}

	}


	.reload-progress-bar {
		display: inline-block;
		margin: 0 auto 10px auto;
		height: 18px;
		border: 2px solid white;
		transition: opacity 500ms ease;
		background-color: black;
		position: relative;

		.sweet-spot {
			position: absolute;
			height: 14px;
			background-color: white;
		}
		.good-enough {
			position: absolute;
			height: 14px;
			background-color: #cccccc;
		}
		.progress-indicator {
			position: absolute;
			top: -8px;
			width: 3px;
			height: 30px;
			background-color: #eeeeee;
			transition: width 500ms ease, height 500ms ease, top 500ms ease;
		}
	}
}

</style>
