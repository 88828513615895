import { isUndefined, some } from 'lodash'
import { MissionQuest, MissionQuestDefinition } from "./MissionStateObjects"

class MissionFactory {
	static createMissionFromEndpoint(missionJson) {
		let {
			user_daily_mission_id,
			user_weekly_mission_id,
			description,
			mission_timespan,
			progress_current,
			progress_max,
			is_complete,
			criteria_json,
			reward
		} = missionJson

		if (some([
			user_daily_mission_id,
			user_weekly_mission_id,
			description,
			mission_timespan,
			progress_current,
			progress_max,
			is_complete,
			criteria_json,
			reward
		], isUndefined)) {
			global.logger.error(`Invalid json from REST API for mission for [${missionJson.description}]`)
		}
		let missionQuestDefinition = new MissionQuestDefinition(criteria_json)
		missionQuestDefinition.description = description

		let missionQuest = new MissionQuest(missionQuestDefinition)
		missionQuest.missionUniqueId = user_daily_mission_id || user_weekly_mission_id
		missionQuest.userDailyMissionId = user_daily_mission_id
		missionQuest.userWeeklyMissionId = user_weekly_mission_id
		missionQuest.missionTimespan = mission_timespan
		missionQuest.description = description
		missionQuest.progressStart = progress_current
		missionQuest.progressCurrent = progress_current
		missionQuest.progressMax = progress_max
		missionQuest.isComplete = is_complete
		missionQuest.reward = reward

		// console.log({missionJson, missionQuest, missionQuestDefinition})
		return missionQuest
	}
}

export default MissionFactory
