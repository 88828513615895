export default {
	name: "Frag Grenade",
	programmaticName: "fragGrenade",
	canonicalName: "GRENADE_FragGrenade",
	itemClass: "grenade",
	itemSubclass: "grenade",
	prop: "fragGrenades",
	image: "grenade-with-pin.png",
	thrownImage: "grenade.png",
	outlineImage: "ak47_outline.png",
	spawnWeighting: 175,
	scaleDown: true,
	throwMod: 0.55,
	rollMod: 0.2,
	minThrowForce: 10,
	maxThrowForce: 150,
	damageNear: 140,
	damageFar: 0,
	explosionRadius: 60,
	fuseTimer: 3,
	corpseForce: 200,
	carryLimit: 4,
	createSmoke: false,
	gunCooldown: 0.4,
	impactExplosion: false
}
