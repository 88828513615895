import mathUtils from "../../../common/misc/mathUtils"
import ParticleAttribute from "./ParticleAttribute"



class ColourAttribute extends ParticleAttribute {
    constructor(attributeBlueprint) {
        super(attributeBlueprint)
        this.attributeBlueprint = attributeBlueprint
        this.colourOverTime = this.attributeBlueprint.colourOverTime
    }

    reset() {
        super.reset()
    }

    die(particle) {
        super.die()
    }

    particleSpawned(particle) {
        super.particleSpawned(this.particleSpawned)
        if(this.attributeBlueprint.randomColour) {
            let r = this.parseRandom(this.attributeBlueprint.randomColour.minR, this.attributeBlueprint.randomColour.maxR)
            let g = this.parseRandom(this.attributeBlueprint.randomColour.minG, this.attributeBlueprint.randomColour.maxG)
            let b = this.parseRandom(this.attributeBlueprint.randomColour.minB, this.attributeBlueprint.randomColour.maxB)
            this.initialColour = {
                r: r,
                g: g,
                b: b
            }
        }
        else {
            this.initialColour = this.attributeBlueprint.initialColour
        }
        particle.tint = mathUtils.colourVecToHex(this.initialColour)
    }

    apply(particle, delta) {
        super.apply(particle)
    }
}

export default ColourAttribute