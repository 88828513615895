const hasPelletSpread = (weapon) => {
	return weapon.pelletCount && weapon.pelletSpread
}

function createWeaponRays(x, y, angle, weapon, collisionSystem, prng) {
	const length = weapon.bulletTravelDistance
	if (hasPelletSpread(weapon)) {
		const { pelletCount, pelletSpread } = weapon
		return spread(x, y, angle, length, pelletCount, pelletSpread, collisionSystem, weapon, prng)
	} else {
		return [single(x, y, angle, length, collisionSystem, weapon, prng)]
	}
}

function single(x, y, angle, length, collisionSystem, weapon, prng) {
	const r = prng.nextFloat() - 0.5
	const theta = angle + (r * weapon.recoilAccumulator)
	const targetX = x + length * Math.cos(theta)
	const targetY = y + length * Math.sin(theta)
	return collisionSystem.computeStartAndEnd({ x, y, targetX, targetY})
}

function spread(x, y, angle, length, pelletCount, pelletSpread, collisionSystem, weapon, prng) {
	const lines = []
	const half = pelletCount / 2
	const start = -half
	const end = half

	for (let j = start; j < end; j++) {
		const r = prng.nextFloat() - 0.5
		const theta = (angle + j * pelletSpread) + (r * weapon.recoilAccumulator)
		lines.push(single(x, y, theta, length, collisionSystem, weapon, prng))
	}

	return lines
}

export default createWeaponRays
