import SAT from 'sat'
import Weapon from '../../common/weapon/Weapon'

const collisions = new Map()

const setRenderText = (renderEntity, text) => {
	if (renderEntity.pickupText.text !== text) {
		renderEntity.pickupText.text = text
	}
	renderEntity.showText = true
}

const hideText = (renderEntity) => {
	renderEntity.showText = false
}

const chooseTextDynamically = (self, item, renderEntity) => {
	if (self.isGhost) {
		hideText(renderEntity)
		return
	}

	if (item.itemClass === 'weapon' && self.weaponSystem.isWeaponActive()) {
		// show weapon text when holding a weapon and standing on a weapon
		setRenderText(renderEntity, `${ item.name} \nPress [e]`)
		return
	} else if (item.itemClass === 'weapon' && !self.weaponSystem.isWeaponActive()) {
		// hide weapon text when holding a utility item and standing on a weapon
		setRenderText(renderEntity, `Weapon required`)
		return
	}

	if (item.itemClass === 'medical') {
		// always show text on medical items
		if (self[item.prop] < item.carryLimit) {
			// we can pick this up
			setRenderText(renderEntity, `${ item.name} \nPress [e]`)
		} else {
			// we're all full of this one
			setRenderText(renderEntity, `${ item.name} \nFULL`)
		}
		return
	}

	if (item.itemClass === 'grenade') {
		if (self[item.prop] < item.carryLimit) {
			// we can pick this up
			setRenderText(renderEntity, `${ item.name} \nPress [e]`)
		} else {
			// we're all full of this one
			setRenderText(renderEntity, `${ item.name} \nFULL`)
		}
		return
	}

	if (item.itemClass !== 'medical' && item.itemClass !== 'weapon') {
		// utility catch-all
		// we can pick this up
		if (item.itemClass === 'kevlar' && self.shields < 50) {
			setRenderText(renderEntity, `${ item.name} \nPress [e]`)
			return
		} else if (item.itemClass === 'kevlar' && self.shields >= 50) {
			setRenderText(renderEntity, `${ item.name} \nMAX`)
			return
		}

		if (item.itemClass === 'sight' && self.equipmentSight === -1) {
			setRenderText(renderEntity, `${ item.name} \nPress [e]`)
			return
		}

		if (item.itemClass === 'scope') {
			setRenderText(renderEntity, `${ item.name} \nPress [e]`)
			return
		}
	}
}

const distSq = (self, item) => {
	const dx = self.allegedX - item.x
	const dy = self.allegedY - item.y
	return dx * dx + dy * dy
}

export default (self, allEntities, renderEntities) => {
	let closest = null
	let prevDist = 999999999
	collisions.forEach(itemEntity => {
		const renderEntity = renderEntities.get(itemEntity.id)
		if (!renderEntity) {
			// looks like this object is *gone* from the world (someone picked it up)
			collisions.delete(itemEntity.id)
			return
		}
		if (SAT.testCircleCircle(self.allegedCollider, itemEntity.collider)) {
			const dist = distSq(self, itemEntity)
			if (dist < prevDist) {
				prevDist = dist
				closest = itemEntity
			}
		}
	})

	// exit collision state
	collisions.forEach(itemEntity => {
		const item = Weapon.byIndex(itemEntity.weaponType)
		const renderEntity = renderEntities.get(itemEntity.id)
		if (itemEntity === closest) {
			// still colliding, apply text
			chooseTextDynamically(self, item, renderEntity)
		} else {
			// collision state exited
			// technically these can still be in collision with the player
			// but are not the closet item, so hide text
			// if player is still overlapping them next frame they
			// will be evaluated again
			collisions.delete(itemEntity.id)
			hideText(renderEntity)
		}
	})

	// enter collision
	allEntities.forEach(itemEntity => {
		if (itemEntity.protocol.name === 'WeaponItem') {
			if (SAT.testCircleCircle(self.allegedCollider, itemEntity.collider)) {
				// collision state entered
				collisions.set(itemEntity.id, itemEntity)
			}
		}
	})
}
