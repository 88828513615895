export default {
	name: "Colt 45",
	programmaticName: "colt45",
	canonicalName: "PISTOL_COLT45",
	itemClass: "weapon",
	itemSubclass: "pistol",
	image: "colt45_default.png",
	heldImage: "colt45_hands.png",
	outlineImage: "colt45_outline.png",
	soundName: "PISTOL",
	reloadSoundName: "PISTOL_reloading",
	spawnWeighting: 100,
	grenadeProjectiles: false,
	damage: 25,
	cooldown: 0.60,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 100,
	recoilRecoveryPeriod: 0.1,
	recoilMax: 1,
	clipSize: 6,
	kick: 140,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 14,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 250,
	corpseForce: 200,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 1.5,
	activeStart: 0.48,
	activeEnd: 0.57,
	uiRotateDeg: -25,
	uiWidth: 135,
	uiTop: 36,
	uiLeft: 58
}
