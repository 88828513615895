

export default {
	name: "MolotovFire",
	image: "fire-fx.png",
	emissionType: "circle",
	lifetime: 10,
	particleLife: 3,
    outerRadius: 30,
	maxParticles: 1000,
	blendMode: "ADD",
	emissionRate: 120,
	dontCacheParticles: true,
	attributes: [
		{
			type: "VELOCITY",
			randomVelocity: {
				minX: -2,
				maxX: 2,
				minY: -7,
				maxY: 0
			}
		},
		{
			type: "SCALE",
			squareScale: true,
			scaleOverTime: true,
			randomlyMirror: {
				onlyX: true
			},
			randomScale: {
				minX: 0.4,
				maxX: 0.5
			},
			finalScale: {
				x: 0
			}
		},
		{
			type: "COLOUR",
			initialColour: {
				r: 122,
				g: 30,
				b: 5,
			}
		},
	]
}
