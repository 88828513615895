export default {
	name: "P1911",
	programmaticName: "p1911",
	canonicalName: "PISTOL_P1911",
	itemClass: "weapon",
	itemSubclass: "pistol",
	image: "p1911_default.png",
	heldImage: "p1911_hands.png",
	outlineImage: "p1911_outline.png",
	soundName: "PISTOL",
	reloadSoundName: "PISTOL_reloading",
	spawnWeighting: 0,
	grenadeProjectiles: false,
	damage: 10,
	cooldown: 0.32,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 11,
	recoilRecoveryPeriod: 1.0,
	recoilMax: 10,
	clipSize: 12,
	kick: 12,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 14,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 250,
	corpseForce: 20,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 1.0,
	activeStart: 0.3,
	activeEnd: 0.5,
	uiRotateDeg: -25,
	uiWidth: 135,
	uiTop: 36,
	uiLeft: 58
}
