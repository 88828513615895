export default {
	pickRandom: function (arr) {
		return arr[Math.floor(Math.random() * arr.length)]
	},

	contains: function (arr, obj) {
		var i, l = arr.length
		for (i = 0; i < l; i++) {
			if (arr[i] == obj) {
				return true
			}
		}
		return false
	},

	remove: function (arr, obj) {
		var i, l = arr.length
		for (i = 0; i < l; i++) {
			if (arr[i] == obj) {
				arr.splice(i, 1)
				return arr
			}
		}
		return false
	},

	overwriteWithRef: function (sourceArray, incomingArray) {
		[].splice.apply(sourceArray, [0, sourceArray.length].concat(incomingArray))
	},

	chunk: function (arr, chunkSize) {
		let tempArray = []
		let i = 0

		while (i < arr.length) {
			tempArray.push(arr.slice(i, i + chunkSize))
			i += chunkSize
		}

		return tempArray
	},

	addOrReplace: function (arr, item) {
		const index = arr.findIndex((_item) => {
			return _item === item
		})
		if(index !== -1) {
			arr[index] = item
		} else {
			arr.push(item)
		}
	},

}

