import nengi from "nengi"

class ChatMessage {
	constructor(message, sender) {
		this.message = message
		this.sender = sender.name
		this.fromVerifiedSender = sender.isVerified
	}
}

ChatMessage.protocol = {
	message: nengi.String,
	sender: nengi.String,
	fromVerifiedSender: nengi.Boolean
}

export default ChatMessage
