import nengi from "nengi"
import MissionMessage from './MissionMessage'

class MissionsCompleted {
	constructor(missionsCompleted) {
		this.missionsCompleted = missionsCompleted
	}
}

MissionsCompleted.protocol = {
	missionsCompleted: { type: MissionMessage, indexType: nengi.UInt16 },
}

export default MissionsCompleted
