import PlayerPickUpItem from "../command/PlayerPickUpItem"
import store from '../ui/store'

const haultKeyInput = (sys) => {
	sys.currentState = {
		w: false,
		s: false,
		a: false,
		d: false,
		r: false,
		f: false,
		g: false,
		h: false,
		c: false,
		one: false,
		two: false,
		three: false,
		four: false,
		five: false,
		mouseDown: false,
		space: false,
		// leave mouse position alone
		mx: sys.currentState.mx,
		my: sys.currentState.my
	}

	sys.frameState = {
		w: false,
		s: false,
		a: false,
		d: false,
		r: false,
		f: false,
		fPressed: false,
		g: false,
		gPressed: false,
		h: false,
		hPressed: false,
		c: false,
		cPressed: false,
		one: false,
		onePressed: false,
		two: false,
		twoPressed: false,
		three: false,
		threePressed: false,
		four: false,
		five: false,
		mouseDown: false,
		mouseClicked: false,
		space: false,
		justReleasedR: false,
	}
}

class InputSystem {
	constructor(client) {
		this.keydownHandlers = new Map()
		this.holdingE = false
		this.client = client

		this.isMouseWithinWindow = false

		this.currentState = {
			w: false,
			s: false,
			a: false,
			d: false,
			r: false,
			f: false,
			g: false,
			h: false,
			c: false,
			one: false,
			two: false,
			three: false,
			four: false,
			five: false,
			mx: 0,
			my: 0,
			mouseDown: false,
			space: false,
		}

		this.frameState = {
			w: false,
			s: false,
			a: false,
			d: false,
			r: false,
			f: false,
			fPressed: false,
			g: false,
			gPressed: false,
			h: false,
			hPressed: false,
			c: false,
			cPressed: false,
			one: false,
			onePressed: false,
			two: false,
			twoPressed: false,
			three: false,
			threePressed: false,
			four: false,
			five: false,
			mouseDown: false,
			mouseClicked: false,
			space: false,
			spacePressed: false,
			justReleasedR: false,
		}

		// disable right click
		document.addEventListener('contextmenu', event => {
			event.preventDefault()
		})

		document.addEventListener('mouseout', event => {
			this.isMouseWithinWindow = false
			haultKeyInput(this)
		})

		document.addEventListener('mouseenter', event => {
			this.isMouseWithinWindow = true
		})

		document.addEventListener("keydown", event => {
			if (!this.isMouseWithinWindow) { return }
			if (window.chatFocused) { return }

			if (event.altKey) {
				event.preventDefault()
			}

			let handler = this.keydownHandlers.get(event.key)
			if (handler) {
				handler()
			}

			// 1
			if (event.keyCode === 49) {
				this.frameState.onePressed = !this.currentState.one
				this.currentState.one = true
				this.frameState.one = true
			}

			// 2
			if (event.keyCode === 50) {
				this.frameState.twoPressed = !this.currentState.two
				this.currentState.two = true
				this.frameState.two = true
			}

			// 3
			if (event.keyCode === 51) {
				this.frameState.threePressed = !this.currentState.three
				this.currentState.three = true
				this.frameState.three = true
			}

			// 4
			if (event.keyCode === 52) {
				this.currentState.four = true
				this.frameState.four = true
			}

			// 5
			if (event.keyCode === 53) {
				this.currentState.five = true
				this.frameState.five = true
			}

			// w or up arrow
			if (event.keyCode === 87 || event.keyCode === 38) {
				this.currentState.w = true
				this.frameState.w = true
			}
			// a or left arrow
			if (event.keyCode === 65 || event.keyCode === 37) {
				this.currentState.a = true
				this.frameState.a = true
			}
			// s or down arrow
			if (event.keyCode === 83 || event.keyCode === 40) {
				this.currentState.s = true
				this.frameState.s = true
			}
			// d or right arrow
			if (event.keyCode === 68 || event.keyCode === 39) {
				this.currentState.d = true
				this.frameState.d = true
			}

			// space
			if (event.keyCode === 32) {
				this.frameState.spacePressed = !this.currentState.space
				this.currentState.space = true
				this.frameState.space = true
			}

			// r
			if (event.keyCode === 82) {
				// we're currently only using this as a keyup action
			}

			// f
			if (event.keyCode === 70) {
				// we're currently only using this as a keyup action
			}

			if (event.keyCode === 77) {
				store.commit("toggleMap")
			}

			if (event.keyCode === 27) {
				store.commit("toggleEscapeMenu")
			}

			// t
			if (event.keyCode === 84) {
				store.commit('toggleChat')
			}

			// l "L"
			if (event.keyCode === 76) {
				store.commit("toggleScoreboard")
			}

			// escape
			if (event.keyCode === 27) {
				if (!window.chatFocused) {
					store.commit("toggleMenu")
				}
			}

			// e
			if (event.keyCode === 69) {
				if (!this.holdingE) {
					this.holdingE = true

					let pickUpItem = new PlayerPickUpItem(true)
					this.client.addCommand(pickUpItem)
				}
			}

			// enter
			if (event.keyCode === 13) {
				this.resetInput()
			}
		})

		document.addEventListener("keyup", event => {
			if (window.chatFocused) { return }

			if (event.keyCode === 87 || event.keyCode === 38) {
				this.currentState.w = false
			}
			if (event.keyCode === 65 || event.keyCode === 37) {
				this.currentState.a = false
			}
			if (event.keyCode === 83 || event.keyCode === 40) {
				this.currentState.s = false
			}
			if (event.keyCode === 68 || event.keyCode === 39) {
				this.currentState.d = false
			}
			if (event.keyCode === 49) {
				this.currentState.one = false
			}
			if (event.keyCode === 50) {
				this.currentState.two = false
			}
			if (event.keyCode === 51) {
				this.currentState.three = false
			}
			if (event.keyCode === 52) {
				this.currentState.four = false
			}
			if (event.keyCode === 53) {
				this.currentState.five = false
			}
			if (event.keyCode === 70) {
				// used to implement on keyup instead of keydown
				this.frameState.f = true
				this.currentState.f = false
			}
			if (event.keyCode === 71) {
				this.frameState.g = true
				this.currentState.g = false
			}
			if (event.keyCode === 72) {
				this.frameState.h = true
				this.currentState.h = false
			}
			if (event.keyCode === 67) {
				this.frameState.c = true
				this.currentState.c = false
			}
			if (event.keyCode === 82) {
				// used to implement on keyup instead of keydown
				this.frameState.r = true
				this.currentState.r = false
			}
			if (event.keyCode === 32) {
				this.currentState.space = false
			}
			if (event.keyCode === 69) {
				this.holdingE = false
			}
		})

		this.lastMouse = Date.now()

		document.addEventListener("mousemove", event => {
			this.isMouseWithinWindow = true
			this.currentState.mx = event.clientX
			this.currentState.my = event.clientY
		})

		document.addEventListener("mousedown", () => {
			if (window.chatFocused || window.mouseOverUI) { return }
			this.frameState.mouseClicked = !this.currentState.mouseDown
			this.currentState.mouseDown = true
			this.frameState.mouseDown = true

			// var diff = Date.now() - this.lastMouse
			// var diffviz = new Array(Math.min(20, Math.floor(diff / 50) + 1))
			// console.log('mouseup for:   ' + diff + 'ms [' + diffviz.join('+') + ']')
			this.lastMouse = Date.now()
		})

		document.addEventListener("mouseup", () => {

			this.currentState.mouseDown = false

			// var diff = Date.now() - this.lastMouse
			// var diffviz = new Array(Math.min(20, Math.floor(diff / 50) + 1))
			// console.log('mousedown for: ' + diff + 'ms [' + diffviz.join('+') + ']')
			this.lastMouse = Date.now()
		})
	}

	// this will spam the handler if the key is held down, optionally
	// make different kinds of input categories like discrete or continuous, where
	// discrete won't fire again until the key has been released and pressed again
	onKeydown(key, callback) {
		this.keydownHandlers.set(key, callback)
	}

	releaseKeys() {
		this.frameState.w = this.currentState.w
		this.frameState.a = this.currentState.a
		this.frameState.s = this.currentState.s
		this.frameState.d = this.currentState.d
		this.frameState.r = this.currentState.r
		this.frameState.f = this.currentState.f
		this.frameState.g = this.currentState.g
		this.frameState.h = this.currentState.h
		this.frameState.c = this.currentState.c
		this.frameState.one = this.currentState.one
		this.frameState.onePressed = false
		this.frameState.two = this.currentState.two
		this.frameState.twoPressed = false
		this.frameState.three = this.currentState.three
		this.frameState.threePressed = false
		this.frameState.four = this.currentState.four
		this.frameState.five = this.currentState.five
		this.frameState.mouseDown = this.currentState.mouseDown
		this.frameState.mouseClicked = false
		this.frameState.space = this.currentState.space
		this.frameState.spacePressed = false
	}

	resetInput() {
		this.currentState.w = false
		this.frameState.w = false
		this.currentState.a = false
		this.frameState.a = false
		this.currentState.s = false
		this.frameState.s = false
		this.currentState.d = false
		this.frameState.d = false

		this.currentState.mouseDown = false
		this.frameState.mouseDown = false
		this.frameState.mouseClicked = false
		this.currentState.space = false
		this.frameState.space = false
	}
}

export default InputSystem
