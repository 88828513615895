<template>
  <div :class="{ outOfRange: teammate.outOfRange }">
    <div class="name">{{ teammate.name }}</div>
    <div class="frame">
      <div class="left">
        <div class="kevlar">
          <div class="foreground" :style="'width:' + (teammate.shields/50) * 150  + 'px;'"></div>
          <div class="background"></div>
        </div>
        <div class="hitpoints">
          <div class="foreground" :style="'width:' + (teammate.hp/100) * 150  + 'px;'"></div>
          <div class="background"></div>
        </div>
      </div>
      <div class="right" v-if="teammate.hp > 0">
        <img
          class="itemImage"
          :style="{
            'margin-left': `${slot1ImageData.left}px`,
			'margin-right': `${slot1ImageData.right}px`
          }"
          :src="slot1ImageData.imgSrc"
        />

        <img
          class="itemImage"
          :style="{
			'margin-left': `${slot2ImageData.left}px`,
			'margin-right': `${slot2ImageData.right}px`
          }"
          :src="slot2ImageData.imgSrc"
        />
      </div>
		<div class="right" v-if="teammate.hp === 0">
		<img class="skullIcon" src="../../creative/spritesheetsourceimages/death_icon.png" />
      </div>
    </div>
  </div>
</template>

<script>
import Weapon from "../../common/weapon/Weapon";
import parcelImageAdapter from "./parcelImageAdapterOutlines";
export default {
  props: ["teammate"],
  computed: {
    slot1Config() {
      return Weapon.byIndex(this.teammate.slot1);
    },
    slot2Config() {
      return Weapon.byIndex(this.teammate.slot2);
    },
    slot1ImageData() {
      return parcelImageAdapter(
        Weapon.byIndex(this.teammate.slot1).programmaticName
      );
    },
    slot2ImageData() {
      return parcelImageAdapter(
        Weapon.byIndex(this.teammate.slot2).programmaticName
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.outOfRange {
	opacity: 0.5;
}

.frame {
  display: flex;
}
.itemImage {
  position: relative;
  top: -8px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

.skullIcon {
	margin-left: 5px;
	width: 16px;
	image-rendering: pixelated;
	image-rendering: -moz-crisp-edges;
	image-rendering: crisp-edges;
}
.kevlar {
  padding: 1px;
  .foreground {
    position: fixed;
    width: 20px;
    height: 6px;
    background-color: #105870;
    border: 1px solid #5190c3;
  }
  .background {
    width: 150px;
    height: 6px;
    background-color: rgba(26, 26, 26, 0.8);
    border: 1px solid rgba(26, 26, 26, 0.8);
  }
}

.hitpoints {
  padding: 2px;
  .foreground {
    position: fixed;
    height: 9px;
    background-color: #185a1a;
    border: 1px solid #4b8d4d;
  }
  .background {
    width: 150px;
    height: 9px;
    background-color: rgba(26, 26, 26, 0.8);
    border: 1px solid rgba(26, 26, 26, 0.8);
  }
}

.right {
  display: flex;
}
</style>
