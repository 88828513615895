export default {
	name: "BMG",
	programmaticName: "bmg",
	canonicalName: "SR_BMG",
	itemClass: "weapon",
	itemSubclass: "sniper_rifle",
	image: "bmg_default.png",
	heldImage: "bmg_hands.png",
	outlineImage: "bmg_outline.png",
	soundName: "SNIPER_RIFLE",
	reloadSoundName: "SNIPER_RIFLE_reloading",
	spawnWeighting: 40,
	grenadeProjectiles: false,
	damage: 55,
	cooldown: 1.5,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 100,
	recoilRecoveryPeriod: 0.1,
	recoilMax: 1,
	clipSize: 2,
	kick: 200,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 30,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 350,
	corpseForce: 250,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 2.5,
	activeStart: 0.55,
	activeEnd: 0.6,
	uiRotateDeg: -41,
	uiWidth: 96,
	uiTop: 41,
	uiLeft: 53
}
