export default {
	name: "AK47",
	programmaticName: "ak47",
	canonicalName: "AR_AK47",
	itemClass: "weapon",
	itemSubclass: "assault_rifle",
	image: "ak47_default.png",
	heldImage: "ak47_hands.png",
	outlineImage: "ak47_outline.png",
	soundName: "ASSAULT_RIFLE",
	reloadSoundName: "ASSAULT_RIFLE_reloading",
	spawnWeighting: 100,
	grenadeProjectiles: false,
	damage: 13,
	cooldown: 0.2,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 7,
	recoilRecoveryPeriod: 0.8,
	recoilMax: 12,
	clipSize: 30,
	kick: 60,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 30,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 250,
	corpseForce: 50,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 1.8,
	activeStart: 0.32,
	activeEnd: 0.38,
	uiRotateDeg: -36,
	uiWidth: 96,
	uiTop: 36,
	uiLeft: 57
}
