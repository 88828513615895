import nengi from "nengi"

class PoisonMessage {
	constructor(currX, currY, currWidth, nextX, nextY, nextWidth) {
		this.currX = currX
		this.currY = currY
		this.currWidth = currWidth
		this.nextX = nextX
		this.nextY = nextY
		this.nextWidth = nextWidth
	}
}

PoisonMessage.protocol = {
	currX: nengi.Float64,
	currY: nengi.Float64,
	currWidth: nengi.Float64,
	nextX: nengi.Int16,
	nextY: nengi.Int16,
	nextWidth: nengi.UInt16
}

export default PoisonMessage
