import store from "../ui/store"

// glue that passes nengi messages through to the window events, most of whom are consumed by react ui
export default (events) => {

	events.on("message::PoisonMessage", message => {
		store.commit("newPoisonDetails", message)
	})

	events.on("message::ChatMessage", message => {
		store.commit("newChatMessage", message)
	})

	events.on("message::ServerMessage", message => {
		store.commit("newServerMessage", message)
	})

	events.on("message::ScoreboardMessage", message => {
		store.commit("newScoreboard", message.scoreboard)
	})

	events.on("message::GameStateChange", message => {
		store.commit('newGameState', message.newState)
	})

	events.on("message::PoisonShrinkingMessage", () => {
		// pop up a 'the poison is moving!' message
		store.commit("togglePoisonShrinking")
		setTimeout(() => {
			// 3 seconds later, hide it
			store.commit("togglePoisonShrinking")
		}, 3000)
	})

	events.on("message::UpdatedKillCountMessage", message => {
		store.commit("newKillCount", message.newKilled)
	})

	events.on("message::RoundSummary", message => {
		store.commit('updateRoundSummary', JSON.parse(message.json))
		store.dispatch("showPostgame", 1200)
	})

	events.on("message::UpdateBattlepassProgression", message => {
		// console.log("message::UpdateBattlepassProgression", message)
		store.commit("updateBattlepassProgression", message)
	})

	events.on("message::AliveCount", message => {
		store.commit("newPlayersAlive", message.aliveCount)
	})

	events.on("message::NewZoomLevel", message => {
		store.commit("newScope", message.newZoomLevel)
	})

	events.on("message::MissionListUpdateMessage", message => {
		// console.log("message::MissionListUpdateMessage", message)
		store.commit('missionListUpdate', message.missions)
	})

	events.on("message::MissionsCompleted", message => {
		// console.log("message::MissionsCompleted", message)
		store.commit('missionsCompleted', message.missionsCompleted)
	})

	events.on("message::KillFeedMessage", message => {
		store.commit('newKillfeedMessage', message)
	})

	events.on("message::DiedToPoisonKillFeedMessage", message => {
		store.commit('newKillfeedMessage', message)
	})

	events.on("message::KillSpreeMessage", message => {
		store.commit('newKillfeedMessage', message)
	})

	events.on("message::KillStreakMessage", message => {
		store.commit('newKillfeedMessage', message)
	})
}
