const canUseBandage = (entity) => {
	return entity.bandages > 0 //&& !entity.isAffectedByTag('bandage')
}

const canUseMedpack = (entity) => {
	return entity.medpacks > 0 && entity.hp < 100
}

const canUsePainkillers = (entity) => {
	return entity.painkillers > 0 //&& !entity.isAffectedByTag('painkillers')
}

export {
	canUseBandage,
	canUseMedpack,
	canUsePainkillers,
}
