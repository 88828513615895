import Grid from "./Grid"
import SAT from "sat"
import raycast from "../map/raycast"
import Wall from "../map/Wall"
import GAME_CONSTANTS from "../balance/gameConstants"

const createCollisionBox = (type, x, y, width, height, pierce) => {
	let collider = new SAT.Box(new SAT.Vector(x, y), width, height).toPolygon()
	collider.type = type
	collider.pierce = pierce
	return collider
}

const createCollider = (type, x, y, dim) => {
	switch (type) {
	case Wall.LEFT:
		return createCollisionBox(type, x * dim, y * dim, dim * 0.5, dim, false)
	case Wall.RIGHT:
		return createCollisionBox(
			type,
			(x + 0.5) * dim,
			y * dim,
			dim * 0.5,
			dim,
			false
		)
	case Wall.TOP:
		return createCollisionBox(type, x * dim, y * dim, dim, dim * 0.5, false)
	case Wall.BOTTOM:
		return createCollisionBox(
			type,
			x * dim,
			(y + 0.5) * dim,
			dim,
			dim * 0.5,
			false
		)
	case Wall.FULL:
		return createCollisionBox(type, x * dim, y * dim, dim, dim, false)

	case Wall.LEFT_PIERCE:
		return createCollisionBox(type, x * dim, y * dim, dim * 0.5, dim, true)
	case Wall.RIGHT_PIERCE:
		return createCollisionBox(
			type,
			(x + 0.5) * dim,
			y * dim,
			dim * 0.5,
			dim,
			true
		)
	case Wall.TOP_PIERCE:
		return createCollisionBox(type, x * dim, y * dim, dim, dim * 0.5, true)
	case Wall.BOTTOM_PIERCE:
		return createCollisionBox(
			type,
			x * dim,
			(y + 0.5) * dim,
			dim,
			dim * 0.5,
			true
		)
	case Wall.FULL_PIERCE:
		return createCollisionBox(type, x * dim, y * dim, dim, dim, true)

	case Wall.TOP_LEFT:
		return createCollisionBox(
			type,
			x * dim,
			y * dim,
			dim * 0.5,
			dim * 0.5,
			false
		)
	case Wall.TOP_RIGHT:
		return createCollisionBox(
			type,
			(x + 0.5) * dim,
			y * dim,
			dim * 0.5,
			dim * 0.5,
			false
		)
	case Wall.BOTTOM_LEFT:
		return createCollisionBox(
			type,
			x * dim,
			(y + 0.5) * dim,
			dim * 0.5,
			dim * 0.5,
			false
		)
	case Wall.BOTTOM_RIGHT:
		return createCollisionBox(
			type,
			(x + 0.5) * dim,
			(y + 0.5) * dim,
			dim * 0.5,
			dim * 0.5,
			false
		)
	case Wall.MIDDLE:
		return createCollisionBox(
			type,
			x * dim + 4,
			y * dim + 4,
			dim * 0.5,
			dim * 0.5,
			false
		)

	case Wall.TOP_LEFT_PIERCE:
		return createCollisionBox(
			type,
			x * dim,
			y * dim,
			dim * 0.5,
			dim * 0.5,
			true
		)
	case Wall.TOP_RIGHT_PIERCE:
		return createCollisionBox(
			type,
			(x + 0.5) * dim,
			y * dim,
			dim * 0.5,
			dim * 0.5,
			true
		)
	case Wall.BOTTOM_LEFT_PIERCE:
		return createCollisionBox(
			type,
			x * dim,
			(y + 0.5) * dim,
			dim * 0.5,
			dim * 0.5,
			true
		)
	case Wall.BOTTOM_RIGHT_PIERCE:
		return createCollisionBox(
			type,
			(x + 0.5) * dim,
			(y + 0.5) * dim,
			dim * 0.5,
			dim * 0.5,
			true
		)
	case Wall.MIDDLE_PIERCE:
		return createCollisionBox(
			type,
			x * dim + 4,
			y * dim + 4,
			dim * 0.5,
			dim * 0.5,
			true
		)

	default:
		throw "create collider invoked with invalid wall type"
	}
}

class CollisionSystem {
	constructor(collisionGrid, tileWidth, tileHeight) {
		this.tileWidth = tileWidth
		this.tileHeight = tileHeight

		// collision grid
		this.grid = null
		this.initializeCollisionGrid(collisionGrid)
	}

	initializeCollisionGrid(collisionGrid) {
		this.grid = new Grid(collisionGrid.width, collisionGrid.height)
		this.grid.fill(null)

		// let blockedTileCount = 0
		for (let x = 0; x < collisionGrid.width; x++) {
			for (let y = 0; y < collisionGrid.height; y++) {
				let type = collisionGrid.get(x, y)
				if (type) {
					let collisionPolygon = createCollider(type, x, y, GAME_CONSTANTS.TILE_SIZE)
					// console.log(collisionPolygon)
					this.grid.set(x, y, collisionPolygon)
					// blockedTileCount++
				}
			}
		}
		// console.log('CollisionSystem loaded with', blockedTileCount, 'tile colliders')
	}

	calculateDistance(x1, y1, x2, y2) {
		return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2))
	}

	computeRay(point, aim, maxLength) {
		// the method formerly known as computeMotherfuckingRay()
		if (!maxLength) maxLength = 9999

		let destX = Math.floor(maxLength * Math.cos(aim))
		let destY = Math.floor(maxLength * Math.sin(aim))

		let raycheck = raycast(
			this.grid,
			point.x,
			point.y,
			destX + point.x,
			destY + point.y,
			9999
		)

		return {
			startX: point.x,
			startY: point.y,
			endX: raycheck.hit ? raycheck.x : destX + point.x,
			endY: raycheck.hit ? raycheck.y : destY + point.y
		}
	}

	computeStartAndEnd(shot) {
		const startX = shot.x
		const startY = shot.y

		const raycheck = raycast(
			this.grid,
			startX,
			startY,
			shot.targetX,
			shot.targetY,
			9999
		)

		return {
			startX: startX,
			startY: startY,
			endX: raycheck.hit ? raycheck.x : shot.targetX,
			endY: raycheck.hit ? raycheck.y : shot.targetY
		}
	}

	// splits a single update for a projectile into numerous smaller updates to avoid tunneling
	// invokves onEachStep after each smaller update so that other logic can tie-in (like seeing
	// if the projectile collides with entities on each smaller step).
	continuousCollisionCheck(projectile, delta, onEachStep) {
		let distancePerTick = projectile.speed * delta
		let maxDistanceBeforeTunneling = projectile.collider.r * 0.5 // might still be a little big...
		let stepsNeededToAvoidTunneling = Math.ceil(
			distancePerTick / maxDistanceBeforeTunneling
		)

		let ccdDt = delta / stepsNeededToAvoidTunneling // a smaller delta
		for (let i = 0; i < stepsNeededToAvoidTunneling; i++) {
			projectile.update(ccdDt)

			if (this.checkMapCollision(projectile.collider)) {
				projectile.needsRemoved = true
			}

			// lock the position of the projectile for the rest of the ccd
			if (projectile.needsRemoved) {
				projectile.x = projectile.endX
				projectile.y = projectile.endY
				continue
			}

			onEachStep(projectile)
		}
	}

	checkLineCircle(x1, y1, x2, y2, circleCollider) {
		/*
		let hit = lineCircleCollision(
			[x1, y1], // line start
			[x2, y2], // line end
			[circleCollider.pos.x, circleCollider.pos.y], // circle center
			circleCollider.r// circle radius
		)
		return hit
		*/

		// does this really always work? creating a 2 point polygon as a "line"
		let line = new SAT.Polygon(new SAT.Vector(), [
			new SAT.Vector(x1, y1),
			new SAT.Vector(x2, y2)
		])
		return SAT.testCirclePolygon(circleCollider, line)
	}

	// tile coordinates
	isTileBlocked(x, y) {
		return !!this.grid.get(x, y)
	}

	// real coordinates
	isPositionBlocked(x, y) {
		const tx = Math.floor(x / this.tileWidth)
		const ty = Math.floor(y / this.tileHeight)
		return this.isTileBlocked(tx, ty)
	}

	checkMapCollision(circleCollider) {
		// position of the circle in grid coordinates
		let tx = Math.floor(circleCollider.pos.x / this.tileWidth)
		let ty = Math.floor(circleCollider.pos.y / this.tileHeight)

		// how far to look around the circle
		let padding = 1

		for (let x = -padding; x <= padding; x++) {
			for (let y = -padding; y <= padding; y++) {
				let gx = tx + x
				let gy = ty + y

				let tileCollider = this.grid.get(gx, gy)

				if (tileCollider) {
					let response = new SAT.Response()
					let collided = SAT.testCirclePolygon(
						circleCollider,
						tileCollider,
						response
					)
					if (collided) {
						return true
					}
				}
			}
		}
		return false
	}

	applyMapCollisions(entity, bounce = false, pierce = false, impactExplosion = false) {
		let circle = entity.collider

		// position of the circle in grid coordinates
		let tx = Math.floor(circle.pos.x / this.tileWidth)
		let ty = Math.floor(circle.pos.y / this.tileHeight)

		// how far to look around the circle
		let padding = 1

		/* Note: the tricky part about checking for collisions in an area of tiles is that
		* it is possible to collide with multiple objects at once, such as at a corner or junction.
		* SAT reports back the depth of the collision (see: https://github.com/jriecken/sat-js).
		* Normally just subtracting these numbers is enough to uncollide the object, however if there
		* are multiple collisions, then we will uncollide the object by too much. The solution is to move
		* the collider on each step so that we don't accrue collisions that are already uncollided by
		* the prior step. All of this is done just to have a nice, firm (no rubberband) collision
		* that also allows players to slide along walls. */

		//let collidedEver = false
		let collisionFree = true
		for (let x = -padding; x <= padding; x++) {
			for (let y = -padding; y <= padding; y++) {
				let gx = tx + x
				let gy = ty + y

				let tileCollider = this.grid.get(gx, gy)

				if (tileCollider) {
					let response = new SAT.Response()
					let collided = SAT.testCirclePolygon(circle, tileCollider, response)
					if (collided) {
						//collidedEver = true
						collisionFree = false
						if (!(pierce && tileCollider.pierce)) {
							if (impactExplosion) {
								entity.fuseComplete = true
								return
							}
							circle.pos.x -= response.overlapV.x
							circle.pos.y -= response.overlapV.y
							entity.x = circle.pos.x
							entity.y = circle.pos.y

							if (bounce) {
								if (Math.abs(response.overlapV.x) - Math.abs(response.overlapV.y) >= 0) {
									entity.vx = -entity.vx
								} else {
									entity.vy = -entity.vy
								}
							}
						}
					}
				}
			}
		}

		return collisionFree
	}

	// todo refactor
	applyAllegedMapCollisions(entity) {
		let circle = entity.allegedCollider

		// position of the circle in grid coordinates
		let tx = Math.floor(circle.pos.x / this.tileWidth)
		let ty = Math.floor(circle.pos.y / this.tileHeight)

		// how far to look around the circle
		let padding = 1
		// let collidedEver = false
		for (let x = -padding; x <= padding; x++) {
			for (let y = -padding; y <= padding; y++) {
				let gx = tx + x
				let gy = ty + y

				let tileCollider = this.grid.get(gx, gy)

				if (tileCollider) {
					let response = new SAT.Response()
					let collided = SAT.testCirclePolygon(circle, tileCollider, response)
					if (collided) {
						// collidedEver = true
						circle.pos.x -= response.overlapV.x
						circle.pos.y -= response.overlapV.y
						entity.allegedX = circle.pos.x
						entity.allegedY = circle.pos.y
					}
				}
			}
		}
	}

	static checkCircleCircle(circleA, circleB) {
		return SAT.testCircleCircle(circleA, circleB)
	}
}

export default CollisionSystem
