export default {
	name: "Smoke Grenade",
	programmaticName: "smokeGrenade",
	canonicalName: "GRENADE_SmokeGrenade",
	itemClass: "grenade",
	itemSubclass: "utility_grenade",
	prop: "smokeGrenades",
	image: "smoke-grenade-with-pin.png",
	thrownImage: "smoke-grenade.png",
	outlineImage: "ak47_outline.png",
	spawnWeighting: 200,
	scaleDown: true,
	throwMod: 0.55,
	rollMod: 0.2,
	minThrowForce: 10,
	maxThrowForce: 150,
	damageNear: 0,
	damageFar: 0,
	explosionRadius: 60,
	fuseTimer: 3,
	corpseForce: 200,
	carryLimit: 4,
	createSmoke: true,
	gunCooldown: 0.4,
	impactExplosion: false,
	smokeDuration: 12,
	expansionDuration: 1,
	puffDuration: 2.2,
	puffDurationRNGMod: 0.4,
	puffFrequency: 0.2,
	puffSize: 2,
	puffSizeRNGMod: 0.5,
	puffRotationSpeed: 0.3,
	puffRotationSpeedRNGMod: 0.1,
	puffMaxAlpha: 0.8,
	puffFadeTime: 0.4,
	puffImage: "cloud.png"
}
