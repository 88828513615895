<template>
	<div class="social-buttons">
		<a href="https://discord.gg/bitfoxgames" target="_blank">
			<img src="../assets/discord.png" alt="Discord">
		</a>
		<a href="https://twitter.com/Other_Ocean" target="_blank">
			<img src="../assets/twitter.png" alt="Twitter">
		</a>
		<a href="https://www.facebook.com/OtherOceanInteractive" target="_blank">
			<img src="../assets/facebook.png" alt="Facebook">
		</a>
		<!--
		<a href="https://www.reddit.com/r/bitfoxgames/" target="_blank">
			<img src="../assets/reddit.png" alt="reddit">
		</a>
		-->

		<a href="mailto:support@otherocean.com" target="_blank">
			<img src="../assets/contact.png" alt="Contact us">
		</a>
	</div>
</template>

<script>
export default {
	name: 'SocialButtons',
}
</script>

<style lang="scss" scoped>
.social-buttons {
	display: flex;
	justify-content: center;

	a {
		flex-shrink: 1;
		padding: 5px;
		cursor: pointer;
	}
}

img {
	height: 50px;
}
</style>
