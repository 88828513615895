import GAME_CONSTANTS from "../../common/balance/gameConstants"
import { Container, Graphics, Sprite } from "pixi.js-legacy"

class Projectile extends Container {
	constructor(entity, visualData) {
		super()
		this.id = entity.id
		this.startX = entity.x
		this.startY = entity.y
		this.x = entity.x
		this.y = entity.y
		this.rotation = entity.rotation - Math.PI
		this.instant = entity.instant

		if (GAME_CONSTANTS.DEBUG.RENDER_HITBOXES) {
			let circleGraphics = new Graphics()
			circleGraphics.beginFill(visualData.color || 0xff0000)
			circleGraphics.drawCircle(0, 0, GAME_CONSTANTS.BULLET_RADIUS)
			circleGraphics.endFill()
			this.addChild(circleGraphics)
		}
		const spriteName = visualData.image ? visualData.image : "rifle_bullet1.png"
		this.bulletSprite = new Sprite.from(spriteName)
		//this.bulletSprite.tint = 0x0ab2fa

		if(visualData.customPivot)
			this.bulletSprite.anchor.set(visualData.customPivot.x, visualData.customPivot.y)
		else
			this.bulletSprite.anchor.set(1, 0.5)

		if(visualData.initialScale)
			this.bulletSprite.scale.set(-visualData.initialScale, visualData.initialScale)
		else
			this.bulletSprite.scale.set(-0.4 * (10 / 230), 0.4)

		this.scaleOverTime = visualData.scaleOverTime
		this.pinsTarget = visualData.pinsTarget
		this.addChild(this.bulletSprite)
	}

	update() {
		let dx = this.x - this.startX
		let dy = this.y - this.startY
		let len = Math.sqrt(dx * dx + dy * dy)
		let ratio = len / 230
		if (this.instant) {
			// console.log(len, ratio)
			if(this.scaleOverTime)
				this.bulletSprite.scale.set(-1 * ratio, 0.4)
		} else {
			if (ratio > 1) {
				ratio = 1
			}
			// console.log(len, ratio)
			if(this.scaleOverTime)
				this.bulletSprite.scale.set(-0.4 * ratio, 0.4)
		}
	}
}

export default Projectile
