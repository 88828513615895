import nengi from "nengi"

class Explosion {
	constructor(x, y, explosionRadius) {
		this.x = x
		this.y = y
		this.explosionRadius = explosionRadius
	}
}

Explosion.protocol = {
	x: nengi.UInt16,
	y: nengi.UInt16,
	explosionRadius: nengi.Float32
}

export default Explosion
