export default {
	name: "Bandage",
	programmaticName: "bandage",
	canonicalName: "BANDAGE",
	prop: "bandages",
	itemClass: "medical",
	itemSubclass: "heal_over_time",
	image: "bandage.png",
	heldImage: "medical_hands.png",
	healthOverTime: 21,
	effectDuration: 7000,
	spawnWeighting: 250,
	carryLimit: 6,
	usageTime: 1.10,
	moveSlowDuration: 1.10,
	moveSlowModifier: 0.6
}
