
import { Graphics } from 'pixi.js-legacy'

export default (aabb, foreground) => {
	// aabb -> rectangle conversion
	const x = aabb.x - aabb.halfWidth
	const y = aabb.y - aabb.halfHeight
	const width = aabb.halfWidth * 2
	const height = aabb.halfHeight * 2
	
	const g = new Graphics()
	g.lineStyle(1, 0xFEEB77, 0.3)
	g.drawRect(x, y, width, height)

	foreground.addChild(g)

	setTimeout(() => {
		foreground.removeChild(g)
	}, 500)
}
