import { Container, Sprite } from "pixi.js-legacy"
class HealthBar extends Container {
	constructor(verified) {
		super()

		this.background = new Sprite.from("16x16.png")
		this.background.tint = 0xff0000
		this.background.scale.x = 1
		this.background.scale.y = 1 / 6

		this.healthBar = new Sprite.from("16x16.png")
		this.healthBar.tint = 0x7cfc00
		this.healthBar.scale.x = 1
		this.healthBar.scale.y = 1 / 6

		this.shieldBar = new Sprite.from("16x16.png")
		this.shieldBar.tint = 0x808080
		this.shieldBar.scale.x = 1
		this.shieldBar.scale.y = 1 / 8

		if (verified) {
			this.star = new Sprite.from("star.png")
			this.star.scale.set(0.2, 0.2)
			this.star.x = -4
			this.addChild(this.star)
		}

		this.addChild(this.background)
		this.addChild(this.healthBar)
		this.addChild(this.shieldBar)

		if (verified) {
			this.x = -7
		} else {
			this.x = -8
		}
		this.y = -12
	}

	setHealth(health) {
		this.healthBar.scale.x = health / 100
	}

	setShields(shields) {
		this.shieldBar.scale.x = shields / 50
	}
}

export default HealthBar
