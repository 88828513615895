import VelocityAttribute from "./attributes/VelocityAttribute"
import ScaleAttribute from "./attributes/ScaleAttribute"
import RotationAttribute from "./attributes/RotationAttribute"
import SpawnEmitterAttribute from "./attributes/SpawnEmitterAttribute"
import ColourAttribute from "./attributes/ColourAttribute"
import AlphaAttribute from "./attributes/AlphaAttribute"

export default (type, attributeBlueprint) => {
    switch(type) {
        case "VELOCITY": 
            return new VelocityAttribute(attributeBlueprint)
        case "SCALE": 
            return new ScaleAttribute(attributeBlueprint)
        case "ROTATION":
            return new RotationAttribute(attributeBlueprint)
        case "SPAWN_EMITTER":
            return new SpawnEmitterAttribute(attributeBlueprint)
        case "COLOUR":
            return new ColourAttribute(attributeBlueprint)
        case "ALPHA":
            return new AlphaAttribute(attributeBlueprint)
        default: 
            return undefined
    }
}
