import nengi from "nengi"

class AFKMessage {
	constructor() {
		this.afk = true
	}
}

AFKMessage.protocol = {
	afk: nengi.Boolean
}

export default AFKMessage
