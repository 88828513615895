export default {
	name: "Double Barrel S686",
	canonicalName: "SG_S686",
	programmaticName: "s686",
	itemClass: "weapon",
	itemSubclass: "shotgun",
	image: "s686_default.png",
	heldImage: "s686_hands.png",
	outlineImage: "s686_outline.png",
	soundName: "SHOTGUN",
	reloadSoundName: "SHOTGUN2_reloading",
	spawnWeighting: 100,
	grenadeProjectiles: false,
	damage: 10,
	cooldown: 0.4,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 1,
	recoilRecoveryPeriod: 0.8,
	recoilMax: 15,
	clipSize: 2,
	kick: 100,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 30,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 120,
	pelletCount: 8,
	pelletSpread: 0.055,
	corpseForce: 150,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 2.0,
	activeStart: 0.39,
	activeEnd: 0.45,
	uiRotateDeg: -39,
	uiWidth: 94,
	uiTop: 40,
	uiLeft: 51
}
