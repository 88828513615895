import { Container, Sprite, BLEND_MODES } from "pixi.js-legacy"

class ResurrectionEffect extends Container {
	constructor() {
		super()
		this.acc = 0
		this.isComplete = false

		const sprites = []
		for (let i = 0; i < 10; i++) {
			const sprite = new Sprite.from('cloud.png')
			sprite.anchor.x = sprite.anchor.y = 0.5
			sprite.scale.x = sprite.scale.y = 0.1
			sprite.rotV = Math.random() * 2
			sprite.alpha = 0.2
			sprite.blendMode = BLEND_MODES.ADD
			sprite.tint = 0xfff094 //yellow  //red/pink: 0xff9494
			sprites.push(sprite)
			this.addChild(sprite)
		}
		this.sprites = sprites
	}

	update(delta) {
		this.acc += delta

		// explosion phase, grow to max over 200 ms
		const scaleFactor = Math.min(this.acc / 0.200, 1)
		this.sprites.forEach(sprite => {
			sprite.scale.x = sprite.scale.y = 1.5 * scaleFactor
		})

		// spin
		this.sprites.forEach(sprite => {
			sprite.rotation += 10 * delta * sprite.rotV
		})

		// mostly disappear, but leave a residue
		if (this.acc > 1 && this.acc < 1.5) {
			this.sprites.forEach(sprite => {
				sprite.alpha -= delta * 2
				if (sprite.alpha < 0.05) {
					// hault the spin, remain as residue
					sprite.alpha = 0.05
					sprite.rotV = 0
				}
			})
		}

		// residue fades
		if (this.acc > 1.5) {
			this.alpha -= delta * 0.2
			if (this.alpha < 0) {
				this.isComplete = true
			}
		}
	}
}

export default ResurrectionEffect
