import nengi from "nengi"

class GameStateChange {
	constructor(newState) {
		this.newState = newState
	}
}

GameStateChange.protocol = {
	newState: nengi.String
}

export default GameStateChange
