export default {
	name: "Glock",
	programmaticName: "glock",
	canonicalName: "PISTOL_GLOCK",
	itemClass: "weapon",
	itemSubclass: "pistol",
	image: "glock_default.png",
	heldImage: "glock_hands.png",
	outlineImage: "glock_outline.png",
	soundName: "PISTOL",
	reloadSoundName: "PISTOL_reloading",
	spawnWeighting: 100,
	grenadeProjectiles: false,
	damage: 9,
	cooldown: 0.11,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 8,
	recoilRecoveryPeriod: 1.0,
	recoilMax: 12,
	clipSize: 17,
	kick: 12,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 14,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 200,
	corpseForce: 20,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 1.5,
	activeStart: 0.25,
	activeEnd: 0.33,
	uiRotateDeg: -25,
	uiWidth: 135,
	uiTop: 36,
	uiLeft: 58
}
