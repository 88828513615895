import ParticleAttribute from "./ParticleAttribute"



class AlphaAttribute extends ParticleAttribute {
    constructor(attributeBlueprint) {
        super(attributeBlueprint)
        this.attributeBlueprint = attributeBlueprint
        this.alphaOverTime = attributeBlueprint.alphaOverTime
        this.elapsedTime = 0
    }

    die(particle) {
        super.die()
    }

    reset() {
        super.reset()
    }

    particleSpawned(particle) {
        super.particleSpawned(this.particleSpawned)
        this.attributeBlueprint = this.attributeBlueprint
        if(this.attributeBlueprint.randomAlpha) {
            this.initialAlpha = this.parseRandom(this.attributeBlueprint.randomAlpha.minA, this.attributeBlueprint.randomAlpha.maxA)
        }
        else {
            this.initialAlpha = this.attributeBlueprint.initialAlpha
        }

        if(this.alphaOverTime) {
            if(this.attributeBlueprint.randomFinalAlpha)
                this.finalAlpha =  this.parseRandom(this.attributeBlueprint.randomFinalAlpha.minX, this.attributeBlueprint.randomFinalAlpha.maxX)
            else
                this.finalAlpha = this.attributeBlueprint.finalAlpha
        }

        particle.alpha = this.initialAlpha
        this.lifetime = particle.lifetime
        this.elapsedTime = 0.0
    }

    apply(particle, delta) {
        super.apply(particle)
        if(this.alphaOverTime) {
            this.elapsedTime += delta
            let percentDone = Math.min(this.elapsedTime / this.lifetime, 1.0)
            let alpha = (percentDone * (this.finalAlpha - this.initialAlpha)) + this.initialAlpha
            particle.alpha = alpha
        }
    }
}

export default AlphaAttribute