import { Howler } from 'howler'
import Weapon from '../../common/weapon/Weapon'
import store from '../ui/store'
import loadSounds from './loadSounds'

Howler.autoUnlock = true

const random = (list) => {
	return list[Math.floor(Math.random() * list.length)]
}

class SoundSystem {
	constructor(game) {
		this.game = game
		this.volume = 1.0
		this.currentMusicPlaying = null
		const { music, sounds } = loadSounds()
		this.sounds = sounds
		this.music = music
		this.originalMusicVolume = this.music['BattleRoyale'][0].volume()

		this.activeSounds = new Map()

		document.addEventListener('musicEnabled', (evt) => {
			const { isEnabled } = evt.detail
			if(isEnabled) {
				this.playMusic()
			} else {
				this.stopMusic()
			}
		})
		document.addEventListener('musicVolume', evt =>{
			const  { volume } = evt.detail
			this.volume = volume / 100.0
			let result = this.originalMusicVolume * this.volume
			this.currentMusicPlaying.volume(result)
		})
	}

	determineSoundVolumeByDistance(x, y) {
		let distance
		let maxDistance = 600
		if (this.game.renderer && this.game.renderer.AB45203628E28928D1FB446B5C6EB50C) {
			let a = this.game.renderer.AB45203628E28928D1FB446B5C6EB50C.x - x
			let b = this.game.renderer.AB45203628E28928D1FB446B5C6EB50C.y - y
			distance = Math.sqrt(a * a + b * b)
			if (distance > maxDistance) {
				distance = maxDistance
			}
		} else {
			distance = 50
		}
		return ((maxDistance - distance) / maxDistance) * 0.5
	}

	determineSoundVolume(x, y, volumeMultiplier) {
		if (!store.getters.sfxEnabled) return 0
		let soundVolumeAtDistance = !x ? 0.5 : this.determineSoundVolumeByDistance(x, y)
		if (soundVolumeAtDistance === 0) return 0
		if (!volumeMultiplier) volumeMultiplier = 1.0
		return soundVolumeAtDistance * volumeMultiplier * (store.getters.sfxVolume / 100)
	}

	playMusic() {
		this.stopMusic()
		this.currentMusicPlaying = this.music['BattleRoyale'][0]
		this.currentMusicPlaying.volume(this.originalMusicVolume * 10.0)
		this.currentMusicPlaying.play()
	}

	retryPlayMusicUntilAudioAvailable() {
		if (!store.getters.musicEnabled) { return } // no music = no retry
		let count = 0
		let self = this
		function reval() {
			count++
			if (!Howler._audioUnlocked) {
				if (count <= 150) { // try for 15 seconds then quit
					setTimeout(reval, 100)
				}
			} else {
				if (!store.getters.musicEnabled) { return } // no music = no retry
				self.playMusic()
			}
		}
		setTimeout(reval, 100)
	}

	stopMusic() {
		if (this.currentMusicPlaying) {
			this.currentMusicPlaying.stop()
			this.currentMusicPlaying = null
		}
	}

	playSound(sound, x, y, volumeMultiplier) {
		if (this.volume === 0) { return }
		if (!store.getters.sfxEnabled) { return }
		let volumeToPlayAt = this.determineSoundVolume(x, y, volumeMultiplier)
		sound.volume(volumeToPlayAt)
		return sound.play()
	}

	// this is a new play function which is resilient to stacking sounds
	play(soundName, options) {
		if (this.volume === 0 || !store.getters.sfxEnabled) { return }

		const now = Date.now()
		const lastPlayed = this.activeSounds.get(soundName)
		if (lastPlayed && now - lastPlayed < 100) {
			// not playing that sound because a similar one was played within 100 ms
			return null
		}

		const { x, y, volume, randomize } = options
		const sound = (randomize) ? random(this.sounds[soundName]) : this.sounds[soundName][0]
		sound.volume(this.determineSoundVolume(x, y, volume))
		this.activeSounds.set(soundName, now)
		return sound.play()
	}

	playWeaponSwap() {
		this.play('pick_up_weapon', { volume: 0.4 })
	}

	playItemInteract(x, y) {
		this.play('pick_up_weapon', { x, y, volume: 0.4 })
	}

	playBulletImpact(x, y) {
		this.play('bullet_impact', { x, y, randomize: true, volume: 0.4 })
	}

	playExplosion(x, y) {
		this.play('explosion', { x, y, randomize: true, volume: 0.8 })
	}

	playMolotovExplosion(x, y) {
		this.play('molotov_impact', { x, y, randomize: true, volume: 1.1 })
	}

	playSmokeGrenade(x, y) {
		return this.playSound(random(this.sounds['smoke_grenade']), x, y, 1.0)
	}

	playResGrenade(x, y) {
		return this.playSound(random(this.sounds['res_grenade']), x, y, 5.0)
	}

	updateSmokeGrenade(soundId, x, y) {
		let sound = random(this.sounds['smoke_grenade'])
		let volumeToPlayAt = this.determineSoundVolume(x, y, 1.0)
		sound.volume(volumeToPlayAt, soundId)
	}

	playReload(type, sound, soundId, weaponType) {
		if (this.volume <= 0) { return false }
		switch (type) {
			case 'startReload':
				sound = this.sounds[Weapon.byIndex(weaponType).reloadSoundName][0]
				soundId = this.playSound(sound, null, null, 2.0)
				break
			case 'activeReloadSuccess':
				sound.rate(2.0, soundId)
				break
			case 'activeReloadFail':
				sound.rate(0.8, soundId)
				break
		}

		return { sound: sound, soundId: soundId }
	}

	playKillingSpree() {
		this.play('killing_spree', {})
	}

	playFrenzy() {
		this.play('frenzy', {})
	}

	playDoubleKill() {
		this.play('double_kill', {})
	}

	playTripleKill() {
		this.play('triple_kill', {})
	}

	playOutOfAmmo() {
		this.play('dry_fire', {})
	}

	playGunShotLocal(weaponType) {
		const soundName = Weapon.byIndex(weaponType).soundName
		this.play(soundName, { randomize: true })
	}

	playGunShot(weaponFiredMsg) {
		const soundName = Weapon.byIndex(weaponFiredMsg.weaponType).soundName
		const { x, y } = weaponFiredMsg
		this.play(soundName, { x, y, randomize: true })
	}
}

export default SoundSystem
