import Grid from "../../common/classes/Grid"
import GAME_CONSTANTS from "../../common/balance/gameConstants"
import { Container, Sprite, RenderTexture } from "pixi.js-legacy"

const TILE_FLIPPED_DIAGONALLY = 0x20000000
const TILE_FLIPPED_HORIZONTALLY = 0x80000000
const TILE_FLIPPED_VERTICALLY = 0x40000000

// creates a unique key for combinations of x and y, used by the cache
function createkey(x, y) {
	return x + y * 10000000
}

let createTile = (source, tileTextures, x, y) => {
	let tileId = source.get(x, y)

	const isFlippedDiagonally = tileId & TILE_FLIPPED_DIAGONALLY
	const isFlippedHorizontally = tileId & TILE_FLIPPED_HORIZONTALLY
	const isFlippedVertically = tileId & TILE_FLIPPED_VERTICALLY
	tileId &= ~(TILE_FLIPPED_DIAGONALLY |
		TILE_FLIPPED_HORIZONTALLY |
		TILE_FLIPPED_VERTICALLY)

	let tile = new Sprite(tileTextures[tileId])

	// pivots are to offset the sprites in-place, so they don't appear on the wrong tile
	if (isFlippedDiagonally) {
		tile.pivot.x = 16
		tile.pivot.y = 16
		tile.scale.x = -1
		tile.scale.y = -1
	}
	if (isFlippedHorizontally) {
		tile.pivot.x = 16
		tile.scale.x = -1
	}
	if (isFlippedVertically) {
		tile.pivot.y = 16
		tile.scale.y = -1
	}

	return tile
}

// backgorund tiles
let createMegaTile = (x1, y1, x2, y2, tileLayers, tileTextures, renderer) => {
	// console.log('createMegaTile', x1, y1, x2, y2)
	// let start = performance.now()
	let dx = x2 - x1
	let dy = y2 - y1
	let temp = new Container()
	for (var i = x1; i < x2; i++) {
		for (var j = y1; j < y2; j++) {
			let localX = i - x1
			let localY = j - y1

			let bg2 = createTile(tileLayers["Background2"], tileTextures, i, j)
			bg2.x = localX * GAME_CONSTANTS.TILE_WIDTH
			bg2.y = localY * GAME_CONSTANTS.TILE_HEIGHT
			// console.log('bg2', tileLayers['Background2'])
			temp.addChild(bg2)
			let bg1 = createTile(tileLayers["Background1"], tileTextures, i, j)
			bg1.x = localX * GAME_CONSTANTS.TILE_WIDTH
			bg1.y = localY * GAME_CONSTANTS.TILE_HEIGHT
			temp.addChild(bg1)
			let bg0 = createTile(tileLayers["Background0"], tileTextures, i, j)
			bg0.x = localX * GAME_CONSTANTS.TILE_WIDTH
			bg0.y = localY * GAME_CONSTANTS.TILE_HEIGHT
			temp.addChild(bg0)
		}
	}
	let texture = RenderTexture.create(dx * GAME_CONSTANTS.TILE_WIDTH, dy * GAME_CONSTANTS.TILE_HEIGHT)
	renderer.render(temp, texture)
	// let stop = performance.now()
	// console.log('mega', stop - start)
	return new Sprite(texture)
}

// foreground tiles
let createMegaTile2 = (x1, y1, x2, y2, tileLayers, tileTextures, renderer) => {
	// console.log('createMegaTile', x1, y1, x2, y2)
	let dx = x2 - x1
	let dy = y2 - y1
	let temp = new Container()
	for (var i = x1; i < x2; i++) {
		for (var j = y1; j < y2; j++) {
			let localX = i - x1
			let localY = j - y1

			let bg2 = createTile(tileLayers["Foreground2"], tileTextures, i, j)
			bg2.x = localX * GAME_CONSTANTS.TILE_WIDTH
			bg2.y = localY * GAME_CONSTANTS.TILE_HEIGHT
			temp.addChild(bg2)
			let bg1 = createTile(tileLayers["Foreground1"], tileTextures, i, j)
			bg1.x = localX * GAME_CONSTANTS.TILE_WIDTH
			bg1.y = localY * GAME_CONSTANTS.TILE_HEIGHT
			temp.addChild(bg1)
			let bg0 = createTile(tileLayers["Foreground0"], tileTextures, i, j)
			bg0.x = localX * GAME_CONSTANTS.TILE_WIDTH
			bg0.y = localY * GAME_CONSTANTS.TILE_HEIGHT
			temp.addChild(bg0)
			if (GAME_CONSTANTS.DEBUG.RENDER_COLLISIONS) {
				let bgz = createTile(tileLayers["Walls"], tileTextures, i, j)
				bgz.x = localX * GAME_CONSTANTS.TILE_WIDTH
				bgz.y = localY * GAME_CONSTANTS.TILE_HEIGHT
				temp.addChild(bgz)
			}
		}
	}
	let texture = RenderTexture.create(dx * GAME_CONSTANTS.TILE_WIDTH, dy * GAME_CONSTANTS.TILE_HEIGHT)
	renderer.render(temp, texture)
	return new Sprite(texture)
}
// TODO clean up and then cull the mega tiles

class WorldRenderer {
	constructor(
		background,
		middleground,
		foreground,
		tileLayers,
		tileTextures,
		initialScale,
		renderer
	) {
		this.cache = new Map()
		this.background = background
		this.middleground = middleground
		this.foreground = foreground
		this.tileLayers = tileLayers
		this.tileTextures = tileTextures
		this.maxWidth = this.tileLayers["Background0"].width
		this.maxHeight = this.tileLayers["Background0"].height
		// console.log({maxWidth: this.maxWidth, maxHeight: this.maxHeight})
		this.tileWidth = GAME_CONSTANTS.TILE_WIDTH
		this.tileHeight = GAME_CONSTANTS.TILE_HEIGHT
		this.viewWidth = 12
		this.viewHeight = 8
		this.prev = {
			tileX: 0,
			tileY: 0,
			megaX: 0,
			megaY: 0
		}
		this.mapMiddleground = {} 

		let start = window.performance.now()
		let chunkWidthInTiles = 16
		let chunkSize = Math.ceil(this.maxWidth / chunkWidthInTiles)
		// console.log({chunkSize})
		this.megaBackground = new Grid(chunkSize, chunkSize)
		this.megaForeground = new Grid(chunkSize, chunkSize)

		// let foo = 0
		for (let i = 0; i < chunkSize; i++) {
			for (let j = 0; j < chunkSize; j++) {
				let sx = i * chunkWidthInTiles
				let sy = j * chunkWidthInTiles
				let ex = (i + 1) * chunkWidthInTiles
				let ey = (j + 1) * chunkWidthInTiles
				let sprite = createMegaTile(
					sx,
					sy,
					ex,
					ey,
					this.tileLayers,
					this.tileTextures,
					renderer
				)
				sprite.x = i * chunkWidthInTiles * 16
				sprite.y = j * chunkWidthInTiles * 16
				sprite.chunkX = i
				sprite.chunkY = j
				sprite.visible = false
				if (GAME_CONSTANTS.DEBUG.SHOW_CHUNK_SEAMS) {
					sprite.scale.set(0.995, 0.995)
					sprite.alpha = 0.5
					sprite.tint = 0xFF7F7F
				}
				this.background.addChild(sprite)
				this.megaBackground.set(i, j, sprite)
				// foo++
			}
		}

		// console.log('chunks', foo)

		for (let i = 0; i < chunkSize; i++) {
			for (let j = 0; j < chunkSize; j++) {
				let sx = i * chunkWidthInTiles
				let sy = j * chunkWidthInTiles
				let ex = (i + 1) * chunkWidthInTiles
				let ey = (j + 1) * chunkWidthInTiles
				let sprite = createMegaTile2(
					sx,
					sy,
					ex,
					ey,
					this.tileLayers,
					this.tileTextures,
					renderer
				)
				sprite.x = i * chunkWidthInTiles * 16
				sprite.y = j * chunkWidthInTiles * 16
				sprite.chunkX = i
				sprite.chunkY = j
				sprite.visible = false
				if (GAME_CONSTANTS.DEBUG.SHOW_CHUNK_SEAMS) {
					sprite.scale.set(0.995, 0.995)
					sprite.alpha = 0.5
					sprite.tint = 0x7F7FFF
				}
				this.foreground.addChild(sprite)
				this.megaForeground.set(i, j, sprite)
			}
		}

		let stop = window.performance.now()
		// console.log('map generation', stop - start, 'ms')

		this.resize(initialScale)
	}

	resize() {
		// show enough tiles to slightly overfill the screen
		/*
        this.viewWidth =
            Math.ceil(window.innerWidth / this.tileWidth * (1 / scale) * 0.5) +
            15
        this.viewHeight =
            Math.ceil(
                window.innerHeight / this.tileHeight * (1 / scale) * 0.5
            ) + 10
            */

		this.viewWidth = 32
		this.viewHeight = 18
		// do a "force" cull
		this.cull(
			this.prev.x * this.tileWidth,
			this.prev.y * this.tileHeight,
			true
		)
	}

	addTile(x, y) {
		let tilekey = createkey(x, y)

		if (this.cache.get(tilekey)) {
			// this tile already endXists in the renderer
			return
		}

		// let fg0 = 0 // this.tileLayers['Foreground0'].get(x, y)
		// let fg1 = 0 // this.tileLayers['Foreground1'].get(x, y)
		// let fg2 = 0 // this.tileLayers['Foreground2'].get(x, y)
		let mg = this.tileLayers["Middleground"].get(x, y)

		// let bg0 = 0 // this.tileLayers['Background0'].get(x, y)
		// let bg1 = 0 // this.tileLayers['Background1'].get(x, y)
		// let bg2 = 0 // this.tileLayers['Background2'].get(x, y)

		let cache = {
			key: tilekey,
			x: x,
			y: y,
			// fg0: null,
			// fg1: null,
			// fg2: null,
			mg: null
			// bg0: null,
			// bg1: null,
			// bg2: null
		}
		/*
        if (fg2 !== 0) {
            let tile = new Sprite(this.tileTextures[fg2])
            tile.x = x * this.tileWidth
            tile.y = y * this.tileHeight
            this.foreground.addChild(tile)
            cache.fg2 = tile
        }
        if (fg1 !== 0) {
            let tile = new Sprite(this.tileTextures[fg1])
            tile.x = x * this.tileWidth
            tile.y = y * this.tileHeight
            this.foreground.addChild(tile)
            cache.fg1 = tile
        }
        if (fg0 !== 0) {
            let tile = new Sprite(this.tileTextures[fg0])
            tile.x = x * this.tileWidth
            tile.y = y * this.tileHeight
            this.foreground.addChild(tile)
            cache.fg0 = tile
        }
        */
		if (mg !== 0) {
			let tile = new Sprite(this.tileTextures[mg])
			tile.x = x * this.tileWidth
			tile.y = y * this.tileHeight
			this.middleground.addChild(tile)
			this.mapMiddleground[tilekey] = tile
			cache.mg = tile
		}
		/*
        if (bg2 !== 0) {
            let tile = new Sprite(this.tileTextures[bg2])
            tile.x = x * this.tileWidth
            tile.y = y * this.tileHeight
            this.background.addChild(tile)
            cache.bg2 = tile
        }
        if (bg1 !== 0) {
            let tile = new Sprite(this.tileTextures[bg1])
            tile.x = x * this.tileWidth
            tile.y = y * this.tileHeight
            this.background.addChild(tile)
            cache.bg1 = tile
        }
        if (bg0 !== 0) {
            let tile = new Sprite(this.tileTextures[bg0])
            tile.x = x * this.tileWidth
            tile.y = y * this.tileHeight
            this.background.addChild(tile)
            cache.bg0 = tile
        }
        */
		this.cache.set(tilekey, cache)
	}

	removeTile(x, y) {
		let tilekey = createkey(x, y)
		let cache = this.cache.get(tilekey)
		if (cache) {
			/*
            if (cache.fg0) {
                this.foreground.removeChild(cache.fg0)
                //cache.fg0.destroy({ children: true })
            }
            if (cache.fg1) {
                this.foreground.removeChild(cache.fg1)
                //cache.fg1.destroy({ children: true })
            }
            if (cache.fg2) {
                this.foreground.removeChild(cache.fg2)
                //cache.fg2.destroy({ children: true })
            }
            */
			if (cache.mg) {
				this.mapMiddleground[cache.mg] = undefined
				this.middleground.removeChild(cache.mg)
				// cache.mg.destroy({ children: true })
			}
			/*
            if (cache.bg0) {
                this.background.removeChild(cache.bg0)
                //cache.bg0.destroy({ children: true })
            }
            if (cache.bg1) {
                this.background.removeChild(cache.bg1)
                //cache.bg1.destroy({ children: true })
            }
            if (cache.bg2) {
                this.background.removeChild(cache.bg2)
                //cache.bg2.destroy({ children: true })
            }
            */
		}
		this.cache.delete(tilekey)
	}

	cull(x, y, force) {
		this.cullMiddleground(x, y, force)
		this.cullMegas(x, y, force)
	}

	cullMegas(x, y, force) {
		let megaX = Math.floor(x / 256)
		let megaY = Math.floor(y / 256)

		if (megaX === this.prev.megaX && megaY === this.prev.megaY && !force) {
			return
		}

		let megabgs = this.megaBackground.toArray()
		let megafgs = this.megaForeground.toArray()
		let padding = 2
		for (var i = 0; i < megabgs.length; i++) {
			let mega = megabgs[i]
			if (
				mega.chunkX >= megaX - padding &&
				mega.chunkY >= megaY - padding &&
				mega.chunkX <= megaX + padding &&
				mega.chunkY <= megaY + padding
			) {
				mega.visible = true
			} else {
				mega.visible = false
			}
		}

		for (let i = 0; i < megafgs.length; i++) {
			let mega = megafgs[i]
			if (
				mega.chunkX >= megaX - padding &&
				mega.chunkY >= megaY - padding &&
				mega.chunkX <= megaX + padding &&
				mega.chunkY <= megaY + padding
			) {
				mega.visible = true
			} else {
				mega.visible = false
			}
		}

		/*
        const padding = 1
        for (var i = megaX - padding; i < megaX + padding; i++) {
            for (var j = megaY - padding; j < megay + padding; j++) {
                let bg = this.megaBackground.get(i, j)
                let fg = this.megaForeground.get(i, j)

                if (bg) {
                    bg.visible = true
                }

                if (fg) {
                    fg.visible = true
                }
            }
        }

        let pbg = this.megaBackground.get(this.prev.megaX, this.prev.megaY)
        if (pbg) {
            pbg.visible = false
        }
        */

		this.prev.megaX = megaX
		this.prev.megaY = megaY
	}

	// only shows tiles near the player, this is what allows for giant maps to be used
	cullMiddleground(x, y, force) {
		// convert game coordinates to tile coordinates
		let tx = Math.floor(x / this.tileWidth)
		let ty = Math.floor(y / this.tileHeight)

		// check if we've moved at least one tile
		if (tx === this.prev.tileX && ty === this.prev.tileY && !force) {
			return
		}

		// calculate start and end coordinates of the visible area
		let startX = tx - this.viewWidth //* 10.2
		let startY = ty - this.viewHeight //* 10.2
		let endX = tx + this.viewWidth //* 10.2
		let endY = ty + this.viewHeight //* 10.2

		// console.log(tx, ty, startX, startY, endX, endY, this.viewHeight, this.viewHeight)

		// limit bounds to within the map
		if (startX < 0) {
			startX = 0
		}

		if (ty < 0) {
			ty = 0
		}

		if (endX > this.maxWidth) {
			endX = this.maxWidth
		}

		if (endY > this.maxHeight) {
			endY = this.maxHeight
		}

		// add tiles within the visible area
		for (let y = startY; y < endY; y++) {
			for (let x = startX; x < endX; x++) {
				this.addTile(x, y)
			}
		}

		// remove tiles that are now outside of the visible area
		this.cache.forEach(tileCacheRecord => {
			if (
				tileCacheRecord.x > endX ||
				tileCacheRecord.x < startX ||
				tileCacheRecord.y > endY ||
				tileCacheRecord.y < startY
			) {
				this.removeTile(tileCacheRecord.x, tileCacheRecord.y)
			}
		})

		// record position (for performance reasons)
		this.prev.tileX = tx
		this.prev.tileY = ty
	}

	getMapAsArray() {
		let resultingArray = this.megaBackground.toArray().concat(this.megaForeground.toArray()).concat(Object.values(this.mapMiddleground))
		return resultingArray
	}
}

export default WorldRenderer
