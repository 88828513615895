import p1911_img from '../../creative/spritesheetsourceimages/p1911_outline.png'
import m16_img from '../../creative/spritesheetsourceimages/m16_outline.png'
import sbs_img from '../../creative/spritesheetsourceimages/sbs_outline.png'
import s686_img from '../../creative/spritesheetsourceimages/s686_outline.png'
import glock_img from '../../creative/spritesheetsourceimages/glock_outline.png'
import dbleagle_img from '../../creative/spritesheetsourceimages/dbleagle_outline.png'
import dragunov_img from '../../creative/spritesheetsourceimages/dragunov_outline.png'
import colt45_img from '../../creative/spritesheetsourceimages/colt45_outline.png'
import mk2_img from '../../creative/spritesheetsourceimages/mk2_outline.png'
import m249_img from '../../creative/spritesheetsourceimages/m249_outline.png'
import ppbizon_img from '../../creative/spritesheetsourceimages/ppbizon_outline.png'
import ump45_img from '../../creative/spritesheetsourceimages/ump45_outline.png'
import qbz95_img from '../../creative/spritesheetsourceimages/qbz95_outline.png'
import bmg_img from '../../creative/spritesheetsourceimages/bmg_outline.png'
import ak47_img from '../../creative/spritesheetsourceimages/ak47_outline.png'
import awm_img from '../../creative/spritesheetsourceimages/awm_outline.png'
import fraggrenade_img from '../../creative/spritesheetsourceimages/fraggrenade_outline.png'
import minigun_img from '../../creative/spritesheetsourceimages/minigun_outline.png'
import grenadelauncher_img from '../../creative/spritesheetsourceimages/grenadeLauncher_outline.png'
import railgun_img from '../../creative/spritesheetsourceimages/railgun_outline.png'
import molotov_img from "../../creative/spritesheetsourceimages/molotov_grenade.png"
import Weapon from '../../common/weapon/Weapon'

const lookup = {
	[Weapon.byName('PISTOL_P1911').programmaticName]: {
		imgSrc: p1911_img,
		width: 55,
	},
	[Weapon.byName('AR_M16').programmaticName]: {
		imgSrc: m16_img,
		width: 80,
	},
	[Weapon.byName('SG_SBS').programmaticName]: {
		imgSrc: sbs_img,
		width: 70,
	},
	[Weapon.byName('SG_S686').programmaticName]: {
		imgSrc: s686_img,
		width: 80,
	},
	[Weapon.byName('PISTOL_GLOCK').programmaticName]: {
		imgSrc: glock_img,
		width: 55,
	},
	[Weapon.byName('SG_DOUBLEEAGLE').programmaticName]: {
		imgSrc: dbleagle_img,
		width: 80,
	},
	[Weapon.byName('SR_DRAGUNOV').programmaticName]: {
		imgSrc: dragunov_img,
		width: 80,
	},
	[Weapon.byName('PISTOL_COLT45').programmaticName]: {
		imgSrc: colt45_img,
		width: 55,
	},
	[Weapon.byName('SMG_MK2').programmaticName]: {
		imgSrc: mk2_img,
		width: 70,
	},
	[Weapon.byName('LMG_M249').programmaticName]: {
		imgSrc: m249_img,
		width: 80,
	},
	[Weapon.byName('SMG_PPBIZON').programmaticName]: {
		imgSrc: ppbizon_img,
		width: 70,
	},
	[Weapon.byName('SMG_UMP45').programmaticName]: {
		imgSrc: ump45_img,
		width: 70,
	},
	[Weapon.byName('AR_QBZ95').programmaticName]: {
		imgSrc: qbz95_img,
		width: 80,
	},
	[Weapon.byName('SR_BMG').programmaticName]: {
		imgSrc: bmg_img,
		width: 80,
	},
	[Weapon.byName('AR_AK47').programmaticName]: {
		imgSrc: ak47_img,
		width: 80,
	},
	[Weapon.byName('SR_AWM').programmaticName]: {
		imgSrc: awm_img,
		width: 80,
	},
	[Weapon.byName('GRENADE_FragGrenade').programmaticName]: {
		imgSrc: fraggrenade_img,
		width: 45,
	},
	[Weapon.byName('HV_RAILGUN').programmaticName]: {
		imgSrc: railgun_img,
		width: 80,
	},
	[Weapon.byName('HV_MINIGUN').programmaticName]: {
		imgSrc: minigun_img,
		width: 80,
	},
	[Weapon.byName('HV_GRENADELAUNCHER').programmaticName]: {
		imgSrc: grenadelauncher_img,
		width: 80,
	},
	[Weapon.byName('GRENADE_GrenadeLauncherGrenade').programmaticName]: {
		imgSrc: grenadelauncher_img,
		width: 80,
	},
	[Weapon.byName('GRENADE_Molotov').programmaticName]: {
		imgSrc: molotov_img,
		width: 40,
	}
}

export default programmaticName => {
	return lookup[programmaticName]
}
