import GrenadeGraphics from "../entity/Grenade"
import ResurrectionEffect from "../entity/ResurrectionEffect"

export default ({ renderer }) => {
	return {
		create: ({ data }) => {
			const entity = new GrenadeGraphics(data)

			renderer.entities.set(entity.id, entity)
			renderer.middleground.addChild(entity)
			if(data.thrownEffect) {
				entity.addThrownEffect(renderer)
			}

			return entity
		},
		delete: ({ entity, sim, id }) => {
			entity.removeThrownEffect(renderer)
			renderer.deleteEntity(id)

			// TODO better type check than the 'image' networked prop
			if (entity.image === 'resurrection-grenade.png') {
				const effect = new ResurrectionEffect()
				effect.x = sim.x
				effect.y = sim.y
				renderer.addEffect(effect, 'foreground')
				renderer.playResGrenade(sim.x, sim.y)

			}
		},
		watch: {
		}
	}
}
