import { Sprite, Texture, BLEND_MODES } from "pixi.js-legacy"
import ParticleAttribute from "./attributes/ParticleAttribute"
import VelocityAttribute from "./attributes/VelocityAttribute"

class Particle extends Sprite {
    constructor(spriteName, particleAttributes, renderer, emitter, anchor, lifetime = -1, initialX = 0, initialY = 0, step = 0, blendMode = BLEND_MODES.NORMAL) {
        super(Texture.from(spriteName))
        this.anchor.set(anchor.x, anchor.y)
        this.attributes = particleAttributes
        this.lifetime = lifetime
        this.elapsedLifetime = 0.0
        this.isDead = false
        this.emitter = emitter
        this.initialPos = {
            x: initialX,
            y: initialY
        }
        this.x = this.initialPos.x
        this.y = this.initialPos.y
        this.renderer = renderer
        this.step = step
        this.blendMode = blendMode
    }

    reset() {
        this.elapsedLifetime = 0.0
        this.isDead = false
        this.x = this.initialPos.x
        this.y = this.initialPos.y
        this.attributes.forEach((attribute) => {
            attribute.reset()
        })
    }

    spawn() {
        this.attributes.forEach((attribute) => {
            attribute.particleSpawned(this)
        })
    }

    die() {
        this.attributes.forEach((attribute) => {
            attribute.die(this)
        })
    }

    update(delta) {
        if(this.isDead)
            return
        
        if(this.lifetime != -1) {
            this.elapsedLifetime += delta
            if(this.elapsedLifetime > this.lifetime) {
                
                this.isDead = true
            }
        }

        this.attributes.forEach(element => {
            element.apply(this, delta)
        });
    }


}

export default Particle