import { Graphics } from "pixi.js-legacy"
import GAME_CONSTANTS from "../../common/balance/gameConstants"

export default function showOnHitEffect(renderer, entityId, color) {
	let entity = renderer.entities.get(entityId)
	if (entity) {
		if (GAME_CONSTANTS.DEBUG.RENDER_HITBOXES) {
			let graphics = new Graphics()
			graphics.lineStyle(1, color)
			graphics.drawCircle(entity.x, entity.y, 10)
			renderer.middleground.addChild(graphics)
			setTimeout(() => {
				renderer.middleground.removeChild(graphics)
				graphics.destroy({
					children: true,
					texture: true,
					baseTexture: true
				})
			}, 64)
		}
	}
}
