import StatefulWeapon from "./StatefulWeapon"

export default function(weaponData) {
	// this error check is really just...hackish and extra for the sake of making sure all weapon code has been refactored
	if (!weaponData || !weaponData.canonicalName) {
		throw new Error(
			"Weapon factory must be pased weapon data, e.g. Weapon.PISTOL; given:" +
        weaponData
		)
	}
	return new StatefulWeapon(weaponData)
}
