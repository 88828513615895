export default {
	name: "4x Scope",
	programmaticName: "fourxscope",
	canonicalName: "FOUR_X_SCOPE",
	itemClass: "scope",
	itemSubclass: "scope",
	equipSlot: "equipmentScope",
	spawnWeighting: 120,
	zoomLevel: 2.3,
	image: "4x_scope.png",
	uiImage: "4xscope.png",
	scaleDown: true
}
