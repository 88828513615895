import axios from "axios"
import ArrayUtils from "../../common/misc/arrayUtils"
import Weapon from "../../common/weapon/Weapon"
import { MissionState, MissionTakedown, CriteriaCheckEvents } from "./MissionStateObjects"
import MissionListUpdateMessage from '../../server/message/MissionListUpdateMessage'
import ServerMessage from "../message/ServerMessage"
import UpdateBattlepassProgression from "../message/UpdateBattlepassProgression"
import MissionsCompleted from "../message/MissionsCompleted"
import MissionFactory from "./MissionFactory"
import { each, difference } from "lodash"
import GAME_CONSTANTS from "../../common/balance/gameConstants"

const SHOW_MISSION_LOGS = GAME_CONSTANTS.DEBUG.SHOW_MISSION_LOGS

class MissionTracker {
	constructor() {
		this.instance = null
		this.clients = new Map()
		this.gameMode = null
	}

	init(instance, mode) {
		this.instance = instance
		this.gameMode = mode
	}

	_logEvent(missionState, eventName, params) {
		let missionEvent = {
			eventName: eventName,
			params: params
		}
		missionState.missionEvents.push(missionEvent)
		return missionEvent
	}

	_markMissionAsUpdated(missionState, mission) {
		missionState.updatedMissions[mission.missionUniqueId] = mission
	}

	_markMissionsAsUpdated(missionState, missions) {
		missions.forEach(mission => {
			this._markMissionAsUpdated(missionState, mission)
		})
	}

	setupMissions(missions, client) {
		this.initializeAndAddClient(client)

		if (client.activeMissions) {
			// retain the activeMissions reference
			client.activeMissions.length = 0
		} else {
			client.activeMissions = []
		}

		each(missions, mj => {
			let mission = MissionFactory.createMissionFromEndpoint(mj)
			if (!mission.isComplete) {
				client.activeMissions.push(mission)
			}
		})

		this.missionStateUpdateClient(client.missionState)
	}

	// adapter for 'gather item'
	gatherAdapter(client, itemType) {
		if (Weapon.byIndex(itemType).itemClass === 'scope') {
			this.trackUsedScope(client.missionState)
		}
		if (Weapon.byIndex(itemType).itemClass === 'kevlar') {
			this.trackUsedShields(client.missionState)
		}
	}

	// adapter for 'use item'
	useAdapter(client, itemType) {
		if (Weapon.byIndex(itemType).itemClass === 'grenade') {
			this.trackUsedGrenades(client.missionState)
		}
		if (Weapon.byIndex(itemType).itemClass === 'medical') {
			this.trackUsedMeds(client.missionState)
		}
	}

	trackWin(missionState) {
		if (!missionState) { return }
		missionState.wins += 1
		missionState.placement = 1
		let updatedMissions = this._checkApplicableMissionCriteria(
			missionState,
			this._logEvent(missionState, CriteriaCheckEvents.GAME_END, [])
		)
		// console.log('trackWin MissionState', missionState.wins)
		if (updatedMissions) {
			this.missionStateUpdateClient(missionState)
			this._markMissionsAsUpdated(missionState, updatedMissions)
		}
		this._postMissionProgress(missionState.ownerClientUserId, Object.values(missionState.updatedMissions), missionState.placement, missionState.killCount)
	}

	trackPlacement(missionState, placement) {
		if (!missionState) { return }
		missionState.placement = placement
		// console.log('trackPlacement() ', placement)
		let updatedMissions = this._checkApplicableMissionCriteria(
			missionState,
			this._logEvent(missionState, CriteriaCheckEvents.GAME_END, { placement: placement })
		)

		if (updatedMissions) {
			this.missionStateUpdateClient(missionState)
			this._markMissionsAsUpdated(missionState, updatedMissions)
		}
		this._postMissionProgress(missionState.ownerClientUserId, Object.values(missionState.updatedMissions), missionState.placement, missionState.killCount)
	}
	
	trackResurrection(missionState) {
		if(!missionState) { return }
		missionState.resurrections += 1
		let updatedMissions = this._checkApplicableMissionCriteria(
			missionState,
			this._logEvent(missionState, CriteriaCheckEvents.RESURRECT, [])
		)
		if(updatedMissions) {
			this.missionStateUpdateClient(missionState)
			this._markMissionsAsUpdated(missionState, updatedMissions)
		}
	}

	trackDeath(missionState) {
		if (!missionState) { return }
		missionState.deathCount += 1
		let updatedMissions = this._checkApplicableMissionCriteria(
			missionState,
			this._logEvent(missionState, CriteriaCheckEvents.DEATH, [])
		)
		if (updatedMissions) {
			this.missionStateUpdateClient(missionState)
			this._markMissionsAsUpdated(missionState, updatedMissions)
		}
	}

	trackKill(missionState, attacker, victim, weapon) {
		if (!missionState) { return }
		if (attacker.id == victim.id) { return } // suicide isn't good enough!
		missionState.killCount += 1
		let subclass = Weapon.byIndex(weapon).itemSubclass
		let takedown = new MissionTakedown({
			kill: true,
			killingWeapon: weapon,
			killingWeaponSubclass: subclass,
			killDistance: null,
			killerName: attacker.name,
			victimName: victim.name,
			// region: attacker.region,
		})
		missionState.takedowns.push(takedown)

		ArrayUtils.addOrReplace(missionState.weaponIdsUsed, weapon)
		ArrayUtils.addOrReplace(missionState.weaponClassesUsed, subclass)

		let updatedMissions = this._checkApplicableMissionCriteria(
			missionState,
			this._logEvent(missionState, CriteriaCheckEvents.KILL, takedown)
		)
		// console.log('trackKill MissionState', missionState.killCount, takedown)
		if (updatedMissions) {
			this.missionStateUpdateClient(missionState)
			this._markMissionsAsUpdated(missionState, updatedMissions)
		}
	}

	trackDamage(missionState, weapon, damage) {
		if (!missionState) { return }
		const subclass = Weapon.byIndex(weapon).itemSubclass
		missionState.damageDealt += damage
		missionState.weaponStats[weapon].damageDealt += damage
		missionState.weaponSubclassStats[subclass].damageDealt += damage

		ArrayUtils.addOrReplace(missionState.weaponIdsUsed, weapon)
		ArrayUtils.addOrReplace(missionState.weaponClassesUsed, subclass)

		let updatedMissions = this._checkApplicableMissionCriteria(
			missionState,
			this._logEvent(missionState, CriteriaCheckEvents.DAMAGE, {
				weapon: weapon,
				weaponClass: subclass,
				damage: damage
			})
		)
		if (updatedMissions) {
			// no client update for performance reasons
			this._markMissionsAsUpdated(missionState, updatedMissions)
		}
	}

	trackHealing(missionState, amount) {
		if (!missionState) { return }
		// console.log(`trackHealing(${amount})`)
		missionState.hasUsedMeds = true
		missionState.damageHealed += amount
		this.trackUsedEquipment(missionState)
	}

	trackMovement(missionState, worldUnitsMoved) {
		if (!missionState) { return }
		missionState.worldUnitsMoved += worldUnitsMoved
		// don't check any criteria or update anything on movement
	}

	trackItemGathered(missionState, itemType) {
		if (!missionState) { return }
		const item = Weapon.byIndex(itemType)
		missionState.itemsGathered[item.programmaticName] = missionState.itemsGathered[item.programmaticName] + 1 || 1
	}

	trackShot(missionState, weapon) {
		if (!missionState) { return }
		const subclass = Weapon.byIndex(weapon).itemSubclass
		missionState.shotsFired += 1
		missionState.weaponStats[weapon].shotsFired += 1
		missionState.weaponSubclassStats[subclass].shotsFired += 1
		ArrayUtils.addOrReplace(missionState.weaponIdsUsed, weapon)
		ArrayUtils.addOrReplace(missionState.weaponClassesUsed, subclass)
	}

	trackUsedEquipment(missionState) {
		if (!missionState) { return }
		missionState.hasUsedEquipment = true
	}

	trackUsedScope(missionState) {
		if (!missionState) { return }
		missionState.hasUsedScope = true
		this.trackUsedEquipment(missionState)
	}

	trackUsedMeds(missionState) {
		if (!missionState) { return }
		missionState.hasUsedMeds = true
		this.trackUsedEquipment(missionState)
	}

	trackUsedShields(missionState) {
		if (!missionState) { return }
		missionState.hasUsedShields = true
		this.trackUsedEquipment(missionState)
	}

	trackUsedGrenades(missionState) {
		if (!missionState) { return }
		missionState.hasUsedGrenades = true
		this.trackUsedEquipment(missionState)
	}

	_checkEventTypeAgainstValidator(events, eventName, validatorCb) {
		return events.reduce((total, event) => {
			if (event.eventName == eventName) {
				if (validatorCb(event)) {
					total += 1
				}
			}
			return total
		}, 0)
	}

	_checkMissionCriteria(missionState, missionQuestDefinition) {
		if (!missionState) { return }
		let mission = missionQuestDefinition
		let criteriaSatisfied = 0
		let checkKills = false
		let checkDamage = false
		let criteriaQualified = []
		let hasGameModeReq = false
		let matchedGameModReq = true
		let progressReport = {
			isComplete: false,
			hasProgress: mission.hasProgress,
			addedProgress: 0,
		}

		// if (SHOW_MISSION_LOGS) {
		// 	console.log(`[MISSION][CRITERIA] must successfully validate: ${mission.criteriaList.join(',')}`)
		// }

		// handle "positive" or "qualifying" criteria
		if (mission.mustWinGame) {
			if (missionState.wins >= 1) {
				progressReport.addedProgress = 1
				criteriaSatisfied++
				criteriaQualified.push('mustWinGame')
			}
		}
		if (mission.mustPlaceAtOrAbove) {
			if (missionState.placement <= mission.mustPlaceAtOrAbove) {
				progressReport.addedProgress = 1
				criteriaSatisfied++
				criteriaQualified.push('mustPlaceAtOrAbove')
			}
		}
		if (mission.mustPlayGames) {
			progressReport.addedProgress = 1
			criteriaSatisfied++
			criteriaQualified.push('mustPlayGames')
		}
		if (mission.mustScoreKills) {
			progressReport.addedProgress = missionState.killCount
			if (missionState.killCount >= mission.mustScoreKills) {
				criteriaSatisfied++
				criteriaQualified.push('mustScoreKills')
			}
			checkKills = true
		}
		if (mission.mustDealDamage) {
			progressReport.addedProgress = missionState.damageDealt
			if (missionState.damageDealt >= mission.mustDealDamage) {
				criteriaSatisfied++
				criteriaQualified.push('mustDealDamage')
			}
			checkDamage = true
		}
		if (mission.mustHealHealth) {
			progressReport.addedProgress = missionState.damageHealed
			if (missionState.damageHealed >= mission.mustHealHealth) {
				criteriaSatisfied++
				criteriaQualified.push('mustHealHealth')
			}
		}
		if (mission.mustUseWeaponClass) {
			if (missionState.weaponClassesUsed.includes(mission.mustUseWeaponClass)) {
				if (mission.cannotUseOtherWeapons && missionState.weaponClassesUsed.length === 1) {
					criteriaSatisfied++
					criteriaQualified.push('cannotUseOtherWeapons')
				}

				// we still met THIS standalone criteria by itself
				criteriaSatisfied++
				criteriaQualified.push('mustUseWeaponClass')

				if (checkKills) {
					// now check logged kills to see if they are the correct class
					let validKills = this._checkEventTypeAgainstValidator(missionState.missionEvents, 'KILL', (event) => {
						let takedown = event.params
						// Need to cover case with grenade launcher, not a fan but didnt want to change subclass of grenade to heavy weapon
						if (takedown.killingWeaponSubclass === mission.mustUseWeaponClass 
							|| (Weapon.byIndex(takedown.killingWeapon) === Weapon.byProgrammaticName('grenadeLauncherGrenade') && mission.mustUseWeaponClass === 'heavy_weapon') ) {
							// if (SHOW_MISSION_LOGS) {
							// 	console.log('[MISSION][CRITERIA] checkKill class, valid takedown event', { takedown })
							// }
							return true
						}
					})
					progressReport.addedProgress = validKills
					// if (SHOW_MISSION_LOGS) {
					// 	console.log(`[MISSION][CRITERIA] checkKill class, set progress to ${progressReport.addedProgress}`)
					// }
				}
				if (checkDamage) {
					progressReport.addedProgress = missionState.weaponSubclassStats[mission.mustUseWeaponClass].damageDealt
					// if (SHOW_MISSION_LOGS) {
					// 	console.log(`[MISSION][CRITERIA] checkDamage class, set progress to ${progressReport.addedProgress}`)
					// }
				}
			} else {
				progressReport.addedProgress = 0
			}
		}
		if (mission.mustUseWeaponId !== undefined && mission.mustUseWeaponId !== null) {
			if (missionState.weaponIdsUsed.includes(mission.mustUseWeaponId)) {
				if (mission.cannotUseOtherWeapons && missionState.weaponIdsUsed.length === 1) {
					criteriaSatisfied++
					criteriaQualified.push('cannotUseOtherWeapons')
				}

				// we still met THIS standalone criteria by itself
				criteriaSatisfied++
				criteriaQualified.push('mustUseWeaponId')

				if (checkKills) {
					// now check logged kills to see if they are the correct id
					let validKills = this._checkEventTypeAgainstValidator(missionState.missionEvents, 'KILL', (event) => {
						let takedown = event.params
						if (takedown.killingWeapon === mission.mustUseWeaponId) {
							// if (SHOW_MISSION_LOGS) {
							// 	console.log('[MISSION][CRITERIA] checkKill id, valid takedown event', { takedown })
							// }
							return true
						}
					})
					progressReport.addedProgress = validKills
					// if (SHOW_MISSION_LOGS) {
					// 	console.log(`[MISSION][CRITERIA] checkKill id, set progress to ${progressReport.addedProgress}`)
					// }
				}
				if (checkDamage) {
					progressReport.addedProgress = missionState.weaponStats[mission.mustUseWeaponId].damageDealt
					// if (SHOW_MISSION_LOGS) {
					// 	console.log(`[MISSION][CRITERIA] checkDamage id, set progress to ${progressReport.addedProgress}`)
					// }
				}
			} else {
				progressReport.addedProgress = 0
			}
		}
		if (mission.mustGatherItem) {
			progressReport.addedProgress = missionState.itemGathered[mission.mustGatherItem]
			if (missionState.itemGathered.includes(mission.mustGatherItem)) {
				criteriaSatisfied++
				criteriaQualified.push('mustGatherItem')
			}
		}
		if(mission.mustResurrectTeammate) {
			progressReport.addedProgress = missionState.resurrections
			if(missionState.resurrections >= mission.mustResurrectTeammate){
				criteriaSatisfied++
				criteriaQualified.push('mustResurrectTeammate')
			}
		}
		if (mission.gameMode) {
			hasGameModeReq = true
			if (mission.gameMode === this.gameMode) {
				progressReport.addedProgress = 1
				criteriaSatisfied++
				criteriaQualified.push('gameMode')
			} else {
				matchedGameModReq = false
			}
		}
		if (mission.cannotUseEquipment) {
			if (!missionState.hasUsedEquipment) {
				criteriaSatisfied++
				criteriaQualified.push('cannotUseEquipment')
			}
		}
		if (mission.cannotUseMeds) {
			if (!missionState.hasUsedMeds) {
				criteriaSatisfied++
				criteriaQualified.push('cannotUseMeds')
			}
		}
		if (mission.cannotUseWeaponAttachments) {
			if (!missionState.hasUsedWeaponAttachments) {
				criteriaSatisfied++
				criteriaQualified.push('cannotUseWeaponAttachments')
			}
		}
		if (mission.cannotUseShields) {
			if (!missionState.hasUsedShields) {
				criteriaSatisfied++
				criteriaQualified.push('cannotUseShields')
			}
		}
		if (mission.cannotUseGrenades) {
			if (!missionState.hasUsedGrenades) {
				criteriaSatisfied++
				criteriaQualified.push('cannotUseGrenades')
			}
		}

		if (progressReport.addedProgress) {
			if (criteriaSatisfied >= mission.criteriaToSatisfy) {
				// if they met all criteria, the mission is complete
				progressReport.isComplete = true
			} else if (criteriaSatisfied == mission.criteriaToSatisfy - 1) {
				// if they met all but ONE criteria, then they've gained progress on the mission
			} else if (criteriaSatisfied < mission.criteriaToSatisfy - 1) {
				// if they met less than that, then they've gained no progress
				progressReport.addedProgress = 0
			}

			if (hasGameModeReq && !matchedGameModReq) {
				progressReport.addedProgress = 0
			}
		}

		if (SHOW_MISSION_LOGS) {
			// console.log(`[MISSION][CRITERIA] ${criteriaSatisfied}/${mission.criteriaToSatisfy} were qualified${progressReport.isComplete ? ', mission completed!' : ''}, addedProgress ${progressReport.addedProgress}`)
			if (criteriaQualified.length) {
				// console.log(`[MISSION][CRITERIA] QUALIFIED:    ${criteriaQualified.join(',')}`)
				if (mission.criteriaList.length != criteriaQualified.length) {
					let diff = difference(mission.criteriaList, criteriaQualified)
					// console.log(`[MISSION][CRITERIA] MISSING:      ${diff.join(',')}`)
				}
			}
		}

		return progressReport
	}

	_checkApplicableMissionCriteria(missionState, latestEvent) {
		let updatedMissions = []
		missionState.missions.forEach((mission) => {
			if (mission.isComplete) {
				return
			}
			let missionQuestDefinition = mission.missionQuestDefinition
			if (missionQuestDefinition.checkCriteriaAfter.includes(latestEvent.eventName)) {
				// if (SHOW_MISSION_LOGS) {
				// 	console.log(`[MISSION] check mission ${mission.description}, on criteria `, missionQuestDefinition.checkCriteriaAfter)
				// }
				let progressReport = this._checkMissionCriteria(missionState, missionQuestDefinition)
				// if (SHOW_MISSION_LOGS) {
				// 	console.log(`[MISSION] progress on ${mission.description} ${progressReport.addedProgress}`)
				// }
				let isUpdated = this._markMissionProgress(mission, progressReport)
				// if (SHOW_MISSION_LOGS) {
				// 	console.log(`[MISSION] isUpdated on ${mission.description}`)
				// }
				if (isUpdated) {
					// if (SHOW_MISSION_LOGS) {
					// 	console.log(`[MISSION] completed/progressed mission ${mission.description} **`)
					// }
					updatedMissions.push(mission)
				}
			}
		})
		if (updatedMissions.length) {
			return updatedMissions
		}
		return false
	}

	_debugResetDailyMissions(clientUserId) {

		const client = this.clients.get(clientUserId)
		axios.post(`${process.env.ATLAS_URL}/bruh/reset-daily-missions`, {
			user_id: client.userId
		}, { headers: { 'bitfox-api-key': process.env.BITFOX_API_KEY } }).then(response => {
			this.instance.message(new ServerMessage("[SYS] Attempted to reset your dailies"))
			return true
		})
		return false
	}

	_postMissionProgress(clientUserId, updatedMissions, placement, kills) {
		const client = this.clients.get(clientUserId)
		let updatedMissionBody = updatedMissions.map(mission => {
			return {
				user_daily_mission_id: mission.userDailyMissionId,
				user_weekly_mission_id: mission.userWeeklyMissionId,
				mission_timespan: mission.missionTimespan,
				progress_current: mission.progressCurrent,
				progress_max: mission.progressMax,
				is_complete: mission.isComplete
			}
		})

		
		// if (SHOW_MISSION_LOGS) {
		// 	console.log(`[MISSION][POSTING TO API] /update-user-missions/${clientUserId}, key: ${process.env.BITFOX_API_KEY}`, { updatedMissionBody })
		// }

		axios.post(`${process.env.ATLAS_URL}/bruh/update-user-missions`, {
			user_id: client.userId,
			missions: updatedMissionBody,
			placement,
			kills,
		}, { headers: { 'bitfox-api-key': process.env.BITFOX_API_KEY } }).then(response => {
			const { data: responseBody } = response
			const {
				level,
				experience,
				experiencePercentTilNextLevel,
				experienceEarnedNow,
				levelsGained,
			} = responseBody

			const client = this.clients.get(clientUserId)
			// if (SHOW_MISSION_LOGS) {
			// 	console.log('[MISSION][POSTING TO API] finished updating user missions, now messaging for battlepass progression', responseBody)
			// }
			/*this.instance.message(new UpdateBattlepassProgression(
				level,
				experience,
				(experiencePercentTilNextLevel * 100), // nengi Int32, 0.45% -> 45%
				experienceEarnedNow,
				levelsGained,
			), client)*/

			let missionsCompleted = []
			updatedMissions.forEach(mission => {
				// if (SHOW_MISSION_LOGS) {
				// 	console.log(`[MISSION][POSTING TO API] setting ${mission.description} progressStart ${mission.progressStart} to current ${mission.progressCurrent}`)
				// }
				if (mission.isComplete) {
					missionsCompleted.push(mission)
				}
				mission.progressStart = mission.progressCurrent
			})
			if(missionsCompleted.length > 0) {
				console.log("Some missions completed")
				console.log(missionsCompleted)	
			}

			this.instance.message(
				new MissionsCompleted(
					this.mapMissionsToClientMissions(missionsCompleted)
				), client)

			if (SHOW_MISSION_LOGS) {
				// console.log(
				// 	"[MISSION][POSTING TO API] Mission update successful!", {
				// 	responseBody,
				// 	missionsCompleted,
				// })
			}

		}).catch(err => {
			global.logger.error("Error when updating mission", err)
		})
	}

	mapMissionsToClientMissions(missions) {
		return missions.map(mission => {
			return {
				missionUniqueId: mission.missionUniqueId,
				description: mission.description,
				progressCurrent: mission.progressCurrent,
				progressMax: mission.progressMax,
				isComplete: mission.isComplete
			}
		})
	}

	missionStateUpdateClient(missionState) {
		if (!missionState) { return }
		if (process.env.NODE_ENV !== 'production') {
			const client = this.clients.get(missionState.ownerClientUserId)
			const missions = this.mapMissionsToClientMissions(missionState.missions)
			this.instance.message(new MissionListUpdateMessage(missions), client)
		}
	}

	_markMissionProgress(mission, progress) {
		let updated = false
		//console.log('markMissionProgress() PRE', mission, progress)
		if (progress.hasProgress && progress.addedProgress > 0) {
			mission.progressCurrent = mission.progressStart + progress.addedProgress
			updated = true
		}
		if ((progress.isComplete && !progress.hasProgress) || mission.progressCurrent >= mission.progressMax) {
			mission.isComplete = true
			mission.progressCurrent = mission.progressMax
			updated = true
		}
		return updated
	}

	initializeAndAddClient(client) {
		if (client.missionState) {
			client.missionState.reset()
		} else {
			client.activeMissions = []
			client.missionState = new MissionState(client.activeMissions)
			client.missionState.ownerClientUserId = client.userId
			this.clients.set(client.userId, client)
		}
	}

	// moves missions from one client to another for reconnect functionality
	migrateMissions(oldClient, newClient) {
		newClient.activeMissions = oldClient.activeMissions
		newClient.missionState = oldClient.missionState
		// replace the client
		this.clients.set(newClient.userId, newClient)
	}

	removeClient(client) {
		this.clients.delete(client.userId)
	}
}

const singleton = new MissionTracker()
export default singleton
