<template>
	<div class="mission-list" v-if="true === false">
		<button v-on:click="toggleList">Toggle Missions (Dev/QA only)</button>
		<div v-if="isVisible">
			<div v-for="mission in missions" :key="mission.missionUniqueId">
				<span class="mission-text">+ {{mission.description}}</span>
				<ProgressBar
					class="progress-bar"
					min-value="0"
					max-value="1"
					:current-value="getMissionPercentComplete(mission)"
					:label-text="`${mission.progressCurrent}/${mission.progressMax}`"
					label-color="#ffffff"
					foreground-color="#185a1a"
					background-color="#272727"
					border-color="#4b8d4d"
				>
					<template #icon>
					</template>
				</ProgressBar>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import ProgressBar from './ProgressBar.vue'

export default {
	components: {
		ProgressBar,
	},
	data: () => {
		return {
			isVisible: false
		}
	},
	methods: {
		toggleList() {
			this.isVisible = !this.isVisible
		},
		getMissionPercentComplete(mission) {
			return mission.progressCurrent / Math.max(1,mission.progressMax)
		},
	},
	computed: {
		...mapGetters([
			"missions"
		]),
		hasMissions: function() {
			return this.missions.length
		},
	}
}
</script>

<style lang='scss' scoped>
.mission-list {
	position: fixed;
	top: 30px;
	left: 30px;
	word-wrap: break-word;
	max-width: 35vw;
	max-height: 60vh;
	overflow-y: scroll;

	.mission-text {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 80%;
		font-weight: 1000;
		color: white;
	}
}

</style>
