import { invert } from 'lodash'
import TWEEN from "@tweenjs/tween.js"
import store from "../ui/store"
import Projectile from "../entity/Projectile"
import BloodEffect from "../entity/BloodEffect"
import ShieldEffect from "../entity/ShieldEffect"
import ImpactEffect from "../entity/ImpactEffect"
import GrenadeExplosion from "../entity/GrenadeExplosion"
import SmokePuff from "../entity/SmokePuff"
import WorldRenderer from "./WorldRenderer"
import GAME_CONSTANTS from "../../common/balance/gameConstants"
import { hideLoadingScreen } from "../ui/loading"
import Weapon from "../../common/weapon/Weapon"
import ParticleEmitter from "./emitters/PIXIParticleEmitter"
import FireEmitter from "./emitters/FireEmitter"
import SmokeEmitter from './emitters/SmokeEmitter'
const { CAMERA_HEIGHT, RENDERER, DEBUG } = GAME_CONSTANTS
const { GAME_WIDTH, GAME_HEIGHT } = RENDERER
const { RENDER_HITBOXES } = true //DEBUG
//const { RENDER_HITBOXES, RENDER_COLLISIONS } = DEBUG
import Poison from "./Poison"
import showOnHitEffect from "./showOnHitEffect"
import { centerCameraJuicy, centerCameraImmediately } from "./cameraLogic"

const depthSort = function (a, b) {
	if (a.y > b.y) {
		return 1
	} else if (a.y < b.y) {
		return -1
	} else {
		return 0
	}
}

import { Sprite, Container, autoDetectRenderer, RENDERER_TYPE, BLEND_MODES, filters } from "pixi.js-legacy"
const RENDERER_TYPE_LOOKUP = invert(RENDERER_TYPE)
const PIXIfilters = filters
import drawDebugWeaponFired from "./drawDebugWeaponFired"
import drawDebugEntityHit from "./drawDebugEntityHit"
import drawDebugAABB from "./drawDebugAABB"

class PIXIRenderer {
	constructor(input, sounds) {
		let canvas = document.getElementById("main-canvas")

		

		this.sounds = sounds
		this.input = input
		this.masterScale = CAMERA_HEIGHT
		this.currentZoomLevel = CAMERA_HEIGHT
		this.targetZoomLevel = CAMERA_HEIGHT
		this.ghostTween = null

		this.AB45203628E28928D1FB446B5C6EB50C = null
		this.circle = null
		this.worldRenderer = null

		this.poison = new Poison(this)
		this.kickAmount = 10

		this.effects = []
		this.persistentMapEffects = []
		this.entities = new Map()

		this.projectiles = new Map()
		this.projectileId = 0 // just mocking an id, to mimic the server

		this.renderer = autoDetectRenderer({
			width: GAME_WIDTH,
			height: GAME_HEIGHT,
			view: canvas,
			antialiasing: false,
			transparent: false,
			resolution: 1
		})

		let self = this;
		(function () {
			let gaIntervalCount = 0

			let gaInterval = setInterval(function () {
				gaIntervalCount++
				if (window.googleanalytics) {
					// console.log('GA found and event sent')
					clearInterval(gaInterval)

					let rendererEvent = {
						eventCategory: 'Graphics Renderer',
						eventAction: RENDERER_TYPE_LOOKUP[self.renderer.type].toLowerCase(),
						eventLabel: self.renderer.context.webGLVersion,
						eventValue: self.renderer.context.webGLVersion,
						nonInteraction: true,
					}
					window.googleanalytics.send('event', rendererEvent)
				}
				if (gaIntervalCount >= 100) {
					clearInterval(gaInterval)
				}
			}, 500)
		})()

		this.stage = new Container()
		this.camera = new Container()
		this.background = new Container()
		this.middleground = new Container()
		this.foreground = new Container()

		this.camera.addChild(this.background)
		this.camera.addChild(this.middleground)
		this.camera.addChild(this.foreground)
		this.stage.addChild(this.camera)

		this.stage.addChild(this.poison)

		this.ghostColorMatrixFilter = new PIXIfilters.ColorMatrixFilter()
		this.camera.filters = [this.ghostColorMatrixFilter]

		this.ghostBlendSprite = new Sprite.from("16x16.png")
		this.ghostBlendSprite.blendMode = BLEND_MODES.ADD
		this.ghostBlendSprite.tint = 0xAAAACC
		this.ghostBlendSprite.width = 99999
		this.ghostBlendSprite.height = 99999
		this.ghostBlendSprite.alpha = 0
		this.particleEmitters = []
		this.camera.addChild(this.ghostBlendSprite)

		window.addEventListener("resize", () => {
			this.resize(this.currentZoomLevel)
			if (this.worldRenderer) {
				this.worldRenderer.resize(this.masterScale)
			}
		})

		this.resize(this.currentZoomLevel)
	}

	listen(client) {
		client.on("message::NewZoomLevel", message => {
			this.setZoomLevel(message.newZoomLevel)
		})

		client.on("message::WeaponFired", message => {
			const entityWhoFired = this.entities.get(message.sourceId)

			// Show muzzle flairs on other peeps
			if (entityWhoFired && entityWhoFired !== this.AB45203628E28928D1FB446B5C6EB50C) {
				entityWhoFired.addMuzzleFlair()
				let weaponFired = Weapon.byIndex(message.weaponType)
				if(weaponFired.hasVisibleProjectile) {
					entityWhoFired.firedGun()
					setTimeout(() => {
						entityWhoFired.readyToFire()
					}, weaponFired.cooldown * 1000)
				}
				
			}

			if (RENDER_HITBOXES) {
				drawDebugWeaponFired(this, message)
			}
		})

		client.on("message::EntityHit", message => {
			const entity = this.entities.get(message.entityId)
			if (entity) {
				if (entity.skin) {
					if (!store.getters.veganModeEnabled) {
						let effect
						if (entity.shields) {
							effect = new ShieldEffect(message.x, message.y)
						} else {
							effect = new BloodEffect(message.x, message.y)
						}
						this.foreground.addChild(effect)
						effect.rendererLayer = this.foreground
						this.effects.push(effect)
					}
					if (RENDER_HITBOXES) {
						drawDebugEntityHit(this.renderer, entity)
					}
				}
			}
		})

		client.on("message::Explosion", message => {
			this.drawGrenadeExplosion(message.x, message.y, message.explosionRadius)
		})

		client.on("message::MolotovExplosion", message => {
			this.drawMolotovFire(message.x, message.y)
		})

		client.on("message::Identity", message => {
			this.myId = message.entityId
		})

		client.on("message::AABBDebug", message => {
			if (store.getters.debugAABB) {
				drawDebugAABB(message, this.foreground)
			}
		})
	}

	addEffect(effect, layerString) {
		this[layerString].addChild(effect)
		effect.rendererLayer = this[layerString]
		this.effects.push(effect)
	}

	playResGrenade(x, y) {
		this.sounds.playResGrenade(x, y)
	}

	drawDebugWeaponFired(message) {
		drawDebugWeaponFired(this, message)
	}

	drawBulletEnd(alreadyHitPlayer, x, y) {
		if (alreadyHitPlayer) return
		var effect = new ImpactEffect(x, y)
		this.foreground.addChild(effect)
		effect.rendererLayer = this.foreground
		this.effects.push(effect)
	}

	drawGrenadeExplosion(x, y, explosionRadius) {
		var effect = new GrenadeExplosion(x, y, explosionRadius)
		this.foreground.addChild(effect)
		effect.rendererLayer = this.foreground
		this.effects.push(effect)
	}

	drawSmokePuff(x, y, image, duration, size, rotationSpeed, maxAlpha, fadeTime, prng) {
		var effect = new SmokePuff(x, y, image, duration, size, rotationSpeed, maxAlpha, fadeTime, prng)
		this.camera.addChild(effect)
		effect.rendererLayer = this.camera
		this.effects.push(effect)
	}

	addPersistentMapEffect(mapEffect) {
		if(mapEffect === undefined)
			return
		this.persistentMapEffects.push(mapEffect)
		this.background.addChild(mapEffect)
	}

	drawMolotovFire(x, y) {
		this.createEmitter(x, y, FireEmitter)
		this.createEmitter(x, y, SmokeEmitter)
		let scorchedEarth = new Sprite.from("fire-fx.png")
		scorchedEarth.tint = 0x0d0c0c
		scorchedEarth.anchor.x = 0.5
		scorchedEarth.anchor.y = 0.5
		scorchedEarth.x = x
		scorchedEarth.y = y
		scorchedEarth.angle = 360 * Math.random()
		this.addPersistentMapEffect(scorchedEarth)
	}


	registerProjectileForCorpse(projectile) {
		// Function to wait for a corpse to pop up that fits the projectiles needs
		this.projectileWaitingForCorpse = projectile
	}

	drawPoison(dim) {
		this.poison.drawEdges(dim)
	}

	resize(newZoomLevel) {
		// cache me?
		let canvas = document.getElementById("main-canvas")
		if (!newZoomLevel) {
			newZoomLevel = this.currentZoomLevel
		}
		this.masterScale = newZoomLevel
		this.currentZoomLevel = newZoomLevel

		let targetRatio = 16 / 9
		let newWidth = window.innerWidth
		let newHeight = window.innerHeight
		let newRatio = newWidth / newHeight

		// leaving this commented code on purpose
		// we used to change view width/height to support vertical displays
		// this is borderline a vision hack (can see farther)
		// going to try the game awhile without it
		if (newRatio > targetRatio) {
			newWidth = newHeight * targetRatio
			canvas.style.width = newWidth + "px"
			canvas.style.height = newHeight + "px"
		} else {
			newHeight = newWidth / targetRatio
			canvas.style.width = newWidth + "px"
			canvas.style.height = newHeight + "px"
		}
		canvas.style.marginTop = -newHeight / 2 + "px"
		canvas.style.marginLeft = -newWidth / 2 + "px"

		canvas.style.position = "absolute"
		canvas.style.top = "50%"
		canvas.style.left = "50%"

		this.renderer.resize(GAME_WIDTH, GAME_HEIGHT)
		this.camera.scale.set(this.masterScale)

		if (this.AB45203628E28928D1FB446B5C6EB50C) {
			centerCameraImmediately(this, this.AB45203628E28928D1FB446B5C6EB50C)
			//this.centerCamera(this.myEntity)
		} else {
			centerCameraImmediately(this, { x: 0, y: 0 })
		}
	}

	reset() {
		this.entities = new Map()
		this.camera = new Container()
		this.background = new Container()
		this.middleground = new Container()
		this.foreground = new Container()

		this.camera.addChild(this.background)
		this.camera.addChild(this.middleground)
		this.camera.addChild(this.foreground)

		this.camera.scale.set(this.masterScale)
		this.persistentMapEffects = []
	}



	loadMap(mapObj, cleanFirst = false) {
		if(cleanFirst) {
			this.cleanUpMapChildren(this.background)
			this.cleanUpMapChildren(this.middleground)
			this.cleanUpMapChildren(this.foreground)
		}
		this.worldRenderer = new WorldRenderer(
			this.background,
			this.middleground,
			this.foreground,
			mapObj.tileLayers,
			mapObj.tileTextures,
			this.masterScale,
			this.renderer
		)
		this.worldRenderer.backgroundColor = "#77797D"
		this.worldRenderer.resize(this.masterScale)

		hideLoadingScreen()

		setTimeout(() => {
			if (this.worldRenderer) {
				this.worldRenderer.resize(this.masterScale)
			}
		}, 1000)
	}

	cleanUpMapChildren(container) {
		// Known problem - This does not account for non-player entities i.e. weapons and grenades and stuff so it breaks if its
		// done after placing objects.
		let mapArr = this.worldRenderer.getMapAsArray()
		for(let i = container.children.length; i >= 0; i--) {
			if(container.children[i] && mapArr.includes(container.children[i])) {
				let sprite = container.children[i]
				sprite.destroy({children: true})
			}
		}
	}

	createEmitter(xPos, yPos, emitterBlueprint, parentOffset = {x: 0, y: 0}, ignoreParenting = false) {
		let particleEmitter = new ParticleEmitter(emitterBlueprint, xPos, yPos, this, parentOffset)
		if(!ignoreParenting)
			this.foreground.addChild(particleEmitter)
		this.particleEmitters.push(particleEmitter)
		return particleEmitter
	}

	signalEmitterDied(particleEmitter) {
		this.foreground.removeChild(particleEmitter)
		this.particleEmitters = this.particleEmitters.filter((emitter) => {
			return emitter !== particleEmitter
		})
		particleEmitter.destroy()
	}

	setZoomLevel(newZoomLevel) {
		this.targetZoomLevel = newZoomLevel
		this.resetPlayerToAlive()
	}

	forceReload() {
		document.location.reload(true) // force a reload from server not cache
	}

	resetPlayerToAlive() {
		// If we ever get in here where this.AB is null, that means there was some kind of weird caching shenanigans.  Signal reload
		if(!this.AB45203628E28928D1FB446B5C6EB50C) {
			this.forceReload()
		}
		if (this.ghostTween) {
			this.ghostTween.stop()
		}

		// tween from our current values
		let tweenFrom = {
			zoomLevel: this.currentZoomLevel,
			modelAlpha: this.AB45203628E28928D1FB446B5C6EB50C.alpha,
			blendAlpha: this.ghostBlendSprite.alpha,
			saturation: this.ghostColorMatrixFilterSaturation
		}

		// tween to the reset defaults
		let tweenTo = {
			zoomLevel: this.targetZoomLevel,
			modelAlpha: 1.0,
			blendAlpha: 0,
			saturation: 0
		}

		this.ghostTween = new TWEEN.Tween(tweenFrom)
			.to(tweenTo, 500)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate((current) => {
				this.resize(current.zoomLevel)
				this.AB45203628E28928D1FB446B5C6EB50C.alpha = current.modelAlpha
				this.ghostColorMatrixFilter.saturate(current.saturation)
				this.ghostColorMatrixFilterSaturation = current.saturation
				this.ghostBlendSprite.alpha = current.blendAlpha
			})
			.start()
	}

	performDeathAnimation() {
		if (this.ghostTween) {
			this.ghostTween.stop()
		}
		let tweenFrom = { zoomLevel: this.masterScale, saturation: 0 }
		let tweenToDeath = { zoomLevel: GAME_CONSTANTS.ZOOM_LEVEL_DEATH_ANIMATION, modelAlpha: 0.0, blendAlpha: 0, saturation: -0.5 }
		let tweenToGhost = { zoomLevel: GAME_CONSTANTS.ZOOM_LEVEL_GHOST, modelAlpha: 0.3, blendAlpha: 0.3 }

		let self = this
		self.AB45203628E28928D1FB446B5C6EB50C.alpha = 0
		self.ghostTween = new TWEEN.Tween(tweenFrom)
			.to(tweenToDeath, 320)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate((current) => {
				self.resize(current.zoomLevel)
				self.ghostColorMatrixFilter.saturate(current.saturation)
				self.ghostColorMatrixFilterSaturation = current.saturation // no documented way to retrieve this value, so we store it
			})
			.onComplete(() => {
				// chaining in a way that ghostTween always refers
				// to the active tween
				self.ghostTween = new TWEEN.Tween(tweenToDeath)
					.delay(2800)
					.to(tweenToGhost)
					.easing(TWEEN.Easing.Quadratic.InOut)
					.onUpdate((current) => {
						self.resize(current.zoomLevel)
						self.AB45203628E28928D1FB446B5C6EB50C.alpha = current.modelAlpha
						self.ghostBlendSprite.alpha = current.blendAlpha
					})
					.onComplete(() => {
					})
				self.ghostTween.start()
			})
		self.ghostTween.start()
	}

	deleteEntity(id) {
		// remove the graphics
		if (this.entities.get(id)) {
			this.foreground.removeChild(this.entities.get(id))
			this.middleground.removeChild(this.entities.get(id))
			const entity = this.entities.get(id)
			entity.destroy({ children: true })
			if (typeof entity.onDestroy === 'function') {
				entity.onDestroy()
			}

			// remove our reference
			this.entities.delete(id)
		}
	}

	createAndSyncProjectile(entity, visualData) {
		const projectile = new Projectile(entity, visualData)
		this.middleground.addChild(projectile)
		this.projectiles.set(projectile.id, projectile)
		return projectile
	}

	removeProjectile(id) {
		const projectile = this.projectiles.get(id)
		this.middleground.removeChild(projectile)
		projectile.destroy({ children: true })
		this.projectiles.delete(id)
	}


	centerCamera(entity) {
		this.camera.x = -entity.x * this.masterScale + 640
		this.camera.y = -entity.y * this.masterScale + 360
	}

	toWorldCoordinates(mouseX, mouseY) {
		// TODO: cache these probably.. or does the browser do it automatically idk
		const canvas = document.getElementById("main-canvas")
		const rect = canvas.getBoundingClientRect()
		const domScale = rect.width / GAME_WIDTH
		const { innerWidth, innerHeight } = window

		const scale = this.masterScale

		const adjustedMouseX = (mouseX / scale - (innerWidth * 0.5) / scale) * (1 / domScale)
		const adjustedMouseY = (mouseY / scale - (innerHeight * 0.5) / scale) * (1 / domScale)

		const camScaledX = this.camera.x / scale - GAME_WIDTH / (scale * 2)
		const camScaledY = this.camera.y / scale - GAME_HEIGHT / (scale * 2)

		// kick compensation
		const dx = this.input.currentState.mx - innerWidth * 0.5
		const dy = this.input.currentState.my - innerHeight * 0.5
		const vlen = Math.max(Math.sqrt(dx * dx + dy * dy), 0.01)
		const normX = dx / vlen
		const normY = dy / vlen
		const kickX = (Math.max(this.kickAmount) + 0) * normX
		const kickY = (Math.max(this.kickAmount) + 0) * normY

		// movement compensation (has a slight offset bug)
		/*
		let moveX = 0
		let moveY = 0
		if (this.E70C365696F211EABB370242AC130002) {
			moveX = camScaledX + this.E70C365696F211EABB370242AC130002.x
			moveY = camScaledY + this.E70C365696F211EABB370242AC130002.y
		}
		*/

		// note: there is an aim quirk in the original bruh where shooting while
		// moving causes the shots to go slightly ahead of where is being aimed
		// e.g. if moving up, rays go slightly higher than the crosshair, as
		// shown by holding a reddot, and removing this move compensation preserves
		// that original behavior
		return {
			x: adjustedMouseX - camScaledX + kickX,// + moveX,
			y: adjustedMouseY - camScaledY + kickY //+ moveY
		}
	}

	showOnHitEffect(entityId, color) {
		showOnHitEffect(this, entityId, color)
	}

	movePlayerIndicatorOnMinimap(x, y) {
		store.commit("newPlayerPosition", { x, y })
	}

	update(delta) {
		if (this.AB45203628E28928D1FB446B5C6EB50C && this.worldRenderer) {
			//centerCameraImmediately(this, this.myEntity, GAME_WIDTH, GAME_HEIGHT);
			centerCameraJuicy(this, this.AB45203628E28928D1FB446B5C6EB50C, delta)
			this.worldRenderer.cull(this.AB45203628E28928D1FB446B5C6EB50C.x, this.AB45203628E28928D1FB446B5C6EB50C.y)
		}

		this.entities.forEach(entity => {
			entity.update(delta)
			if(this.projectileWaitingForCorpse && entity.type === 2) {// Corpse type
				if(entity.pinned && !entity.hasAPinnedProjectile) {
					entity.hasAPinnedProjectile = true
					this.projectileWaitingForCorpse.stickToEntity(entity, {x: Math.random() - 0.5, y: Math.random() - 0.5 })
					projectile.projectileWaitingForCorpse = undefined
				}
					
			} 

		})

		for (let i = this.effects.length - 1; i > -1; i--) {
			const effect = this.effects[i]
			effect.update(delta)
			if (effect.isComplete) {
				this.effects.splice(i, 1)
				if (effect.rendererLayer) {
					effect.rendererLayer.removeChild(effect)
					effect.rendererLayer = null
				} else {
					console.error(`Effect doesn't have a renderer layer, so can't be removed from the renderer`, effect)
				}
			}
		}

		const kickDecay = Math.max(280, this.kickAmount * 2) * delta
		this.kickAmount -= kickDecay
		// this.kickAmount -= 280 * 1 / 60
		if (this.kickAmount < 0) {
			this.kickAmount = 0
		}

		this.projectiles.forEach(m => {
			if (m.update) {
				m.update(delta)
			}
		})

		this.particleEmitters.forEach(m => {
			m.update(delta)
		})
		
		// depth sort the middleground objects
		this.middleground.children.sort(depthSort)
		this.renderer.render(this.stage)

		TWEEN.update()
		
	}
}

export default PIXIRenderer
