import nengi from 'nengi'

class Settings {
	constructor(config) {
		Object.assign(this, config)
	}
}

Settings.protocol = {
	// note: if this gets more complicated just make it json
	showOwnUsername: nengi.Boolean,
	showOwnHealthBar: nengi.Boolean,
	sfxEnabled: nengi.Boolean,
	musicEnabled: nengi.Boolean,
	disableHud: nengi.Boolean,
	veganModeEnabled: nengi.Boolean,
	preferredLanguage: nengi.String,
	hasBattlepass: nengi.Boolean,
	musicVolume: nengi.Float32,
	sfxVolume: nengi.Float32
}

export default Settings
