import store from '../ui/store'
import PRNG from '../../common/classes/PRNG'
import createWeaponRays from '../../common/weapon/createWeaponRays'
import {
	useBandage,
	useMedpack,
	usePainkillers,
} from '../../common/weapon/clientUse'
import Weapon from "../../common/weapon/Weapon"

import dynamicItemText from './dynamicItemText'

const channelingStrategy = {
	'slot3': { use: useBandage },
	'slot4': { use: useMedpack },
	'slot5': { use: usePainkillers }
}

class Predictor {
	constructor(renderer, simulator, soundSystem, client) {
		this.renderer = renderer
		this.simulator = simulator
		this.soundSystem = soundSystem
		this.client = client
		this.prng = new PRNG(666)
		//this.myId = null Doesnt seem to be set, currently uses the simulators
		this.firingDesyncFlag = 0

		this.client.on('predictionErrorFrame', predictionErrorFrame => {
			// assuming a single predicted entity, instead of actually inspecting the predictionErrorEntity to see which it is
			const entity = this.simulator.predictedEntity
			predictionErrorFrame.entities.forEach(predictionErrorEntity => {
				// rewind state
				Object.assign(entity, predictionErrorEntity.proxy)

				predictionErrorEntity.errors.forEach(predictionError => {
					//console.log('prediciton warning', predictionError)
					if (entity) {
						// correct the error
						entity[predictionError.prop] = predictionError.actualValue
					}
				})

				// now that the error is corrected, re-apply sent commands to our local state
				const commandSets = this.client.getUnconfirmedCommands()
				commandSets.forEach((commandSet, clientTick) => {
					commandSet.forEach(command => {
						if (command.protocol.name === 'P7DA84105F9F54B2D20C66308619D78D') {
							entity.move(command, command.delta)
							if (this.simulator.collisionSystem) {
								this.simulator.collisionSystem.applyAllegedMapCollisions(entity)
							}
							const {
								id, allegedX, allegedY,
								activeSlot, slot1, slot2, slot1Ammo, slot2Ammo,
								bandages, medpacks, painkillers
							} = entity

							const prediction = {
								id, allegedX, allegedY,
								activeSlot, slot1, slot2, slot1Ammo, slot2Ammo,
								bandages, medpacks, painkillers,
								protocol: entity.protocol
							}
							// overwrite any pending predictions with this newly predicted state that accounts
							// for the corrected error
							this.client.addCustomPrediction(clientTick, prediction, [
								'allegedX', 'allegedY',
								'activeSlot', 'slot1', 'slot2', 'slot1Ammo', 'slot2Ammo',
								'bandages', 'medpacks', 'painkillers'
							])

							this.renderer.AB45203628E28928D1FB446B5C6EB50C.x = allegedX
							this.renderer.AB45203628E28928D1FB446B5C6EB50C.y = allegedY
						}
					})
				})

				predictionErrorEntity.errors.forEach(predictionError => {
					//console.log('prediciton warning', predictionError)
					if (entity) {
						// invoking hooks from the "niceClientExtension", otherwise prediction
						// would be mutating clientside entities and bypassing those hooks
						// TODO: should this be right here? or after re-applying commands. this is working for now
						const name = entity.protocol.name
						const factory = client.factory[name]

						if (factory.watch && factory.watch[predictionError.prop]) {
							const rendererEntity = this.renderer.entities.get(predictionError.id)
							factory.watch[predictionError.prop]({
								id: predictionError.id,
								value: predictionError.actualValue,
								entity: rendererEntity,
								sim: entity
							})
						}
					}
				})
			})
		})

		this.client.on("message::WeaponFired", message => {
			if(message.sourceId === this.simulator.myId) {
				// Fix for weapon desync, the least network intensive possible
				if(this.firingDesyncFlag > 2) {
					this.simulator.predictedEntity.weaponSystem.currentWeapon.isFiring = true
				} else if(!this.simulator.predictedEntity.weaponSystem.currentWeapon.isFiring) {
					this.firingDesyncFlag++
				} else {
					this.firingDesyncFlag = 0
				}
			}
		})
	}

	applyP7DA84105F9F54B2D20C66308619D78D(command) {
		const entity = this.simulator.predictedEntity
		/*
		// leaving this here incase we want to do deterministic buffs/debuffs with our meds
		if (entity.affects.length) {
			entity.affects.forEach((affect) => {
				if (!affect.tick(command.delta)) {
				}
			})
		}
		*/
		entity.move(command, command.delta)
		if (this.simulator.collisionSystem) {
			this.simulator.collisionSystem.applyAllegedMapCollisions(entity)
		}

		const weaponSystem = this.simulator.predictedEntity.weaponSystem

		if (command.r && !this.simulator.predictedEntity.isGhost) {
			this.predictReload()
		}

		if (command.one) {
			weaponSystem.changeSlot(1, entity)
		}
		if (command.two) {
			weaponSystem.changeSlot(2, entity)
		}
		if (command.three) {
			weaponSystem.changeSlot(3, entity)
		}
		if (command.four) {
			weaponSystem.changeSlot(4, entity)
		}
		if (command.five) {
			weaponSystem.changeSlot(5, entity)
		}

		const isFiring = (command.mouseDown || command.space)

		if (isFiring && weaponSystem.isWeaponActive()) {
			this.tryFire({ aim: command.aim, mouseClicked: command.mouseClicked, spacePressed: command.spacePressed })
		}

		if (isFiring && !weaponSystem.isItemActive()) {
			weaponSystem.cancelChanneling()
			store.commit('useItemFinished')
		}

		if (!isFiring && weaponSystem.isItemActive()) {
			weaponSystem.cancelChanneling()
			entity.moveSlowModifier = 1.0
			entity.moveSlowDuration = 0.0
			store.commit('useItemCancelled')
		}

		if (isFiring && weaponSystem.isItemActive()) {
			const useReport = weaponSystem.channeling(entity, command.delta)

			if (useReport.valid) {
				if (!useReport.complete) {
					entity.moveSlowModifier = weaponSystem.currentWeapon.moveSlowModifier
					entity.moveSlowDuration = 0.2
					store.commit('useItemProgress', {
						useItem: weaponSystem.currentType,
						useItemProgress: useReport.current,
						useItemDuration: useReport.max,
					})
				} else {
					channelingStrategy[useReport.slot].use(entity)
					entity.moveSlowModifier = 1.0
					entity.moveSlowDuration = 0.0
					store.commit('useItemFinished')
				}
			}
			else if(weaponSystem.currentWeapon.itemUnavailableText && !store.getters.triedToUseItem) {
				// Update the store with the unavailable text for ItemUsePopup.vue
				store.commit('newItemUnavailableText', weaponSystem.currentWeapon.itemUnavailableText)
				store.commit('useItemWasAttempted')
				setTimeout(() => {
					store.commit('endOfUseItemPopup')
				}, 2000)

			}
		}

		weaponSystem.update(command.delta)

		const prediction = {
			id: entity.id,
			allegedX: entity.allegedX,
			allegedY: entity.allegedY,
			weapon: entity.weapon,
			activeSlot: entity.activeSlot,
			slot1: entity.slot1,
			slot2: entity.slot2,
			slot1Ammo: entity.slot1Ammo,
			slot2Ammo: entity.slot2Ammo,
			bandages: entity.bandages,
			medpacks: entity.medpacks,
			painkillers: entity.painkillers,
			protocol: entity.protocol
		}

		//console.log('predicting ammo', prediction.slot1Ammo)

		this.client.addCustomPrediction(this.client.tick, prediction, [
			'allegedX', 'allegedY',
			'weapon', 'activeSlot', 'slot1', 'slot2', 'slot1Ammo', 'slot2Ammo',
			'bandages', 'medpacks', 'painkillers'
		])

		store.commit('newActiveSlot', weaponSystem.activeSlot)
		this.triggerSlotRefresh()

		this.renderer.AB45203628E28928D1FB446B5C6EB50C.x = entity.allegedX
		this.renderer.AB45203628E28928D1FB446B5C6EB50C.y = entity.allegedY
		// not reconcilable, no need, isMoving is now clientisde
		this.renderer.AB45203628E28928D1FB446B5C6EB50C.isMoving = entity.isMoving

		this.renderer.movePlayerIndicatorOnMinimap(
			this.renderer.AB45203628E28928D1FB446B5C6EB50C.x,
			this.renderer.AB45203628E28928D1FB446B5C6EB50C.y
		)
	}

	// is this still used? may have been replaced by a simplified prediction model
	switchWeapon(type) {
		const weaponSystem = this.simulator.predictedEntity.weaponSystem
		const ammo = weaponSystem.currentWeapon.currentAmmo
		weaponSystem.switchWeapon(type)
		weaponSystem.currentWeapon.currentAmmo = ammo
		this.triggerSlotRefresh()
	}

	// is this still used? may have been replaced by a simplified prediction model
	predictChangeSlot(num) {
		const weaponSystem = this.simulator.predictedEntity.weaponSystem
		weaponSystem.changeSlot(num, this.simulator.predictedEntity)
		store.commit('newActiveSlot', weaponSystem.activeSlot)
		weaponSystem.syncToEntity(this.simulator.predictedEntity)
		this.triggerSlotRefresh()
	}

	triggerSlotRefresh() {
		// poking vuex so that it updates
		const weaponSystem = this.simulator.predictedEntity.weaponSystem
		const slot = weaponSystem.activeSlot
		const ammo = weaponSystem.currentWeapon.currentAmmo
		store.commit('newAmmo', { slot, ammo })
	}

	predictReload() {
		const weaponSystem = this.simulator.predictedEntity.weaponSystem
		this.prng = new PRNG(666)
		let reloadStarted = weaponSystem.startReload(
			// endCb
			() => {
				this.triggerSlotRefresh()
				store.commit('reloadActionFinished')
			},
			// progressCb
			(currentProgress) => {
				store.commit('reloadActionProgress', currentProgress)
			},
			// activeReloadFailCb
			() => {
				store.commit('reloadAttemptWasFumbled')
				if (this.soundSystem && this.sound) {
					this.soundSystem.playReload(
						'activeReloadFail',
						this.sound,
						this.soundId
					)
				}
			},
			// activeReloadSuccessCb
			() => {
				store.commit('reloadAttemptWasPerfect')
				store.dispatch('resetPlayerReloadBar')
				if (this.soundSystem && this.sound) {
					this.soundSystem.playReload(
						'activeReloadSuccess',
						this.sound,
						this.soundId
					)
				}
			},
			// activeReloadGoodEnoughCb
			() => {
				store.commit('reloadAttemptWasGoodEnough')
			},
		)

		if (reloadStarted) {
			store.commit('reloadActionStarted')
			if (this.soundSystem) {
				let soundData = this.soundSystem.playReload(
					'startReload',
					null,
					null,
					this.renderer.AB45203628E28928D1FB446B5C6EB50C.currentlyEquipped
				)
				this.sound = soundData.sound
				this.soundId = soundData.soundId
			}
		}
	}

	tryFire(command) {
		const weaponSystem = this.simulator.predictedEntity.weaponSystem
		const fireResult = weaponSystem.currentWeapon.checkCanFire(0)

		if (weaponSystem.isWeaponActive()) {
			if (fireResult.canFire && !this.simulator.predictedEntity.isGhost) {
				this.predictFire(command)

				if (weaponSystem.currentWeapon.moveSlowDuration > 0) {
					this.simulator.predictedEntity.moveSlowDuration = weaponSystem.currentWeapon.moveSlowDuration
					this.simulator.predictedEntity.moveSlowModifier = weaponSystem.currentWeapon.moveSlowModifier
				}

				return true
			} else if (!fireResult.canFire && fireResult.reason === 'reloading') {
				if (command.mouseClicked || command.spacePressed) {
					this.predictReload()
				}
			} else if (!fireResult.canFire && fireResult.reason === 'out of ammo') {
				// can't fire, out of ammo!
				if (command.mouseClicked || command.spacePressed) {
					this.predictReload()
				} else {
					if (weaponSystem.currentWeapon.canDryFire()) {
						weaponSystem.currentWeapon.dryFire()
						if (this.soundSystem) {
							this.soundSystem.playOutOfAmmo(
								this.renderer.AB45203628E28928D1FB446B5C6EB50C.currentlyEquipped
							)
						}
					}
				}
				return false
			}
		}
	}

	predictFire(command) {
		//console.log('PREDICT FIRE', this.simulator.predictedEntity.weaponSystem.activeSlot)
		const weaponSystem = this.simulator.predictedEntity.weaponSystem
		if (weaponSystem.isItemActive()) {
			return null // exit early if holding an item instead of a weapon
		}

		if (this.simulator.collisionSystem && weaponSystem.isWeaponActive()) {
			let fireResult = weaponSystem.tryFire(0, command.mouseClicked || command.spacePressed, null)
			/*(ammo, clipSize) => {
				const slot = weaponSystem.activeSlot
				store.commit('newAmmo', { slot, ammo })
			})
			*/

			if (!fireResult.canFire) {
				if (fireResult.reason === 'out of ammo') {
					// shush linter
				}
			} else {
				const slot = weaponSystem.activeSlot
				const ammo = weaponSystem.currentWeapon.currentAmmo


				let offset = this.simulator.applyWeaponBulletOffsets(command.aim, fireResult.weapon)

				store.commit('newAmmo', { slot, ammo })
				if(weaponSystem.currentWeapon.overwrittenFlairLifetime)
					this.renderer.AB45203628E28928D1FB446B5C6EB50C.addMuzzleFlair(weaponSystem.currentWeapon.overwrittenFlairLifetime)
				else
					this.renderer.AB45203628E28928D1FB446B5C6EB50C.addMuzzleFlair()
				this.renderer.kickAmount += weaponSystem.currentWeapon.kickAmount
				if (this.soundSystem) {
					this.soundSystem.playGunShotLocal(
						this.renderer.AB45203628E28928D1FB446B5C6EB50C.currentlyEquipped
					)
				}

				if (fireResult.weapon.grenadeProjectiles) {
					// Do nothing
				} else {
					const weapon = fireResult.weapon
					// leaving this so that  others can test gunTip offsets on predicted shots
					//const gunTipX = this.simulator.predictedEntity.allegedX + Math.cos(command.aim) * weapon.barrelLength
					//const gunTipY =this.simulator.predictedEntity.allegedY + Math.sin(command.aim) * weapon.barrelLength
					const lines = createWeaponRays(
						this.simulator.predictedEntity.allegedX, // formerly gun tip
						this.simulator.predictedEntity.allegedY,
						command.aim,
						weapon,
						this.simulator.collisionSystem,
						this.prng
					)


					const projectileData = {
						color: 0xffffff,
						image: weapon.bulletImage,
						scaleOverTime: !weapon.dontScaleBulletOverTime,
						initialScale: weapon.customBulletScale,
						lifeAfterHit: weapon.bulletLifeAfterHit,
						pinsTarget: weapon.pinsTarget,
						customPivot: weapon.customProjectilePivot
					}
					// looks good, despite no longer deliberately coming from the gun tip
					lines.forEach(line => {
						
						// Need to shift the start based on our aim for some guns we hold lower, 0 if not the case
						line.startX += offset.x
						line.startY += offset.y

						this.simulator.beginProjectileSimulation(
							line,
							this.simulator.predictedEntity.id,
							projectileData,
							weapon.instantSpeedProjectile,
							weapon.config.damage
							//gunTipX, // trying this without gun tip offsets
							// gunTipY
						)
					})

					// If our weapon has a visible projectile, alert the player visuals to update
					if(weapon.hasVisibleProjectile) {
						this.renderer.AB45203628E28928D1FB446B5C6EB50C.firedGun()
						setTimeout(() => {
							this.renderer.AB45203628E28928D1FB446B5C6EB50C.readyToFire()
						}, weapon.cooldown * 1000)
					}
				}
			}
		}
	}

	

	predictGrenadeThrow(grenadeData) {
		let quantity = 0
		if (grenadeData.index == Weapon.GRENADE_FragGrenade.index) {
			quantity = this.simulator.predictedEntity.fragGrenades
		}
		else if (grenadeData.index == Weapon.GRENADE_SmokeGrenade.index) {
			quantity = this.simulator.predictedEntity.smokeGrenades
		}
		else if (grenadeData.index == Weapon.GRENADE_Molotov.index) {
			quantity = this.simulator.predictedEntity.molotovGrenades
		}

		if (quantity > 0) {
			this.simulator.predictedEntity.weaponSystem.triggerGlobalCd(grenadeData.gunCooldown)
		}
	}

	update(delta) {
		if (!this.simulator.predictedEntity) {
			return
		}
		const predictedEntity = this.simulator.predictedEntity
		predictedEntity.predictorUpdate(delta)
		dynamicItemText(predictedEntity, this.client.sims, this.renderer.entities)
	}
}

export default Predictor
