import ParticleAttribute from "./ParticleAttribute"



class VelocityAttribute extends ParticleAttribute {
    constructor(attributeBlueprint) {
        super(attributeBlueprint)
        this.attributeBlueprint = attributeBlueprint
    }

    reset() {
        super.reset()
    }

    die(particle) {
        super.die()
    }

    particleSpawned(particle) {
        super.particleSpawned(this.particleSpawned)
        if(this.attributeBlueprint.randomVelocity) {
            this.initialVelocity = {
                x: this.parseRandom(this.attributeBlueprint.randomVelocity.minX, this.attributeBlueprint.randomVelocity.maxX),
                y: this.parseRandom(this.attributeBlueprint.randomVelocity.minY, this.attributeBlueprint.randomVelocity.maxY)
            }
        }
        else if(this.attributeBlueprint.steppedVelocity) {
            let velocityDir = this.getVelocityDir(particle.step)
            this.initialVelocity = {
                x: velocityDir.x * this.attributeBlueprint.initialVelocity,
                y: velocityDir.y * this.attributeBlueprint.initialVelocity
            }
        }
        else {
            this.initialVelocity = this.attributeBlueprint.initialVelocity
        }

        if(this.attributeBlueprint.gravity) {
            if(this.attributeBlueprint.gravity.random)
                this.gravity = this.parseRandom(this.attributeBlueprint.gravity.min, this.attributeBlueprint.gravity.max)
            else 
                this.gravity = this.attributeBlueprint.gravity.value
        }
    }

    getVelocityDir(step) {
        let radToRotate = ((step * this.attributeBlueprint.stepRot) * Math.PI) / 180.0
        return {
            x: Math.cos(radToRotate),
            y: Math.sin(radToRotate)
        }
    }

    apply(particle, delta) {
        super.apply(particle)
        particle.x += delta * this.initialVelocity.x
        particle.y += delta * this.initialVelocity.y

    }
}

export default VelocityAttribute