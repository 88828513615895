import nengi from "nengi"

class PlayerUseItem {
	constructor(slot, x, y, aim, grenadeType) {
		this.slot = slot
		this.x = x
		this.y = y
		this.aim = aim
		this.grenadeType = grenadeType
	}
}

PlayerUseItem.protocol = {
	slot: nengi.Int8,
	x: nengi.Int16,
	y: nengi.Int16,
	aim: nengi.Float64,
	grenadeType: nengi.Int8
}

export default PlayerUseItem
