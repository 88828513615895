let Wall = {
	NONE: 0,

	LEFT: 1,
	RIGHT: 2,
	TOP: 3,
	BOTTOM: 4,
	FULL: 5,

	LEFT_PIERCE: 6,
	RIGHT_PIERCE: 7,
	TOP_PIERCE: 8,
	BOTTOM_PIERCE: 9,
	FULL_PIERCE: 10,

	TOP_LEFT: 11,
	TOP_RIGHT: 12,
	BOTTOM_LEFT: 13,
	BOTTOM_RIGHT: 14,
	MIDDLE: 15,

	TOP_LEFT_PIERCE: 16,
	TOP_RIGHT_PIERCE: 17,
	BOTTOM_LEFT_PIERCE: 18,
	BOTTOM_RIGHT_PIERCE: 19,
	MIDDLE_PIERCE: 20
}

export default Wall
