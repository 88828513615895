import { put, get } from "axios"

export const heartbeatRequest = async authToken => {
	const apiResults = await put(
		`${process.env.ATLAS_URL}/shared/heartbeat`,
		{},
		{
			headers: { 'auth-token': authToken }
		}
	)
	return apiResults.data
}

export const updateGamePreferencesRequest = async (headerBlob, newPreferences) => {
	const apiResults = await put(`${process.env.ATLAS_URL}/bruh/user-game-preferences`, newPreferences, {
		headers: headerBlob
	})
	return apiResults.data
}

export const getUserAdRewardsProgress = async authToken => {
	const apiResults = await get(
		`${process.env.ATLAS_URL}/bruh/get-user-adrewards-progress`,
		{
			headers: { 'auth-token': authToken }
		}
	)
	return apiResults.data
}

export const getCurrentAdRewards = async () => {
	const apiResults = await get(
		`${process.env.ATLAS_URL}/bruh/current-season-adrewards`)
	return apiResults.data
}
