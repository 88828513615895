<template>
  <div
    class="post-game"
    v-if="showPostgame && roundSummary && !showConfirmWatchAd"
  >
    <div class="left panel light">
      <div class="round-summary">
        <h1>{{ heading }}</h1>
        <div class="rank">Rank {{ roundSummary.placement }}</div>
        <div class="stats">Shots Fired {{ roundSummary.shotsFired }}</div>
        <div class="stats">Damage Dealt {{ roundSummary.damageDealt }}</div>
        <div class="stats">Damage Taken {{ roundSummary.damageTaken }}</div>
        <div class="stats">Poison Damage {{ roundSummary.poisonDamage }}</div>
      </div>
      <div class="items">
        <div class="weapon-section">
          <div class="weapon" v-for="weapon in weapons">
            <img
              :src="weapon.image.imgSrc"
              :style="{
                width: '80%',
                height: '80%',
                transform: `scale(1.5)`,
              }"
            />
            <div class="damage">{{ weapon.damage }}</div>
          </div>
        </div>
        <div class="utility-section">
          <div class="stacked" v-for="item in items">
            <img
              :src="item.image.imgSrc"
              :style="{
                transform: `scale(1.8) rotate(${Math.random() * 45}deg)`,
                width: '100%',
                height: 'auto'
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="right panel light">
      <div
        class="panel-close"
        v-on:click="hidePostgame()"
        @mouseover="onMouseover()"
        @mouseleave="onMouseleave()"
      >
        <div class="close-icon" v-on:click="hidePostgame()"></div>
      </div>
      <!-- <div v-if="!hasBattlepass" class="battlepass-summary">
				<p>{{ battlepassCTA }}</p>
			</div> -->
      <!-- <div v-if="hasBattlepass" class="battlepass-summary">
				<div class="level">
					<span class="label">Level</span>
					<span class="number">{{battlepass.level}}</span>
				</div>
				<div class="experience">
					Total XP: {{battlepass.experience}}
					<br />
					Earned XP: {{battlepass.round.experienceEarnedNow}}
				</div>
				<ProgressBar class="progress-bar"
							 min-value="0"
							 max-value="100"
							 :current-value="battlepass.experiencePercentTilNextLevel"
							 :label-text="battlepass.experiencePercentTilNextLevel + '%'"
							 foreground-color="#e5b200"
							 background-color="#272727"
							 border-color="#bb6f00"
							 style="width: 95%">
					<template #icon>
					</template>
				</ProgressBar>
				<div class="mission"
					 v-for="mission in battlepassMissionsCompletedThisRound"
					 v-bind:key="mission.description">
					<div>
						<img src="../../creative/spritesheetsourceimages/check_icon.png" />
						{{mission.description}}
					</div>
				</div>
			</div> -->
      <SocialButtons></SocialButtons>
    </div>

    <!-- <div class="center panel light">
			<div v-if="hasAdReward && !unlockedCurrentAdReward">
				<p>{{watchAdCTAIntro}} {{adsRequired}} {{watchAdCTAEnding}} {{friendlyAdRewardName}}!</p>
				<p>{{adsWatched}}/{{adsRequired}} {{adsWatchedLabel}}</p>
			</div>
			<div v-else>
				<p>{{watchAdCTANoReward}}</p>
			</div>

			<div class="button-row">
				<p class="center" v-if="!adWatchable">{{adCountdownLabel}} {{adTimeString}}</p>
				<div class="green-button" v-if="adWatchable" v-on:click="swapShowConfirmWatchAd()"><p class="center-text">{{watchAd}}</p></div>
			</div>
		</div> -->
  </div>
  <div v-else-if="showPostgame && roundSummary && showConfirmWatchAd">
    <div class="middle panel light">
      <p>{{ watchAdExitWarning }}</p>
      <div class="button-row">
        <div class="green-button" v-on:click="goToVidAd()">
          <p class="center-text">{{ yes }}</p>
        </div>
        <div class="green-button" v-on:click="swapShowConfirmWatchAd()">
          <p class="center-text">{{ no }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProgressBar from "./ProgressBar.vue";
import SocialButtons from "./SocialButtons.vue";
import Weapon from "../../common/weapon/Weapon";
import parcelImageAdapterWeaponsItems from "./parcelImageAdapterWeaponsItems.js";
import { t } from "./i18n.js";
import moment from "moment";

export default {
  components: {
    ProgressBar,
    SocialButtons,
  },
  data() {
    return {
      battlepassCTA: t("battlepassCTA"),
      watchAdCTAIntro: t("watchAdCTAIntro"),
      watchAdCTAEnding: t("watchAdCTAEnding"),
      watchAdCTANoReward: t("watchAdCTANoReward"),
      adsWatchedLabel: t("adsWatchedLabel"),
      watchAd: t("watchAd"),
      watchAdExitWarning: t("watchAdExitWarning"),
      yes: t("yes"),
      no: t("no"),
      adCountdownLabel: t("adCountdown"),
      nowTime: null,
      adTimeString: "00:00:00",
      adWatchable: false,
    };
  },
  methods: {
    ...mapActions(["hidePostgame", "goToVidAd", "swapShowConfirmWatchAd"]),
    onMouseover: function () {
      window.mouseOverUI = true;
    },
    onMouseleave: function () {
      window.mouseOverUI = false;
    },
    pad(num) {
      return ("0" + num).substr(-2);
    },
  },
  computed: {
    ...mapGetters([
      "showPostgame",
      "roundSummary",
      "battlepass",
      "battlepassMissionsCompletedThisRound",
      "hasBattlepass",
      "showConfirmWatchAd",
      "nextAdTime",
      "adsWatched",
      "adsRequired",
      "adRewardId",
      "hasAdReward",
      "friendlyAdRewardName",
      "unlockedCurrentAdReward",
    ]),
    heading() {
      if (!this.roundSummary) {
        return null;
      }
      if (this.roundSummary.wins > 0) {
        return "You Win!";
      }
      if (this.roundSummary.diedToPoison) {
        return "Killed by Poison";
      }
      if (this.roundSummary.killerName) {
        return `Killed by ${this.roundSummary.killerName} wielding a ${
          Weapon.byIndex(this.roundSummary.killerWeapon).name
        }.`;
      }
    },
    weapons() {
      // builds a summary of each weapon and damage ordered by damage
      const weapons = [];
      Object.keys(this.roundSummary.weaponDamages).forEach((canonicalName) => {
        const wconfig = Weapon.byName(canonicalName);
        weapons.push({
          name: wconfig.name,
          damage: this.roundSummary.weaponDamages[canonicalName],
          image: parcelImageAdapterWeaponsItems(wconfig.programmaticName),
        });
      });
      return weapons.sort((a, b) => {
        if (a.damage > b.damage) {
          return -1;
        } else if (a.damage < b.damage) {
          return 1;
        }
        return 0;
      });
    },
    items() {
      // builds a summary of each item ordered by quantity
      const items = [];
      Object.keys(this.roundSummary.gathered).forEach((canonicalName) => {
        const wconfig = Weapon.byName(canonicalName);
        if (wconfig.itemClass === "weapon") {
          return;
        }

        let quantity = this.roundSummary.gathered[canonicalName];
        if (wconfig.itemClass === "scope") {
          // limit scope pick ups displayed to 1, since these can be juggled
          quantity = 1;
        }
        items.push({
          name: wconfig.name,
          image: parcelImageAdapterWeaponsItems(wconfig.programmaticName),
          quantity,
        });
      });
      items.sort((a, b) => {
        if (a.quantity > b.quantity) {
          return -1;
        } else if (a.quantity < b.quantity) {
          return 1;
        }
        return 0;
      });

      // builds a list of each item, appearing once per time picked up
      const itemsEach = [];
      items.forEach((itemSummary) => {
        const single = Object.assign({}, itemSummary);
        for (let i = 0; i < itemSummary.quantity; i++) {
          single.index = i;
          itemsEach.push(single);
        }
      });
      return itemsEach;
    },
  },
  watch: {
    nowTime: {
      handler(value) {
        if (!this.showPostgame) {
          return;
        }

        let now = this.nowTime;
        if (this.nowTime == null) {
          now = moment();
        }

        if (this.nextAdTime == null || now.isAfter(this.nextAdTime)) {
          this.adTimeString = "00:00:00";

          setTimeout(() => (this.nowTime = moment()), 1000);

          //console.log("next: " + this.timeToNextAd + ", now: " + now)

          if (this.nextAdTime == null && this.userType === "registered") {
            this.adWatchable = false;
          } else {
            this.adWatchable = true;
          }
          return;
        }

        let diff = moment.duration(this.nextAdTime.diff(now));
        this.adTimeString =
          this.pad(diff.hours()) +
          ":" +
          this.pad(diff.minutes()) +
          ":" +
          this.pad(diff.seconds());

        setTimeout(() => (this.nowTime = moment()), 1000);

        this.adWatchable = false;
      },
      immediate: true,
    },
    showPostgame: {
      handler(value) {
        if (value) {
          this.nowTime = moment();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
$post-game-height: 60vh;
$post-game-width: 60vw;
$post-game-half-height: $post-game-height / 2;
$post-game-half-width: $post-game-width / 2;

.left {
  float: left;
  max-width: 400px;
}

.right {
  float: right;
  max-width: 400px;
}

.center {
  float: right;
  margin-right: 1%;
  max-width: 300px;
}

.middle {
  margin: auto;
  width: 33%;
}

.post-game {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -$post-game-half-width;
  margin-top: -$post-game-half-height;
  width: $post-game-width;
  height: $post-game-height;

  .round-summary {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    display: block;

    h1 {
      color: rgb(255, 147, 6);
      text-shadow: -1px -1px 0 rgb(0, 0, 0), 1px -1px 0 rgb(0, 0, 0),
        -1px 1px 0 rgb(0, 0, 0), 1px 1px 0 rgb(0, 0, 0);
    }
    .rank {
      font-size: 1.2em;
    }

    .stats {
      font-size: 0.9em;
      padding: 2px;
    }
  }

  .items {
    display: flex;
    align-items: center;

    .weapon-section {
      display: flex;
      flex-flow: row wrap;
      padding-left: 16px;
      .weapon {
        display: flex;
        border-bottom: solid 2px rgb(149, 149, 149);
        height: auto;
        .damage {
          z-index: 2;
          margin-top: 8px;
          margin-right: 8px;
          color: rgb(255, 196, 119);
          border-radius: 4px;
          padding: 2px;
          background-color: white;
          text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
            1px 1px 0 #000;
        }
      }
    }

    .utility-section {
      margin-left: 8px;
      margin-top: 8px;
      display: flex;
      flex-flow: row wrap;

      .stacked {
      }
    }
  }
}

.battlepass-summary {
  padding: 16px;
  width: 400px;
  margin-top: 8px;

  .level {
    .label {
      font-size: 1.4em;
      text-transform: uppercase;
    }
    .number {
      font-size: 2.2em;
      transform: scale(1, 1.2);
      color: #3c57a6;
      text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
        1px 1px 0 white;
    }
  }

  .experience {
    margin-bottom: 10px;
  }
}

.panel {
  border-image: url(./assets/bruh-panel.png);
  border-image-slice: 18% fill;
  border-image-width: 12px;
  padding: 16px;

  &.light {
    color: black;
    border-image: url(./assets/bruh-light-panel.png) round;
    border-image-slice: 10 fill;
    border-image-width: 20px;
  }

  .panel-close {
    position: absolute;
    cursor: pointer;
    pointer-events: all;
    top: -20px;
    right: -20px;

    .close-icon {
      width: 48px;
      height: 48px;
      background-image: url("../images/close_icon.png");
    }
  }

  .button-row {
    display: flex;
    margin-top: 10px;
    justify-content: center;

    .green-button {
      cursor: pointer;
      pointer-events: all;
      width: 100px;
      height: 40px;
      background-image: url("./assets/bruh-green-button.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      text-align: center;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.center-text {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
