<template>
	<div class="scoreboard" v-if="scoreboardShowing">
		<table>
			<tr>
				<th>#</th>
				<th></th>
				<th></th>
				<th>W</th>
				<th>K</th>
			</tr>
			<tr class="score" v-for="(score, index) in scoreboardScores" :key="index">
				<td>{{index + 1}}</td>
				<td v-if="score.isGhost" style="width: 18px;">💀</td>
				<td v-else-if="score.isVerified" style="width: 18px;">⭐</td>
				<td v-else style="width: 18px;"></td>
				<td style="text-align: left;" v-bind:class="{developer: score.isDeveloper}"><img v-if="score.isDeveloper" style="height: 20px; position: relative; top: 3px;" src="./assets/dev_icon.png" alt="This player is an other ocean developer">{{score.name}}</td>
				<td >{{score.wins}}</td>
				<td >{{score.kills}}</td>
			</tr>
		</table>
	</div>
	<div class="hidden" v-else>
		[L] toggle scoreboard
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "Scoreboard",
	computed: {
		...mapGetters(["scoreboardShowing", "scoreboardScores"])
	}
}
</script>

<style lang="scss" scoped>
.scoreboard {
	position: fixed;
	right: 16px;
	top: 251px;
	width: 200px;
	font-size: 10pt;
	color: rgb(233, 233, 233);
}
table {
	width: 100%;
	background-color: rgba(26, 26, 26, 0.8);
	border: solid 2px #7d8085;
}
th {
	color: rgb(172, 172, 172);
}
td {
	text-align: center;

	&.developer {
		 color: #75B08F;
	}
}
.hidden {
	position: fixed;
	right: 16px;
	top: 231px;
	text-align: right;
	color: rgb(233, 233, 233);
}
</style>
