import nengi from "nengi"

class MolotovExplosion {
	constructor(x, y) {
		this.x = x
		this.y = y
	}
}

MolotovExplosion.protocol = {
	x: nengi.UInt16,
	y: nengi.UInt16,
}

export default MolotovExplosion
