  /** 
   * Basic particle emitter create for Bruh.io using pixi legacy.  
   * 
   * Format : Emitter blueprint
   *
    "name": "Test Emitter", // Name of emitter
    "image": "image_name.png", // image for particle, assumes in the client/images folder
    "emissionType": "point", // type of emission, can be point
    "lifetime": num, // -1 is loop or setting loop to true, in seconds
    "particleLifetime": num, // Life of particles
    "maxParticles": num, // Max amount of particles on screen from this particle emitter
    "emissionRate": num, // Rate of emission.  If burst is true, this is the amount per burst.  If not this is
    the amount of particles per second
    "loop": true, // Whether the emitter should loop continuously.  False by default
    "burst": true, // Whether the emitter should burst.  False by default
    "burstRate": num, // Amount of bursts per second
    "attributes": [
        {
            "type": "VELOCITY", // Can be VELOCITY
            // The rest is attribute specific
        }
    ]
   * 
   */ 


import {ParticleContainer, RENDERER_TYPE, BLEND_MODES, Container } from "pixi.js-legacy"
import AttributeFactory from "../AttributeFactory"
import Particle from "../Particle"
import mathUtils from "../../../common/misc/mathUtils"

class PIXIParticleEmitter extends Container {
    constructor(emitterBlueprint, x, y, renderer, parentOffset) {
        super()
        this.emitterBlueprint = emitterBlueprint
        this.particles = []
        this.cachedParticles = []
        this.emissionType = emitterBlueprint.emissionType
        if(this.emissionType === 'circle') {
            this.innerRadius = emitterBlueprint.innerRadius ? emitterBlueprint.innerRadius : 0
            this.outerRadius = emitterBlueprint.outerRadius
        }

        this.lifetime = emitterBlueprint.lifetime
        this.particleLifetime = emitterBlueprint.particleLife
        this.loop = emitterBlueprint.loop
        this.burst = emitterBlueprint.burst
        if(this.burst)
            this.burstRate = emitterBlueprint.burstRate
        
        this.maxParticles = emitterBlueprint.maxParticles
        this.emissionRate = emitterBlueprint.emissionRate
        this.spriteImage = emitterBlueprint.image
        this.blendMode = emitterBlueprint.blendMode ? BLEND_MODES[emitterBlueprint.blendMode] : BLEND_MODES.NORMAL
        this.parentOffset = parentOffset

        this.elapsedTime = 0
        this.activeCountdown = 0

        this.x = x
        this.y = y
        this.originalPos = {
            x: this.x,
            y: this.y
        }
        this.renderer = renderer
        this.step = 0
        this.maintainWorldPos = emitterBlueprint.maintainWorldPos

        this.particleAnchor = emitterBlueprint.anchor
        if(!this.particleAnchor)
            this.particleAnchor = {
                x: 0.5,
                y: 0.5
            }

        this.dontCacheParticles = emitterBlueprint.dontCacheParticles
        if(!this.dontCacheParticles)
            this.cacheParticles()

    }

    createAttributes(emitterBlueprint) {
        let attrBlueprint = emitterBlueprint.attributes
        let createdAttributes = []
        for(let i = 0; i < attrBlueprint.length; i++) {
            let currAtt = attrBlueprint[i]
            if(this.hasAttributeAlready(createdAttributes, currAtt)) {
                console.error('Trying to add 2 attributes with the ' + currAtt.type + ' type')
                continue
            }
            let createdAtt = AttributeFactory(currAtt.type, attrBlueprint[i], this.renderer)
            createdAttributes.push(createdAtt)
        }
        return createdAttributes
    }

    cacheParticles() {
        for(let i = 0; i < this.maxParticles; i++) {
            let attributes = this.createAttributes(this.emitterBlueprint)
            let particle = new Particle(this.spriteImage, attributes, this.renderer, this, this.particleAnchor, this.particleLifetime, 0, 0, this.step, this.blendMode)
            this.cachedParticles.push(particle)
        }
    }

    hasAttributeAlready(createdAttributes, attToAdd) {
        return createdAttributes.filter( (element) => {
            return element.attType === attToAdd.type
        }).length !== 0
    }

    update(delta) {

        this.elapsedTime += delta
        this.activeCountdown -= delta
        if(!this.loop && this.elapsedTime >= this.lifetime) {
            if(!this.isDead)
                this.die()
        }
        if(this.activeCountdown <= 0 && !this.isDead) {
            if(this.burst) {
                this.activeCountdown = this.burstRate // Convert from seconds
                for(let i = 0; i < this.emissionRate; i++) {
                    this.spawnParticle(this.spriteImage)
                }
            }
            else {
                this.activeCountdown = 1 / this.emissionRate
                this.spawnParticle(this.spriteImage)
            }
        }

        let deadParticles = []
        this.particles.forEach((particle) => {
            particle.update(delta)
            if(particle.isDead) {
                deadParticles.push(particle)
            }
     
        })

        let filtered = this.particles.filter((element) => {
            return !element.isDead
        })
        this.particles = filtered

        for(let i = 0; i < deadParticles.length; i++) {
            if(this.maintainWorldPos)
                this.renderer.foreground.removeChild(deadParticles[i])
            else
                this.removeChild(deadParticles[i])
            if(this.dontCacheParticles) {
                deadParticles[i].destroy()
            }
            else {
                deadParticles[i].visible = false
                this.cachedParticles.push(deadParticles[i])
                deadParticles[i].die()
                deadParticles[i].reset()
            }
        }

        if(this.isDead && this.particles.length === 0) {
            this.renderer.signalEmitterDied(this)
        }
    }

    die() {
        this.isDead = true
    }

    spawnParticle(spriteName) {
        if(this.dontCacheParticles) {
            let attributes = this.createAttributes(this.emitterBlueprint)
            let spawnPos = this.getParticleSpawnPos()
            let particle = new Particle(spriteName, attributes, this.renderer, this, this.particleAnchor, this.particleLifetime, spawnPos.x, spawnPos.y, this.step++, this.blendMode)
            particle.spawn()
            if(this.maintainWorldPos)
                this.renderer.foreground.addChild(particle)
            else
                this.addChild(particle)
            this.particles.push(particle)
            if(this.particles.length > this.maxParticles) {
                let particle = this.particles.shift()
                if(this.maintainWorldPos)
                    this.renderer.foreground.removeChild(particle)
                else
                    this.removeChild(particle)
                particle.destroy()
            }
        }
        else {
            let particle
            let spawnPos = this.getParticleSpawnPos()
            if(this.cachedParticles.length > 0) {
                particle = this.cachedParticles.pop()
                particle.step = this.step++
            }
            else {
                particle = this.particles.pop()
                particle.reset()
                particle.step = this.step++
            }
            particle.x = spawnPos.x
            particle.y = spawnPos.y

            particle.spawn()
            particle.visible = true
            this.particles.splice(0, 0, particle)
            if(this.maintainWorldPos)
                this.renderer.foreground.addChild(particle)
            else
                this.addChild(particle) 
            this.addChild(particle) 
                this.addChild(particle) 

        }
    }

    getParticleSpawnPos() {
        let addedPosVal = {
            x: 0,
            y: 0
        }
        if(this.maintainWorldPos) {
            this.determineRotatedPos()
            // We need to move our x and y based on where our parent is
            addedPosVal.x = this.x + this.parentOffset.x
            addedPosVal.y = this.y + this.parentOffset.y
        }
        switch(this.emissionType) {
            case 'point':
                return {
                    x: addedPosVal.x,
                    y: addedPosVal.y
                }
            case 'circle':
                let possibleDist = (this.outerRadius - this.innerRadius)

                let dir = { 
                    x: Math.random() - 0.5,
                    y: Math.random() - 0.5
                }
                dir = mathUtils.normalizeVector(dir)

                let endPos = {
                    x: (dir.x * ((possibleDist * Math.random()) + this.innerRadius)) + addedPosVal.x,
                    y: (dir.y * ((possibleDist * Math.random()) + this.innerRadius)) + addedPosVal.y
                }
                return endPos
            default:
                return {
                    x: addedPosVal.x,
                    y: addedPosVal.y
                }
        }
    }

    determineRotatedPos() {
        let xPos = this.originalPos.x * Math.cos(this.parentOffset.rotation) - this.originalPos.y * Math.sin(this.parentOffset.rotation)
        let yPos = this.originalPos.x * Math.sin(this.parentOffset.rotation) + this.originalPos.y * Math.cos(this.parentOffset.rotation)
        this.x = xPos
        this.y = yPos
    }


}

export default PIXIParticleEmitter