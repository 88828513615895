export default {
	name: "Minigun",
	programmaticName: "minigun",
	canonicalName: "HV_MINIGUN",
	itemClass: "weapon",
	itemSubclass: "heavy_weapon",
	image: "minigun_default.png",
	heldImage: "minigun_hands.png",
	outlineImage: "minigun_outline.png",
	soundName: "MACHINE_GUN",
	reloadSoundName: "MACHINE_GUN_reloading",
	spawnWeighting: 10,
	grenadeProjectiles: false,
	overwrittenFlairLifetime: 0.025,
	damage: 10,
	cooldown: 0.55,
	escalatingCooldown: true,
	cooldownCap: 0.05,
	cooldownEscalationTime: 3,
	shotsUntilMaxRecoil: 30,
	recoilRecoveryPeriod: 2.5,
	recoilMax: 25,
	clipSize: 200,
	kick: 10,
	moveSlowModifier: 0.5,
	moveSlowDuration: 0.56,
	barrelLength: 35,
	shiftProjectileY: -5,
	predictFromBarrelTip: true,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 275,
	corpseForce: 150,
	reloadable: false,
	instantSpeedProjectile: false,
	uiRotateDeg: -37,
	uiWidth: 90,
	uiTop: 43,
	uiLeft: 51
}
