import nengi from "nengi"

class KillFeedMessage {
	constructor(killerName, killedName, killedWith, suicide = false, resurrected = false) {
		this.killerName = killerName
		this.killedName = killedName
		this.killedWith = killedWith
		this.suicide = suicide
		this.resurrected = resurrected
		this.variant = Math.floor(Math.random() * 30)
	}
}

KillFeedMessage.protocol = {
	killerName: nengi.String,
	killedName: nengi.String,
	killedWith: nengi.UInt8,
	suicide: nengi.Boolean,
	resurrected: nengi.Boolean,
	variant: nengi.UInt8
}

export default KillFeedMessage
