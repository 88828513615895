export default {
	name: "PP-Bizon",
	programmaticName: "ppbizon",
	canonicalName: "SMG_PPBIZON",
	itemClass: "weapon",
	itemSubclass: "submachine_gun",
	image: "ppbizon_default.png",
	heldImage: "ppbizon_hands.png",
	outlineImage: "ppbizon_outline.png",
	soundName: "SUBMACHINE_GUN",
	reloadSoundName: "SUBMACHINE_GUN_reloading",
	spawnWeighting: 100,
	grenadeProjectiles: false,
	damage: 10,
	cooldown: 0.16,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 18,
	recoilRecoveryPeriod: 0.8,
	recoilMax: 9,
	clipSize: 20,
	kick: 25,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 17,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 225,
	corpseForce: 50,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 1.6,
	activeStart: 0.5,
	activeEnd: 0.56,
	uiRotateDeg: -35,
	uiWidth: 117,
	uiTop: 38,
	uiLeft: 55
}
