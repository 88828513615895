import Grid from "../../common/classes/Grid"
import { Rectangle, Texture } from "pixi.js-legacy"
import imageUrl from "../../common/map/tilesets/tileset.png"

let parseSize = function(sizeString) {
	let size = sizeString.split(':').at(1).split(',')
	return {
		w: parseInt(size[0]),
		h: parseInt(size[1])
	}
}

let parseBounds = function(boundsString) {
	// xy, width height
	let allValues = boundsString.split(':').at(1).split(',')
	return {
		x: parseInt(allValues[0]),
		y: parseInt(allValues[1]),
		w: parseInt(allValues[2]),
		h: parseInt(allValues[3])

	}
}

let parseImage = function(imageEntry, framesObj) {
	let imageName = imageEntry[0]
	let parsedBounds = parseBounds(imageEntry[1])
	framesObj[`${imageName}.png`] = {
		frame: parsedBounds,
		rotated: false,
		trimmed: false,
		spriteSourceSize: {
			x: 0,
			y: 0,
			w: parsedBounds.w,
			h: parsedBounds.h
		},
		sourceSize: {
			w: parsedBounds.w,
			h: parsedBounds.h
		}
	}

}

let middleware = function(resource, next) {
	if (
		!resource ||
    !resource.data ||
    resource.extension !== 'atlas'
	) {
		return next()
	}
    // This needs to turn a spineattempts.atlas into the proper json formatted from texturepacker yayyyy
    let eachLine = resource.data.split(/\r?\n/)
	// First 6 lines are
	// empty line zzzz
	// Image url
	// Image size, size: x, y
	// format: format
	// filter: filter, //Dont care
	// repeat: none if no repeating textures, should be none

	//Get rid of empty line
	let imageUrl = eachLine.shift()
	let sizeWH = parseSize(eachLine.shift())
	// Get rid of filter
	eachLine.shift()
	// Get rid of pma? 
	eachLine.shift()
	// Theres also a new line at the end of the file
	if(eachLine.length % 2 !== 1) {
		console.log("Atlas file not formatted correctly")
		return next()
	}

	let formattedObj = {
		frames: {},
		animations:{},
		meta: {
			app: "bruh.io_custom",
			version: "0.1",
			image: imageUrl,
			format: "RGBA8888",
			size: sizeWH,
			scale: 1
		}
	}
	
	while(eachLine.length > 7) {
		parseImage(eachLine.splice(0, 2), formattedObj.frames)
	}

	resource.parsedSpineSpritesheet = formattedObj

	next()
}


export default middleware
