import { Container, Sprite, Texture } from "pixi.js-legacy"

class ImpactEffect extends Container {
	constructor(x, y) {
		super()
		this.x = x
		this.y = y
		this.isComplete = false
		this.animationFrame = 1
		this.accumulator = 0

		this.impactSprite = new Sprite.from("explosion001.png")
		this.impactSprite.anchor.x = 0.5
		this.impactSprite.anchor.y = 0.5
		this.impactSprite.scale.x = 0.3
		this.impactSprite.scale.y = 0.3
		this.addChild(this.impactSprite)
	}

	update(delta) {
		this.accumulator += delta

		if (this.accumulator > 0.035) {
			this.animationFrame++
			if (this.animationFrame === 12) {
				this.animationFrame = 1
				this.isComplete = true
			} else {
				let frameStr =
				this.animationFrame >= 10
					? "explosion0" + this.animationFrame + ".png"
					: "explosion00" + this.animationFrame + ".png"
				this.impactSprite.texture = new Texture.from(frameStr)
			}
		}
	}
}

export default ImpactEffect
