export default {
	name: "M16A4",
	programmaticName: "m16",
	canonicalName: "AR_M16",
	itemClass: "weapon",
	itemSubclass: "assault_rifle",
	image: "m16_default.png",
	heldImage: "m16_hands.png",
	outlineImage: "m16_outline.png",
	soundName: "ASSAULT_RIFLE",
	reloadSoundName: "ASSAULT_RIFLE_reloading",
	spawnWeighting: 100,
	grenadeProjectiles: false,
	damage: 9,
	cooldown: 0.15,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 10,
	recoilRecoveryPeriod: 0.5,
	recoilMax: 11,
	clipSize: 30,
	kick: 30,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 30,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 275,
	corpseForce: 50,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 2.1,
	activeStart: 0.35,
	activeEnd: 0.43,
	uiRotateDeg: -37,
	uiWidth: 90,
	uiTop: 43,
	uiLeft: 51
}
