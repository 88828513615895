<template>
	<div class="itemSlots">
		<EquipmentSlots class="equip-wrapper"></EquipmentSlots>

		<div class="wrap" v-on:click="clickSlot(1)">
			<div class="item" :class="{ active: activeSlot === 'slot1' }" v-if="slot1Config && slot1ImageData">
				<img class="itemImage" :style="{
					top: `${slot1ImageData.top}px`,
					left: `${slot1ImageData.left}px`,
					transform: `rotate(${slot1ImageData.rot}deg) scale(${slot1ImageData.scale})`,
				}" :src="slot1ImageData.imgSrc" />
			</div>
			<div class="keybind">1</div>
		</div>

		<div class="wrap" v-on:click="clickSlot(2)">
			<div class="item" :class="{ active: activeSlot === 'slot2' }" v-if="slot2Config && slot2ImageData">
				<img class="itemImage" :style="{
					top: `${slot2ImageData.top}px`,
					left: `${slot2ImageData.left}px`,
					transform: `rotate(${slot2ImageData.rot}deg) scale(${slot2ImageData.scale})`,
				}" :src="slot2ImageData.imgSrc" />
			</div>
			<div class="keybind">2</div>
		</div>

		<div v-on:click="clickSlot(3)">
			<ItemSlot
				label="3"
				:has-item="!!bandages"
				:is-active="activeSlot === 'slot3'"
				:quantity="bandages">
				<template #image>
					<img class="medImage" :class="{ active: activeSlot === 'slot3' }" src="../../creative/spritesheetsourceimages/bandage.png" />
				</template>
			</ItemSlot>
		</div>

		<div v-on:click="clickSlot(4)">
			<ItemSlot
				label="4"
				:has-item="!!medpacks"
				:is-active="activeSlot === 'slot4'"
				:quantity="medpacks">
				<template #image>
					<img class="medImage" :class="{ active: activeSlot === 'slot4' }" src="../../creative/spritesheetsourceimages/medpack.png" />
				</template>
			</ItemSlot>
		</div>

		<div v-on:click="clickSlot(5)">
			<ItemSlot
				label="5"
				:has-item="!!painkillers"
				:is-active="activeSlot === 'slot5'"
				:quantity="painkillers">
				<template #image>
					<img class="medImage" :class="{ active: activeSlot === 'slot5' }" src="../../creative/spritesheetsourceimages/painkillers.png" />
				</template>
			</ItemSlot>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Weapon from "../../common/weapon/Weapon";
import parcelImageAdapter from "./parcelImageAdapterWeaponsItems";
import ItemSlot from "./ItemSlot"
import EquipmentSlots from "./EquipmentSlots"

export default {
	components: {
		ItemSlot,
		EquipmentSlots,
	},
	computed: {
		...mapGetters([
			"activeSlot",
			"slot1",
			"slot2",
			"bandages",
			"medpacks",
			"painkillers",
			"fragGrenades",
			"smokeGrenades",
			"molotovGrenades"
		]),
		slot1Config() {
			return Weapon.byIndex(this.slot1);
		},
		slot2Config() {
			return Weapon.byIndex(this.slot2);
		},
		slot1ImageData() {
			return parcelImageAdapter(Weapon.byIndex(this.slot1).programmaticName)
		},
		slot2ImageData() {
			return parcelImageAdapter(Weapon.byIndex(this.slot2).programmaticName)
		},
	},
	methods: {
		clickSlot: function(num) {
			// HACK NOTE! we have disabled clicking on slots to change slots
			// the code is being left here b/c it is likely coming back in the future
			// after a click/input overhaul
			// so please note this return that stops the functionality!
			return
			const zeroKeycode = 48
			let event = new KeyboardEvent("keydown", {keyCode: zeroKeycode + num, which: zeroKeycode + num});
			document.dispatchEvent(event);
			event = new KeyboardEvent("keyup", {keyCode: zeroKeycode + num, which: zeroKeycode + num});
			document.dispatchEvent(event);
		},
	},
};
</script>

<style lang="scss" scoped>

.faded {
	opacity: 0.5;
}

.itemSlots {
	position: fixed;
	right: 16px;
	bottom: 8px;
	display: flex;
	/*cursor: pointer; */
	pointer-events: all;

	.equip-wrapper {
		position: fixed;
		right: 16px;
		bottom: 122px;
		display: flex;
	}

	.wrap {
		margin: 7px;

		.item {
			position: relative;
			width: 71px;
			height: 71px;
			border: solid 3px #cacacc;
			box-shadow: 0 0 0 3px #3e3f2d;
			border-radius: 8px;
			color: white;
			background-color: #abaea5;
			transition: transform 0.25s ease-in-out;

			.itemImage {
				position: relative;
				image-rendering: pixelated;
				image-rendering: -moz-crisp-edges;
				image-rendering: crisp-edges;
				pointer-events: none;
			}

			.itemImage.active {
				transform: translate(0px, -15px) rotate(-15deg);
			}

			.medImage {
				width: 36px;
				top: 10px;
				left: 8px;
				position: relative;
				image-rendering: pixelated;
				image-rendering: -moz-crisp-edges;
				image-rendering: crisp-edges;
				transition: transform 0.25s ease-in-out;
			}

			.medImage.active {
				transform: translate(0px, -5px) rotate(-8deg);
			}

			.quantity {
				position: absolute;
				top: 40px;
				left: 48px;
				font-size: 22px;
				color: white;
				text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
			}
		}

		.item.active {
			background-color: #f3c114;
			box-shadow: 0 0 0 3px #ffffff;
			border: solid 2px rgb(0, 0, 0);
		}

		.active {
			transform: scale(1.08);
		}

		.keybind {
			font-weight: bold;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			background-color: rgba(0, 0, 0, 0.35);
			width: 22px;
			border-radius: 4px;
			margin-top: 5px;
			color: white;
		}
	}

	.medImage {
		width: 36px;
		top: 10px;
		left: 8px;
		position: relative;
		image-rendering: pixelated;
		image-rendering: -moz-crisp-edges;
		image-rendering: crisp-edges;
		transition: transform 0.25s ease-in-out;
	}

	.medImage.active {
		transform: translate(0px, -5px) rotate(-8deg);
	}

}
</style>
