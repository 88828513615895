<template>
	<div class="self">
		<ItemUsePopup v-if="activeSlot === 'slot4'"></ItemUsePopup>
		<ActiveReload v-if="activeSlot === 'slot1' || activeSlot === 'slot2'"></ActiveReload>
		<div class="use-things" v-if="useItem && useItemProgress && useItemDuration">
			<ProgressBar
				class="progress-bar"
				min-value="0"
				max-value="1"
				:current-value="useItemProgress / useItemDuration"
				:label-text="`${Math.floor(useItemProgress * 10) / 10}s`"
				label-color="#ffffff"
				foreground-color="#e5b200"
				background-color="#272727"
				border-color="#bb6f00"
				animation-class="fast-animation"
			>
				<template #icon>
					<!-- TODO: would be nice to show a graphic for useItem -->
				</template>
			</ProgressBar>
		</div>
		<div class="kevlar">
			<div class="left">
				<img class="hpIcon" src="../../creative/spritesheetsourceimages/shield_icon.png" />
			</div>
			<div class="right">
				<div class="foreground" :style="{ width: `${shieldWidth}px`, border: shieldBorder }"></div>
				<div class="text">{{ shields }}</div>
				<div class="background"></div>
			</div>
		</div>

		<div class="hitpoints">
			<div class="left">
				<img class="hpIcon" src="../../creative/spritesheetsourceimages/healthbar_icon.png" />
			</div>
			<div class="right">
				<div class="foreground" :style="{ width: `${healthWidth}px`, border: healthBorder }"></div>
				<div class="alt-foreground" :style="{ width: `${healOverTimeWidth}px`, border: healOverTimeBorder }"></div>
				<div class="text">{{ health }}</div>
				<div class="background"></div>
			</div>
		</div>

		<div class="mode">Match in Progress</div>
		<div class="coords" v-if="isDebugEnv">world [{{ coords.x }}, {{ coords.y }}] | tile [{{ coords.tileX }}, {{ coords.tileY }}] | region [{{ coords.regionX }}, {{ coords.regionY }}]</div>
		<div class="coords" v-if="!isDebugEnv">region [{{ coords.regionX }}, {{ coords.regionY }}]</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ActiveReload from './ActiveReload.vue'
import ItemUsePopup from './ItemUsePopup.vue'
import ProgressBar from './ProgressBar.vue'

export default {
	components: {
		ActiveReload,
		ProgressBar,
		ItemUsePopup
	},
	computed: {
		...mapGetters(['activeSlot', 'coords', 'health', 'hpHealingOverTime', 'shields', 'slot1Ammo', 'slot1ClipSize', 'slot2Ammo', 'slot2ClipSize', 'useItemProgress', 'useItemDuration', 'useItem', 'isDebugEnv']),
		healthPlusHealOverTime: function() {
			return this.health + this.hpHealingOverTime
		},
		shieldWidth: function() {
			return (this.shields/50) * 300
		},
		healthWidth: function() {
			return (this.health/100) * 300
		},
		healOverTimeWidth: function() {
			return Math.min(300, (this.healthPlusHealOverTime/100) * 300)
		},
		shieldBorder: function () {
			return this.shields ? '2px solid #5190c3' : '0'
		},
		healthBorder: function() {
			return this.health ? '2px solid #4b8d4d' : '0'
		},
		healOverTimeBorder: function() {
			return this.healthPlusHealOverTime ? '2px solid #4b8d4d' : '0'
		},
	}
}
</script>

<style lang='scss' scoped>

.bullets {
	image-rendering: pixelated;
	image-rendering: -moz-crisp-edges;
	image-rendering: crisp-edges;
	width: 58px;
}
.self {
	position: fixed;

	width: 340px;
	margin-left: -170px;

	left: 50%;
	bottom: 20px;
	color: white;

	.ammo {
		font-size: 24px;
		text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
		text-align: center;
	}
	.left {
		padding-right: 4px;
	}

	.right {

	}

	.use-things {
		width: 140px;
		margin: 0 auto 24px auto;
	}

	.kevlar {
		display: flex;
		padding: 2px;
		.text {
			position: fixed;
			height: 24px;
			line-height: 24px;
			padding-left: 6px;
		}
		.foreground {
			position: fixed;
			width: 150px;
			height: 22px;
			background-color: #105870;
			border: 0;
		}
		.background {
			width: 300px;
			height: 22px;
			background-color: rgba(26, 26, 26, 0.8);
			border: 2px solid rgba(26, 26, 26, 0.8);
		}
	}
	.hpIcon {
		position: relative;
		image-rendering: pixelated;
		image-rendering: -moz-crisp-edges;
		image-rendering: crisp-edges;
		width: 24px;
	}

	.shieldIcon {
		position: relative;
		image-rendering: pixelated;
		image-rendering: -moz-crisp-edges;
		image-rendering: crisp-edges;
		width: 24px;
	}

	.hitpoints {
		display: flex;
		padding: 2px;
		.text {
			position: fixed;
			height: 24px;
			line-height: 24px;
			padding-left: 6px;
			z-index: 2;
		}
		.foreground {
			position: fixed;
			width: 300px;
			height: 22px;
			background-color: #185a1a;
			border: 0;
			z-index: 1;
		}
		.alt-foreground {
			position: fixed;
			width: 150px;
			height: 22px;
			background-color: #caffcc;
			border: 0;
			opacity: 0.4;
			z-index: 0;
		}
		.background {
			width: 300px;
			height: 22px;
			background-color: rgba(26, 26, 26, 0.8);
			border: 2px solid rgba(26, 26, 26, 0.8);
		}
	}

	.mode {
		color: rgb(185, 185, 185);
		text-align: center;
	}

	.coords {
		font-size: 11px;
		color: rgb(185, 185, 185);
		text-align: center;
	}
}
</style>
