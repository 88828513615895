import { Container, Sprite } from "pixi.js-legacy"
import GAME_CONSTANTS from "../../common/balance/gameConstants"

// stretches a 16x16 sprite to draw a rectangle w/o using graphics objects
function perfRect(obj, x, y, width, height) {
	obj.x = x
	obj.y = y
	obj.scale.x = width / GAME_CONSTANTS.TILE_WIDTH
	obj.scale.y = height / GAME_CONSTANTS.TILE_HEIGHT
}

class Poison extends Container {
	constructor(objectThatHasMasterScale) {
		super()

		this.objectThatHasMasterScale = objectThatHasMasterScale
		this.top = new Sprite.from("16x16.png")
		this.top.tint = 0x46785a
		this.bottom = new Sprite.from("16x16.png")
		this.bottom.tint = 0x46785a
		this.left = new Sprite.from("16x16.png")
		this.left.tint = 0x46785a
		this.right = new Sprite.from("16x16.png")
		this.right.tint = 0x46785a

		this.addChild(this.top)
		this.addChild(this.bottom)
		this.addChild(this.left)
		this.addChild(this.right)

		this.visibleAlpha = 0.75
	}

	drawEdges(dim) {
		if (dim.topY < 0) {
			this.top.alpha = this.visibleAlpha
			perfRect(
				this.top,
				0,
				0,
				1280,
				Math.round(dim.topY * -this.objectThatHasMasterScale.masterScale)
			)
		} else {
			this.top.alpha = 0
		}

		if (dim.bottomY < 0) {
			this.bottom.alpha = this.visibleAlpha
			perfRect(
				this.bottom,
				0,
				720 + Math.round(dim.bottomY * this.objectThatHasMasterScale.masterScale),
				1280,
				Math.round(dim.bottomY * -this.objectThatHasMasterScale.masterScale)
			)
		} else {
			this.bottom.alpha = 0
		}

		if (dim.leftX < 0) {
			this.left.alpha = this.visibleAlpha
			perfRect(
				this.left,
				0,
				Math.round(dim.topY * -this.objectThatHasMasterScale.masterScale),
				Math.round(dim.leftX * -this.objectThatHasMasterScale.masterScale),
				721 + Math.round((dim.topY + dim.bottomY) * this.objectThatHasMasterScale.masterScale)
			)
		} else {
			this.left.alpha = 0
		}

		if (dim.rightX < 0) {
			this.right.alpha = this.visibleAlpha
			perfRect(
				this.right,
				1280 + Math.round(dim.rightX * this.objectThatHasMasterScale.masterScale),
				Math.round(dim.topY * -this.objectThatHasMasterScale.masterScale),
				Math.round(dim.rightX * -this.objectThatHasMasterScale.masterScale),
				721 + Math.round((dim.topY + dim.bottomY) * this.objectThatHasMasterScale.masterScale)
			)
		} else {
			this.right.alpha = 0
		}
	}
}

export default Poison
