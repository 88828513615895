import PoisonMessage from "../message/PoisonMessage"
import PoisonShrinkingMessage from "../message/PoisonShrinkingMessage"
import EntityHit from '../message/EntityHit'
import { GAME_CONSTANTS, MAP_CONFIG } from '../../common/balance/gameConstants'
import { addPoisonDamage } from "./metrics"
import { over } from "lodash"

const lerp = (a, b, r) => {
	return a * (1 - r) + b * r
}
const calculateLargerSquare = (px, py, pw, mapName) => {
	let width = Math.min(pw * 2, MAP_CONFIG[mapName].MAP_WIDTH)
	let x = px - Math.random() * pw
	let y = py - Math.random() * pw
	if (x < 0) {
		x = 0
	}
	if (x + width > MAP_CONFIG[mapName].MAP_WIDTH) {
		x = MAP_CONFIG[mapName].MAP_WIDTH - width
	}
	if (y < 0) {
		y = 0
	}
	if (y + width > MAP_CONFIG[mapName].MAP_WIDTH) {
		y = MAP_CONFIG[mapName].MAP_WIDTH - width
	}
	return {
		width: width,
		x: x,
		y: y
	}
}

class Poison {
	constructor(instance, gameServer, overrideMapName = '') {
		this.instance = instance
		this.gameServer = gameServer
		if(overrideMapName)
			this.overrideMapName = overrideMapName

		this.prevX = 0
		this.prevY = 0
		this.prevWidth = 0

		const mapName = this.overrideMapName ? this.overrideMapName : this.gameServer.mapName
		this.speed = MAP_CONFIG[mapName].POISON_SPEED
		this.startDelay = MAP_CONFIG[mapName].POISON_START_DELAY
		this.isMoving = true
		// 12.00 = realistic end is 5m11s, literal end is 5m41s
		// 15.00 = realistic end is 4m08s, literal end is 4m38s
		// 16.50 = realistic end is 3m35s, literal end is 4m05s
		// 18.75 = realistic end is 3m17s, literal end is 3m37s
		this.reset(true)
	}

	reset(useProdSizes) {
		const mapName = this.overrideMapName ? this.overrideMapName : this.gameServer.mapName
		this.acc = 0
		this.isFinale = false
		this.isMoving = true
		this.startDelay = MAP_CONFIG[mapName].POISON_START_DELAY

		this.stages = []
		this.setupStages(useProdSizes)
		let curr = this.stages.pop() // this.stages[this.stages.length-1]
		let next = this.stages.pop() // this.stages[this.stages.length-2]
		this.prevWidth = curr.width
		this.prevX = curr.x
		this.prevY = curr.y
		this.currWidth = curr.width
		this.currX = curr.x
		this.currY = curr.y
		this.nextWidth = next.width
		this.nextX = next.x
		this.nextY = next.y

		// calculate a time to spend closing that obeys the speed variable
		this.closeDuration = (this.currWidth - this.nextWidth) / this.speed
		// console.log('this.close', this.closeDuration, this.currWidth, this.nextWidth)
		// this.stages.pop()
		this.broadcast()
	}

	receivedMapName(mapName) {
		if(this.overrideMapName != mapName) {
			this.overrideMapName = mapName
			this.reset(true)
		}
	}

	sync(message) {
		Object.assign(this, message)
	}

	getSafeArea() {
		return {
			x: this.currX,
			y: this.currY,
			width: this.currWidth,
			height: this.currWidth
		}
	}

	viewIntersection(view) {
		let leftX = view.x - this.currX
		let rightX = this.currX + this.currWidth - (view.x + view.width)

		let topY = view.y - this.currY
		let bottomY = this.currY + this.currWidth - (view.y + view.height)
		return {
			leftX: leftX,
			rightX: rightX,
			topY: topY,
			bottomY: bottomY
		}
	}

	isTouching(x, y, r) {
		let radius = -r
		if (
			x + radius > this.currX &&
      x - radius < this.currX + this.currWidth &&
      y + radius > this.currY &&
      y - radius < this.currY + this.currWidth
		) {
			return false
		} else {
			return true
		}
	}

	calculateFinalSquare(useProdSizes) {
		const mapName = this.overrideMapName ? this.overrideMapName : this.gameServer.mapName
		if(useProdSizes) {
			return {
				width: 256,
				x: Math.random() * (MAP_CONFIG[mapName].MAP_WIDTH - 256),
				y: Math.random() * (MAP_CONFIG[mapName].MAP_WIDTH - 256),
			}
		} else {
			return {
				width: 64,
				x: 2720 + (Math.random() * 1376) - 64,
				y: 2720 + (Math.random() * 1376) - 64,
			}
		}
	}

	setupStages(useProdSizes) {
		const mapName = this.overrideMapName ? this.overrideMapName : this.gameServer.mapName
		let finalStage = this.calculateFinalSquare(useProdSizes) // 256
		let stages = [finalStage]

		let count = 0
		let previousStage = finalStage
		let size = 256
		while(count < MAP_CONFIG[mapName].POISON_STAGES) {
			let newStage = calculateLargerSquare(previousStage.x, previousStage.y, size, mapName)
			stages.push(newStage)

			count++
			previousStage = newStage
			size = Math.min(size * 2, MAP_CONFIG[mapName].MAP_WIDTH, MAP_CONFIG[mapName].MAP_HEIGHT)
		}

		this.stages = stages
	}

	move(delta) {
		this.acc += delta

		const progress = this.acc / this.closeDuration
		// shrink
		this.currWidth = lerp(this.prevWidth, this.nextWidth, progress)
		this.currX = lerp(this.prevX, this.nextX, progress)
		this.currY = lerp(this.prevY, this.nextY, progress)

		if (this.currWidth <= this.nextWidth) {
			if (this.stages.length >= 1) {
				// advance to next stage
				this.acc = 0
				let next = this.stages.pop()
				// console.log('next', next)
				this.prevWidth = this.currWidth
				this.prevY = this.currY
				this.prevX = this.currX
				this.nextX = next.x
				this.nextY = next.y
				this.nextWidth = next.width
				this.closeDuration = (this.currWidth - this.nextWidth) / this.speed
				this.instance.messageAll(new PoisonShrinkingMessage())
			} else if (!this.isFinale) {
				// trigger finale
				this.acc = 0
				// console.log('triggering finale')
				this.isFinale = true
				this.prevWidth = this.currWidth
				this.prevY = this.currY
				this.prevX = this.currX
				this.nextWidth = 0
				this.nextX = this.currX + this.currWidth * 0.5
				this.nextY = this.currY + this.currWidth * 0.5
				this.closeDuration = (this.currWidth - this.nextWidth) / this.speed
				this.instance.messageAll(new PoisonShrinkingMessage())
			} else if (this.isFinale) {
				if (this.currWidth <= 0) {
					this.currWidth = 0
					this.currX = this.nextX
					this.currY = this.nextY
					// console.log('GG BRUH')
					// this.reset()
				}
			}
		}

		this.broadcast()
	}

	shrinkToNextStage() {
		const currentStagesLeft = this.stages.length
		if(currentStagesLeft == 0) {
			return
		}
		while(currentStagesLeft === this.stages.length) {
			this.move(1)
		}
	}

	checkAndHandleCollisions(players, now) {
		const deaths = []
		const mapName = this.overrideMapName ? this.overrideMapName : this.gameServer.mapName
		players.forEach(p => {
			if (!p.isGhost) {
				if (this.isTouching(p.x, p.y, GAME_CONSTANTS.PLAYER.COLLIDER_RADIUS)) {
					if (now - p.lastDamagedByPoison >= GAME_CONSTANTS.POISON_TICK_DURATION) {
						const result = p.takeDamage(MAP_CONFIG[mapName].POISON_DAMAGE)

						addPoisonDamage(p.client, GAME_CONSTANTS.POISON_DAMAGE)

						if (result.died) {
							const corpseForceVector = { fx: 0, fy: 0, force: 0 }
							deaths.push({ victim: p, corpseForceVector })
						}

						this.instance.addLocalMessage(new EntityHit(p.id, p.x, p.y))
						p.lastDamagedByPoison = now
					}
				}
			}
		})
		return deaths
	}

	update(delta, players) {
		if(this.isMoving) {
			if(this.startDelay > 0) {
				this.startDelay -= delta
			} else {
				this.move(delta)
			}
		}
		return this.checkAndHandleCollisions(players, Date.now())

	}

	broadcast() {
		if(this.instance) {
			// global update to all players
			this.instance.messageAll(
				new PoisonMessage(
					this.currX,
					this.currY,
					this.currWidth,
					this.nextX,
					this.nextY,
					this.nextWidth
				)
			)
		}
	}
}

export default Poison
