import nengi from "nengi"

// contains the roundStats metrics object, along with 3 more props
class RoundSummary {
	constructor(roundStats, diedToPoison, killerName, killerWeapon) {
		const report = Object.assign({}, roundStats)
		report.diedToPoison = diedToPoison
		report.killerName = killerName // undefined allowed
		report.killerWeapon = killerWeapon // undefined allowed
		this.json = JSON.stringify(report)
	}
}

RoundSummary.protocol = {
	json: nengi.String,
}

export default RoundSummary
