export default {
	name: "Medpack",
	programmaticName: "medpack",
	prop: "medpacks",
	canonicalName: "MEDPACK",
	itemClass: "medical",
	itemSubclass: "heal",
	image: "medpack.png",
	heldImage: "medical_hands.png",
	itemUnavailableText: "Health Already Full",
	health: 100,
	spawnWeighting: 150,
	carryLimit: 2,
	usageTime: 3.50,
	moveSlowDuration: 3.50,
	moveSlowModifier: 0.35
}
