import { Container, Sprite } from "pixi.js-legacy"

class Corpse extends Container {
	constructor(entity) {
		super()
		this.characterSprite = new Sprite.from(entity.skin + "_corpse.png")
		this.characterSprite.anchor.set(0.5, 0.5)
		this.characterSprite.scale.set(0.5, 0.5)
		this.addChild(this.characterSprite)

		this.id = entity.id
		this.x = entity.x
		this.y = entity.y
		this.skin = entity.skin
		this.rotation = 0 // Math.PI * 0.5
		this.pinned = entity.pinned
		this.hasAPinnedProjectile = false
			

		if (Math.random() > 0.5) {
			this.scale.x = -1
		}
		if(this.pinned) {
			this.rotation = Math.PI * 0.5 * -this.scale.x
		}
	}

	update() {}
}

export default Corpse
