import nengi from "nengi"

class WeaponFired {
	constructor(sourceId, x, y, targetX, targetY, weaponType, instantSpeed, victimId, fatalShot) {
		this.sourceId = sourceId
		this.x = x
		this.y = y
		this.targetX = targetX
		this.targetY = targetY
		this.weaponType = weaponType
		this.instantSpeed = instantSpeed
		this.victimId = victimId
		this.fatalShot = fatalShot
	}
}

WeaponFired.protocol = {
	sourceId: nengi.UInt16,
	x: nengi.UInt16,
	y: nengi.UInt16,
	targetX: nengi.UInt16,
	targetY: nengi.UInt16,
	weaponType: nengi.UInt8,
	instantSpeed: nengi.Boolean,
	victimId: nengi.UInt16,
	fatalShot: nengi.Boolean
}

export default WeaponFired
