let epsilon = 0.0000001

function within(a, b, c) {
	return a - epsilon <= b && b <= c + epsilon
}

// possible sources/evolution for this lineIntersection approach:
// https://gist.github.com/gordonwoodhull/50eb65d2f048789f9558
// http://stackoverflow.com/questions/563198/how-do-you-detect-where-two-line-segments-intersect/1968345#1968345
// "Ticks of the Wiundows Game Programming Gurus" by Andre LeMoth
// adapted again by alex // timetocode

// returns false if no intersection, otherwise returns intersection point
function lineIntersection(x1, y1, x2, y2, x3, y3, x4, y4) {
	let l1dot = x1 * y2 - y1 * x2
	let l2dot = x3 * y4 - y3 * x4
	let denom = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4)

	let x = (l1dot * (x3 - x4) - (x1 - x2) * l2dot) / denom
	let y = (l1dot * (y3 - y4) - (y1 - y2) * l2dot) / denom

	if (isNaN(x) || isNaN(y)) {
		return false
	} else {
		// non-intersections
		if (x1 >= x2) {
			if (!within(x2, x, x1)) {
				return false
			}
		} else {
			if (!within(x1, x, x2)) {
				return false
			}
		}

		if (y1 >= y2) {
			if (!within(y2, y, y1)) {
				return false
			}
		} else {
			if (!within(y1, y, y2)) {
				return false
			}
		}

		if (x3 >= x4) {
			if (!within(x4, x, x3)) {
				return false
			}
		} else {
			if (!within(x3, x, x4)) {
				return false
			}
		}

		if (y3 >= y4) {
			if (!within(y4, y, y3)) {
				return false
			}
		} else {
			if (!within(y3, y, y4)) {
				return false
			}
		}
	}

	return { x: x, y: y }
}

export default lineIntersection
