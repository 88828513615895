import nengi from "nengi"
import SAT from "sat"
import GAME_CONSTANTS from "../../common/balance/gameConstants"

class Grenade {
	constructor(data, x, y, vx, vy, force, ownerId) {
		const initialHeight = 0.6
		const initialVerticalSpeed = 0.2

		this.x = x
		this.y = y
		this.rotation = Math.random() * Math.PI * 2
		this.h = initialHeight
		this.ownerId = ownerId

		this.data = data
		this.vx = vx
		this.vy = vy
		this.momentum = force

		this.hSpeed = initialVerticalSpeed
		this.rotDir = Math.random() <= 0.5 ? 1 : -1

		this.fuse = data.fuseTimer
		this.fuseComplete = false

		this.landedOnGround = false

		this.collider = new SAT.Circle(
			new SAT.Vector(this.x, this.y),
			GAME_CONSTANTS.GRENADE_RADIUS
		)

		this.explosionRadius = this.data.explosionRadius
		this.image = this.data.thrownImage
		this.thrownEffect = this.data.thrownEffect
		this.impactExplosion = this.data.impactExplosion
	}

	update(delta) {
		const verticalAcceleration = -1.2
		const airRotationSpeed = 3
		const groundedRotationMod = 0.15

		if (this.fuse <= 0) return

		this.fuse -= delta
		if (this.fuse <= 0) {
			this.fuseComplete = true // boom
			return
		}

		if (this.h > 0) {
			// In the air
			this.h += this.hSpeed * delta
			this.hSpeed += verticalAcceleration * delta
			this.rotation += airRotationSpeed * this.rotDir * delta
			if (this.h <= 0) {
				this.h = 0
			}
		} else {
			// On the ground
			if (this.impactExplosion) {
				this.fuseComplete = true
				return
			}
			this.momentum *= Math.pow(this.data.rollMod, delta)
			this.rotation += this.momentum * groundedRotationMod * this.rotDir * delta
		}

		this.x += this.vx * this.momentum * delta
		this.y += this.vy * this.momentum * delta

		this.collider.pos.x = this.x
		this.collider.pos.y = this.y
	}
}

Grenade.protocol = {
	x: { type: nengi.UInt16, interp: true },
	y: { type: nengi.UInt16, interp: true },
	rotation: nengi.Float32,
	h: nengi.Float32,
	explosionRadius: nengi.Float32,
	image: nengi.String,
	thrownEffect: nengi.String,
	ownerId: nengi.UInt16
}

export default Grenade
