import ParticleAttribute from "./ParticleAttribute"



class RotationAttribute extends ParticleAttribute {
    constructor(attributeBlueprint) {
        super(attributeBlueprint)
        this.attributeBlueprint = attributeBlueprint
        this.rotationOverTime = this.attributeBlueprint.rotationOverTime
    }

    reset() {
        super.reset()
    }

    die(particle) {
        super.die()
    }

    particleSpawned(particle) {
        super.particleSpawned(this.particleSpawned)
        if(this.attributeBlueprint.randomRotation) {
            this.initialRotation = this.parseRandom(this.attributeBlueprint.randomRotation.minRot, this.attributeBlueprint.randomRotation.maxRot)
        }
        else if(this.attributeBlueprint.steppedRotation) {
            this.initialRotation = (this.attributeBlueprint.stepRot * particle.step) + this.attributeBlueprint.initialRot
        }
        else {
            this.initialRotation = this.attributeBlueprint.rotation ? this.attributeBlueprint.rotation : 0
        }
        particle.angle = this.initialRotation

        if(this.rotationOverTime) {
            if(this.attributeBlueprint.randomSpeed) 
                this.rotationSpeed = this.parseRandom(this.attributeBlueprint.randomSpeed.rotSpeedMin, this.attributeBlueprint.randomSpeed.rotSpeedMax)
            else
                this.rotationSpeed = this.attributeBlueprint.rotationSpeed
        }
    }

    apply(particle, delta) {
        super.apply(particle)
        if(this.rotationOverTime)
            particle.angle += delta * this.rotationSpeed
    }
}

export default RotationAttribute