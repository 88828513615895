import { Container, Sprite, Texture } from "pixi.js-legacy"

class GrenadeExplosion extends Container {
	constructor(x, y, explosionRadius) {
		super()
		this.x = x
		this.y = y
		this.isComplete = false
		this.animationFrame = 1
		this.accumulator = 0

		const explosionSpriteScaleFactor = 15 // just eyeballed this, will probably need to be changed if we get different explosion graphics

		this.impactSprite = new Sprite.from("explosion001.png")
		this.impactSprite.anchor.x = 0.5
		this.impactSprite.anchor.y = 0.5
		this.impactSprite.scale.x = explosionRadius / explosionSpriteScaleFactor
		this.impactSprite.scale.y = explosionRadius / explosionSpriteScaleFactor
		this.addChild(this.impactSprite)
	}

	update(delta) {
		this.accumulator += delta

		if (this.accumulator > 0.035) {
			this.animationFrame++
			if (this.animationFrame === 12) {
				this.animationFrame = 1
				this.isComplete = true
			} else {
				let frameStr =
					this.animationFrame >= 10
						? "explosion0" + this.animationFrame + ".png"
						: "explosion00" + this.animationFrame + ".png"

				this.impactSprite.texture = new Texture.from(frameStr)
			}
		}
	}
}

export default GrenadeExplosion
