import createWeaponItemFactory from "./createWeaponItemFactory"
import createCorpseFactory from "./createCorpseFactory"
import createProjectileFactory from "./createProjectileFactory"
import createGrenadeFactory from "./createGrenadeFactory"
import createSmokeCloudFactory from "./createSmokeCloudFactory"
import createPlayerHooks from "./createPlayerHooks"

export default ({ renderer, simulator, gameClient }) => {
	return {
		"WeaponItem": createWeaponItemFactory({ renderer, simulator }),
		"Corpse": createCorpseFactory({ renderer, simulator }),
		"Projectile": createProjectileFactory({ renderer, simulator }),
		"Grenade": createGrenadeFactory({ renderer, simulator }),
		"SmokeCloud": createSmokeCloudFactory({ renderer, simulator, gameClient }),
		"Player": createPlayerHooks({ renderer, simulator, gameClient })
	}
}
