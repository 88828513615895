export default {
	'tooManyPlayersInSpecifiedParty': 'There are already too many players on the server with your party ID.',
	'connectionLost': 'Connection lost. Either the server has gone down or you have lost internet connectivity.',
	'afkDisconnection' : 'You were disconnected due to being afk - reconnect to rejoin',
	'youAreBanned': 'You are banned.',
	'battlepassCTA': 'Unlock a Battle Pass from the store to earn progress as you play!',

	'watchAdCTAIntro': 'Watch',
	'watchAdCTAEnding': 'ads to unlock ',
	'watchAdCTANoReward': 'You can watch an ad to get some coins!',
	'adsWatchedLabel' : 'Ads watched',
	'watchAd' : 'Watch Ad',
	'watchAdExitWarning' : 'Watching an ad will exit the current game.  Do you wish to continue?',
	'yes': 'Yes',
	'no' : 'No',
	'adCountdown': 'Ad available in:',

	'killfeedSuicide0': "committed suicide",
	'killfeedSuicide1': "committed seppuku",
	'killfeedSuicide2': "committed sudoku",
	'killfeedSuicide3': "couldn't take it anymore",
	'killfeedSuicide4': "will not be taken alive",
	'killfeedSuicide5': "is never going back to prison",
	'killfeedSuicide6': "doesn't want to talk about it",
	'killfeedSuicide7': "pointed the gun the wrong way",
	'killfeedSuicide8': "is having one of those days",
	'killfeedSuicide9': "is their own worst enemy",
	'killfeedSuicide10': "threw the pin and dropped the grenade",
	'killfeedSuicide11': "dun goofed",
	'killfeedSuicide12': "didn't die, they quit",
	'killfeedSuicide13': "alt-f4'd",
	'killfeedSuicide14': "was the only worthy opponent for themselves",
	'killfeedSuicide15': "quit the hard way",
	'killfeedSuicide16': "has had enough of y'all",
	'killfeedSuicide17': "cancelled themselves",
	'killfeedSuicide18': "died doing what they love: damage",
	'killfeedSuicide19': "took a long walk off a short pier",
	'killfeedSuicide20': "won a Darwin Award",
	'killfeedSuicide21': "encountered a critical error and needed to shut down",
	'killfeedSuicide22': "is the weakest link, goodbye",
	'killfeedSuicide23': "meant to do that",
	'killfeedSuicide24': "isn't going to give you the pleasure",
	'killfeedSuicide25': "has been reading too much news",
	'killfeedSuicide26': "trusted no one, not even themselves",
	'killfeedSuicide27': "straight up yeeted themselves",
	'killfeedSuicide28': "attacked the cuccos",
	'killfeedSuicide29': "is confused! It hurt itself in its confusion!",

	'killfeedPoison0': "died to poison",
	'killfeedPoison1': "died to poison like a chump",
	'killfeedPoison2': "embarrassed themselves in the poison",
	'killfeedPoison3': "thought they could handle the poison",
	'killfeedPoison4': "was lost in the poison",
	'killfeedPoison5': "forgot their gas mask",
	'killfeedPoison6': "forgot to keep moving",
	'killfeedPoison7': "needs to work on their situational awareness",
	'killfeedPoison8': "and poison don't mix",
	'killfeedPoison9': "died to poison of all things, wow",
	'killfeedPoison10': "lost sight of themselves",
	'killfeedPoison11': "fought the poison and the poison won",
	'killfeedPoison12': "terminated with code 0x8BADF00D",
	'killfeedPoison13': "won't let Big Poison tell them what to do",
	'killfeedPoison14': "is fuming about their death",
	'killfeedPoison15': "is pretty choked up about this outcome",
	'killfeedPoison16': "should stop texting while playing",
	'killfeedPoison17': "had nothing but a bad time",
	'killfeedPoison18': "had an allergic reaction to... nope, just poison",
	'killfeedPoison19': "smelled their own fart",
	'killfeedPoison20': "got smoked out",
	'killfeedPoison21': "'s goggles did nothing",
	'killfeedPoison22': "wwwwwwwwwwwwwwwwwasasdsdsasdsssssssss",
	'killfeedPoison23': "rolled a 1 on their constitution save",
	'killfeedPoison24': "chose poorly",
	'killfeedPoison25': "became pretty toxic",
	'killfeedPoison26': "switched glasses when your back was turned! Ha ha, you fool! You fell victim to one of the classic blunders!",
	'killfeedPoison27': "forgot to buy antidotes",
	'killfeedPoison28': "was hit by toxic! It's super effective!",
	'killfeedPoison29': "let the toxicity get to them",

	'killfeedResurrected0': "was resurrected",
	'killfeedResurrected1': "was brought back",
	'killfeedResurrected2': "returns",
	'killfeedResurrected3': "rises again",
	'killfeedResurrected4': "just couldn't stay away",
	'killfeedResurrected5': "is literally too angry to die",
	'killfeedResurrected6': "can do this all day",
	'killfeedResurrected7': "was only faking",
	'killfeedResurrected8': "doesn't know when to quit",
	'killfeedResurrected9': "will defy the very laws of nature to win this computer game",
	'killfeedResurrected10': "was rebuilt - we have the technology",
	'killfeedResurrected11': "was not quite dead yet",
	'killfeedResurrected12': "was revived, turns out you CAN heal stupid",
	'killfeedResurrected13': "has been to hell and back, and back to hell - and back",
	'killfeedResurrected14': "has returned as a zombie (that's only half true)",
	'killfeedResurrected15': "never gave up, and never surrendered",
	'killfeedResurrected16': "is back, baby!",
	'killfeedResurrected17': "was just kidding, you guys",
	'killfeedResurrected18': "is back to kick ass and chew bubblegum",
	'killfeedResurrected19': "is back to chew ass and kick bubblegum",
	'killfeedResurrected20': "just tripped, they're fine, everyone's fine!",
	'killfeedResurrected21': "returns to finish the fight",
	'killfeedResurrected22': 'said to the god of death: "not today"',
	'killfeedResurrected23': "is back, bruhs never die!",
	'killfeedResurrected24': "heard the tragedy of Darth Plagueis the Wise",
	'killfeedResurrected25': "is giving you guys one more chance",
	'killfeedResurrected26': "has great health insurance, thanks Obama!",
	'killfeedResurrected27': "had a phoenix down",
	'killfeedResurrected28': "must have casual mode on",
	'killfeedResurrected29': "wakes up and smells the ashes",

	'killfeed2Spree': "scored a double kill!",
	'killfeed3Spree': "scored a triple kill!",

	'killStreak5': "is on a 5 streak!",
	'killStreak10': "is on a 10 streak!"
}
