import GAME_CONSTANTS from "../../common/balance/gameConstants"
import Utils from "../../common/classes/Utils"
import MuzzleFlair from "./MuzzleFlair"
import LaserSight from "./LaserSight"
import HealthBar from "../visuals/HealthBar"
import Weapon from "../../common/weapon/Weapon"
import store from "../ui/store"
import {
	Container,
	Text,
	Graphics,
	Sprite,
	Texture,
	BLEND_MODES
} from "pixi.js-legacy"

class Player extends Container {
	constructor(entity) {
		super()
		this.client = null
		this.isVerified = entity.isVerified || false
		this.healthBar = new HealthBar(this.isVerified)
		this.addChild(this.healthBar)
		this.id = entity.id
		this.x = entity.x
		this.y = entity.y
		this.prevX = this.x
		this.prevY = this.y
		this._hp = entity.hp
		this.hp = entity.hp
		this._shields = entity.shields
		this.shields = entity.shields
		this.aim = entity.aim
		this._isWinner = false
		this._isGhost = false
		this.isHealingOverTime = false
		this.isGhost = entity.isGhost
		this.isHealingOverTime = entity.isHealingOverTime
		this.canMove = true
		this.isMoving = false
		this.moveSlowModifier = 1.0
		this.moveSlowDuration = 0

		const teamIndicator = Sprite.from('playerarrow_icon.png')
		teamIndicator.anchor.set(0.5, 0.5)
		teamIndicator.y = -17
		teamIndicator.tint = 0x00ff00
		teamIndicator.zIndex = 10
		teamIndicator.visible = false

		this.addChild(teamIndicator)
		this.teamIndicator = teamIndicator

		this.equipmentSight = entity.equipmentSight
		this.equipmentChest = entity.equipmentChest
		this.equipmentScope = entity.equipmentScope
		this.equipmentHead = entity.equipmentHead

		this.skin = entity.skin
		this.weaponSkin = entity.weaponSkin
		this.facing = "right"
		this.lastFacing = "right"
		this.animationFrame = 0
		this.runAnimationFrame = 0
		this.idleAnimationFrame = 0
		this.accumulator = 0
		this.idleAccumulator = 0
		this.runAccumulator = 0
		this.id = entity.id
		this.currentlyEquipped = entity.weapon
		this.flairs = []

		this.effectsArr = []
		this.effects = new Container()
		this.addChild(this.effects)

		this.characterSprite = new Sprite()
		const characterSpriteSrc = `banananew_idle_0.png`
		this.characterSprite.texture = new Texture.from(characterSpriteSrc)
		this.characterSprite.anchor.set(0.5, 0.5)
		this.characterSprite.scale.set(0.5, 0.5)

		this.handSprite = new Sprite()
		this.handSprite.anchor.set(0.25, 0.5)

		this.weaponSprite = new Sprite()
		this.weaponSprite.anchor.set(0.25, 0.5)
		this.weaponSprite.scale.set(0.5, 0.5)

		this.chestSpriteName = ""
		this.chestSprite = new Sprite()
		this.chestSprite.anchor.set(0.5, 0.5)
		this.chestSprite.scale.set(0.5, 0.5)

		this.weaponSprite.addChild(this.handSprite)

		this.visibleProjectile = new Sprite()
		this.visibleProjectile.visible = false
		//this.visibleProjectile.x = 5
		//this.visibleProjectile.y = 5
		this.addChild(this.visibleProjectile)
		this.hasVisibleProjectile = false
		this.hasFiredVisibleProjectile = false
		

		//this.setWeaponSprite(this.currentlyEquipped)

		// let downScale = 1 / 6

		// let name = entity.name
		// this._name = name
		// this.nameText = new Text(this._name, {
		// 	fontFamily: "Russo One",
		// 	fontSize: 36,
		// 	fill: 0xffffff,
		// 	align: "center"
		// })

		// this.nameText.scale.set(downScale, downScale)
		// this.nameText.anchor.set(0.5, 1)
		// this.nameText.y = -12
		// this.nameText.x = -0.5
		// this.addChild(this.nameText)
		// if (store.getters.disableHud) {
		// 	this.nameText.alpha = 0
		// }
		// this.name = entity.name

		if (GAME_CONSTANTS.DEBUG.RENDER_HITBOXES) {
			let circleGraphics = new Graphics()
			circleGraphics.beginFill(0xff0000)
			circleGraphics.drawCircle(
				0,
				0,
				GAME_CONSTANTS.PLAYER.COLLIDER_RADIUS
			)
			circleGraphics.endFill()
			circleGraphics.alpha = 0.5
			this.addChild(circleGraphics)
			this.addChild(this.characterSprite)
			this.addChild(this.chestSprite)
			this.addChild(this.weaponSprite)
		} else {
			this.addChild(this.characterSprite)
			this.addChild(this.chestSprite)
			this.addChild(this.weaponSprite)
		}
	}

	// set name(value) {
	// 	this._name = value
	// 	if (this.nameText) {
	// 		// this.nameText.setText(value)
	// 	}
	// }

	showTeamIndicator() {
		this.teamIndicator.visible = true
	}

	handsToBackground() {
		this.removeChild(this.characterSprite)
		this.removeChild(this.weaponSprite)
		this.addChild(this.weaponSprite)
		this.addChild(this.characterSprite)
	}

	handsToForeground() {
		this.removeChild(this.weaponSprite)
		this.addChild(this.weaponSprite)
	}

	deleteHealthBar() {
		this.removeChild(this.healthBar)
		delete this.healthBar
	}

	// deleteName() {
	// 	this.removeChild(this.nameText)
	// 	delete this.nameText
	// }

	addMuzzleFlair(overwrittenLifetime = 0.075) {
		let muzzleFlairSprite = new MuzzleFlair(
			this.weaponSprite.rotation,
			this.weaponSprite,
			this,
			overwrittenLifetime
		)
		this.flairs.push(muzzleFlairSprite)
		this.weaponSprite.addChild(muzzleFlairSprite)
	}

	addLaserSight() {
		if (this.laserSightLine) {
			this.removeLaserSight()
		}
		this.laserSightLine = new LaserSight()
		this.weaponSprite.addChild(this.laserSightLine)
	}

	// Used for if we have a visible projectile, only called if current weapon has a visible projectile
	firedGun() {
		if(this.hasVisibleProjectile) {
			this.visibleProjectile.visible = false
		}
		this.hasFiredVisibleProjectile = true
			
	}

	// Used for if we have a visible projectile, only called if current weapon has a visible projectile
	readyToFire() {
		if(this.hasVisibleProjectile) {
			this.visibleProjectile.visible = true
		}
		this.hasFiredVisibleProjectile = false
			
	}

	updateLaserSight(length) {
		if (this.laserSightLine) {
			this.laserSightLine.update(this, length)
		}
	}

	removeLaserSight() {
		this.weaponSprite.removeChild(this.laserSightLine)
		this.laserSightLine = null
	}

	hasLaserSight() {
		return this.equipmentSight !== -1
	}

	setWeaponSprite(weaponType) {
		this.currentlyEquipped = weaponType

		const itemConfig = Weapon.byIndex(weaponType)
		let weaponSpriteSrc = ``
		if (itemConfig.itemClass === "medical") {
			// meds don't have skins
			// business opportunity for the aspiring junior
			weaponSpriteSrc = itemConfig.image
			this.weaponSprite.texture = new Texture.from(weaponSpriteSrc)
			this.weaponSprite.anchor.x = -0.20
			this.visibleProjectile.visible = false
			this.hasVisibleProjectile = false
		} else {
			if(itemConfig.itemSubclass === "heavy_weapon") 
				weaponSpriteSrc = `${itemConfig.programmaticName}_default.png`
			else
				weaponSpriteSrc = `${itemConfig.programmaticName}_${this.weaponSkin}.png`
			this.weaponSprite.texture = new Texture.from(weaponSpriteSrc)
			this.weaponSprite.anchor.set(0.25, 0.5)

			if(itemConfig.hasVisibleProjectile) {
				this.visibleProjectile.texture = new Texture.from(itemConfig.bulletImage)
				this.visibleProjectile.scale.set(this.facing == "left" ? -itemConfig.customBulletScale : itemConfig.customBulletScale, itemConfig.customBulletScale)
				this.visibleProjectile.anchor.set(itemConfig.visibleProjectileAnchor.x, itemConfig.visibleProjectileAnchor.y)
				// This will technically cause an issue when we have two guns with visible projectiles, will need a rework at that point
				this.visibleProjectile.visible = !this.hasFiredVisibleProjectile 
				this.hasVisibleProjectile = true
			}
			else {
				this.visibleProjectile.visible = false
				this.hasVisibleProjectile = false
			}
			
		}

		const handSpriteSrc = itemConfig.heldImage
		this.handSprite.texture = new Texture.from(handSpriteSrc)

		if (this.laserSightLine) {
			this.removeLaserSight()
			this.addLaserSight()
		}
	}

	setChestSprite(armorType) {
		this.chestSpriteName = Weapon.byIndex(armorType).equippedImageName
		this.chestSprite.texture = new Texture.from(
			this.chestSpriteName + "_idle_0.png"
		)
	}

	removeChestSprite() {
		this.chestSpriteName = ""
		if (this.chestSprite) {
			this.chestSprite.destroy()
		}
		this.chestSprite = new Sprite()
		this.chestSprite.anchor.set(0.5, 0.5)
		this.chestSprite.scale.set(0.5, 0.5)
		this.addChild(this.chestSprite)
		this.handsToForeground()
	}

	decorateAsWinner() {
		for (let i = 0; i < 10; i++) {
			const glowLayer = new Sprite.from("radial-fade.png")
			const scale = (1 + Math.random()) / 2.5
			glowLayer.scale.set(scale, scale)
			glowLayer.anchor.set(0.5, 0.5)
			glowLayer.tint = 0xffdf00
			if (Math.random() > 0.5) {
				glowLayer.blendMode = BLEND_MODES.ADD
			}
			glowLayer.alpha = 0.33
			glowLayer.rotation = Math.random() * Math.PI * 2
			glowLayer.rotationRate = Math.random() * 0.3
			this.effects.addChild(glowLayer)
			this.effectsArr.push(glowLayer)
		}
	}

	removeWinnerDecorations() {
		this.effectsArr.forEach(effect => {
			this.effects.removeChild(effect)
		})
	}

	/* NOTE: every setter could be moved to hooks, for now check both places */
	set hp(value) {
		this._hp = value
		if (this.healthBar) {
			this.healthBar.setHealth(value)
		}
	}

	set shields(value) {
		this._shields = value
		if (value <= 0 && this.chestSpriteName) {
			this.removeChestSprite()
		}
		if (this.healthBar) {
			this.healthBar.setShields(value)
		}
	}

	get shields() {
		return this._shields
	}

	set isGhost(value) {
		if (value) {
			this.alpha = 0.3
		} else {
			this.alpha = 1.0
		}
		this._isGhost = value
	}

	get isGhost() {
		return this._isGhost
	}

	set isWinner(value) {
		if (this._isWinner !== value) {
			if (value) {
				this.decorateAsWinner()
			} else {
				this.removeWinnerDecorations()
			}
			this._isWinner = value
		}
	}

	updateSprite(delta) {
		this.accumulator += delta

		if (this.canMove) {
			let easyAim = Utils.radToDeg(this.aim + Math.PI)

			let movingDirection = this.prevX < this.x ? "right" : "left"

			if (easyAim >= 90 && easyAim <= 270) {
				this.facing = "right"
			} else {
				this.facing = "left"
			}

			if (this.facing !== this.lastFacing) {
				this.characterSprite.scale.x *= -1
				this.weaponSprite.scale.x *= -1
				this.visibleProjectile.scale.x *= -1
				this.lastFacing = this.facing
			}

			if (this.facing === "left") {
				this.weaponSprite.rotation = this.aim + Math.PI // <= Y THO (ノಠ益ಠ)ノ彡┻━┻ // Scale ^
				this.visibleProjectile.rotation = this.aim + Math.PI
			} else {
				this.weaponSprite.rotation = this.aim
				this.visibleProjectile.rotation = this.aim
			}

			let isWalkingBackwards = movingDirection !== this.facing

			let spriteSuffix = null

			if (this.isMoving) {
				this.runAccumulator += delta
				if (this.runAccumulator > 0.1) {
					if (isWalkingBackwards) {
						this.runAnimationFrame--
					} else {
						this.runAnimationFrame++
					}
					if (this.runAnimationFrame > 7) {
						this.runAnimationFrame = 0
					}
					if (this.runAnimationFrame < 0) {
						this.runAnimationFrame = 7
					}
					spriteSuffix = "_move_" + this.runAnimationFrame + ".png"
					this.runAccumulator = 0
					this.idleAnimationFrame = 0
					this.idleAccumulator = 0
				}
			} else {
				// the next line causes the idle animation to appear instantly when movement is stopped
				// which causes the visual effect of putting the foot down quickly
				spriteSuffix = "_idle_" + this.idleAnimationFrame + ".png"

				this.idleAccumulator += delta
				if (this.idleAccumulator > 0.4) {
					this.idleAnimationFrame++
					if (this.idleAnimationFrame > 7) {
						this.idleAnimationFrame = 0
					}
					spriteSuffix = "_idle_" + this.idleAnimationFrame + ".png"
					this.idleAccumulator = 0
					this.runAnimationFrame = 0
					this.runAccumulator = 0
				}
			}

			if (spriteSuffix !== null) {
				this.characterSprite.texture = new Texture.from(
					this.skin + spriteSuffix
				)
				if (this.chestSpriteName) {
					this.chestSprite.texture = new Texture.from(
						this.chestSpriteName + spriteSuffix
					)
				}
			}
		}

		this.prevX = this.x
		this.prevY = this.y
	}

	sendPositionToMinimap() {
		store.commit("newPlayerPosition", { x: this.x, y: this.y })
	}

	update(delta) {
		this.updateSprite(delta)
		this.effectsArr.forEach(effect => {
			effect.rotation += effect.rotationRate
		})

		for (var i = this.flairs.length - 1; i > -1; i--) {
			var flair = this.flairs[i]
			flair.update(delta)
			if (flair.isComplete) {
				this.flairs.splice(i, 1)
				flair.destroy({ children: true })
				this.weaponSprite.removeChild(flair)
			}
		}
	}
}

export default Player
