export default {
	name: "Resurrection Grenade",
	programmaticName: "resGrenade",
	canonicalName: "GRENADE_ResGrenade",
	itemClass: "grenade",
	itemSubclass: "grenade",
	prop: "resGrenades",
	image: "resurrection-grenade.png",
	thrownImage: "resurrection-grenade.png",
	outlineImage: "ak47_outline.png",
	spawnWeighting: 50,
	onlyInParty: true,
	defaultsToInSolo: "fragGrenade",
	throwMod: 0.55,
	rollMod: 0.2,
	minThrowForce: 10,
	maxThrowForce: 150,
	damageNear: 0,
	damageFar: 0,
	explosionRadius: 60,
	fuseTimer: 5,
	corpseForce: 200,
	carryLimit: 1,
	createSmoke: false,
	gunCooldown: 0.4,
	impactExplosion: false
}
