import Weapon from '../../common/weapon/Weapon'
import allWeapons from '../../common/weapon/allWeapons'

class MissionState {
	constructor(missionList) {
		this.missions = missionList
		this.updatedMissions = {}
		this.missionEvents = []
		this.takedowns = []
		this.weaponClassesUsed = []
		this.weaponIdsUsed = []
		this.weaponStats = {}
		this.weaponSubclassStats = {}

		this.reset()
	}

	reset() {
		this.updatedMissions = {}
		this.missionEvents.length = 0
		this.takedowns.length = 0
		this.weaponClassesUsed.length = 0
		this.weaponIdsUsed.length = 0
		this.weaponStats.length = 0

		this.wins = 0

		this.killCount = 0
		this.assistCount = 0
		this.deathCount = 0

		this.damageDealt = 0
		this.damageTaken = 0
		this.damageHealed = 0
		this.circleDamageTaken = 0
		this.lastCircleStage = null

		this.shotsFired = 0
		this.timesReloaded = 0
		this.timesPerfectReloaded = 0

		this.hasUsedEquipment = false
		this.hasUsedMeds = false
		this.healingItemsUsed = 0
		this.hasUsedWeaponAttachments = false
		this.hasUsedShields = false
		this.hasUsedGrenades = false

		this.resurrections = 0

		this.itemsGathered = {}

		this.worldUnitsMoved = 0
		this._lastWorldUnitsMoved = 0

		allWeapons.forEach((weapon, index) => {
			let stats = {
				killCount: 0,
				assistCount: 0,

				damageDealt: 0,
				damageTakenWhileUsing: 0,

				shotsFired: 0,
				timesReloaded: 0,
				timesPerfectReloaded: 0
			}
			this.weaponStats[index] = Object.create(stats)
			this.weaponSubclassStats[weapon.itemSubclass] = Object.create(stats)
		})
	}
}

let CriteriaCheckEvents = {
	'KILL': 'KILL',
	'DEATH': 'DEATH',
	'GAME_END': 'GAME_END',
	'SHOT': 'SHOT',
	'DAMAGE': 'DAMAGE',
	'GATHER_ITEM': 'GATHER_ITEM',
	'USE_ITEM': 'USE_ITEM',
	'RESURRECT': 'RESURRECT'
}

Object.freeze(CriteriaCheckEvents)

class MissionQuestDefinition {
	constructor(options) {
		let {
			checkCriteriaAfter,
			hasProgress,
			gameMode,
			mustPlayGames,
			mustPlaceAtOrAbove,
			mustWinGame,
			mustScoreKills,
			mustDealDamage,
			mustHealHealth,
			//mustDie,
			mustUseWeaponClass,
			mustUseWeaponId,
			mustGatherItem,
			// killRange,
			// circleStage,
			// mustBeInRegion,
			cannotUseOtherWeapons,
			cannotUseEquipment,
			cannotUseMeds,
			cannotUseWeaponAttachments,
			cannotUseShields,
			cannotUseGrenades,
			mustResurrectTeammate,
		} = options

		this.checkCriteriaAfter = checkCriteriaAfter || []
		if(!Array.isArray(this.checkCriteriaAfter)) {
			this.checkCriteriaAfter = [this.checkCriteriaAfter]
		}
		this.hasProgress = hasProgress || null


		this.gameMode = gameMode || null //TODO
		this.mustPlayGames = mustPlayGames || null //TODO
		this.mustPlaceAtOrAbove = mustPlaceAtOrAbove || null
		this.mustWinGame = mustWinGame || null
		//this.mustDie = mustDie || null
		this.mustScoreKills = mustScoreKills || null
		this.mustDealDamage = mustDealDamage || null
		this.mustHealHealth = mustHealHealth || null
		this.mustUseWeaponClass = mustUseWeaponClass || null
		this.mustUseWeaponId = mustUseWeaponId || null
		this.mustResurrectTeammate = mustResurrectTeammate || null
		if(this.mustUseWeaponId !== null) {
			this.mustUseWeaponId = Weapon.byProgrammaticName(this.mustUseWeaponId).index
		}
		this.mustGatherItem = mustGatherItem || null
		// this.killRange = killRange || null //TODO
		// this.circleStage = circleStage || null //TODO
		// this.mustBeInRegion = mustBeInRegion || null //TODO
		this.cannotUseOtherWeapons = cannotUseOtherWeapons || null
		this.cannotUseEquipment = cannotUseEquipment || null
		this.cannotUseMeds = cannotUseMeds || null
		this.cannotUseWeaponAttachments = cannotUseWeaponAttachments || null
		this.cannotUseShields = cannotUseShields || null
		this.cannotUseGrenades = cannotUseGrenades || null

		this.criteriaToSatisfy = 0
		this.criteriaList = []
		let ignoredKeys = ['criteriaToSatisfy', 'criteriaList', 'checkCriteriaAfter', 'hasProgress']
		Object.keys(this).forEach((key) => {
			if(this[key] !== null && !ignoredKeys.includes(key)) {
				// console.log(`added criteria for ${key}`)
				this.criteriaToSatisfy++
				this.criteriaList.push(key)
			}
		})
	}
}

class MissionQuest {
	constructor(missionQuestDefinition) {
		this.userDailyMissionId = null
		this.userWeeklyMissionId = null
		this.missionTimespan = null
		this.description = ""
		this.isComplete = false
		this.progressCurrent = 0
		this.progressMax = 1
		this.missionQuestDefinition = missionQuestDefinition
		this.reward = 100
	}
}

class MissionTakedown {
	constructor(options) {
		let { kill, killingWeapon, killingWeaponSubclass, killDistance, killerName, victimName } = options
		this.kill = kill || false
		// this.assist = false
		this.killingWeapon = killingWeapon
		this.killingWeaponSubclass = killingWeaponSubclass || null
		this.killDistance = killDistance || null
		this.killerName = killerName || "nobody"
		this.victimName = victimName || "nobody"
	}
}

export { MissionQuestDefinition, MissionQuest, MissionState, MissionTakedown, CriteriaCheckEvents }
