<template>
	<div class="chat" v-if="chatShowing">
		<div class="inner-border" @mouseover="onMouseover()" @mouseleave="onMouseleave()">
			<div class="messages" id="scroller">
				<div class="message" v-for="message in chatMessages" :key="message.index">
					<div v-if="message.sender">
						<span class="textUser" v-html="message.sender"></span>:
						<span class="textMessage" v-html="message.message"></span>
					</div>
					<div v-else>
						<span class="textServer" v-html="message.msg"></span>
					</div>
				</div>
			</div>
			<div class="prompt">
				<input id="txt1" type="text" class="chatInput" maxlength="50" placeholder="Press ENTER to chat..." v-on:keydown.enter="send" @focus="onInputFocus()" @blur="onInputUnfocus()"/>
				<button class="chatSubmit" v-on:click="send">SEND</button>
			</div>
		</div>
	</div>
	<div class="hidden" v-else>
		[T] toggle chat
	</div>
</template>

<script>
import { mapGetters } from "vuex";

const ENTER_KEYCODE = 13

export default {
	name: "app",
	computed: {
		...mapGetters(["gameClient", "chatMessages", "chatShowing"])
	},
	created: function() {
		window.addEventListener('keydown', this.onKeydown)
	},
	beforeDestroy: function() {
		window.removeEventListener('keydown', this.onKeydown)
	},
	methods: {
		send: function(event) {
			event.preventDefault()
			event.stopPropagation()
			var t = document.getElementById("txt1")

			if (t.value !== "") {
				this.gameClient.chatCommand(t.value)

				t.value = ""
			}

			t.blur()
			// var tmp = document.createElement("input");
			// document.body.appendChild(tmp);
			// tmp.focus();
			// document.body.removeChild(tmp);
		},
		onInputFocus: function() {
			window.chatFocused = true
		},
		onInputUnfocus: function() {
			window.chatFocused = false
		},
		onMouseover: function() {
			window.mouseOverUI = true
		},
		onMouseleave: function() {
			window.mouseOverUI = false
		},
		onKeydown: function(event) {
			if(event.which == ENTER_KEYCODE) {
				if (!this.chatShowing) {
					this.$store.commit('toggleChat')
				}

				setTimeout(function(){
					// only allow us to enter chat if we have no other focus, so as not to disrupt other inputs/etc.
					if(document.activeElement.tagName == 'BODY') {
						var t = document.getElementById("txt1")
						if(t) {
							t.focus()
						}
					}
				}, 100)
			}
		}
	},
	updated: function(event) {
		var s = document.getElementById("scroller")
		if (s) {
			s.scroll({
				top: s.scrollHeight,
				behavior: "smooth"
			})
		}
	}
};
</script>

<style lang="scss" scoped>
.chat {
	position: fixed;
	left: 16px;
	bottom: 4px;
	width: 380px;
	height: 220px;
	color: rgb(233, 233, 233);
	background-color: rgba(26, 26, 26, 0.8);
	border: solid 2px #7d8085;
	cursor: text;
	pointer-events: all;
	user-select: text;

	.inner-border {
		position: relative;
		width: 100%;
		height: 100%;
		border: solid 2px rgb(17, 17, 17);
	}

	.messages {
		position: absolute;
		left: 0px;
		bottom: 35px;
		width: 374px;
		height: 179px;
		color: rgb(233, 233, 233);
		overflow-x: hidden;
		overflow-y: auto;
	}

	.chatInput {
		border: white 1px solid;
		position: absolute;
		bottom: 0px;
		color: rgb(233, 233, 233);
		background-color: rgba(26, 26, 26, 0.8);
		border: 0px;
		padding: 8px;
		width: 310px;
		font-family: "Avenir", Helvetica, Arial, sans-serif;
	}

	.chatSubmit {
		position: absolute;
		color: white;
		border: none;
		background-color: rgb(151, 150, 150);
		bottom: 2px;
		right: 2px;
		padding: 6px;
	}
}
.textUser {
	color: cyan;
}
.textServer {
	color: yellow;
}
.textMessage {
	color: white;
	font-family: "Avenir", Helvetica, Arial, sans-serif;
}
.hidden {
	position: fixed;
	left: 16px;
	bottom: 16px;
	color: rgb(233, 233, 233);
}
</style>
