export default {
	name: "Kevlar Vest",
	programmaticName: "kevlar",
	canonicalName: "KEVLAR",
	itemClass: "kevlar",
	itemSubclass: "shield",
	image: "kevlar_drop.png",
	equippedImageName: "kevlar",
	spawnWeighting: 250,
	equipSlot: "equipmentChest",
	shields: 15,
	maxShields: 50,
	carryLimit: 5
}
