import Grid from "../../classes/Grid"
import Wall from "../Wall"

// 12033, 12034, 12035, 12036, 12037

let toWallType = type => {
	if (type === 12049) {
		// console.log('left')
		return Wall.LEFT
	} else if (type === 12050) {
		// console.log('right')
		return Wall.RIGHT
	} else if (type === 12051) {
		// console.log('top')
		return Wall.TOP
	} else if (type === 12052) {
		// console.log('bottom')
		return Wall.BOTTOM
	} else if (type === 12053) {
		// console.log('full')
		return Wall.FULL
	} else if (type === 12033) {
		// console.log('left')
		return Wall.LEFT_PIERCE
	} else if (type === 12034) {
		// console.log('right')
		return Wall.RIGHT_PIERCE
	} else if (type === 12035) {
		// console.log('top')
		return Wall.TOP_PIERCE
	} else if (type === 12036) {
		// console.log('bottom')
		return Wall.BOTTOM_PIERCE
	} else if (type === 12037) {
		// console.log('full')
		return Wall.FULL_PIERCE
	} else {
		return Wall.NONE
	}
}

// hardcoded to read the 'Collisions' layer out of a map and produce a grid of true/false
// where true means there is a wall and false means there is empty space
function convertMap(tiledMap) {
	let collisionLayer = tiledMap.layers.find(layer => {
		return layer.name === "Walls"
	})

	let collisionGrid = new Grid(collisionLayer.width, collisionLayer.height)

	for (let i = 0; i < collisionLayer.data.length; i++) {
		// presuming that any tile value other than 0 means blocked
		collisionGrid.cells[i] = toWallType(collisionLayer.data[i])
	}

	return collisionGrid
}

export default convertMap
