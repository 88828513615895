import nengi from 'nengi'

class AABBDebug {
	// <aabb> { x, y, halfWidth, halfHeight }
	constructor(aabb) {
		Object.assign(this, aabb)
	}
}

AABBDebug.protocol = {
	x: nengi.Float32,
	y: nengi.Float32,
	halfWidth: nengi.Float32,
	halfHeight: nengi.Float32
}

export default AABBDebug
