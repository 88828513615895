import nengi from "nengi"

class AliveCount {
	constructor(aliveCount) {
		this.aliveCount = aliveCount
	}
}

AliveCount.protocol = {
	aliveCount: nengi.Number
}

export default AliveCount
