<template>
	<div>
		<div class="killfeed" v-for="(kill, index) in killFeed" :key="index">
			<div v-if="(time - kill.time) < 10000">
				<div v-if="kill.resurrected" class="res" :style="{'left':getLerp(time - kill.time)}">
					<div>
						<span style='color:rgb(255, 255, 128)'>{{kill.killedName}}</span>
						{{kill.text}}
					</div>
				</div>
				<div v-else-if="kill.suicide" class="killNoImage" :style="{'left':getLerp(time - kill.time)}">
					<div>
						<span style='color:rgb(255, 128, 128)'>{{kill.killedName}}</span>
						{{kill.text}}
					</div>
				</div>
				<div v-else-if="kill.killSpree || kill.kills" class="killNoImage" :style="{'left':getLerp(time - kill.time)}">
					<div>
						<span style='color:rgb(255, 255, 255)'>{{kill.name}}</span>
						{{kill.text}}
					</div>
				</div>
				<div v-else-if="kill.killerName" class="kill" :style="{'left':getLerp(time - kill.time)}">
					<div>{{kill.killerName}}</div>
					<img class="outline" :style="{
						width: `${outlineImageData(kill.image).width}px`,w
					}" :src="outlineImageData(kill.image).imgSrc" />
					<div style='color:rgb(255, 128, 128)'>{{kill.killedName}}</div>
				</div>
				<div v-else class="killNoImage" :style="{'left':getLerp(time - kill.time)}">
					<div>
						<span style='color:rgb(255, 128, 128)'>{{kill.name}}</span>
						{{kill.text}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import parcelImageAdapter from "./parcelImageAdapterOutlines";

export default {
	name: "killFeed",
	computed: {
		...mapGetters(["killFeed"])
	},
	data () {
		return {
			time: null
		}
	},
	created () {
		this.getTime()
		setInterval(this.getTime, 10)
	},
	destroyed () {
		clearInterval(this.getTime)
	},
	methods: {
		getTime: function () {
			this.time = (new Date()).getTime()
		},
		getLerp: function (time) {
			return Math.min(-200 + (time / 2), 0) + 'px'
		},
		outlineImageData: function (programmaticName) {
			return parcelImageAdapter(programmaticName)
		}
	}
};
</script>

<style lang="scss" scoped>
.killfeed {
	position: relative;
}
.kill {
	position: relative;
	font-size: 14px;
	line-height: 24px;
	display: inline-flex;
	color: white;
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8) , rgba(0, 0, 0, 0.2));
	padding-left: 6px;
	padding-right: 6px;
	margin-top: 3px;
}
.killNoImage {
	position: relative;
	font-size: 14px;
	display: inline-flex;
	color: white;
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8) , rgba(0, 0, 0, 0.2));
	padding-left: 6px;
	padding-right: 6px;
	margin-top: 3px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.res {
	position: relative;
	font-size: 14px;
	display: inline-flex;
	color: white;
	background-image: linear-gradient(to right, rgba(136, 116, 0, 0.8) , rgba(0, 0, 0, 0.2));
	padding-left: 6px;
	padding-right: 6px;
	margin-top: 3px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.outline {
	position: relative;
	height: 24px;
	//top: -4px;
	object-fit: none;
	image-rendering: pixelated;
	image-rendering: -moz-crisp-edges;
	image-rendering: crisp-edges;
	pointer-events: none;
	overflow: visible;
}
</style>
