export default {
	name: "Red Dot Sight",
	programmaticName: "reddot",
	canonicalName: "REDDOT",
	itemClass: "sight",
	itemSubclass: "sight",
	image: "laser_pickup.png",
	spawnWeighting: 100,
	equipSlot: "equipmentSight"
}
