export default {
	name: "Grenade Launcher",
	programmaticName: "grenadeLauncher",
	canonicalName: "HV_GRENADELAUNCHER",
	itemClass: "weapon",
	itemSubclass: "heavy_weapon",
	image: "grenadeLauncher_default.png",
	heldImage: "grenadeLauncher_hands.png",
	outlineImage: "grenadeLauncher_outline.png",
	soundName: "HV_GRENADELAUNCHER",
	reloadSoundName: "HV_GRENADELAUNCHER_reloading",
	spawnWeighting: 10,
	grenadeProjectiles: true,
	grenadeProjectile: "grenadeLauncherGrenade",
	grenadeDistanceVariance: 0.1,
	grenadeAngleVariance: 0.3,
	cooldown: 0.5,
	escalatingCooldown: false,
	clipSize: 10,
	kick: 200,
	moveSlowModifier: 0.7,
	moveSlowDuration: 0.5,
	barrelLength: 25,
	laserSightColor: "0xff0000",
	reloadable: false,
	bulletTravelDistance: 35,
	instantSpeedProjectile: false,
	uiRotateDeg: -37,
	uiWidth: 90,
	uiTop: 43,
	uiLeft: 51
}
