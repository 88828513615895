import { Container, Sprite, Texture, PI_2 } from "pixi.js-legacy"

class SmokePuff extends Container {
	constructor(x, y, image, duration, size, rotationSpeed, maxAlpha, fadeTime, prng) {
		super()
		this.x = x
		this.y = y
		this.isComplete = false
		this.accumulator = 0
		this.duration = duration
		this.rotationSpeed = rotationSpeed
		this.maxAlpha = maxAlpha
		this.fadeTime = fadeTime
		this.prng = prng

		this.rotation = this.prng.nextFloat() * PI_2

		this.sprite = new Sprite.from(image)
		this.sprite.anchor.x = 0.5
		this.sprite.anchor.y = 0.5
		this.sprite.scale.x = size
		this.sprite.scale.y = size
		this.sprite.alpha = 0
		this.addChild(this.sprite)
	}

	update(delta) {
		this.accumulator += delta
		this.rotation += this.rotationSpeed * delta

		if (this.accumulator <= this.duration * this.fadeTime) {
			this.sprite.alpha = (this.accumulator / (this.duration * this.fadeTime)) * this.maxAlpha
		} else if (this.accumulator >= this.duration * (1 - this.fadeTime)) {
			this.sprite.alpha = ((this.duration - this.accumulator) / (this.duration * this.fadeTime)) * this.maxAlpha
		} else {
			this.sprite.alpha = this.maxAlpha
		}

		if (this.accumulator > this.duration) {
			this.isComplete = true
		}
	}
}

export default SmokePuff
