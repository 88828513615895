import nengi from "nengi"
import SAT from "sat"
import CharacterAffect from "../../common/classes/CharacterAffect"
import GAME_CONSTANTS from "../../common/balance/gameConstants"
import { addDistance } from "../core/metrics"
import WeaponSystem from '../../common/weapon/WeaponSystem'

class Player {
	constructor(name, skin, weaponSkin) {

		// mutated and synced by weaponsystem, slot1 & slot2 must be
		// declared before the weaponSystem
		this.slot1 = null
		this.slot2 = null
		this.weaponSystem = new WeaponSystem(this)

		this.client = null
		this.name = name
		this.skin = skin
		this.weaponSkin = weaponSkin
		this.lastDamagedByPoison = Date.now()
		this._x = 0
		this._y = 0

		// a position reached via client input and client delta
		this.allegedX = 0
		this.allegedY = 0

		this.canMove = true
		this.cannotMoveTimeout = 0
		this.isMoving = false
		this.zoomLevel = GAME_CONSTANTS.ZOOM_LEVEL_DEFAULT
		this.hp = 100
		this.maxHp = 100
		this.hpHealingOverTime = 0
		this.shields = 0
		this.maxShields = 100
		this.damageReduction = 0
		this.moveSlowModifier = 1.0
		this.moveSlowDuration = 0

		this.equipmentChest = -1
		this.equipmentHead = -1
		this.equipmentScope = -1
		this.equipmentSight = -1

		this.affects = []
		this.movementSpeed = GAME_CONSTANTS.PLAYER.MOVEMENT_SPEED_DEFAULT
		this.aim = 0
		this.isGhost = false
		this.isWinner = false
		this.painkillers = 0
		this.medpacks = 0
		this.bandages = 0
		this.isHealingOverTime = false
		this.fragGrenades = 0
		this.smokeGrenades = 0
		this.resGrenades = 0
		this.molotovGrenades = 0
		this.isPlayer = true
		this._weapon = null

		this.rapidKills = 0
		this.spreeTimer = 0



		// collider, a circle shape slightly smaller than a grid tile
		this.collider = new SAT.Circle(
			new SAT.Vector(this.x, this.y),
			GAME_CONSTANTS.PLAYER.COLLIDER_RADIUS
		)

		this.allegedCollider = new SAT.Circle(
			new SAT.Vector(this.x, this.y),
			GAME_CONSTANTS.PLAYER.COLLIDER_RADIUS
		)

		this.allegedPositions = []

		this.velX = 0
		this.velY = 0
	}


	get x() {
		return this._x
	}
	set x(value) {
		this._x = value
		this.collider.pos.x = value
	}

	get y() {
		return this._y
	}
	set y(value) {
		this._y = value
		this.collider.pos.y = value
	}

	get activeSlot() {
		return this.weaponSystem.activeSlot
	}

	set activeSlot(value) {
		this.weaponSystem.activeSlot = value
	}
	// confusion: weapon vs activeSlot + slot1 or slot2
	get weapon() {
		return this._weapon
	}

	set weapon(value) {
		this._weapon = value
	}

	get slot1Ammo() {
		return this.weaponSystem.slot1.currentAmmo
	}

	set slot1Ammo(value) {
		this.weaponSystem.slot1.currentAmmo = value
	}

	get slot2Ammo() {
		return this.weaponSystem.slot2.currentAmmo
	}

	set slot2Ammo(value) {
		this.weaponSystem.slot2.currentAmmo = value
	}

	reset() {
		this.hp = 100
		this.shields = 0
		this.isGhost = false
		this.zoomLevel = 3
		this.canMove = true
		this.clearInventory()
	}

	resurrect() {
		this.reset()
		this.hp = 25
	}

	clearInventory() {
		this.medpacks = 0
		this.painkillers = 0
		this.bandages = 0
		this.fragGrenades = 0
		this.smokeGrenades = 0
		this.resGrenades = 0
		this.molotovGrenades = 0

		this.equipmentChest = -1
		this.equipmentHead = -1
		this.equipmentSight = -1
		this.equipmentScope = -1
	}

	clearAffects() {
		for (let i = 0; i < this.affects.length; i++) {
			this.affects.pop()
		}

		this.hpHealingOverTime = 0
		this.isHealingOverTime = false

		this.affected = false
	}

	takeDamage(amount) {
		const ret = { hpDamageTaken: 0, shieldDamageTaken: 0, died: false }
		amount = amount * (100 - this.damageReduction) / 100 // TODO: ensure float math is fine
		/* console.log(
            'dealing ' +
                amount +
                ' damage to player with ' +
                this.hp +
                ' hp, ' +
                this.shields +
                ' shields'
        ) */
		if (this.shields > 0) {
			this.shields -= amount
			ret.shieldDamageTaken += amount
			// console.log('shields', this.shields)
			if (this.shields <= 0) {
				this.hp -= -this.shields
				ret.hpDamageTaken += -this.shields
				ret.shieldDamageTaken += this.shields
				this.shields = 0
				this.unequip("equipmentChest")
			}
		} else {
			this.hp -= amount
			ret.hpDamageTaken += amount
		}
		// console.log('hp', this.hp)
		// this.dropLoot() // PINATA MODE
		if (this.hp <= 0) {
			this.hp = 0
			this.die()
			ret.died = true
		}

		return ret
	}

	heal(amount) {
		const newHp = Math.min(this.hp + amount, this.maxHp)
		const amountHealed = newHp - this.hp
		this.hp = newHp
		return amountHealed
	}

	healOverTime(amount, durationMs, tag = "", healMetricCallback) {
		const tickAmount = amount / durationMs * 1000
		this.hpHealingOverTime += amount
		this.isHealingOverTime = true
		const self = this
		let affect = new CharacterAffect({
			affectType: "healOverTime",
			durationMs: durationMs,
			tag: tag,
			targetArray: this.affects,
			tickFn: () => {
				self.hpHealingOverTime -= tickAmount
				/*
				console.log('heal tick', {
					hpHealingOverTime1: self.hpHealingOverTime + tickAmount,
					hpHealingOverTime2: self.hpHealingOverTime,
					tickAmount
				})
				*/
				const amountHealed = self.heal(tickAmount)
				if (healMetricCallback) {
					healMetricCallback(amountHealed)
				}
			},
			affectOffFn: () => {
				/*
				console.log('affectOffFn', {
					affects: self.affects,
					affect,
				})
				*/
				self.affects.splice(self.affects.indexOf(affect), 1)
				self.setHealOverTime()
			}
		})
		self.affects.push(affect)
	}

	setHealOverTime() {
		let isHealingOverTime = false
		this.affects.forEach(affect => {
			// console.log('affect', affect)
			if (affect.affectType === "healOverTime") {
				// console.log('healing true')
				isHealingOverTime = true
			}
		})
		// console.log('set healing to ', isHealingOverTime)
		// console.log('affects', this.affects)
		this.isHealingOverTime = isHealingOverTime
	}

	isAffectedByTag(tag) {
		var affected = false
		this.affects.forEach(affect => {
			if (affect.tag === tag) {
				affected = true
			}
		})
		return affected
	}

	addShields(amount, itemMax) {
		if (this.shields >= itemMax) {
			return true
		}
		this.shields = Math.min(this.shields + amount, this.maxShields, itemMax)
	}

	hasAffect(affectType) {
		for (let affect in this.affects) {
			if (affect.affectType == affectType) {
				return affect
			}
		}
	}

	die() {
		this.isGhost = true
		this.canMove = false
		this.clearAffects()

		//console.log('set player canMove to ', this.canMove)
		let affect = new CharacterAffect({
			affectType: "cannotMove",
			durationMs: 4000,
			targetArray: this.affects,
			affectOffFn: () => {
				this.canMove = true
				this.affects.splice(this.affects.indexOf(this), 1)
				//console.log('wearoff set player canMove to ', this.canMove)
			}
		})
		this.affects.push(affect)
		this.toBeDeleted = true
	}

	equip(item) {
		if (!item.equipSlot) {
			return false
		}

		if (this[item.equipSlot] !== -1) {
			this.unequip(item.equipSlot)
		}

		this[item.equipSlot] = item.index
		//TODO: mission
		return item.equipSlot
	}

	unequip(equipSlot) {
		if (!equipSlot) {
			// console.error("Tried to unequip without an equip slot")
			return false
		}

		this[equipSlot] = null
		return equipSlot
	}

	move(command, delta) {
		let x = 0
		let y = 0
		// moving faster if ghost
		const factor = (this.isGhost) ? GAME_CONSTANTS.PLAYER.MOVEMENT_SPEED_GHOST_FACTOR : 1
		let speed = this.movementSpeed * factor

		this.aim = command.aim

		if (this.moveSlowDuration > 0) {
			speed *= this.moveSlowModifier
		}

		if (this.canMove) {
			if (command.w || command.a || command.s || command.d) {
				this.isMoving = true
			} else {
				this.isMoving = false
			}

			if (command.a) { x -= 1 }
			if (command.d) { x += 1 }
			if (command.s) { y += 1 }
			if (command.w) { y -= 1 }

			// vector math to make sure that diagonal movement isn't faster
			const len = Math.sqrt(x * x + y * y)
			if (len > 0) {
				this.velX = x / len
				this.velY = y / len
			}

			const moveX = this.velX * speed * delta
			const moveY = this.velY * speed * delta

			this.allegedX += moveX
			this.allegedY += moveY

			this.velX = 0
			this.velY = 0
		}

		this.allegedCollider.pos.x = this.allegedX
		this.allegedCollider.pos.y = this.allegedY
	}

	// only to be invoked after allegedX, allegedY have been adjust by collision logic
	postAllegedMove() {
		this.allegedPositions.push({ x: this.allegedX, y: this.allegedY })
	}

	setPosition(x, y) {
		this.x = x
		this.y = y
		this.allegedX = x
		this.allegedY = y
		this.collider.pos.x = this.x
		this.collider.pos.y = this.y
	}

	calculateDistanceToAllegedPosition() {
		let dist = 0
		let currX = this.x
		let currY = this.y
		for (var i = this.allegedPositions.length - 1; i >= 0; i--) {
			let next = this.allegedPositions[i]
			let dx = next.x - currX
			let dy = next.y - currY
			let len = Math.sqrt(dx * dx + dy * dy)
			dist += len
			currX = next.x
			currY = next.y
		}
		return dist
	}

	addRapidKill() {
		this.rapidKills++
		this.spreeTimer = GAME_CONSTANTS.SPREE_WINDOW
	}

	update(delta) {
		let allowableDesyncDistance = 100
		let totalDist = this.calculateDistanceToAllegedPosition()

		// this allows x,y to catch up to allegedX,Y at varying speeds
		// in an effort to look natural without looking too much like
		// there is a sprint ability
		let modifier = 1.0
		if (totalDist > 20) {
			modifier = 1.2
		} else if (totalDist > 5) {
			modifier = 1.05
		}

		let speed = this.movementSpeed

		if (this.moveSlowDuration > 0) {
			speed *= this.moveSlowModifier
			this.moveSlowDuration -= delta
		}

		if (this.isGhost) {
			speed *= GAME_CONSTANTS.PLAYER.MOVEMENT_SPEED_GHOST_FACTOR
			allowableDesyncDistance *= GAME_CONSTANTS.PLAYER.MOVEMENT_SPEED_GHOST_FACTOR
		}

		let dist = speed * delta * modifier

		if (totalDist > allowableDesyncDistance) {
			// console.log('server rubberbanded a player')
			this.allegedPositions = []
			this.allegedX = this.x
			this.allegedY = this.y
		}

		// while the entity still has movement, and isn't caught up to alleged
		while (dist > 0 && this.allegedPositions.length > 0) {
			// head towards the next node
			let next = this.allegedPositions.shift()
			let dx = next.x - this.x
			let dy = next.y - this.y

			let len = Math.sqrt(dx * dx + dy * dy)
			// console.log('d', dist, 'l', len)
			if (len > dist) {
				// case: the remaining distance to the next node is farther than we can move in one step
				// result: move as far as we can towards it
				let ratio = dist / len
				addDistance(this.client, dist)
				this.x += dx * ratio
				this.y += dy * ratio
				dist = 0
			} else if (len <= dist) {
				// case: the remaining distance can be covered
				// result: move all the way to it, and subtract distance traveled from the distance remaining
				// console.log('reaching next node')
				this.x = next.x
				this.y = next.y
				dist -= len
				addDistance(this.client, len)
			}
		}
		// move its collider (used for collisions against the map geometry)
		this.collider.pos.x = this.x
		this.collider.pos.y = this.y

		if (this.affects.length) {
			this.affects.forEach((affect) => {
				if (!affect.tick(delta)) {
					//TODO: dead code..?
					//console.log('splice', this.affects.splice(index, 1))
				}
			})
		}

		// activates/extends the countdown window for timed kills after getting a kill
		if (this.rapidKills > 0) {
			this.spreeTimer -= delta
			if (this.spreeTimer <= 0) {
				this.rapidKills = 0
				this.spreeTimer = GAME_CONSTANTS.SPREE_WINDOW
			}
		}
	}

	predictorUpdate(delta) {
		if (this.moveSlowDuration > 0) {
			this.moveSlowDuration -= delta
		}
	}
}

Player.protocol = {
	x: { type: nengi.Float64, interp: true },
	y: { type: nengi.Float64, interp: true },
	allegedX: nengi.Float64,
	allegedY: nengi.Float64,
	movementSpeed: nengi.UInt8,
	hp: nengi.UInt8,
	maxHp: nengi.UInt8,
	hpHealingOverTime: nengi.UInt8,
	shields: nengi.UInt8,
	maxShields: nengi.UInt8,
	name: nengi.String,
	skin: nengi.String,
	weaponSkin: nengi.String,
	canMove: nengi.Boolean,
	isMoving: nengi.Boolean,
	isGhost: nengi.Boolean,
	isWinner: nengi.Boolean,
	isHealingOverTime: nengi.Boolean,
	medpacks: nengi.UInt8,
	painkillers: nengi.UInt8,
	bandages: nengi.UInt8,
	fragGrenades: nengi.UInt8,
	smokeGrenades: nengi.UInt8,
	resGrenades: nengi.UInt8,
	molotovGrenades: nengi.UInt8,
	weapon: nengi.UInt8,
	activeSlot: nengi.String,
	slot1: nengi.UInt8,
	slot2: nengi.UInt8,
	slot1Ammo: nengi.UInt8,
	slot2Ammo: nengi.UInt8,
	aim: nengi.Float64,
	isVerified: nengi.Boolean,
	zoomLevel: nengi.Number,
	equipmentChest: nengi.Int8,
	equipmentHead: nengi.Int8,
	equipmentScope: nengi.Int8,
	equipmentSight: nengi.Int8
}

export default Player
