import nengi from "nengi"
import SAT from "sat"
import GAME_CONSTANTS from "../../common/balance/gameConstants"

class SmokeCloud {
	constructor(data, x, y, ownerId) {
		this.x = x
		this.y = y
		this.ownerId = ownerId

		this.data = data

		this.radius = data.explosionRadius
		this.expansionDuration = data.expansionDuration
		this.puffDuration = data.puffDuration
		this.puffDurationRNGMod = data.puffDurationRNGMod
		this.puffFrequency = data.puffFrequency
		this.puffSize = data.puffSize
		this.puffSizeRNGMod = data.puffSizeRNGMod
		this.puffRotationSpeed = data.puffRotationSpeed
		this.puffRotationSpeedRNGMod = data.puffRotationSpeedRNGMod
		this.puffMaxAlpha = data.puffMaxAlpha
		this.puffFadeTime = data.puffFadeTime
		this.puffImage = data.puffImage

		this.duration = data.smokeDuration
		this.needsRemoved = false

		this.collider = new SAT.Circle(
			new SAT.Vector(this.x, this.y),
			0
		)
	}

	update(delta) {
		if (this.duration <= 0) return

		this.duration -= delta
		if (this.duration <= 0) {
			this.needsRemoved = true
			return
		}
	}
}

SmokeCloud.protocol = {
	x: { type: nengi.UInt16, interp: true },
	y: { type: nengi.UInt16, interp: true },
	radius: nengi.Float32,
	expansionDuration: nengi.Float32,
	puffDuration: nengi.Float32,
	puffDurationRNGMod: nengi.Float32,
	puffFrequency: nengi.Float32,
	puffSize: nengi.Float32,
	puffSizeRNGMod: nengi.Float32,
	puffRotationSpeed: nengi.Float32,
	puffRotationSpeedRNGMod: nengi.Float32,
	puffMaxAlpha: nengi.Float32,
	puffFadeTime: nengi.Float32,
	puffImage: nengi.String,
	ownerId: nengi.UInt16
}

export default SmokeCloud
