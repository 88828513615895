export default {
	name: "Railgun",
	programmaticName: "railgun",
	canonicalName: "HV_RAILGUN",
	itemClass: "weapon",
	itemSubclass: "heavy_weapon",
	image: "railgun_default.png",
	heldImage: "railgun_hands.png",
	outlineImage: "railgun_outline.png",
	bulletImage: "railgun_projectile.png",
	customBulletScale: 0.6,
	dontScaleBulletOverTime: true,
	bulletLifeAfterHit: 2000,
	soundName: "SNIPER_RIFLE",
	customProjectilePivot: {x: 0.6, y: 0.5},
	reloadSoundName: "SNIPER_RIFLE_reloading",
	spawnWeighting: 10,
	grenadeProjectiles: false,
	damage: 110,
	cooldown: 1.7,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 1,
	recoilRecoveryPeriod: 0.1,
	recoilMax: 1,
	clipSize: 3,
	kick: 160,
	moveSlowModifier: 0.4,
	moveSlowDuration: 0.8,
	barrelLength: 32,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 400,
	corpseForce: 250,
	reloadable: false,
	instantSpeedProjectile: true,
	pinsTarget: true,
	hasVisibleProjectile: true,
	visibleProjectileAnchor: {x: 0.1, y: 0.45},
	uiRotateDeg: -41,
	uiWidth: 96,
	uiTop: 41,
	uiLeft: 55
}
