import nengi from "nengi"

class ServerMessage {
	constructor(msg) {
		this.msg = msg
	}
}

ServerMessage.protocol = {
	msg: nengi.String
}

export default ServerMessage
