import GAME_CONSTANTS from "../../common/balance/gameConstants"
import { Container, Graphics, Sprite } from "pixi.js-legacy"
import Weapon from "../../common/weapon/Weapon"
import Emitters from "../visuals/allEmitters"
import allEmitters from "../visuals/allEmitters"


class Grenade extends Container {
	constructor(entity) {
		super()
		this.id = entity.id
		this.x = entity.x
		this.y = entity.y
		this.rotation = entity.rotation
		this.h = entity.h
		this.explosionRadius = entity.explosionRadius
		this.image = entity.image
		this.thrownEffect = entity.thrownEffect

		if (GAME_CONSTANTS.DEBUG.RENDER_HITBOXES) {
			let circleGraphics = new Graphics()
			circleGraphics.beginFill(0xff0000)
			circleGraphics.drawCircle(0, 0, GAME_CONSTANTS.GRENADE_RADIUS)
			circleGraphics.endFill()
			this.addChild(circleGraphics)
		}

		if (GAME_CONSTANTS.DEBUG.RENDER_GRENADE_RADIUS) {
			let circleGraphics = new Graphics()
			circleGraphics.beginFill(0xff0000, 0.25)
			circleGraphics.drawCircle(0, 0, this.explosionRadius)
			circleGraphics.endFill()
			this.addChild(circleGraphics)
		}

		this.shadowGraphics = new Sprite.from("white_circle_64x64.png")
		this.shadowGraphics.scale.x = GAME_CONSTANTS.GRENADE_RADIUS / 64
		this.shadowGraphics.scale.y = GAME_CONSTANTS.GRENADE_RADIUS / 64
		this.shadowGraphics.anchor.set(0.5, 0.5)
		this.shadowGraphics.tint = 0x000000
		this.shadowGraphics.alpha = 0.4
		this.addChild(this.shadowGraphics)

		this.grenadeSprite = new Sprite.from(this.image)
		this.grenadeSprite.anchor.set(0.5, 0.5)

		this.addChild(this.grenadeSprite)
	}

	addThrownEffect(renderer) {
		let result = allEmitters.find(emitter => this.thrownEffect === emitter.name)
		let offset = {
			x: this.x,
			y: this.y,
			rotation: this.rotation
		}
		if(result) {
			this.thrownEmitter = renderer.createEmitter(0, -5, result, offset, true)	
			this.addChild(this.thrownEmitter)
		}

	}

	removeThrownEffect() {
		if(this.thrownEmitter) {
			this.thrownEmitter.die()
		}
	}

	update() {
		let scale = GAME_CONSTANTS.GRENADE_BASE_SCALE + (this.h * GAME_CONSTANTS.GRENADE_SCALE_H_MOD)
		this.grenadeSprite.scale.set(scale, scale)
		if(this.thrownEmitter) {
			this.thrownEmitter.parentOffset = {
				x: this.x,
				y: this.y,
				rotation: this.rotation
			}
		}
		this.shadowGraphics.x = Math.sin(this.transform._rotation) * (GAME_CONSTANTS.GRENADE_BASE_SHADOW + (this.h * GAME_CONSTANTS.GRENADE_SHADOW_H_MOD))
		this.shadowGraphics.y = Math.cos(-this.transform._rotation) * (GAME_CONSTANTS.GRENADE_BASE_SHADOW + (this.h * GAME_CONSTANTS.GRENADE_SHADOW_H_MOD))
	}
}

export default Grenade
