<template>
	<div class="kills-and-remaining">
		<div class="kills">
			<img class="icon" src="../../creative/spritesheetsourceimages/kills_icon.png" />
			{{ kills }} Kills
		</div>
		<div class="players-alive">
			<img class="icon" src="../../creative/spritesheetsourceimages/alive_icon.png" />
			{{ playersAlive }} Alive
		</div>
		<div class="average-ping">
			<img src="../assets/ping.png" class="icon">
			<span class="ping-text" v-bind:class="{'bad': averagePing >= 100 }">{{ averagePing }} ping</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	computed: {
		...mapGetters(["playersAlive", "kills", "averagePing"]),
	}
}
</script>

<style lang="scss" scoped>
.kills-and-remaining {
	position: relative;
	display: flex;
}
.kills,.players-alive {
	color: white;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.players-alive {
	margin-left: 6px;
}
.icon {
	width: 24px;
	height: 24px;
	margin-bottom: -7px;
}

.average-ping {
	margin-left: 5px;
}

.average-ping img {
	width: 26px;
	height: 22px;
}

.ping-text {
	color: white;

	&.bad {
		color: red;
	}
}
</style>
