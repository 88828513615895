import Grid from "../../common/classes/Grid"
import { Rectangle, Texture } from "pixi.js-legacy"
import imageUrl from "../../common/map/tilesets/tileset.png"

let middleware = function(resource, next) {
	if (
		!resource ||
    !resource.data ||
    !resource.data.tilesets ||
    !resource.data.layers
	) {
		return next()
	}

	resource.data.tilesets[0].image = imageUrl

	let loadOptions = {
		crossOrigin: resource.crossOrigin,
		// loadType: resource.LOAD_TYPE.IMAGE,
		parentResource: resource
	}

	// copy tile layers from Tiled json to Grid objects
	resource.tileLayers = {}
	resource.data.layers.forEach(layer => {
		if (layer.type !== "tilelayer") {
			return
		}
		let grid = new Grid(layer.width, layer.height)
		for (let i = 0; i < layer.data.length; i++) {
			grid.cells.push(layer.data[i])
		}
		resource.tileLayers[layer.name] = grid
	})

	// load tilesheet textures from Tiled
	resource.tileTextures = {}
	resource.data.tilesets.forEach(tileset => {
		this.add(tileset.image, loadOptions, function(subResource) {
			let baseTexture = Texture.from(subResource.name)
			let i = tileset.firstgid
			for (
				let y = tileset.margin;
				y < tileset.imageheight;
				y += tileset.tileheight + tileset.spacing
			) {
				for (
					let x = tileset.margin;
					x < tileset.imagewidth;
					x += tileset.tilewidth + tileset.spacing
				) {
					let rect = new Rectangle(
						x,
						y,
						tileset.tilewidth,
						tileset.tileheight
					)
					resource.tileTextures[i] = new Texture(baseTexture, rect)
					i++
				}
			}
		})
	})

	next()
}

export default middleware
