import parseTiledMapForObjects from "./parseTiledMapForObjects"

const TILE_IDS = {
	// the id of the little wizard used to mark player spawns
	6: "playerSpawn" // yea this didnt really need a type unless...there are different types of player spawns later
}

export default tiledMap => {
	return parseTiledMapForObjects(tiledMap, "PlayerSpawns", TILE_IDS)
}
