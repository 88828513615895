

class ParticleAttribute {

    constructor (attributeBlueprint) {
        this.attType = attributeBlueprint.type
    }

    reset() {
        
    }

    apply(particle, delta) {
    }

    die(particle) {
        
    }

    particleSpawned(particle) {

    }

    parseRandom(min, max) {
        return min + (Math.random() * (max - min))
    }

}

export default ParticleAttribute