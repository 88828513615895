import _ from "lodash"

/**
 * USAGE
 *
 * as an enum...
 * Weapon.PISTOL returns
 * { name: 'Pistol',
 *   image: 'pistol.png',
 *   cooldown: 0.3,
 *   zoom: 0.25,
 *   barrelLength: 14,
 *   bulletTravelDistance: 150,
 *   canonicalName: 'PISTOL',
 *   index: 0 }
 *
 * Array of weapon types:
 * Weapon.arr.forEach(weapon => {} ) where weapon is data such as PISTOL above
 *
 * Sending data over the network
 * message.weaponType = Weapon.PISTOL.index aka 0
 *
 * Receiving data from the network
 * Weapon.byIndex(0) returns the same thing as Weapon.PISTOL
 *
 */

let obj = {
	index: {},
	arr: []
}

import weaponData from "./allWeapons"

for (var i = 0; i < weaponData.length; i++) {
	let weaponDatum = weaponData[i]
	let canonicalName = weaponDatum.canonicalName

	let data = Object.assign({}, weaponDatum)
	data.canonicalName = canonicalName
	data.index = i
	Object.freeze(data)

	obj[canonicalName] = data
	obj.index[i] = data
	obj.arr.push(data)
}

obj.byIndex = function(index) {
	return obj.index[index]
}

obj.byName = function(name) {
	return _.find(obj.arr, { canonicalName: name })
}

obj.byProgrammaticName = function(name) {
	return _.find(obj.arr, { programmaticName: name })
}

obj.maxIndex = obj.arr.length - 1

export default obj
