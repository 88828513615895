import GAME_CONSTANTS from "../../common/balance/gameConstants"
const { GAME_WIDTH, GAME_HEIGHT } = GAME_CONSTANTS.RENDERER

// a weight of how much the view centers on the scoped position vs the player
const SCOPE_WEIGHT = 0.5

function calculateCameraTarget(renderer, entity) {
	const halfCanvasWidth = GAME_WIDTH * 0.5
	const halfCanvasHeight = GAME_HEIGHT * 0.5

	// TODO: cache renderer on window resize
	const windowWidth = window.innerWidth
	const windowHeight = window.innerHeight

	// how off center the mouse is
	const dx = renderer.input.currentState.mx - windowWidth * 0.5
	const dy = renderer.input.currentState.my - windowHeight * 0.5

	// length of the mouse's vector, from center
	const vlen = Math.sqrt(dx * dx + dy * dy)
	// maxmimum length of the vector if it went to the screen edge
	const mlen = Math.sqrt(
		windowWidth * 0.5 * windowWidth * 0.5 +
		windowHeight * 0.5 * windowHeight * 0.5
	)
	// unit vector (direction that the vector points, without magnitude)
	const normX = dx / vlen
	const normY = dy / vlen
	// how much the player is trying to scope, from 0 - 1.0 based on how far away the mouse is from the character
	const scopeAmount = vlen / mlen

	// new camera position (the math says center the camera on the player, then offset in the scope direction+amount, then offset it by the kickAmount)
	const targetX = -entity.x * renderer.masterScale + halfCanvasWidth
		+ -normX * scopeAmount * SCOPE_WEIGHT * halfCanvasWidth
		+ renderer.kickAmount * normX

	const targetY = -entity.y * renderer.masterScale + halfCanvasHeight
		+ -normY * scopeAmount * SCOPE_WEIGHT * halfCanvasHeight
		+ renderer.kickAmount * normY

	return { targetX, targetY }
}

export function centerCameraJuicy(renderer, entity, delta) {
	const { targetX, targetY } = calculateCameraTarget(renderer, entity)
	const dx0 = targetX - renderer.camera.x
	const dy0 = targetY - renderer.camera.y

	const adjustmentStrength = 20
	renderer.camera.x = Math.round(renderer.camera.x + dx0 * adjustmentStrength * delta)
	renderer.camera.y = Math.round(renderer.camera.y + dy0 * adjustmentStrength * delta)
}

export function centerCameraImmediately(renderer, entity) {
	const { targetX, targetY } = calculateCameraTarget(renderer, entity)

	renderer.camera.x = targetX
	renderer.camera.y = targetY
}
