<template>
	<div class="minimap">
		<div class="text">toggle full map [M]</div>
		<Map></Map>
	</div>
</template>

<script>
import Map from './Map'
export default {
	name: "Minimap",
	components: {
		Map
	}
}
</script>

<style lang="scss" scoped>
.minimap {
	position: fixed;
	right: 16px;
	top: 24px;
	width: 200px;
	height: 200px;

	.text {
		text-align: right;
		color: rgb(233, 233, 233);
		padding-bottom: 4px;
	}
}
</style>
