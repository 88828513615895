import { Graphics } from "pixi.js-legacy"

export default function drawDebugEntityHit(renderer, entity) {
	const graphics = new Graphics()
	graphics.lineStyle(1, 0xff0000)
	graphics.drawCircle(entity.x, entity.y, 12)
	renderer.middleground.addChild(graphics)
	setTimeout(() => {
		renderer.middleground.removeChild(graphics)
		graphics.destroy({
			children: true,
			texture: true,
			baseTexture: true
		})
	}, 64)
}
