<template>
	<button class="button" :disabled="disabled" v-bind:class="{ active: isActive }" @click="handleClick()">
		<slot name="body"></slot>
	</button>
</template>

<script>
export default {
	name: "Button",
	props: ["is-active", "disabled"],
	methods: {
		handleClick() {
			this.$emit('click')
		}
	}
}
</script>

<style lang="scss" scoped>
.button {
	color: white;
	font-size: 130%;
	font-weight: 500;
	padding: 8px;
	cursor: pointer;
	pointer-events: all;
	image-rendering: auto;

	&.small {
		font-size: 100%;
	}

	&.large {
		font-size: 150%;
	}

	&.slim {
		padding: 3px;
	}

	background-color: #382f34;
	border-image: url(./assets/bruh-button.png);
	border-image-slice: 4 fill;
	border-image-width: 12px;

	&:hover,
	&.active {
		background-color: #7a7f85;
		border-image: url(./assets/bruh-button-hover.png);
		border-image-slice: 4 fill;
		border-image-width: 12px;
	}

	&.green {
		background-color: #26821f;
		border-image: url(./assets/bruh-green-button.png);
		border-image-slice: 4 fill;
		border-image-width: 12px;

		&:hover,
		&.active {
			background-color: #00ab00;
			border-image: url(./assets/bruh-green-button-hover.png);
			border-image-slice: 4 fill;
			border-image-width: 12px;
		}
	}
}
</style>
