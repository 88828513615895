import nengi from "nengi"

class PlayerSubmitChatMessage {
	constructor(m) {
		this.m = m
	}
}

PlayerSubmitChatMessage.protocol = {
	m: nengi.String
}

export default PlayerSubmitChatMessage
