<template>
	<div class="item-slot" :class="{
		active: isActive,
		small: size == 'small',
		faded: !hasItem
		}">
		<div class="item">
			<slot name="image">
				<img class="item-image" v-if="itemImageData" :style="{
					top: `${itemImageData.top}px`,
					left: `${itemImageData.left}px`,
					transform: `rotate(${itemImageData.rot}deg) scale(${itemImageData.scale})`,
					}" :src="itemImageData.imgSrc" />
			</slot>
		</div>
		<div class="quantity" v-if="quantity !== -1">{{ quantity }}</div>
		<div class="label" v-if="label">{{ label }}</div>
	</div>

</template>

<script>
export default {
	name: 'ItemSlot',
	props: {
		label: String,
		size: String,
		hasItem: Boolean,
		isActive: Boolean,
		quantity: {
			type: Number,
			default: -1,
		},
		// you can exclude this, and instead pass an img in with the "image" slot
		itemImageData: {
			type: Object,
			default: null
		},
	},
	mounted: function() {
		// console.log('label: ', this.label)
	}
}
</script>

<style lang="scss" scoped>
.item-slot {
	margin: 7px;
	position: relative;

	&.small {
		.item {
			width: 54px;
			height: 54px;

		}

		.quantity {
			top: 30px;
			left: 38px;
			font-size: 18px;
		}

		.label {

		}
	}

	&.faded {
		.item {
			opacity: 0.5;
		}

		.item-image {
			opacity: 0.5;
		}
	}

	.active, &.active {
		transform: scale(1.08);

		.item {
			background-color: #f3c114;
			box-shadow: 0 0 0 3px #ffffff;
			border: solid 2px rgb(0, 0, 0);
		}

		.item-image {
			transform: translate(0px, -15px) rotate(-15deg);
		}
	}

	.item {
		position: relative;
		width: 71px;
		height: 71px;
		border: solid 3px #cacacc;
		box-shadow: 0 0 0 3px #3e3f2d;
		border-radius: 8px;
		color: white;
		background-color: #abaea5;
		transition: transform 0.25s ease-in-out;

	}

	.quantity {
		position: absolute;
		top: 40px;
		left: 48px;
		font-size: 22px;
		color: white;
		text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	}

	.item-image {
		position: relative;
		image-rendering: pixelated;
		image-rendering: -moz-crisp-edges;
		image-rendering: crisp-edges;
	}


	.label {
		font-weight: bold;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		background-color: rgba(0, 0, 0, 0.35);
		width: 22px;
		border-radius: 4px;
		margin-top: 2px;
		color: white;
	}
}
</style>
