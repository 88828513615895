<template>
	<div class="progress-bar" :class="animationClass">
		<div class="icon">
			<slot name="icon"></slot>
		</div>
		<div class="bar" ref="bar">
			<div class="foreground" :style="{
				'width': `${barWidth}px`,
				'border': foregroundBorder,
				'background-color': foregroundColor
			}"></div>
			<div class="label" :style="{
				'color': labelColor || 'black'
			}">{{ labelText }}</div>
			<div class="background"></div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	props: [
		'animationClass',
		'minValue',
		'maxValue',
		'currentValue',
		'foregroundColor',
		'backgroundColor',
		'borderColor',
		'labelText',
		'labelColor',
	],
	computed: {
		barMaxWidth: function() {
			if(!this.refsAreMounted) {
				return 100
			}
			return this.$refs.bar.clientWidth
		},
		barWidth: function() {
			return this.percentValue * this.barMaxWidth
		},
		percentValue: function() {
			return this.currentValue / (this.maxValue - this.minValue)
		},
		foregroundBorder: function() {
			return (this.currentValue > this.minValue) ? `2px solid ${this.borderColor}` : '0'
		}
	},
	data() {
		return {
			refsAreMounted: false
		}
	},
	mounted() {
		this.refsAreMounted = true
	},
}
</script>

<style lang="scss" scoped>
.progress-bar {
    display: flex;
    padding: 2px;
	width: 100%;

	.icon {
		padding-right: 4px;
		img {
			position: relative;
			image-rendering: pixelated;
			image-rendering: -moz-crisp-edges;
			image-rendering: crisp-edges;
			width: 24px;
		}
	}

	.bar {
		width: 100%;

		.foreground {
			position: absolute;
			width: 75%;
			height: 22px;
			background-color: #105870;
			border: 0;

			transition: 1.0s ease-in-out;
			transition-property: width;
		}

		.background {
			width: 100%;
			height: 22px;
			background-color: rgba(26, 26, 26, 0.8);
			border: 2px solid rgba(26, 26, 26, 0.8);
		}

		.label {
			position: absolute;
			height: 24px;
			line-height: 24px;
			padding-left: 6px;
		}
	}

	&.slow-animation {
		.bar {
			.foreground {
				transition: 1.5s ease-in-out;
				transition-property: width;
			}
		}
	}

	&.fast-animation {
		.bar {
			.foreground {
				transition: 0.1s linear;
				transition-property: width;
			}
		}
	}
}


</style>
