import nengi from "nengi"

class KillSpreeMessage {
	constructor(name, killSpree) {
		this.name = name
        this.killSpree = killSpree
		// this.variant = Math.floor(Math.random() * 30)
	}
}

KillSpreeMessage.protocol = {
	name: nengi.String,
    killSpree: nengi.Number,
	// variant: nengi.UInt8
}

export default KillSpreeMessage
