import nengi from "nengi"

class PartyIdentities {
	constructor(ids) {
		this.ids = ids
	}
}

PartyIdentities.protocol = {
	ids: { type: nengi.UInt16, indexType: nengi.UInt8 }
}

export default PartyIdentities
