export default {
	name: "Painkillers",
	programmaticName: "painkillers",
	canonicalName: "PAINKILLERS",
	prop: "painkillers",
	itemClass: "medical",
	itemSubclass: "heal_over_time",
	image: "painkillers.png",
	heldImage: "medical_hands.png",
	healthOverTime: 60,
	effectDuration: 30000,
	spawnWeighting: 150,
	carryLimit: 3,
	usageTime: 3.00,
	moveSlowDuration: 3.00,
	moveSlowModifier: 0.5
}
