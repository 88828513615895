import CorpseGraphics from "../entity/Corpse"

export default ({ renderer }) => {
	return {
		create: ({ data }) => {
			const entity = new CorpseGraphics(data)
			renderer.entities.set(entity.id, entity)
			renderer.middleground.addChild(entity)
			return entity
		},
		delete: ({ id, /* entity */ }) => {
			renderer.deleteEntity(id)
		},
		watch: {
		}
	}
}
