import p1911_img from "../../creative/spritesheetsourceimages/p1911_default.png"
import m16_img from "../../creative/spritesheetsourceimages/m16_default.png"
import sbs_img from "../../creative/spritesheetsourceimages/sbs_default.png"
import s686_img from "../../creative/spritesheetsourceimages/s686_default.png"
import glock_img from "../../creative/spritesheetsourceimages/glock_default.png"
import dbleagle_img from "../../creative/spritesheetsourceimages/dbleagle_default.png"
import dragunov_img from "../../creative/spritesheetsourceimages/dragunov_default.png"
import colt45_img from "../../creative/spritesheetsourceimages/colt45_default.png"
import mk2_img from "../../creative/spritesheetsourceimages/mk2_default.png"
import m249_img from "../../creative/spritesheetsourceimages/m249_default.png"
import ppbizon_img from "../../creative/spritesheetsourceimages/ppbizon_default.png"
import ump45_img from "../../creative/spritesheetsourceimages/ump45_default.png"
import qbz95_img from "../../creative/spritesheetsourceimages/qbz95_default.png"
import bmg_img from "../../creative/spritesheetsourceimages/bmg_default.png"
import ak47_img from "../../creative/spritesheetsourceimages/ak47_default.png"
import awm_img from "../../creative/spritesheetsourceimages/awm_default.png"
import railgun_img from "../../creative/spritesheetsourceimages/railgun_default.png"
import minigun_img from "../../creative/spritesheetsourceimages/minigun_default.png"
import glauncher_img from "../../creative/spritesheetsourceimages/grenadeLauncher_default.png"

import laser_img from "../../creative/spritesheetsourceimages/laser_pickup.png"
import scope_2x_img from "../../creative/spritesheetsourceimages/2x_scope.png"
import scope_4x_img from "../../creative/spritesheetsourceimages/4x_scope.png"
import scope_8x_img from "../../creative/spritesheetsourceimages/8x_scope.png"
import grenade_img from "../../creative/spritesheetsourceimages/grenade.png"
import grenade_with_pin_img from "../../creative/spritesheetsourceimages/grenade-with-pin.png"
import smoke_grenade_img from "../../creative/spritesheetsourceimages/smoke-grenade.png"
import smoke_grenade_with_pin_img from "../../creative/spritesheetsourceimages/smoke-grenade-with-pin.png"
import molotov_grenade_img from "../../creative/spritesheetsourceimages/molotov_grenade.png"

import bandage_img from "../../creative/spritesheetsourceimages/bandage.png"
import medpack_img from "../../creative/spritesheetsourceimages/medpack.png"
import painkillers_img from "../../creative/spritesheetsourceimages/painkillers.png"
import kevlar_img from "../../creative/spritesheetsourceimages/kevlar_drop.png"
import resurrection_grenade_img from "../../creative/spritesheetsourceimages/resurrection-grenade.png"

// apparently we can just do this going forward
//import images from '../../creative/spritesheetsourceimages/*.png'


import Weapon from "../../common/weapon/Weapon"
import railgun from "../../common/balance/weapons/railgun"

// placeholder, Sam is going to rescue me with a spritesheet implementation
const lookup = {
	[Weapon.byName("PISTOL_P1911").programmaticName]: {
		imgSrc: p1911_img,
		rot: -21,
		scale: 2.5,
		left: 9,
		top: 4
	},
	[Weapon.byName("AR_M16").programmaticName]: {
		imgSrc: m16_img,
		rot: -39,
		scale: 1.6,
		left: 3,
		top: 10
	},
	[Weapon.byName("SG_SBS").programmaticName]: {
		imgSrc: sbs_img,
		rot: -33,
		scale: 2.2,
		left: -7,
		top: 12
	},
	[Weapon.byName("SG_S686").programmaticName]: {
		imgSrc: s686_img,
		rot: -40,
		scale: 1.7,
		left: 2,
		top: 12
	},
	[Weapon.byName("PISTOL_GLOCK").programmaticName]: {
		imgSrc: glock_img,
		rot: -33,
		scale: 2.5,
		left: 9,
		top: 2
	},
	[Weapon.byName("PISTOL_SUPERSHOOTY").programmaticName]: {
		imgSrc: glock_img,
		rot: -33,
		scale: 2.5,
		left: 9,
		top: 2
	},
	[Weapon.byName("SG_DOUBLEEAGLE").programmaticName]: {
		imgSrc: dbleagle_img,
		rot: -40,
		scale: 1.7,
		left: 9,
		top: 6
	},
	[Weapon.byName("SR_DRAGUNOV").programmaticName]: {
		imgSrc: dragunov_img,
		rot: -40,
		scale: 1.5,
		left: 2,
		top: 14
	},
	[Weapon.byName("PISTOL_COLT45").programmaticName]: {
		imgSrc: colt45_img,
		rot: -33,
		scale: 2.5,
		left: 9,
		top: 2
	},
	[Weapon.byName("SMG_MK2").programmaticName]: {
		imgSrc: mk2_img,
		rot: -40,
		scale: 2,
		left: 3,
		top: 10
	},
	[Weapon.byName("LMG_M249").programmaticName]: {
		imgSrc: m249_img,
		rot: -40,
		scale: 1.6,
		left: 4,
		top: 12
	},
	[Weapon.byName("SMG_PPBIZON").programmaticName]: {
		imgSrc: ppbizon_img,
		rot: -40,
		scale: 2,
		left: 5,
		top: 8
	},
	[Weapon.byName("SMG_UMP45").programmaticName]: {
		imgSrc: ump45_img,
		rot: -40,
		scale: 2,
		left: 12,
		top: 7
	},
	[Weapon.byName("AR_QBZ95").programmaticName]: {
		imgSrc: qbz95_img,
		rot: -40,
		scale: 1.6,
		left: 8,
		top: 9
	},
	[Weapon.byName("SR_BMG").programmaticName]: {
		imgSrc: bmg_img,
		rot: -40,
		scale: 1.5,
		left: 3,
		top: 12
	},
	[Weapon.byName("AR_AK47").programmaticName]: {
		imgSrc: ak47_img,
		rot: -40,
		scale: 1.5,
		left: 7,
		top: 8
	},
	[Weapon.byName("SR_AWM").programmaticName]: {
		imgSrc: awm_img,
		rot: -40,
		scale: 1.6,
		left: 4,
		top: 12
	},
	[Weapon.byName("HV_RAILGUN").programmaticName]: {
		imgSrc: railgun_img,
		rot: -40,
		scale: 1.2,
		left: 0,
		top: 15
	},
	[Weapon.byName("HV_GRENADELAUNCHER").programmaticName]: {
		imgSrc: glauncher_img,
		rot: -40,
		scale: 1.2,
		left: 0,
		top: 15
	},
	[Weapon.byName("HV_MINIGUN").programmaticName]: {
		imgSrc: minigun_img,
		rot: -40,
		scale: 1.2,
		left: 0,
		top: 15
	},
	[Weapon.byName("REDDOT").programmaticName]: {
		imgSrc: laser_img,
		rot: 0,
		scale: 2.5,
		left: 15,
		top: 14
	},
	[Weapon.byName("TWO_X_SCOPE").programmaticName]: {
		imgSrc: scope_2x_img,
		rot: -20,
		scale: 1.4,
		left: 0,
		top: 14
	},
	[Weapon.byName("FOUR_X_SCOPE").programmaticName]: {
		imgSrc: scope_4x_img,
		rot: -20,
		scale: 1.4,
		left: 2,
		top: 14
	},
	[Weapon.byName("EIGHT_X_SCOPE").programmaticName]: {
		imgSrc: scope_8x_img,
		rot: -20,
		scale: 1.4,
		left: 4,
		top: 12
	},

	[Weapon.byName("GRENADE_FragGrenade").programmaticName]: {
		imgSrc: grenade_with_pin_img,
		rot: 0,
		scale: 1.8,
		left: 8,
		top: 9
	},
	[Weapon.byName("GRENADE_SmokeGrenade").programmaticName]: {
		imgSrc: smoke_grenade_with_pin_img,
		rot: 0,
		scale: 1.8,
		left: 5,
		top: 7
	},
	[Weapon.byName("GRENADE_ResGrenade").programmaticName]: {
		imgSrc: resurrection_grenade_img,
		rot: 0,
		scale: 1.8,
		left: 5,
		top: 7
	},
	[Weapon.byName("GRENADE_Molotov").programmaticName]: {
		imgSrc: molotov_grenade_img,
		rot: 0,
		scale: 1.8,
		left: 8,
		top: 9
	},

	[Weapon.byName("BANDAGE").programmaticName]: {
		imgSrc: bandage_img,
		rot: 0,
		scale: 1.8,
		left: 5,
		top: 7
	},

	[Weapon.byName("MEDPACK").programmaticName]: {
		imgSrc: medpack_img,
		rot: 0,
		scale: 1.8,
		left: 5,
		top: 7
	},

	[Weapon.byName("PAINKILLERS").programmaticName]: {
		imgSrc: painkillers_img,
		rot: 0,
		scale: 1.8,
		left: 5,
		top: 7
	},

	[Weapon.byName("KEVLAR").programmaticName]: {
		imgSrc: kevlar_img,
		rot: 0,
		scale: 1.8,
		left: 5,
		top: 7
	},
}

export default programmaticName => {
	return lookup[programmaticName]
}
