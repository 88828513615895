import nengi from "nengi"

class UpdateBattlepassProgression {
	constructor(level, experience, experiencePercentTilNextLevel, experienceEarnedNow, levelsGained) {
		this.level = level
		this.experience = experience
		this.experiencePercentTilNextLevel = experiencePercentTilNextLevel
		this.experienceEarnedNow = experienceEarnedNow
		this.levelsGained = levelsGained
	}
}

UpdateBattlepassProgression.protocol = {
	placement: nengi.Int8,
	level: nengi.Int8,
	experience: nengi.Int32,
	experiencePercentTilNextLevel: nengi.Int32,
	experienceEarnedNow: nengi.Int32,
	levelsGained: nengi.Int8
}

export default UpdateBattlepassProgression
