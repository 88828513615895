class Scoreboard {
	constructor() {
		this.stats = new Map()
		this.sorted = new Array(10)
		this.dirty = true
	}

	addPlayer(id, name, isGhost, isVerified, isDeveloper) {
		this.stats.set(id, {
			name,
			id,
			isGhost,
			isDeveloper,
			isVerified,
			kills: 0,
			wins: 0,
		})
		this.dirty = true
	}

	removePlayer(id) {
		this.stats.delete(id)
		this.dirty = true
	}

	addKill(id) {
		if (this.stats.has(id)) {
			this.stats.get(id).kills++
			this.dirty = true
		}
	}

	addWin(id) {
		if (this.stats.has(id)) {
			this.stats.get(id).wins++
			this.dirty = true
		}
	}

	ghostify(id) {
		if (this.stats.has(id)) {
			this.stats.get(id).isGhost = true
		}
		this.dirty = true
	}

	resurrect(id) {
		if (this.stats.has(id)) {
			this.stats.get(id).isGhost = false
		}
		this.dirty = true
	}

	toJSON() {
		if (this.dirty) {
			this.sorted = Array.from(this.stats.values())
				.sort((a, b) => a.kills == b.kills ? (a.wins == b.wins ? 0 : (a.wins < b.wins ? 1 : -1)) : (a.kills < b.kills ? 1 : -1))
				.slice(0, 10)
			this.dirty = false
		}
		return JSON.stringify(this.sorted)
	}
}

const singleton = new Scoreboard()
export default singleton
