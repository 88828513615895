import { Graphics } from "pixi.js-legacy"

export default function drawDebugWeaponFired(renderer, message) {
	const graphics = new Graphics()
	graphics.lineStyle(1, 0xff0000)
	graphics.moveTo(message.x, message.y)
	graphics.lineTo(message.targetX, message.targetY)
	graphics.alpha = 0.5
	renderer.middleground.addChild(graphics)
	setTimeout(() => {
		renderer.middleground.removeChild(graphics)
		graphics.destroy({
			children: true,
			texture: true,
			baseTexture: true
		})
	}, 500)
}
