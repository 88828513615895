import dotenv from "dotenv"
import { some } from "lodash"
import GameClient from "./core/GameClient"
import {
	Loader,
	SCALE_MODES,
	Spritesheet,
	settings
} from "pixi.js-legacy"
import imageUrl from "./images/spritesheet_spine.png"
import atlasUrl from "./images/spritesheet_spine.atlas"
//import jsonUrl from "./images/spritesheet-pat.json"
import Vue from "vue"
import App from "./ui/App.vue"
import store from "./ui/store"
import spineLoaderMiddleware from "./visuals/spineLoaderMiddleware"
import { parse } from "query-string"

window.currentLocale = navigator.language.substring(0, 2)

dotenv.config()

let {
	identifier_type: identifierType,
	identifier,
	game_instance_address: gameInstanceAddress,
	party_id: partyId
} = parse(window.location.search)

if (some([identifier, identifierType, gameInstanceAddress], (v) => { return v === undefined })) {
	// boot them back to homepage if required params are not present
	// console.error('missing some required parameters!')
	if (process.env.NODE_ENV !== 'local') {
		window.location.replace(process.env.GAME_WEBSITE_URL)
	}
}

if (process.env.NODE_ENV !== 'local') {
	window.history.pushState({}, document.title, "/")
}

let prefixedGameInstanceAddress

if (gameInstanceAddress.indexOf('localhost') > -1 || gameInstanceAddress.indexOf('127.0.0.1') > -1) {
	prefixedGameInstanceAddress = `ws://${gameInstanceAddress}`
} else {
	prefixedGameInstanceAddress = `wss://${gameInstanceAddress}`
}

const clientConnectionParams = {
	version: process.env.GAME_VERSION,
	identifier,
	identifierType
}

if (partyId) {
	clientConnectionParams.partyId = partyId
}

if (process.env.NODE_ENV === "production") {
	window.history.replaceState({}, "", "/")
}

const start = function (instanceAddress, connectionParams) {
	window.gameStarted = true
	window.PIXILoader = new Loader()
	document.querySelector("body").style.background = "#000"
	settings.SCALE_MODE = SCALE_MODES.NEAREST
	window.PIXILoader.add("spritesheetImage", imageUrl)
	//window.PIXILoader.add("spritesheetJson", jsonUrl)
	window.PIXILoader.add("atlasData", atlasUrl)
	window.PIXILoader.use(spineLoaderMiddleware)
	window.PIXILoader.load(function (loader, resources) {
		const texture = resources.spritesheetImage.texture.baseTexture
		//console.log(resources)
		const jsonData = resources.atlasData.parsedSpineSpritesheet
		//const atlasData = resources.atlasData.data
		const sheet = new Spritesheet(texture, jsonData)
		sheet.parse(() => { })
		const game = new GameClient(instanceAddress, connectionParams)
		let prev = performance.now()
		const loop = function () {
			window.requestAnimationFrame(loop)
			const now = performance.now()
			const delta = (now - prev) / 1000
			prev = now
			game.update(delta)
		}
		loop()
	})
}

new Vue({
	el: "#game-ui",
	store,
	render: h => h(App)
})

if (identifierType === "registered") {
	store.dispatch("initClientHeartbeats", identifier)
}
store.commit("identifierUpdated", { identifier, identifierType })

start(prefixedGameInstanceAddress, clientConnectionParams)

// console.log({ga: window.ga, googleanalytics: window.googleanalytics})
// window.ga('set', 'page', 'test-page')
// window.ga('send', 'pageview')
// window.ga('event', 'test-event')

// console.log('send', window.ga.send)
// console.log('set', window.ga.set)
function retrieveGoogleAnalytics(ga) {
	if (ga && ga.getAll) {
		ga = ga.getAll()
		if (ga && ga.length) {
			ga = ga[0]
			return ga
		}
	}
}

function getValidGoogleAnalyticsObject(ga) {
	if (ga) {
		let gaset = ga.set
		ga.set = function () {
			// console.log('GA::set', arguments)
			return gaset.apply(ga, arguments)
		}
		let gasend = ga.send
		ga.send = function () {
			// console.log('GA::send', arguments)
			return gasend.apply(ga, arguments)
		}
		return ga
	} else {
		return createGoogleAnalyticsShim()
	}
}

function createGoogleAnalyticsShim() {
	return {
		set: function () {
			// console.log('shim GA::set', arguments)
		},
		send: function () {
			// console.log('shim GA::send', arguments)
		},
	}
}

setTimeout(function () {
	let ga = retrieveGoogleAnalytics(window.ga)
	window.googleanalytics = getValidGoogleAnalyticsObject(ga)
}, 2500)

