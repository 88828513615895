import parseTiledMapForObjects from "./parseTiledMapForObjects"
import GAME_CONSTANTS from "./../balance/gameConstants"

const TILE_IDS = {
	// sprite of a closed treasure chest with silver trim
	70: "weapon",
	// does not exist
	11997: "medpack"
}

export default tiledMap => {
	let itemSpawns = parseTiledMapForObjects(tiledMap, "ItemSpawns", TILE_IDS)
	itemSpawns.forEach(item => {
		item.y = item.y - GAME_CONSTANTS.TILE_SIZE
	})
	return itemSpawns
}
