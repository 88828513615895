import p1911 from "../balance/weapons/p1911"
import ak47 from "../balance/weapons/ak47"
import awm from "../balance/weapons/awm"
import bmg from "../balance/weapons/bmg"
import colt45 from "../balance/weapons/colt45"
import dbleagle from "../balance/weapons/dbleagle"
import dragunov from "../balance/weapons/dragunov"
import glock from "../balance/weapons/glock"
import m16 from "../balance/weapons/m16"
import m249 from "../balance/weapons/m249"
import medpack from "../balance/weapons/medpack"
import bandage from "../balance/weapons/bandage"
import painkillers from "../balance/weapons/painkillers"
import mk2 from "../balance/weapons/mk2"
import ppbizon from "../balance/weapons/ppbizon"
import qbz95 from "../balance/weapons/qbz95"
import kevlarVest from "../balance/weapons/kevlarVest"
import s686 from "../balance/weapons/s686"
import sbs from "../balance/weapons/sbs"
import redDot from "../balance/weapons/redDot"
import ump45 from "../balance/weapons/ump45"
import scope_two_x from "../balance/weapons/scope_two_x"
import scope_four_x from "../balance/weapons/scope_four_x"
import scope_eight_x from "../balance/weapons/scope_eight_x"
import supershooty from "../balance/weapons/supershooty"
import fragGrenade from "../balance/weapons/fragGrenade"
import railgun from "../balance/weapons/railgun"
import minigun from "../balance/weapons/minigun"
import grenadeLauncher from "../balance/weapons/grenadeLauncher"
import grenadeLauncherGrenade from "../balance/weapons/grenadeLauncherGrenade"
import smokeGrenade from "../balance/weapons/smokeGrenade"
import resGrenade from "../balance/weapons/resGrenade"
import molotov from "../balance/weapons/molotov"

export default [
	p1911,
	ak47,
	awm,
	bmg,
	colt45,
	dbleagle,
	dragunov,
	glock,
	m16,
	m249,
	medpack,
	bandage,
	painkillers,
	mk2,
	ppbizon,
	qbz95,
	kevlarVest,
	s686,
	sbs,
	redDot,
	ump45,
	scope_two_x,
	scope_four_x,
	scope_eight_x,
	supershooty,
	fragGrenade,
	railgun,
	minigun,
	grenadeLauncher,
	grenadeLauncherGrenade,
	smokeGrenade,
	resGrenade,
	molotov,
]
