<template>
	<div id="app">
		<div v-if="!disableHud">
			<MissionList></MissionList>
			<Minimap></Minimap>
			<WorldMap></WorldMap>
			<KillsAndRemaining></KillsAndRemaining>
			<Scoreboard></Scoreboard>
			<Party></Party>
			<Chat></Chat>
			<Self></Self>
			<Slots></Slots>
			<RoundSummary></RoundSummary>
		</div>
		
		<EscapeMenu></EscapeMenu>
	</div>
	
</template>

<script>
import { mapGetters } from "vuex"
import Slots from "./Slots.vue"
import Self from "./Self.vue"
import Party from "./Party.vue"
import Chat from "./Chat.vue"
import Disconnected from './Disconnected.vue'
import WorldMap from './WorldMap.vue'
import Minimap from './Minimap.vue'
import Scoreboard from './Scoreboard.vue'
import MissionList from './MissionList.vue'
import RoundSummary from './RoundSummary.vue'
import KillsAndRemaining from './KillsAndRemaining.vue'
import EscapeMenu from './EscapeMenu.vue'

export default {
	name: "app",
	components: {
		Slots,
		Self,
		Party,
		Chat,
		Disconnected,
		Minimap,
		WorldMap,
		Scoreboard,
		MissionList,
		RoundSummary,
		KillsAndRemaining,
		EscapeMenu,
	},
	computed: {
		...mapGetters(["pageTitle", "isConnected", "disableHud"]),
	},
	mounted: function() {
		document.title = this.pageTitle
	}
};
</script>

<style lang="scss">
@import "../assets/fonts.scss";

* {
	font-family: "Russo One", "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

#app {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	min-width: 924px;
	min-height: 718px;
	overflow: hidden;
	z-index: 99999;
	cursor: url('../assets/crosshair.png') 16 16, url('../assets/crosshair.cur'), auto;
	pointer-events: none;
	user-select: none;

	button {
		pointer-events: all;
	}

	a, input {
		pointer-events: all;
		user-select: auto;
	}
}
</style>
