export default {
	name: "SUPERSHOOTY 45",
	programmaticName: "supershooty",
	canonicalName: "PISTOL_SUPERSHOOTY",
	itemClass: "weapon",
	itemSubclass: "pistol",
	image: "glock_default.png",
	heldImage: "glock_hands.png",
	outlineImage: "glock_outline.png",
	soundName: "SNIPER_RIFLE",
	reloadSoundName: "SNIPER_RIFLE_reloading",
	spawnWeighting: 0,
	grenadeProjectiles: false,
	damage: 40,
	cooldown: 0.20,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 100,
	recoilRecoveryPeriod: 1.0,
	recoilMax: 0,
	clipSize: 45,
	kick: 20,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 14,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 600,
	corpseForce: 150,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 0.8,
	activeStart: 0.3,
	activeEnd: 0.7,
	uiRotateDeg: -25,
	uiWidth: 135,
	uiTop: 36,
	uiLeft: 58
}
