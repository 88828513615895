import Weapon from "../../common/weapon/Weapon"
import { Container, Sprite, Texture } from "pixi.js-legacy"
class MuzzleFlair extends Container {
	constructor(rot, ws, player, lifetime) {
		super()
		this.x = 0
		this.y = 0
		this.isComplete = false
		this.animationFrame = 1
		this.accumulator = 0
		this.muzzleSprite = new Sprite.from("muzzleflair001.png")
		//this.muzzleSprite.tint = 0x73d1fa
		let weapon = Weapon.byIndex(player.currentlyEquipped)
		this.muzzleSprite.x = weapon.barrelLength
		this.muzzleSprite.y = weapon.shiftProjectileY ? -weapon.shiftProjectileY : 0
		this.muzzleSprite.anchor.set(0, 0.5)
		this.lifetime = lifetime
		// this.muzzleSprite.scale.set(0.05, 0.05)
		this.addChild(this.muzzleSprite)
	}

	update(delta) {
		this.accumulator += delta

		if (this.accumulator > this.lifetime) {
			this.animationFrame++
			if (this.animationFrame === 4) {
				this.isComplete = true
			} else {
				this.muzzleSprite.texture = new Texture.from(
					"muzzleflair00" + this.animationFrame + ".png"
				)
			}
		}
	}
}

export default MuzzleFlair
