export default {
	name: "GL Grenade",
	programmaticName: "grenadeLauncherGrenade",
	canonicalName: "GRENADE_GrenadeLauncherGrenade",
	itemClass: "grenade",
	itemSubclass: "grenade",
	prop: "grenadeLauncherGrenades",
	image: "grenadeLauncher_projectile.png",
	thrownImage: "grenadeLauncher_projectile.png",
	outlineImage: "grenadeLauncher_outline.png",
	spawnWeighting: 0,
	throwMod: 0.9,
	rollMod: 0.1,
	minThrowForce: 100,
	maxThrowForce: 160,
	damageNear: 60,
	damageFar: 20,
	explosionRadius: 50,
	fuseTimer: 1.3,
	corpseForce: 150,
	carryLimit: 1,
	createSmoke: false,
	gunCooldown: 0.0
}
