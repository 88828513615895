import { Graphics } from "pixi.js-legacy"
import GAME_CONSTANTS from "../../common/balance/gameConstants"

const circles = new Map()

const drawPositions = (message, renderer) => {
	const positions = JSON.parse(message.json)

	const cacheIds = []
	positions.forEach(position => {
		const { id, x, y } = position
		let circle = circles.get(id)
		if (!circle) {
			// create new
			//console.log('creating new!', id, x, y)
			circle = new Graphics()
			circle.id = id
			circle.lineStyle(1, 0xff0000)
			circle.drawCircle(0, 0, GAME_CONSTANTS.PLAYER.COLLIDER_RADIUS)
			circle.endFill()
			circle.alpha = 0.8
			circles.set(id, circle)
			renderer.middleground.addChild(circle)
		}
		// update existing
		circle.x = x
		circle.y = y
		//console.log('update existing', id, x, y)
		cacheIds.push(id)
	})

	//console.log('cacheids', cacheIds)
	// remove any not mentioned this frame
	circles.forEach(circle => {
		if (cacheIds.indexOf(circle.id) === -1) {
			//console.log('removing circle', circle.id)
			renderer.middleground.removeChild(circle)
			circles.delete(circle.id)
		}
	})
}


export default drawPositions
