import nengi from "nengi"

class MapName {
	constructor(name) {
		this.name = name
	}
}

MapName.protocol = {
	name: nengi.UTF8String
}

export default MapName
