export default (client) => {

	client.onConnect(res => {
		client.emit('connected', res)
	})

	client.onClose(() => {
		client.emit('disconnected')
	})

	client.readNetworkAndEmit = () => {
		const network = client.readNetwork()

		// order matters: message events should be emited before entity events
		network.messages.forEach(message => {
			client.emit(`message::${message.protocol.name}`, message)
		})

		network.localMessages.forEach(localMessage => {
			client.emit(`message::${localMessage.protocol.name}`, localMessage)
		})

		network.entities.forEach(snapshot => {
			snapshot.createEntities.forEach(entity => {
				client.emit(`create::${entity.protocol.name}`, entity)
				client.emit(`create`, entity)
			})

			snapshot.updateEntities.forEach(update => {
				client.emit(`update`, update)
			})

			snapshot.deleteEntities.forEach(id => {
				client.emit(`delete`, id)
			})
		})

		network.predictionErrors.forEach(predictionErrorFrame => {
			client.emit(`predictionErrorFrame`, predictionErrorFrame)
		})
	}

	client.sims = new Map()
	client.entities = new Map()

	// gather constructors from nengiConfig
	const constructors = {}
	client.config.protocols.entities.forEach(ep => {
		constructors[ep[0]] = ep[1]
	})

	client.on('create', data => {
		// construct the entity (nengiConfig constructor)
		const name = data.protocol.name
		const constructor = constructors[name]
		if (!constructor) {
			console.error(`No constructor found for ${name}`)
		}
		const sim = new constructor(data)
		Object.assign(sim, data)
		client.sims.set(sim.id, sim)

		// construct the client entity (from factory)
		if (client.factory) {
			const factory = client.factory[name]
			if (factory) {
				const entity = factory.create({ data, sim })
				Object.assign(entity, data)

				// invoke watchers for the initial state
				if (factory.watch) {
					data.protocol.keys.forEach(prop => {
						if (factory.watch[prop]) {
							factory.watch[prop]({
								id: entity.id,
								value: data[prop],
								entity,
								sim
							})
						}
					})
				}

				client.entities.set(entity.id, entity)
			}
		}
	})

	client.on('update', update => {
		if (client.entityUpdateFilter(update)) {
			return
		}
		const sim = client.sims.get(update.id)
		if (sim) {
			sim[update.prop] = update.value
		} else {
			console.error('tried to update a sim that did not exist')
		}
		const entity = client.entities.get(update.id)
		if (entity) {
			entity[update.prop] = update.value

			const name = entity.protocol.name
			const factory = client.factory[name]

			if (factory.watch && factory.watch[update.prop]) {
				factory.watch[update.prop]({
					id: update.id,
					value: update.value,
					entity,
					sim
				})
			}

		} else {
			console.error('tried to update an entity that did not exist')
		}
	})


	client.on('delete', id => {
		const entity = client.entities.get(id)
		const name = entity.protocol.name
		const factory = client.factory[name]
		const sim = client.sims.get(id)

		if (client.sims.has(id)) {
			client.sims.delete(id)
		} else {
			console.error('tried to delete an entity that did not exist')
		}

		if (client.entities.has(id)) {
			client.entities.delete(id)
			factory.delete({ id, entity, sim })
		} else {
			console.error('tried to delete an entity that did not exist')
		}
	})
}
