export default {
	name: "AWM",
	programmaticName: "awm",
	canonicalName: "SR_AWM",
	itemClass: "weapon",
	itemSubclass: "sniper_rifle",
	image: "awm_default.png",
	heldImage: "awm_hands.png",
	outlineImage: "awm_outline.png",
	soundName: "SNIPER_RIFLE",
	reloadSoundName: "SNIPER_RIFLE_reloading",
	spawnWeighting: 60,
	grenadeProjectiles: false,
	damage: 45,
	cooldown: 1.2,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 100,
	recoilRecoveryPeriod: 0.1,
	recoilMax: 1,
	clipSize: 5,
	kick: 120,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 32,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 350,
	corpseForce: 150,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 2,
	activeStart: 0.62,
	activeEnd: 0.67,
	uiRotateDeg: -41,
	uiWidth: 96,
	uiTop: 41,
	uiLeft: 55
}
