export default {
	name: "Dragunov",
	programmaticName: "dragunov",
	canonicalName: "SR_DRAGUNOV",
	itemClass: "weapon",
	itemSubclass: "sniper_rifle",
	image: "dragunov_default.png",
	heldImage: "dragunov_hands.png",
	outlineImage: "dragunov_outline.png",
	soundName: "SNIPER_RIFLE",
	reloadSoundName: "SNIPER_RIFLE_reloading",
	spawnWeighting: 120,
	grenadeProjectiles: false,
	damage: 25,
	cooldown: 0.8,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 100,
	recoilRecoveryPeriod: 0.1,
	recoilMax: 1,
	clipSize: 10,
	kick: 90,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 32,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 350,
	corpseForce: 110,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 2.2,
	activeStart: 0.45,
	activeEnd: 0.5,
	uiRotateDeg: -37,
	uiWidth: 90,
	uiTop: 43,
	uiLeft: 51
}
