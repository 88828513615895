const switchBackToWeaponIfOutOfItem = (entity, item) => {
	if (entity[item] === 0) {
		entity.weaponSystem.backToWeapon()
	}
}

const useBandage = (entity) => {
	entity.bandages--
	switchBackToWeaponIfOutOfItem(entity, 'bandages')
}

const useMedpack = (entity) => {
	entity.medpacks--
	switchBackToWeaponIfOutOfItem(entity, 'medpacks')
}


const usePainkillers = (entity) => {
	entity.painkillers--
	switchBackToWeaponIfOutOfItem(entity, 'painkillers')
}

export {
	useBandage,
	useMedpack,
	usePainkillers
}
