export default {
	name: "M249",
	programmaticName: "m249",
	canonicalName: "LMG_M249",
	itemClass: "weapon",
	itemSubclass: "assault_rifle",
	image: "m249_default.png",
	heldImage: "m249_hands.png",
	outlineImage: "m249_outline.png",
	soundName: "MACHINE_GUN",
	reloadSoundName: "MACHINE_GUN_reloading",
	spawnWeighting: 50,
	grenadeProjectiles: false,
	damage: 5,
	cooldown: 0.08,
	escalatingCooldown: false,
	shotsUntilMaxRecoil: 10,
	recoilRecoveryPeriod: 1.5,
	recoilMax: 14,
	clipSize: 50,
	kick: 20,
	moveSlowModifier: 1.0,
	moveSlowDuration: 0.0,
	barrelLength: 27,
	laserSightColor: "0xff0000",
	bulletTravelDistance: 225,
	corpseForce: 50,
	reloadable: true,
	instantSpeedProjectile: false,
	reloadDuration: 2.5,
	activeStart: 0.7,
	activeEnd: 0.78,
	uiRotateDeg: -37,
	uiWidth: 90,
	uiTop: 43,
	uiLeft: 51
}
